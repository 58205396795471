export const GetDistance = (lat1, lon1, lat2, lon2) => {
    if ((lat1 === lat2) && (lon1 === lon2)) {
        return 0;
    } else {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        dist = dist * 1.609344;
        return dist;
    }
};

export const getHeading = (lat1, lon1, lat2, lon2) => {
    const toRadians = (degrees) => degrees * Math.PI / 180.0;
    const toDegrees = (radians) => radians * 180.0 / Math.PI;

    const dLon = toRadians(lon2 - lon1);
    const lat1Rad = toRadians(lat1);
    const lat2Rad = toRadians(lat2);

    const y = Math.sin(dLon) * Math.cos(lat2Rad);
    const x = Math.cos(lat1Rad) * Math.sin(lat2Rad) -
        Math.sin(lat1Rad) * Math.cos(lat2Rad) * Math.cos(dLon);

    let bearing = toDegrees(Math.atan2(y, x));
    bearing = (bearing + 360) % 360;  // Normalize to 0-360 degrees

    return bearing;
}

export const GetTripDistance = async (data) => {
    if (data) {
        const arr = Object.keys(data)
            .map(i => {
                data[i].id = i
                return data[i]
            });
        let distance = 0;

        let coords = [];
        try {

            if (arr.length == 0 || arr.length == 1) {
                distance = 0;
            } else if (arr.length == 2) {
                let distance1 = await GetDistance(arr[0].lat ? arr[0].lat : arr[0].latitude, arr[0].lng ? arr[0].lng : arr[0].longitude, arr[1].lat ? arr[1].lat : arr[1].latitude, arr[1].lng ? arr[1].lng : arr[1].longitude);
                distance = distance1;
                coords.push({latitude: arr[0].lat ? arr[0].lat : arr[0].latitude, longitude: arr[0].lng ? arr[0].lng : arr[0].longitude});
                coords.push({latitude: arr[1].lat ? arr[1].lat : arr[1].latitude, longitude: arr[1].lng ? arr[1].lng : arr[1].longitude});
            } else {
                for (let i = 0; i < (arr.length - 1); i++) {
                    if (arr[i].type == 'metter') {
                        distance += arr[i].distance;
                    }
                }
                coords.push({latitude: arr[arr.length - 1].lat ? arr[arr.length - 1].lat : arr[arr.length - 1].latitude, longitude: arr[arr.length - 1].lng ? arr[arr.length - 1].lng : arr[arr.length - 1].longitude});
                if (distance === 0) {
                    distance = await GetDistance(arr[0].lat ? arr[0].lat : arr[0].latitude, arr[0].lng ? arr[0].lng : arr[0].longitude, arr[1].lat ? arr[1].lat : arr[1].latitude, arr[1].lng ? arr[1].lng : arr[1].longitude);
                }
            }
        } catch (error) {
            console.log(error);
        }
        return {
            distance: distance,
            coords: arr
        };
    } else {
        return {
            distance: 0,
            coords: []
        };
    }
}
