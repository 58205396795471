import {firebase} from "../config/configureFirebase";
import {GetDistance} from "../other/GeoFunctions";
import {fetchAddressfromCoords} from '../other/GoogleAPIFunctions';
import {child, get, onValue, orderByKey, push, query, update, ref} from "firebase/database";
import store from "../store/store";
import {UPDATE_CONNECT_INFO} from "../store/types";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const checkConnection = () => {
    const {connectedRef} = firebase;
    onValue(connectedRef, (snapshot) => {
        if (snapshot.val() === true) {
            store.dispatch({
                type: UPDATE_CONNECT_INFO,
                payload: true
            });

        } else {
            store.dispatch({
                type: UPDATE_CONNECT_INFO,
                payload: false
            });
        }
    });
};
export const saveAddresses = async (booking, driverLocation) => {
    const {singleUserRef} = firebase;
    let latlng = driverLocation.lat + "," + driverLocation.lng;
    let address = await fetchAddressfromCoords(latlng);
    onValue(child(singleUserRef(booking.customer), "savedAddresses"), (savedAdd) => {
        if (savedAdd.val()) {
            let addresses = savedAdd.val();
            let didNotMatch = true;
            for (let key in addresses) {
                let entry = addresses[key];
                if (
                    GetDistance(
                        entry.lat,
                        entry.lng,
                        driverLocation.lat,
                        driverLocation.lng
                    ) < 0.1
                ) {
                    didNotMatch = false;
                    let count = entry.count ? entry.count + 1 : 1;
                    update(child(singleUserRef(booking.customer), "savedAddresses/" + key), {count: count});
                    break;
                }
            }
            if (didNotMatch) {
                push(child(singleUserRef(booking.customer), "savedAddresses"), {
                    description: address,
                    lat: booking.drop.lat,
                    lng: booking.drop.lng,
                    count: 1,
                });
            }
        } else {
            push(child(singleUserRef(booking.customer), "savedAddresses"), {
                description: address,
                lat: booking.drop.lat,
                lng: booking.drop.lng,
                count: 1,
            });
        }
    }, {onlyOnce: true});
    return address;
};

export const addActualsToBooking = async (booking, address = null, driverLocation = null) => {
    var settings = store.getState().settingsdata.settings;
    const end_time = new Date();
    const diff = (end_time.getTime() - parseFloat(booking.pickupTime)) / 1000;
    const totalTimeTaken = Math.abs(Math.round(diff));
    if (settings.prepaid) {
        booking.trip_end_time = end_time.getHours() + ":" + end_time.getMinutes() + ":" + end_time.getSeconds();
        booking.endTime = end_time.getTime();
        booking.total_trip_time = totalTimeTaken;
        return booking;
    } else {
        booking.trip_end_time = end_time.getHours() + ":" + end_time.getMinutes() + ":" + end_time.getSeconds();
        booking.endTime = end_time.getTime();
        booking.total_trip_time = totalTimeTaken;
        let trackingString = await AsyncStorage.getItem('tracking');
        let trackingJson = null;
        console.log("trackingString", trackingString);
        if (trackingString) {
            try {
                trackingJson = await JSON.parse(trackingString);
            } catch (e) {
                console.log("lỗi parse json", e);
            }
        }
        booking.coords = trackingJson;
        return booking;
    }
};


export const addActualsToBookingOnline = async (booking, address = null, driverLocation = null) => {
    const {settingsRef, trackingRef} = firebase;
    const settingsdata = await get(settingsRef);
    const settings = settingsdata.val();
    const end_time = new Date();
    const diff = (end_time.getTime() - parseFloat(booking.pickupTime)) / 1000;
    const totalTimeTaken = Math.abs(Math.round(diff));
    if (settings.prepaid) {
        booking.trip_end_time = end_time.getHours() + ":" + end_time.getMinutes() + ":" + end_time.getSeconds();
        booking.endTime = end_time.getTime();
        booking.total_trip_time = totalTimeTaken;
        return booking;
    } else {
        booking.trip_end_time = end_time.getHours() + ":" + end_time.getMinutes() + ":" + end_time.getSeconds();
        booking.endTime = end_time.getTime();
        booking.total_trip_time = totalTimeTaken;
        const trackingSnap = await get(query(trackingRef(booking.id), orderByKey()));
        const trackingVal = trackingSnap.val();
        booking.coords = Object.values(trackingVal);
        return booking;
    }

};

export const getListCoordBooking = async (bookingId) => {
    const {trackingRef} = firebase;
    const trackingSnap = await get(query(trackingRef(bookingId), orderByKey()));
    const trackingVal = trackingSnap.val();
    return Object.values(trackingVal);
}