import {EDIT_CATRUCDEM, FETCH_CATRUCDEM, FETCH_CATRUCDEM_DRIVER, FETCH_CATRUCDEM_DRIVER_FAILED, FETCH_CATRUCDEM_DRIVER_SUCCESS, FETCH_CATRUCDEM_FAILED, FETCH_CATRUCDEM_SUCCESS, FETCH_GROUP_DRIVERS_SUCCESS, SET_CATRUCDEM, STOP_FETCH_CATRUCDEM, UPDATE_CATRUCDEM_HISTORY, UPDATE_SESSION_TOTAL_LOAD} from "../store/types";
import {language} from 'config';
import {GetCurrentMiniTimeStamp} from "../other/DateFunctions";
import {firebase} from '../config/configureFirebase';
import {off, onValue, push, remove, set, update} from "firebase/database";
import store from "../store/store";
import {deleteLocal, saveDbFromFbToLocal} from "../other/StorageDbHelper";

export const fetchCaTrucDem = () => (dispatch) => {
    const {
        caTrucDemsRef
    } = firebase;

    dispatch({
        type: FETCH_CATRUCDEM,
        payload: null
    });

    onValue(caTrucDemsRef, snapshot => {
        if (snapshot.val()) {
            dispatch({
                type: FETCH_CATRUCDEM_SUCCESS,
                payload: snapshot.val()
            });
        } else {
            dispatch({
                type: FETCH_CATRUCDEM_FAILED,
                payload: language.no_ca_truc_dem
            });
        }
    });
};

export const stopFetchCaTrucDem = () => (dispatch) => {
    const {
        caTrucDemsRef
    } = firebase;

    dispatch({
        type: STOP_FETCH_CATRUCDEM,
        payload: null
    });

    off(caTrucDemsRef);
};


export const fetchDriverCaTrucDem = (date) => (dispatch) => {
    const {
        caTrucDemRef
    } = firebase;

    dispatch({
        type: FETCH_CATRUCDEM_DRIVER,
        payload: null
    });
    off(caTrucDemRef(date));
    onValue(caTrucDemRef(date), async snapshot => {
        if (snapshot.val()) {
            let catrucdem = snapshot.val();
            catrucdem.id = date;
            dispatch({
                type: FETCH_CATRUCDEM_DRIVER_SUCCESS,
                payload: catrucdem
            });
            const totalLoad = store.getState().sessiondata.totalLoadData ? parseInt(store.getState().sessiondata.totalLoadData) : 0;
            dispatch({
                type: UPDATE_SESSION_TOTAL_LOAD, payload: totalLoad + 1
            });

            await saveDbFromFbToLocal(FETCH_CATRUCDEM_DRIVER_SUCCESS, catrucdem);

        } else {
            dispatch({
                type: FETCH_CATRUCDEM_DRIVER_FAILED,
                payload: language.no_ca_truc_dem
            });
            await deleteLocal(FETCH_CATRUCDEM_DRIVER_SUCCESS);
        }
    }, error => {
        throw new error;
    });
};

export const editCaTrucDem = (catrucdems, method) => (dispatch) => {

    const {
        caTrucDemRef,
        caTrucDemsRef,
        catrucdemHistoryRef
    } = firebase;

    dispatch({
        type: EDIT_CATRUCDEM,
        payload: method
    });

    if (method === 'Add') {
        push(caTrucDemsRef, catrucdems).then((res) => {
            let key = res.key;
            if (catrucdems.history) {
                push(catrucdemHistoryRef(key), catrucdems.history);
            } else {
                push(catrucdemHistoryRef(key), {date_add: GetCurrentMiniTimeStamp(), content: "Khởi tạo ca trực đêm"});
            }
        });
    } else if (method === 'Delete') {
        remove(caTrucDemRef(catrucdems.id));
    } else if (method === 'Update') {
        update(caTrucDemRef(catrucdems.id), catrucdems);
        if (catrucdems.history) {
            push(catrucdemHistoryRef(catrucdems.id), catrucdems.history);
        }
    } else {
        set(caTrucDemRef(catrucdems.id), catrucdems);
    }
}

export const updateStatusHistory = (date, historyKey, data) => (dispatch) => {
    const {
        catrucdemHistoryUpdateRef,
        driverByPushTokenRef,
        singleUserRef
    } = firebase;

    dispatch({
        type: UPDATE_CATRUCDEM_HISTORY,
        payload: data
    });
    onValue(catrucdemHistoryUpdateRef(date, historyKey), snapshotHistory => {
        if (snapshotHistory.val()) {
            let dataH = snapshotHistory.val();
            if (data.status && data.status === 'approved' && dataH.from_driver_token) {
                onValue(driverByPushTokenRef(dataH.from_driver_token), snapshotFrom => {
                    if (snapshotFrom.val()) {
                        const data = snapshotFrom.val();
                        const arr = Object.keys(data)
                            .map(i => {
                                data[i].id = i;
                                let driverFrom = data[i];
                                if (driverFrom.queue == false && driverFrom.id) {
                                    update(singleUserRef(driverFrom.id), {driverActiveStatus: false});
                                }
                            });

                    }
                }, {onlyOnce: true});
            }
            if (data.status && data.status === 'approved' && dataH.to_driver_token) {
                onValue(driverByPushTokenRef(dataH.to_driver_token), snapshotFrom => {
                    if (snapshotFrom.val()) {
                        const data2 = snapshotFrom.val();
                        const arr2 = Object.keys(data2)
                            .map(i => {
                                data2[i].id = i;
                                let driverTo = data2[i];
                                if (driverTo.queue == false && driverTo.id) {
                                    update(singleUserRef(driverTo.id), {driverActiveStatus: false});
                                }
                            });

                    }
                }, {onlyOnce: true});
            }
            update(catrucdemHistoryUpdateRef(date, historyKey), data);
        }
    }, {onlyOnce: true});


}

export const setCaTrucDem = (date, catrucdem) => (dispatch) => {
    const {
        caTrucDemRef
    } = firebase;

    dispatch({
        type: SET_CATRUCDEM,
        payload: catrucdem
    });
    set(caTrucDemRef(date), catrucdem);
}

