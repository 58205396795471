import {ADD_REPORT_DRIVER, EDIT_REPORT_DRIVER, FETCH_REPORT_DRIVERS, FETCH_REPORT_DRIVERS_FAILED, FETCH_REPORT_DRIVERS_SUCCESS, STOP_FETCH_REPORT_DRIVERS} from "../store/types";
import {language} from 'config';
import {firebase} from '../config/configureFirebase';
import {limitToLast, off, onValue, push, query, set} from "firebase/database";

export const fetchReportDrivers = () => (dispatch) => {
    const {
        reportdriverRef
    } = firebase;

    dispatch({
        type: FETCH_REPORT_DRIVERS,
        payload: null,
    });
    off(query(reportdriverRef, limitToLast(500)));
    onValue(query(reportdriverRef, limitToLast(500)), (snapshot) => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const reports = Object.keys(data)
                .map((i) => {
                    data[i].id = i;
                    return data[i];
                });

            dispatch({
                type: FETCH_REPORT_DRIVERS_SUCCESS,
                payload: reports.reverse()
            });
        } else {
            dispatch({
                type: FETCH_REPORT_DRIVERS_FAILED,
                payload: language.report_driver_not_found,
            });
        }
    });
};

export const stopFetchReportDrivers = () => (dispatch) => {
    const {
        reportdriverRef
    } = firebase;

    dispatch({
        type: STOP_FETCH_REPORT_DRIVERS,
        payload: null,
    });
    off(reportdriverRef);
}


export const addReport = (report) => (dispatch) => {

    const {
        reportdriverRef,
    } = firebase;

    dispatch({
        type: ADD_REPORT_DRIVER,
        report_driver: report,
    });

    push(reportdriverRef, report);
};


export const editReportDriver = (reportDrivers, method) => (dispatch) => {
    const {
        reportdriverRef
    } = firebase;

    dispatch({
        type: EDIT_REPORT_DRIVER,
        payload: method
    });
    set(reportdriverRef, reportDrivers);
}

