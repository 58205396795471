import {FETCH_MARKETS, FETCH_MARKETS_FAILED, FETCH_MARKETS_SUCCESS, PUSH_MARKET, PUSH_MARKET_FAILED, PUSH_MARKET_SUCCESS, PUSH_MARKET_TRANSACTION, PUSH_MARKET_TRANSACTION_FAILED, PUSH_MARKET_TRANSACTION_SUCCESS} from "../store/types";
import {GetCurrentMiniTimeStamp, GetCurrentTimeStamp} from "../other/DateFunctions";
import store from "../store/store";
import {off, onValue, push, set} from "firebase/database";
import {firebase} from '../config/configureFirebase';
import {updateWalletBalance} from "./paymentactions";
import {pushPoint} from "./pointsactions";

export const fetchMarkets = () => (dispatch) => {
    const {
        marketsRef
    } = firebase;

    dispatch({
        type: FETCH_MARKETS,
        payload: null,
    });
    off(marketsRef());
    onValue(marketsRef(), (snapshot) => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data)
                .filter(i => data[i].status === 'processing')
                .map(i => {
                    data[i].id = i;
                    return data[i];
                });
            dispatch({
                type: FETCH_MARKETS_SUCCESS,
                payload: {
                    markets: arr
                }
            });
        } else {
            dispatch({
                type: FETCH_MARKETS_FAILED,
                payload: "Không tìm thấy dữ liệu giao dịch",
            });
        }
    });
};

export const pushMarket = (data) => (dispatch) => {
    const {
        marketsRef
    } = firebase;

    dispatch({
        type: PUSH_MARKET,
        payload: null,
    });

    push(marketsRef(), data)
        .then(() => {
            if (data.type == 'buy' && data.walletBalance) {
                let walletBalance = data.currentBalance - (data.basePrice * data.points);
                let details = {
                    type: 'BuyEtoMarket',
                    amount: data.basePrice * data.points,
                    date: GetCurrentMiniTimeStamp(),
                    txRef: GetCurrentMiniTimeStamp(),
                    transaction_id: GetCurrentMiniTimeStamp()
                }

                dispatch(updateWalletBalance(walletBalance, details));
            }
            if (data.type == 'sell' && data.walletBalance) {
                let dataPoint = {
                    point: data.points,
                    basePrice: data.basePrice,
                    status: 'success',
                    uid: data.uid,
                    note: 'Yêu cầu bán ETO trên sàn giao dịch',
                    date_add: GetCurrentTimeStamp(),
                    type: 'sell'
                }
                dispatch(pushPoint(data.uid, dataPoint));
            }
            dispatch({
                type: PUSH_MARKET_SUCCESS,
                payload: data,
            });
        })
        .catch(err => {
            dispatch({
                type: PUSH_MARKET_FAILED,
                payload: data,
            });
        });
};

export const pushMarketTransaction = (data) => (dispatch) => {
    const {
        historyMarketsRef,
        marketByIdRef,
        marketPointsByIdRef,
        marketStatusByIdRef,
        refApi
    } = firebase;

    dispatch({
        type: PUSH_MARKET_TRANSACTION,
        payload: null,
    });

    onValue(marketByIdRef(data.id), snapshot => {
        if (snapshot.val()) {
            let dataMarket = snapshot.val();
            let totalPoints = parseInt(dataMarket.points);

            if (dataMarket.total_transaction && dataMarket.total_transaction > 1) {
                dispatch({
                    type: PUSH_MARKET_TRANSACTION_FAILED,
                    payload: "Có giao dịch khác đang cùng thực hiện. Vui lòng đợi giao dịch trước đó thực hiện xong!",
                });
            } else {
                if (totalPoints < parseInt(data.points)) {
                    dispatch({
                        type: PUSH_MARKET_TRANSACTION_FAILED,
                        payload: "Số điểm giao dịch không thể vượt quá số điểm yêu cầu",
                    });
                } else {
                    if (dataMarket.type === 'sell') {
                        // Cộng ví tien cho nguoi ban
                        let driverActive = store.getState().usersdata.drivers;
                        let driverSell = driverActive.filter((d) => d.id === dataMarket.uid)[0];
                        let walletBalance = driverSell.walletBalance + (dataMarket.basePrice * data.points);

                        let details = {
                            type: 'SellEtoMarket',
                            amount: data.basePrice * data.points,
                            date: GetCurrentMiniTimeStamp(),
                            txRef: GetCurrentMiniTimeStamp(),
                            transaction_id: GetCurrentMiniTimeStamp()
                        }
                        dispatch(updateWalletBalance(walletBalance, details, dataMarket.uid));
                        // cong diem point cua nguoi mua
                        let dataPoint = {
                            point: data.points,
                            basePrice: data.basePrice,
                            status: 'success',
                            uid: data.uid,
                            note: 'Mua ETO trên Sàn #' + data.id,
                            date_add: GetCurrentTimeStamp(),
                            type: 'buy'
                        }
                        dispatch(pushPoint(data.uid, dataPoint, data.driver_code));

                        push(historyMarketsRef(data.id), data)
                            .then(() => {
                                if ((totalPoints - parseInt(data.points)) <= 0) {
                                    set(marketStatusByIdRef(data.id), "success");
                                }
                                set(marketPointsByIdRef(data.id), totalPoints - parseInt(data.points));
                                refApi.setTotalTransactionApi(data.id, 0);
                                dispatch({
                                    type: PUSH_MARKET_TRANSACTION_SUCCESS,
                                    payload: data,
                                });
                            })
                            .catch(err => {
                                dispatch({
                                    type: PUSH_MARKET_TRANSACTION_FAILED,
                                    payload: dataMarket,
                                });
                            });
                    } else {
                        let driverActive = store.getState().usersdata.drivers;
                        let driverBuy = driverActive.filter((d) => d.id === data.uid)[0];
                        // tru diem point cua nguoi ban
                        let dataPoint = {
                            point: data.points,
                            basePrice: data.basePrice,
                            status: 'success',
                            uid: data.uid,
                            driverCode: data.driver_code,
                            fromDriver: data.uid,
                            note: 'Bán ETO trên Sàn #' + data.id,
                            date_add: GetCurrentTimeStamp(),
                            type: 'sell'
                        }
                        dispatch(pushPoint(data.uid, dataPoint, data.driver_code));

                        // cong diem point cua nguoi mua
                        /*let dataPointBuy = {
                            point: data.points,
                            basePrice: data.basePrice,
                            status: 'success',
                            uid: dataMarket.uid,
                            driverCode: data.driver_code,
                            fromDriver: driverBuy.id,
                            note: 'Mua ETO trên Sàn #' + data.id,
                            date_add: GetCurrentTimeStamp(),
                            type: 'buy'
                        }
                        api.pushPoint(dataMarket.uid, dataPointBuy, data.driver_code);*/

                        // Cộng ví tien cho nguoi bán
                        let walletBalance = driverBuy.walletBalance - (dataMarket.basePrice * data.points);
                        let details = {
                            type: 'BuyEtoMarket',
                            amount: data.basePrice * data.points,
                            date: GetCurrentMiniTimeStamp(),
                            txRef: GetCurrentMiniTimeStamp(),
                            transaction_id: GetCurrentMiniTimeStamp()
                        }

                        dispatch(updateWalletBalance(walletBalance, details, data.uid));

                        push(historyMarketsRef(data.id), data)
                            .then(() => {
                                if ((totalPoints - parseInt(data.points)) <= 0) {
                                    set(marketStatusByIdRef(data.id), "success");
                                }
                                set(marketPointsByIdRef(data.id), totalPoints - parseInt(data.points));
                                refApi.setTotalTransactionApi(data.id, 0);
                                dispatch({
                                    type: PUSH_MARKET_TRANSACTION_SUCCESS,
                                    payload: dataMarket,
                                });
                            })
                            .catch(err => {
                                dispatch({
                                    type: PUSH_MARKET_TRANSACTION_FAILED,
                                    payload: dataMarket,
                                });
                            });

                    }
                }
            }
        }
    }, {onlyOnce: true});
};
