export const features = {
    AllowCriticalEditsAdmin: true,
    AllowCountrySelection: true,
    WebsitePagesEnabled: true,
    MobileLoginEnabled: true,
    FacebookLoginEnabled: false,
    AppleLoginEnabled: false,
    TouchLoginEnabled: false,
    GoogleLoginEnabled: false
};
