import {EDIT_MISTAKE, FETCH_DRIVER_MISTAKES, FETCH_DRIVER_MISTAKES_FAILED, FETCH_DRIVER_MISTAKES_SUCCESS, FETCH_MISTAKES, FETCH_MISTAKES_FAILED, FETCH_MISTAKES_SUCCESS, SEND_MISTAKE, SEND_MISTAKE_FAILED, SEND_MISTAKE_SUCCESS, STOP_FETCH_MISTAKES,} from "../store/types";

import {language} from 'config';
import store from "../store/store";
import {RequestPushMsg} from '../other/NotificationFunctions';
import {firebase} from '../config/configureFirebase';
import {off, onValue, push, remove, set, update} from "firebase/database";

export const fetchMistakes = () => (dispatch) => {

    const {
        mistakeRef
    } = firebase;

    dispatch({
        type: FETCH_MISTAKES,
        payload: null
    });
    off(mistakeRef);
    onValue(mistakeRef, snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data).map(i => {
                data[i].id = i
                return data[i]
            });
            dispatch({
                type: FETCH_MISTAKES_SUCCESS,
                payload: arr
            });
        } else {
            dispatch({
                type: FETCH_MISTAKES_FAILED,
                payload: "No data available."
            });
        }
    });
};

export const stopFetchMistakes = () => (dispatch) => {
    const {
        mistakeRef
    } = firebase;

    dispatch({
        type: STOP_FETCH_MISTAKES,
        payload: null
    });
    off(mistakeRef)
};


export const fetchDriverMistakes = (driverCode) => (dispatch) => {

    const {
        driverMistakeRef
    } = firebase;

    dispatch({
        type: FETCH_DRIVER_MISTAKES,
        payload: null
    });
    off(driverMistakeRef(driverCode));
    onValue(driverMistakeRef(driverCode), snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data).map(i => {
                data[i].id = i;
                return data[i];
            });

            dispatch({
                type: FETCH_DRIVER_MISTAKES_SUCCESS,
                payload: arr
            });
        } else {
            dispatch({
                type: FETCH_DRIVER_MISTAKES_FAILED,
                payload: "No data available."
            });
        }
    });
};

export const editMistakes = (mistakes, method) => (dispatch) => {

    const {
        mistakeRef,
        mistakeEditRef,
        mistakeHistoryRef
    } = firebase;

    dispatch({
        type: EDIT_MISTAKE,
        payload: {method, mistakes}
    });
    if (method === 'Add') {
        push(mistakeRef, mistakes).then((res) => {
            let key = res.key;
            if (mistakes.history) {
                push(mistakeHistoryRef(key), mistakes.history);
            } else {
                push(mistakeHistoryRef(key), {date_add: new Date().getTime(), content: "Khởi tạo vi phạm"});
            }
        });
    } else if (method === 'Delete') {
        remove(mistakeEditRef(mistakes.id));
    } else if (method === 'Update') {
        update(mistakeEditRef(mistakes.id), mistakes);
        if (mistakes.history) {
            push(mistakeHistoryRef(mistakes.id), mistakes.history);
        }
    } else {
        set(mistakeEditRef(mistakes.id), mistakes);
    }
}

export const sendMistake = (mistake) => (dispatch) => {

    dispatch({
        type: SEND_MISTAKE,
        payload: mistake
    });

    let users = store.getState().usersdata.users;
    let arr = [];
    for (let i = 0; i < users.length; i++) {
        let usr = users[i];
        let obj = {
            "to": null,
            "title": mistake.title,
            "msg": mistake.body,
        };
        if (mistake.usertype === 'All' && mistake.devicetype === 'All') {
            if (usr.pushToken) {
                obj.to = usr.pushToken;
                arr.push(obj);
            }
        } else if (mistake.usertype === 'All' && mistake.devicetype !== 'All') {
            if (usr.pushToken && usr.userPlatform === mistake.devicetype) {
                obj.to = usr.pushToken;
                arr.push(obj);
            }
        } else if (mistake.usertype !== 'All' && mistake.devicetype === 'All') {
            if (usr.pushToken && usr.usertype === mistake.usertype) {
                obj.to = usr.pushToken;
                arr.push(obj);
            }
        } else {
            if (usr.pushToken && usr.usertype === mistake.usertype && usr.userPlatform === mistake.devicetype) {
                obj.to = usr.pushToken;
                arr.push(obj);
            }
        }
    }

    if (arr.length > 0) {
        for (let x = 0; x < arr.length; x++) {
            RequestPushMsg(arr[x].to, arr[x].title, arr[x].msg)
        }
        dispatch({
            type: SEND_MISTAKE_SUCCESS,
            payload: arr
        });
    } else {
        dispatch({
            type: SEND_MISTAKE_FAILED,
            payload: language.no_user_match,
        });
    }
}

