import {FETCH_TRIGGER_ACTION, UPDATE_SESSION_TOTAL_LOAD} from "../store/types";
import {firebase} from '../config/configureFirebase';
import {off, onValue} from "firebase/database";
import store from "../store/store";

export const monitorActionChanges = (uid = null, status = 'PENDING') => (dispatch) => {
    const {
        auth,
        triggerActionRef
    } = firebase;
    let uidCheck = uid ? uid : (auth.currentUser && auth.currentUser.uid ? auth.currentUser.uid : null);
    off(triggerActionRef(uidCheck, status));
    onValue(triggerActionRef(uidCheck, status), async snapshot => {
        if (snapshot.val()) {
            let data = snapshot.val();
            const result = await Object.keys(data)
                .map((i) => {
                    data[i].id = i;
                    data[i].uid = uid;
                    return data[i];
                });

            dispatch({
                type: FETCH_TRIGGER_ACTION,
                payload: result
            });
            const totalLoad = store.getState().sessiondata.totalLoadData ? parseInt(store.getState().sessiondata.totalLoadData) : 0;
            dispatch({
                type: UPDATE_SESSION_TOTAL_LOAD, payload: totalLoad + 1
            });
        } else {
            dispatch({
                type: FETCH_TRIGGER_ACTION,
                payload: []
            });
        }
    }, error => {
        throw new error;
    });
}

export const setActionStatus = (uid = null, status = 'DONE') => {
    const {
        auth,
        triggerActionRef
    } = firebase;
    const authState = store.getState().auth;
    let uidCheck = uid ? uid : (auth.currentUser && auth.currentUser.uid ? auth.currentUser.uid : null);
    off(triggerActionRef(uid, status));
    onValue(triggerActionRef(uid, status), async snapshot => {
        if (snapshot.val()) {
            let data = snapshot.val();
            const result = await Object.keys(data)
                .map((i) => {
                    data[i].id = i;
                    data[i].uid = uid;
                    return data[i];
                });
        }
    });
}

