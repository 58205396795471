export const colors = {
    input_bg: 'rgba(210,210,210,0.84)',
    TRANSPARENT: 'transparent',
    WHITE: '#fff',
    MAIN2: "#7f68ec",
    MAIN3: '#6445e5',
    MAIN4: '#c39130',
    MAIN5: '#612C58',
    MAIN5_BUTTON: '#7c3d71',
    BGNEW: '#AED581',
    MAIN3_OP: 'rgba(140,128,187,0.62)',
    BLACK: '#000000',
    RED: 'red',
    SKY: '#1E81D3',
    DARK: "#070807",
    BACKGROUND_DARK: "#3b3b3b",
    DEEP_SKY: "#0370bc",
    LIGHT_RED: "#FF2121",
    DULL_RED: "#B41B00",
    MAIN: "#3d9284",
    BUTTON_1: "#0669b6",
    BLACK_OPC: {
        prime: "rgba(0,0,0,0.31)"
    },
    LINK: "#0d6398",
    GREY: {
        default: '#243235',
        primary: "#CFD1D3",
        secondary: "#9b9b9b",
        btnPrimary: '#666666',
        btnSecondary: "#ababab",
        iconPrimary: "#c8c8c8",
        iconSecondary: "#3d3d3d",
        background: "rgba(22,22,22,0.8)",
        Smoke_Grey: "#dcdcdc",
        Trolley_Grey: "#808080",
        border: "#d6d6d6",
        Deep_Nobel: "#9f9f9f",
        whiteish: "#E5E3E3",
    },
    BLUE: {
        default: "blue",
        primary: "rgba(111, 202, 186, 1)",
        secondary: "#007aff",
        light: "#8ec4e6",
        dark: "#111b1e",
        sky: "#4a90e2",
        Deep_Blue: "#0386e1",
        greenish_blue: '#3CC5E7'
    },
    YELLOW: {
        primary: "#fda33b",
        secondary: "#ffe446",
        light: "#dbd6a0"
    },
    GREEN: {
        default: "green",
        light: "#32db64",
        medium: "#0cab03",
        bright: "#018E16",
        prime: "#1FCD6C",
        second: "#0D1724",
    },
    PINK: "#ff006b"
}
