import React, {useContext, useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import CircularLoading from "../components/CircularLoading";
import {useDispatch, useSelector} from "react-redux";
import ConfirmationDialogRaw from '../components/ConfirmationDialogRaw';
import {language} from 'config';
import {api, FirebaseContext} from 'common';
import {formatPriceRound, HiddenPhone} from "common/src/other/CommonFunctions";
import {ConvertSecondToHms, ConvertTimeStampToDate} from "common/src/other/DateFunctions";
import {formatNumber} from "react-native-currency-input";

const BookingHistory = () => {
    const {
        cancelBooking,
        updateBooking,
        removeBookingSignals,
        fetchBookings,
        stopFetchBookings
    } = api;
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [role, setRole] = useState(null);
    const formatPrice = (value) => {
        return formatPriceRound(value, formatNumber) + "(đ)";
    }
    const columns = [
        {title: language.booking_id, field: 'id', editable: 'never'},
        {title: "Mã CK Tra cứu", field: 'trip_code', editable: 'never'},
        {
            title: language.booking_date,
            field: 'tripdate',
            render: rowData => rowData.tripdate ? <span>{ConvertTimeStampToDate(rowData.tripdate)}</span> : null
        },
        {title: 'Mã Tài xế', field: 'codeDriver'},
        {title: language.pickup_address, field: 'pickupAddress'},
        {title: language.drop_address, field: 'dropAddress'},
        {title: "SĐT Khách", field: 'customer_contact', render: rowData => <span>{HiddenPhone(rowData.customer_contact)}</span>},
        {
            title: "LX trong BK",
            field: 'pushed_drivers',
            render: rowData => rowData.pushed_drivers && typeof rowData.pushed_drivers == 'string' ?
                <span>{rowData.pushed_drivers}</span> : "Không có"
        },
        {
            title: "LX trong điểm",
            field: 'pushed_in_drivers',
            render: rowData => rowData.pushed_in_drivers && typeof rowData.pushed_in_drivers == 'string' ?
                <span>{rowData.pushed_in_drivers}</span> : "Không có"
        },
        {
            title: "Điểm bắn",
            field: 'driver_group',
            render: rowData => rowData.driver_group && rowData.driver_group.label ?
                <span>{rowData.driver_group && rowData.driver_group.label ? rowData.driver_group.label : "Toàn bộ LX(trong BK)"}</span> : "Không có"
        },
        {title: "Ghi chú", field: 'customer_note'},
        {title: language.assign_driver, field: 'driver_name'},
        {title: language.booking_status, field: 'status', render: rowData => <span>{language[rowData.status]}</span>},
        {title: language.cancellation_reason, field: 'reason'},
        {title: language.car_type, field: 'carType'},
        {title: language.customer_name, field: 'customer_name'},
        {
            title: language.otp,
            field: 'otp',
            render: rowData => rowData.status === 'NEW' || rowData.status === 'ACCEPTED' ?
                <span>{rowData.otp}</span> : null
        },
        {
            title: language.trip_cost,
            field: 'trip_cost',
            render: rowData => rowData.trip_cost ?
                <span>{formatPrice(rowData.trip_cost)}</span> : null
        },
        {title: language.trip_cost, field: 'trip_cost'},
        {title: language.trip_start_time, field: 'trip_start_time'},
        {
            title: language.trip_end_time,
            field: 'trip_end_time',
            render: rowData => rowData.trip_end_time ?
                <span>{ConvertTimeStampToDate(rowData.trip_end_time)}</span> : null
        },
        {
            title: language.total_time,
            field: 'total_trip_time',
            render: rowData => rowData.total_trip_time ?
                <span>{ConvertSecondToHms(rowData.total_trip_time)}</span> : null
        },
        {
            title: language.distance,
            field: 'distance',
            render: rowData => rowData.distance ?
                <span>{Math.round(rowData.distance * 100) / 100}</span> : null
        },
        ///
        {title: language.vehicle_no, field: 'vehicle_number'},
        {title: language.trip_cost_driver_share, field: 'driver_share'},
        {title: language.convenience_fee, field: 'convenience_fees'},
        {title: language.discount_ammount, field: 'discount'},
        {title: language.Customer_paid, field: 'customer_paid'},
        {title: language.payment_mode, field: 'payment_mode'},
        {title: language.payment_gateway, field: 'gateway'},
        {title: language.cash_payment_amount, field: 'cashPaymentAmount'},
        {title: language.card_payment_amount, field: 'cardPaymentAmount'},
        {title: language.wallet_payment_amount, field: 'usedWalletMoney'}
    ];
    const [data, setData] = useState([]);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState('');
    const bookinglistdata = useSelector(state => state.bookinglistdata);

    useEffect(() => {
        if (auth.info.uid && auth.user.usertype) {
            dispatch(fetchBookings(auth.info.uid, auth.user.usertype));
            return () => {
                dispatch(stopFetchBookings(auth.info.uid, auth.user.usertype));
            }
        }
    }, []);

    useEffect(() => {
        if (bookinglistdata.bookings) {
            setData(bookinglistdata.bookings);
            data.map((itemBooking, index) => {
                if (itemBooking.tripdate) {
                    itemBooking.tripdate = ConvertTimeStampToDate(itemBooking.tripdate);
                }

            });
        } else {
            setData([]);
        }
    }, [bookinglistdata.bookings]);

    useEffect(() => {
        if (auth.info && auth.user) {
            setRole(auth.user.usertype);
        }
    }, [auth.info]);

    const onConfirmClose = (value) => {
        if (value) {
            dispatch(cancelBooking({
                reason: value,
                booking: selectedBooking,
                cancel_by: 'driver'
            }));
        }
        setOpenConfirm(false);
    }

    return (
        bookinglistdata.loading ? <CircularLoading/> :
            <div>
                <MaterialTable
                    title={language.booking_title}
                    columns={columns}
                    data={data}
                    options={{
                        exportButton: true,
                        pageSize: 25,
                        filtering: true,
                        pageSizeOptions: [10, 25, 50, 100]
                    }}
                    editable={{
                        isEditable: (oldData) => oldData.requestedDrivers ? true : false,
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    if (oldData.id) {
                                        dispatch(removeBookingSignals(oldData.id));
                                    }
                                }, 600);
                            })
                    }}
                />
                <ConfirmationDialogRaw
                    open={openConfirm}
                    onClose={onConfirmClose}
                    value={''}
                />
            </div>

    );
}

export default BookingHistory;
