import {EDIT_MISTAKE_CONFIG, FETCH_MISTAKE_CONFIG, FETCH_MISTAKE_CONFIG_FAILED, FETCH_MISTAKE_CONFIG_SUCCESS} from "../store/types";
import {firebase} from '../config/configureFirebase';
import {off, onValue, push, remove, set} from "firebase/database";

export const fetchMistakeConfig = () => (dispatch) => {

    const {
        mistakeConfigRef
    } = firebase;

    dispatch({
        type: FETCH_MISTAKE_CONFIG,
        payload: null
    });
    off(mistakeConfigRef);
    onValue(mistakeConfigRef, snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();

            const arr = Object.keys(data).map(i => {
                data[i].id = i
                return data[i]
            });
            dispatch({
                type: FETCH_MISTAKE_CONFIG_SUCCESS,
                payload: arr
            });
        } else {
            dispatch({
                type: FETCH_MISTAKE_CONFIG_FAILED,
                payload: "No data available."
            });
        }
    });
};

export const editMistakeConfig = (mistakeConfig, method) => (dispatch) => {

    const {
        mistakeConfigRef,
        mistakeConfigEditRef
    } = firebase;

    dispatch({
        type: EDIT_MISTAKE_CONFIG,
        payload: {method, mistakeConfig}
    });
    if (method === 'Add') {
        push(mistakeConfigRef, mistakeConfig);
    } else if (method === 'Delete') {
        remove(mistakeConfigEditRef(mistakeConfig.id));
    } else {
        set(mistakeConfigEditRef(mistakeConfig.id), mistakeConfig);
    }
}

