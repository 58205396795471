import {EDIT_FORCE_NOTIFY, FETCH_FORCE_NOTIFY, FETCH_FORCE_NOTIFY_FAILED, FETCH_FORCE_NOTIFY_SUCCESS, PUSH_FORCE_NOTIFY, STOP_FETCH_FORCE_NOTIFY} from "../store/types";
import {language} from 'config';
import store from "../store/store";
import {firebase} from '../config/configureFirebase';
import {off, onValue, push, update} from "firebase/database";
import {GetCurrentMiniTimeStamp} from "../other/DateFunctions";

export const fetchForceNotify = () => (dispatch) => {
    const {
        forcenotifyAllRef
    } = firebase;

    dispatch({
        type: FETCH_FORCE_NOTIFY,
        payload: null
    });
    let user = store.getState().auth;
    if (user) {
        off(forcenotifyAllRef);
        onValue(forcenotifyAllRef, snapshot => {
            if (snapshot.val()) {
                const data = snapshot.val();
                if (user.info && user.info.profile && user.info.profile.codeDriver) {
                    if (user.info.profile.usertype == 'driver') {
                        const arr = Object.keys(data).map(i => {
                            data[i].id = i
                            return data[i]
                        });
                        const newData = arr.filter((f) => f.driverCode == user.info.profile.codeDriver && f.status === 'pending');
                        dispatch({
                            type: FETCH_FORCE_NOTIFY_SUCCESS,
                            payload: newData
                        });
                    } else {
                        dispatch({
                            type: FETCH_FORCE_NOTIFY_SUCCESS,
                            payload: []
                        });
                    }
                } else {
                    const arr = Object.keys(data).map(i => {
                        data[i].id = i
                        return data[i]
                    });
                    dispatch({
                        type: FETCH_FORCE_NOTIFY_SUCCESS,
                        payload: arr
                    });
                }

            } else {
                dispatch({
                    type: FETCH_FORCE_NOTIFY_FAILED,
                    payload: language.no_force_notify
                });
            }
        });
    } else {
        dispatch({
            type: FETCH_FORCE_NOTIFY_FAILED,
            payload: language.no_force_notify
        });
    }

};

export const stopFetchForceNotify = () => (dispatch) => {
    const {
        forcenotifyAllRef
    } = firebase;

    dispatch({
        type: STOP_FETCH_FORCE_NOTIFY,
        payload: null
    });
    off(forcenotifyAllRef);
};

export const pushForceNotify = (data) => (dispatch) => {
    const {
        auth,
        forcenotifyAllRef
    } = firebase;

    dispatch({
        type: PUSH_FORCE_NOTIFY,
        payload: null
    });
    var user = auth.currentUser;
    let dataPush = {
        date_add: GetCurrentMiniTimeStamp(),
        driverCode: data.driverCode,
        title: data.title ? data.title : null,
        content: data.content ? data.content : null,
        type: data.type ? data.type : 'warning',
        add_by: data.add_by ? data.add_by : user.uid,
        status: data.status ? data.status : 'pending'
    }
    push(forcenotifyAllRef, dataPush).then((res) => {
        let key = res.key;
    });
};
export const editForceNotify = (id, data) => (dispatch) => {
    const {
        forcenotifyByIdRef
    } = firebase;

    dispatch({
        type: EDIT_FORCE_NOTIFY,
        payload: null
    });
    update(forcenotifyByIdRef(id), data);
};

