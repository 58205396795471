import {FETCH_REPORTS, FETCH_REPORTS_FAILED, FETCH_REPORTS_SUCCESS, INIT_REPORT_BY_DATE, INIT_REPORT_BY_DATE_FAILED, INIT_REPORT_BY_DATE_SUCCESS, SET_REPORT_ITEM, SET_REPORT_ITEM_FAILED, SET_REPORT_ITEM_SUCCESS} from "../store/types";
import {GetCurrentDateReport} from "../other/DateFunctions";
import {firebase} from '../config/configureFirebase';
import {off, onValue, set} from "firebase/database";

export const fetchReports = (uid = null, date = null) => (dispatch) => {
    const {
        reportsRef,
        reportsDateRef,
        auth
    } = firebase;

    dispatch({
        type: FETCH_REPORTS,
        payload: null
    });

    let uidCheck = uid ? uid : (auth.currentUser && auth.currentUser.uid ? auth.currentUser.uid : null);
    if (date) {
        off(reportsDateRef(uidCheck, date));
        onValue(reportsDateRef(uidCheck, date), snapshot => {
            if (snapshot.val()) {
                dispatch({
                    type: FETCH_REPORTS_SUCCESS,
                    payload: snapshot.val()
                });
            } else {
                dispatch({
                    type: FETCH_REPORTS_FAILED,
                    payload: "Không có bản ghi báo cáo"
                });
            }
        });
    } else {
        off(reportsRef(uidCheck));
        onValue(reportsRef(uidCheck), snapshot => {
            if (snapshot.val()) {
                dispatch({
                    type: FETCH_REPORTS_SUCCESS,
                    payload: snapshot.val()
                });
            } else {
                dispatch({
                    type: FETCH_REPORTS_FAILED,
                    payload: "Không có bản ghi báo cáo"
                });
            }
        });
    }


};

export const initDateReport = (uid, data) => (dispatch) => {
    const {
        reportsDateRef
    } = firebase;

    dispatch({
        type: INIT_REPORT_BY_DATE,
        payload: null
    });
    let dataSet = {
        time_active: 1,
        time_active_out: 1,
        total_booking_cancel: 0
    }
    if (data && data.date_key) {
        set(reportsDateRef(uid, data.date_key), dataSet)
            .then((data) => {
                dispatch({
                    type: INIT_REPORT_BY_DATE_SUCCESS,
                    payload: dataSet
                });
            }).catch(error => {
            dispatch({
                type: INIT_REPORT_BY_DATE_FAILED,
                payload: error
            });
        });
    } else {
        let date_key = GetCurrentDateReport();
        set(reportsDateRef(uid, date_key), dataSet)
            .then((data) => {
                dispatch({
                    type: INIT_REPORT_BY_DATE_SUCCESS,
                    payload: dataSet
                });
            }).catch(error => {
            dispatch({
                type: INIT_REPORT_BY_DATE_FAILED,
                payload: error
            });
        });
    }
};

export const setReportItem = (uid, date_key, type_report, data) => (dispatch) => {
    const {
        reportItemRef,
        reportObjectRef
    } = firebase;

    dispatch({
        type: SET_REPORT_ITEM,
        payload: null
    });
    if (date_key) {
        if (type_report) {
            set(reportItemRef(uid, date_key, type_report), data)
                .then((item) => {
                    dispatch({
                        type: SET_REPORT_ITEM_SUCCESS,
                        payload: item
                    });
                }).catch(error => {
                dispatch({
                    type: SET_REPORT_ITEM_FAILED,
                    payload: error
                });
            });
        } else {
            set(reportObjectRef(uid, date_key), data)
                .then((item) => {
                    dispatch({
                        type: SET_REPORT_ITEM_SUCCESS,
                        payload: item
                    });
                }).catch(error => {
                dispatch({
                    type: SET_REPORT_ITEM_FAILED,
                    payload: error
                });
            });
        }

    } else {
        let date_key = GetCurrentDateReport();
        set(reportItemRef(uid, date_key, type_report), data)
            .then((item) => {
                dispatch({
                    type: SET_REPORT_ITEM_SUCCESS,
                    payload: item
                });
            }).catch(error => {
            dispatch({
                type: SET_REPORT_ITEM_FAILED,
                payload: error
            });
        });
    }
};
