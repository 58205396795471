import {
    FETCH_ALL_DRIVERS,
    FETCH_ALL_DRIVERS_FAILED,
    FETCH_ALL_DRIVERS_QUEUE,
    FETCH_ALL_DRIVERS_QUEUE_FAILED,
    FETCH_ALL_DRIVERS_QUEUE_SUCCESS,
    FETCH_ALL_DRIVERS_SUCCESS, FETCH_ALL_FLEETADMIN, FETCH_ALL_FLEETADMIN_FAILED, FETCH_ALL_FLEETADMIN_SUCCESS, FETCH_ALL_ONCE_DRIVER, FETCH_ALL_ONCE_DRIVER_FAILED, FETCH_ALL_ONCE_DRIVER_SUCCESS,
    FETCH_ALL_ONCE_USERS,
    FETCH_ALL_ONCE_USERS_FAILED,
    FETCH_ALL_ONCE_USERS_SUCCESS,
    FETCH_ALL_USERS,
    FETCH_ALL_USERS_FAILED,
    FETCH_ALL_USERS_SUCCESS,
    FETCH_DRIVER_ACTIVE, FETCH_DRIVER_ACTIVE_FAILED, FETCH_DRIVER_ACTIVE_SUCCESS,
    FETCH_DRIVER_IN_GROUP,
    FETCH_DRIVER_IN_GROUP_FAILED,
    FETCH_DRIVER_IN_GROUP_SUCCESS, FETCH_DRIVER_NEAR, FETCH_DRIVER_NEAR_SUCCESS,
    FETCH_DRIVER_OUT_GROUP,
    FETCH_DRIVER_OUT_GROUP_FAILED,
    FETCH_DRIVER_OUT_GROUP_SUCCESS,
    FETCH_DRIVER_OUT_IN_GROUP,
    FETCH_DRIVER_OUT_IN_GROUP_FAILED,
    FETCH_DRIVER_OUT_IN_GROUP_SUCCESS, GET_ALL_DRIVERS, GET_ALL_DRIVERS_FAILED, GET_ALL_DRIVERS_SUCCESS,
    SELECT_USER,
    SELECT_USER_FAILED,
    SELECT_USER_SUCCESS,
    UPDATE_USER_GROUP,
    UPDATE_USER_GROUP_FAILED,
    UPDATE_USER_GROUP_SUCCESS,
    UPDATE_USER_LOCK_STATUS,
    UPDATE_USER_LOCK_STATUS_FAILED,
    UPDATE_USER_LOCK_STATUS_SUCCESS
} from "../store/types";

export const INITIAL_STATE = {
    users: null,
    once_users: null,
    user_detail: null,
    drivers: null,
    fleets: null,
    loading: false,
    drivers_active: null,
    driversqueue: null,
    driverInGroup: null,
    driverOutGroup: null,
    driverOutInGroup: null,
    get_drivers: null,
    driverNears: null,
    error: {
        flag: false,
        msg: null
    }
}

export const usersreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_DRIVER_NEAR:
            return {
                ...state,
                driverNears: null
            };
        case FETCH_DRIVER_NEAR_SUCCESS:
            return {
                ...state,
                driverNears: action.payload
            };
        case FETCH_ALL_USERS:
            return {
                ...state,
                loading: true
            };
        case FETCH_ALL_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload,
                loading: false
            };
        case FETCH_ALL_USERS_FAILED:
            return {
                ...state,
                drivers_active: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case FETCH_ALL_FLEETADMIN:
            return {
                ...state,
                loading: true
            };
        case FETCH_ALL_FLEETADMIN_SUCCESS:
            return {
                ...state,
                fleets: action.payload,
                loading: false
            };
        case FETCH_ALL_FLEETADMIN_FAILED:
            return {
                ...state,
                fleets: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case FETCH_DRIVER_ACTIVE:
            return {
                ...state,
                loading: true
            };
        case FETCH_DRIVER_ACTIVE_SUCCESS:
            return {
                ...state,
                drivers_active: action.payload,
                loading: false
            };
        case FETCH_DRIVER_ACTIVE_FAILED:
            return {
                ...state,
                drivers_active: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case FETCH_DRIVER_IN_GROUP:
            return {
                ...state,
                loading: true
            };
        case FETCH_DRIVER_IN_GROUP_SUCCESS:
            return {
                ...state,
                driverInGroup: action.payload,
                loading: false
            };
        case FETCH_DRIVER_IN_GROUP_FAILED:
            return {
                ...state,
                driverInGroup: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case FETCH_DRIVER_OUT_GROUP:
            return {
                ...state,
                loading: true
            };
        case FETCH_DRIVER_OUT_GROUP_SUCCESS:
            return {
                ...state,
                driverOutGroup: action.payload,
                loading: false
            };
        case FETCH_DRIVER_OUT_GROUP_FAILED:
            return {
                ...state,
                driverOutGroup: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case FETCH_DRIVER_OUT_IN_GROUP:
            return {
                ...state,
                loading: true
            };
        case FETCH_DRIVER_OUT_IN_GROUP_SUCCESS:
            return {
                ...state,
                driverOutInGroup: action.payload,
                loading: false
            };
        case FETCH_DRIVER_OUT_IN_GROUP_FAILED:
            return {
                ...state,
                driverOutInGroup: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case FETCH_ALL_DRIVERS:
            return {
                ...state,
                loading: true
            };
        case FETCH_ALL_DRIVERS_SUCCESS:
            return {
                ...state,
                drivers: action.payload,
                loading: false
            };
        case FETCH_ALL_DRIVERS_FAILED:
            return {
                ...state,
                drivers: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case GET_ALL_DRIVERS:
            return {
                ...state,
                loading: true
            };
        case GET_ALL_DRIVERS_SUCCESS:
            return {
                ...state,
                get_drivers: action.payload,
                loading: false
            };
        case GET_ALL_DRIVERS_FAILED:
            return {
                ...state,
                get_drivers: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case FETCH_ALL_ONCE_DRIVER:
            return {
                ...state,
                loading: true
            };
        case FETCH_ALL_ONCE_DRIVER_SUCCESS:
            return {
                ...state,
                once_drivers: action.payload,
                loading: false
            };
        case FETCH_ALL_ONCE_DRIVER_FAILED:
            return {
                ...state,
                once_drivers: null,
                loading: false,
                error: {
                    flag: true,
                }
            };

        case FETCH_ALL_DRIVERS_QUEUE:
            return {
                ...state,
                loading: true
            };
        case FETCH_ALL_DRIVERS_QUEUE_SUCCESS:
            return {
                ...state,
                driversqueue: action.driversqueue,
                loading: false
            };
        case FETCH_ALL_DRIVERS_QUEUE_FAILED:
            return {
                ...state,
                driversqueue: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.driversqueue
                }
            };
        case FETCH_ALL_ONCE_USERS:
            return {
                ...state,
                loading: true
            };
        case FETCH_ALL_ONCE_USERS_SUCCESS:
            return {
                ...state,
                once_users: action.payload,
                loading: false
            };
        case FETCH_ALL_ONCE_USERS_FAILED:
            return {
                ...state,
                once_users: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case SELECT_USER:
            return {
                ...state,
                user_detail: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.user_detail
                }
            };
        case SELECT_USER_SUCCESS:
            return {
                ...state,
                user_detail: action.user_detail,
                loading: false
            };
        case UPDATE_USER_GROUP:
            return {
                ...state
            };
        case UPDATE_USER_GROUP_SUCCESS:
            return {
                ...state,
                message: action.payload
            };
        case UPDATE_USER_GROUP_FAILED:
            return {
                ...state,
                message: action.payload
            };

        case UPDATE_USER_LOCK_STATUS:
            return {
                ...state
            };
        case UPDATE_USER_LOCK_STATUS_SUCCESS:
            return {
                ...state,
                message: action.payload
            };
        case UPDATE_USER_LOCK_STATUS_FAILED:
            return {
                ...state,
                message: action.payload
            };

        case SELECT_USER_FAILED:
            return {
                ...state,
                user_detail: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.user_detail
                }
            };
        default:
            return state;
    }
};
