import {EDIT_BOOKING_NOTI_DETAIL, FETCH_BOOKING_NOITI_DETAIL_FAILED, FETCH_BOOKING_NOTI_DETAIL, FETCH_BOOKING_NOTI_DETAIL_SUCCESS} from "../store/types";

const INITIAL_STATE = {
    notis: null,
    error: {
        flag: false,
        msg: null
    },
    loading: false
}

export const bookingnotireducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_BOOKING_NOITI_DETAIL_FAILED:
            return {
                ...state,
                notis: null
            };
        case FETCH_BOOKING_NOTI_DETAIL:
            return {
                ...state,
                loading: true
            };
        case FETCH_BOOKING_NOTI_DETAIL_SUCCESS:
            return {
                ...state,
                notis: action.notis,
                loading: false
            };

        case EDIT_BOOKING_NOTI_DETAIL:
            return {
                ...state
            };
        default:
            return state;
    }
};