import {EDIT_WITHDRAWS, FETCH_WITHDRAWS, FETCH_WITHDRAWS_FAILED, FETCH_WITHDRAWS_SUCCESS} from "../store/types";
import {firebase} from '../config/configureFirebase';
import {child, off, onValue, update} from "firebase/database";

export const fetchWithdraws = () => (dispatch) => {

    const {
        withdrawRef
    } = firebase;

    dispatch({
        type: FETCH_WITHDRAWS,
        payload: null
    });
    off(withdrawRef);
    onValue(withdrawRef, snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data).map(i => {
                data[i].id = i
                return data[i]
            });
            dispatch({
                type: FETCH_WITHDRAWS_SUCCESS,
                payload: arr.reverse()
            });
        } else {
            dispatch({
                type: FETCH_WITHDRAWS_FAILED,
                payload: "No WITHDRAWS available."
            });
        }
    });
};

export const completeWithdraw = (entry) => (dispatch) => {

    const {
        withdrawRef
    } = firebase;

    dispatch({
        type: EDIT_WITHDRAWS,
        payload: entry
    });
    update(child(withdrawRef, entry.id), {...entry, processed: true, procesDate: new Date().toString()});
}
