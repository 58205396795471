import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{width: '100%', mr: 1, justifyContent: 'center'}}>
                <img
                    loading="lazy"
                    width="40"
                    style={{
                        marginLeft: `${Math.round(
                            props.value,
                        )}%`
                    }}
                    srcSet={'https://i.ibb.co/FBGWywb/x4cb-icon.png' + ' 2x'}
                    src={'https://i.ibb.co/FBGWywb/x4cb-icon.png'}
                    alt={'https://i.ibb.co/FBGWywb/x4cb-icon.png'}
                />
                <LinearProgress variant="determinate" {...props} />
                <Typography variant="body2" color="text.secondary">{`${100 - props.value} phút di chuyển`}</Typography>
            </Box>
            {/*<Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>*/}
            <Box sx={{minWidth: 20}}>
                <img src={require("../../assets/img/ico_start_13x.png")} alt="Điểm đón" style={{width: 20, height: 20}}/>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

export default function WaitingCustomerComponent(props) {
    const [progress, setProgress] = React.useState(props.second ? props.second : 10);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
        }, 800);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Box sx={{width: '100%'}}>
            <LinearProgressWithLabel value={progress}/>
        </Box>
    );
}