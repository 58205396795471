import {FETCH_MESSAGES, FETCH_MESSAGES_FAILED, FETCH_MESSAGES_SUCCESS, SEND_MESSAGE, SEND_MESSAGE_SUCCESS, STOP_FETCH_MESSAGES} from "../store/types";
import {language} from 'config';
import {RequestPushMsg} from '../other/NotificationFunctions';
import {GetCurrentMiniTimeStamp} from "../other/DateFunctions";
import store from "../store/store";
import {firebase} from '../config/configureFirebase';
import {child, off, onValue, push, set} from "firebase/database";
import {getDownloadURL, uploadBytesResumable} from "firebase/storage";

export const fetchChatMessages = (bookingId) => (dispatch) => {

    const {
        chatRef
    } = firebase;

    dispatch({
        type: FETCH_MESSAGES,
        payload: bookingId,
    });
    off(chatRef(bookingId));
    onValue(chatRef(bookingId), snapshot => {
        if (snapshot.val()) {
            let rootEntry = snapshot.val();
            var message = store.getState().chatdata.messages;
            let msgPush = message.filter((m) => m.key != bookingId);
            for (let key in rootEntry) {
                let entryKey = rootEntry[key]
                for (let msgKey in entryKey) {
                    entryKey[msgKey].smsId = msgKey;
                    entryKey[msgKey].key = bookingId;
                    msgPush.push(entryKey[msgKey]);
                }
            }
            dispatch({
                type: FETCH_MESSAGES_SUCCESS,
                payload: msgPush,
            });
        } else {
            dispatch({
                type: FETCH_MESSAGES_FAILED,
                payload: language.chat_not_found,
            });
        }
    })
};

export const sendMessage = (data) => (dispatch) => {
    const {
        chatRef,
        chatMessageRef,
        chatMessageStoreRef,
        auth
    } = firebase;

    let drivers = store.getState().usersdata.users;

    const authState = store.getState().auth;
    const uid = auth && auth.currentUser && auth.currentUser ? auth.currentUser.uid : authState && authState.user ? authState.user.id : null;

    dispatch({
        type: SEND_MESSAGE,
        payload: data,
    });

    const chatId = (data.booking && data.booking.customer ? data.booking.customer + ',' + data.booking.driver : (data.team && data.team.id ? data.team.id + "," + uid : GetCurrentMiniTimeStamp()));
    var today = new Date();
    var time = today.getHours() + ":" + today.getMinutes();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = mm + ':' + dd + ':' + yyyy;
    let chatKey = data.booking && data.booking.id ? data.booking.id : (data.team && data.team.id ? data.team.id : GetCurrentMiniTimeStamp());
    var chatFrom = '';
    var sourceChat = '';
    if (data.booking) {
        chatFrom = data.role == 'rider' ? data.booking.customer : data.booking.driver;
        sourceChat = data.role;
    }
    if (data.team) {
        chatFrom = uid;
        sourceChat = data.team.admin == uid ? "admin" : "member";
    }

    if (data.message && data.message.blob_data) {
        var msg = {
            at: GetCurrentMiniTimeStamp(),
            message: '',
            from: chatFrom,
            type: data.message.type == 'img' ? 'img' : 'audio',
            msgDate: today,
            msgTime: time,
            source: sourceChat
        };

        var chatUid = '';
        let imageBlob = data.message.blob_data;
        push(child(chatRef(chatKey), chatId), msg).then((res) => {
            chatUid = res.key;
            uploadBytesResumable(chatMessageStoreRef(chatKey, chatId, chatUid), imageBlob).then(() => {
                imageBlob.close()
                return getDownloadURL(chatMessageStoreRef(chatKey, chatId, chatUid));
            }).then((url) => {
                msg.message = url;
                if (chatUid) {
                    set(chatMessageRef(chatKey, chatId, chatUid), url);
                }
                if (data.booking) {
                    if (data.message.type == 'img') {
                        if (data.role == 'rider' || data.role == 'admin' || data.role == 'fleetadmin') {
                            RequestPushMsg(data.booking.driver_token, language.notification_title + language.chat_requested, " Đã gửi ảnh");
                        } else {
                            if (data.booking.customer_token) {
                                RequestPushMsg(data.booking.customer_token, language.notification_title + language.chat_requested, " Đã gửi ảnh");
                            }
                        }
                    }
                    if (data.message.type == 'audio') {
                        if (data.role == 'rider' || data.role == 'admin' || data.role == 'fleetadmin') {
                            RequestPushMsg(data.booking.driver_token, language.notification_title + language.chat_requested, " Đã gửi âm thanh");
                        } else {
                            if (data.booking.customer_token) {
                                RequestPushMsg(data.booking.customer_token, language.notification_title + language.chat_requested, " Đã gửi âm thanh");
                            }
                        }
                    }
                }

                if (data.team) {
                    if (data.team.members) {
                        data.team.members.map((member) => {
                            if (member.uid != uid && member.status === 'accepted') {
                                let driver = drivers.filter((d) => d.uid === member.uid)[0];
                                if (driver) {
                                    if (data.message.type == 'img') {
                                        RequestPushMsg(driver.pushToken, language.notification_title + ' ' + data.team.name, " Đã gửi ảnh");
                                    }
                                    if (data.message.type == 'audio') {
                                        RequestPushMsg(driver.pushToken, language.notification_title + ' ' + data.team.name, " Đã gửi âm thanh");
                                    }
                                }
                            }
                        });
                    }
                }

                dispatch({
                    type: SEND_MESSAGE_SUCCESS,
                    payload: msg,
                });
            })
        }).catch(error => {
            dispatch({
                type: SEND_MESSAGE_SUCCESS,
                payload: msg,
            });
        });
    } else {
        if (data.message && data.message.includes("http://maps.google.com?q=")) {
            const msg = {
                at: GetCurrentMiniTimeStamp(),
                message: data.message,
                from: chatFrom,
                type: "map",
                msgDate: today,
                msgTime: time,
                source: sourceChat
            };

            push(child(chatRef(chatKey), chatId), msg).then((res) => {
                var chatUid = res.key;
                if (data.team) {
                    if (data.team.members) {
                        data.team.members.map((member) => {
                            if (member.uid != uid && member.status === 'accepted') {
                                let driver = drivers.filter((d) => d.uid === member.uid)[0];
                                if (driver) {
                                    RequestPushMsg(driver.pushToken, language.notification_title + ' ' + data.team.name, " Đã gửi vị trí");
                                }
                            }
                        });
                    }
                }
                if (data.booking) {
                    if (data.role == 'rider' || data.role == 'admin' || data.role == 'fleetadmin') {
                        RequestPushMsg(data.booking.driver_token, language.notification_title + language.chat_requested, " Đã gửi vị trí");
                    } else {
                        if (data.booking.customer_token) {
                            RequestPushMsg(data.booking.customer_token, language.notification_title + language.chat_requested, " Đã gửi vị trí");
                        }
                    }
                }

                dispatch({
                    type: SEND_MESSAGE_SUCCESS,
                    payload: msg,
                });
            }).catch(error => {
                dispatch({
                    type: SEND_MESSAGE_SUCCESS,
                    payload: msg,
                });
            });
        } else {
            const msg = {
                at: GetCurrentMiniTimeStamp(),
                message: data.message,
                from: chatFrom,
                type: "msg",
                msgDate: today,
                msgTime: time,
                source: sourceChat
            };

            push(child(chatRef(chatKey), chatId), msg).then((res) => {
                var chatUid = res.key;
                if (data.team) {
                    if (data.team.members) {
                        data.team.members.map((member) => {
                            if (member.uid != uid && member.status === 'accepted') {
                                let driver = drivers.filter((d) => d.uid === member.uid)[0];
                                if (driver) {
                                    RequestPushMsg(driver.pushToken, language.notification_title + ' ' + data.team.name, data.message);
                                }
                            }
                        });
                    }
                }

                if (data.booking) {
                    if (data.role == 'rider' || data.role == 'admin' || data.role == 'fleetadmin') {
                        RequestPushMsg(data.booking.driver_token, language.notification_title + language.chat_requested, data.message);
                    } else {
                        if (data.booking.customer_token) {
                            RequestPushMsg(data.booking.customer_token, language.notification_title + language.chat_requested, data.message);
                        }
                    }
                }

                dispatch({
                    type: SEND_MESSAGE_SUCCESS,
                    payload: msg,
                });
            }).catch(error => {
                dispatch({
                    type: SEND_MESSAGE_SUCCESS,
                    payload: msg,
                });
            });
        }
    }
}

export const stopFetchMessages = (bookingId) => (dispatch) => {
    const {
        chatRef
    } = firebase;

    dispatch({
        type: STOP_FETCH_MESSAGES,
        payload: bookingId,
    });
    off(chatRef(bookingId));
}


