import {FETCH_TASKS_RUNNER, FETCH_TASKS_RUNNER_FAILED, FETCH_TASKS_RUNNER_SUCCESS, PUSH_TASKS_RUNNER, PUSH_TASKS_RUNNER_FAILED, PUSH_TASKS_RUNNER_SUCCESS} from "../store/types";
import {firebase} from '../config/configureFirebase';
import {off, onValue, push} from "firebase/database";

export const fetchTasksRunner = () => (dispatch) => {
    const {
        tasksRunnerRef
    } = firebase;

    dispatch({
        type: FETCH_TASKS_RUNNER,
        payload: null,
    });
    off(tasksRunnerRef());
    onValue(tasksRunnerRef(), (snapshot) => {
        if (snapshot.val()) {
            let data = snapshot.val();
            const arr = Object.keys(data)
                .map(i => {
                    data[i].id = i;
                    return data[i];
                });
            dispatch({
                type: FETCH_TASKS_RUNNER_SUCCESS,
                payload: {
                    tasks: arr,
                }
            });
        } else {
            dispatch({
                type: FETCH_TASKS_RUNNER_FAILED,
                payload: "Không có task",
            });
        }
    });
};

export const pushTaskRunner = (data) => (dispatch) => {
    const {
        tasksRunnerRef
    } = firebase;

    dispatch({
        type: PUSH_TASKS_RUNNER,
        payload: null,
    });
    push(tasksRunnerRef(), data).then((res) => {
        dispatch({
            type: PUSH_TASKS_RUNNER_SUCCESS,
            payload: {
                task: data
            }
        });
    }).catch(error => {
        dispatch({
            type: PUSH_TASKS_RUNNER_FAILED,
            payload: error.code + ": " + error.message,
        });
    });
}

