import React, {useContext, useEffect} from 'react';
import CircularLoading from "../components/CircularLoading";
import {useDispatch, useSelector} from "react-redux";
import {language} from 'config';
import {api, FirebaseContext} from 'common';
import {GetCurrentDateCaTrucDem, GetCurrentTimeStamp} from "common/src/other/DateFunctions";
import {fetchNewCall} from "common/src/actions/pacallcenteractions";

function AuthLoading(props) {
    const {refApi} = useContext(FirebaseContext);
    const {setUserLoginHistoryApi, updateProfileDoRef} = refApi;
    const {
        fetchUser,
        fetchCarTypes,
        fetchGroupDrivers,
        fetchSettings,
        fetchBookings,
        fetchCancelReasons,
        fetchNotifications,
        signOutL,
        fetchFleetUsersOnline,
        fetchDriverCaTrucDem,
        fetchMistakeConfig,
        fetchBookingNotiOn,
        fetchNewCall
    } = api;
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const settingsdata = useSelector((state) => state.settingsdata);

    useEffect(() => {
        dispatch(fetchSettings());
    }, [dispatch, fetchSettings]);

    useEffect(() => {
        try {
            dispatch(fetchCarTypes());
            dispatch(fetchGroupDrivers());
            dispatch(fetchDriverCaTrucDem(GetCurrentDateCaTrucDem()));
            dispatch(fetchFleetUsersOnline());
            dispatch(fetchUser());
            dispatch(fetchMistakeConfig());
        } catch (e) {
            console.log("init load data", e)
        }

    }, [settingsdata.settings, dispatch, fetchCarTypes, fetchGroupDrivers, fetchFleetUsersOnline, fetchDriverCaTrucDem, fetchUser, fetchMistakeConfig, fetchBookingNotiOn]);

    useEffect(() => {
        if (auth.user) {
            let role = auth.user.usertype;
            if (role === 'rider') {
                dispatch(fetchBookings(auth.user.uid, role));
                dispatch(fetchCancelReasons());
            } else if (role === 'driver') {
                dispatch(fetchBookings(auth.user.uid, role));
                dispatch(fetchGroupDrivers());
                dispatch(fetchNotifications());
                dispatch(fetchCancelReasons());
            } else if (role === 'admin') {
                dispatch(fetchBookingNotiOn());
                dispatch(fetchNewCall());
                //dispatch(fetchForceNotify());
                //dispatch(fetchUsers());
                //dispatch(fetchOnceUsers());
                dispatch(fetchBookings(auth.info.uid, role));
                //dispatch(fetchAllBookings());
                //dispatch(fetchPromos());
                //dispatch(fetchDriverEarnings(auth.info.uid, role));
                //dispatch(fetchEtoWallet());
                //dispatch(fetchNotifications());
                //dispatch(fetchEarningsReport());
                //dispatch(fetchCancelReasons());
                dispatch(fetchGroupDrivers());
                dispatch(fetchCancelReasons());
                //dispatch(fetchGroupArea());
                //dispatch(fetchReportDrivers());
                //dispatch(fetchWithdraws());
                //dispatch(fetchCallsPending());
                //dispatch(fetchAllDriversQueue());
                //dispatch(fetchMistakes());
                //dispatch(fetchCallDetailAll());
                //dispatch(fetchAreas());
            } else if (role === 'fleetadmin') {
                console.log('auth.info.uid', auth.user.uid);
                if (!auth.user.isOnline) {
                    if (setUserLoginHistoryApi) {
                        setUserLoginHistoryApi(auth.user.uid, GetCurrentTimeStamp(), {
                            type: 'login', at: GetCurrentTimeStamp(), lat: "temp",
                            lng: "temp"
                        });
                    }
                    updateProfileDoRef(auth.user.uid, {isOnline: true});
                }
                try {
                    dispatch(fetchGroupDrivers());
                } catch (e) {
                    console.log('lôi fetchGroupDrivers', e);
                }

                try {
                    dispatch(fetchCancelReasons());
                } catch (e) {
                    console.log('lôi fetchCancelReasons', e);
                }

                try {
                    dispatch(fetchBookings(auth.user.uid, role));
                } catch (e) {
                    console.log('lôi fetchBookings', e);
                }
                dispatch(fetchBookingNotiOn());
                dispatch(fetchNewCall());
                /*dispatch(fetchCancelReasons());
                dispatch(fetchForceNotify());
                //dispatch(fetchUsers());
                dispatch(fetchOnceUsers());
                dispatch(fetchAllBookings());
                dispatch(fetchDriverEarnings(auth.info.uid, role));
                dispatch(fetchGroupDrivers());
                dispatch(fetchReportDrivers());
                dispatch(fetchAllDrivers());
                //dispatch(fetchAllDriversQueue());
                dispatch(fetchCallsPending());
                dispatch(fetchMistakes());
                dispatch(fetchCallDetailAll());*/
            } else {
                alert(language.not_valid_user_type);
                dispatch(signOutL());
            }
        }
    }, [auth.user, dispatch, fetchBookings, fetchCancelReasons, fetchGroupDrivers, signOutL, fetchNotifications, fetchBookingNotiOn, fetchNewCall]);


    return settingsdata.loading ? (
        <CircularLoading/>
    ) : settingsdata.settings ? (
        auth.loading ? (
            <CircularLoading/>
        ) : (
            props.children
        )
    ) : (
        <div>
            <span>No Database Settings found</span>
        </div>
    );
}

export default AuthLoading;
