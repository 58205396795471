import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Circle, DirectionsRenderer, GoogleMap, InfoWindow, Marker, Polygon, TrafficLayer, withGoogleMap, withScriptjs} from "react-google-maps";
import {language} from "config";
import AddBookings from "../views/AddBookings";
import throttle from 'lodash/throttle';
import {api} from "common";
import {fetchAddressFullfromCoords} from "common/src/other/GoogleAPIFunctions";
import InfoWindowDriver from "./InfoWinfows/InfoWindowDriver";
import MarkerQueue from "./Markers/MarkerQueue";
import {useDispatch, useSelector} from "react-redux";
import UpdatePickupBooking from "../views/UpdatePickupBooking";
import AvailableCarQueue from "./Markers/AvailableCarQueue";
import PopupBookingModal from "./Dialogs/PopupBookingModal";
import {colors} from "../config/theme";
import {makeStyles} from "@material-ui/core/styles";
import store from "common/src/store/store";
import {FareCalculator2} from "common/src/other/FareCalculator";
import {findSurroundingNumbers} from "common/src/other/CommonFunctions";


const useStyles = makeStyles(theme => ({
    labelMarkerQueue: {
        marginTop: -28,
        backgroundColor: colors.BLUE.secondary,
        paddingLeft: 3,
        paddingRight: 3
    },
    labelMarkerActive: {
        marginTop: -28,
        backgroundColor: colors.GREY.secondary,
        paddingLeft: 3,
        paddingRight: 3
    },
    labelMarkerInActive: {
        marginTop: -28,
        backgroundColor: colors.RED,
        paddingLeft: 3,
        paddingRight: 3
    },

}));
const image4Urls = [
    '4x0.png',
    '4x30.png',
    '4x60.png',
    '4x90.png',
    '4x120.png',
    '4x150.png',
    '4x180.png',
    '4x210.png',
    '4x240.png',
    '4x270.png',
    '4x300.png',
    '4x330.png',
];

const image5Urls = [
    '5x0.png',
    '5x30.png',
    '5x60.png',
    '5x90.png',
    '5x120.png',
    '5x150.png',
    '5x180.png',
    '5x210.png',
    '5x240.png',
    '5x270.png',
    '5x300.png',
    '5x330.png',
];

const image7Urls = [
    '7x0.png',
    '7x30.png',
    '7x60.png',
    '7x90.png',
    '7x120.png',
    '7x150.png',
    '7x180.png',
    '7x210.png',
    '7x240.png',
    '7x270.png',
    '7x300.png',
    '7x330.png',
];

const listCar = {
    taxi: image4Urls,
    sedan: image5Urls,
    suv: image7Urls
}


function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = {current: null};

const Map = withScriptjs(withGoogleMap(props => {
        const loaded = useRef(false);
        const {
            userDetail,
            bookingDetail,
            updateTripPickup,
            updateTripDrop
        } = api;
        const classes = useStyles();
        const dispatch = useDispatch();

        if (typeof window !== 'undefined' && !loaded.current && !window.google) {
            if (!document.querySelector('#google-maps')) {
                loadScript(
                    props.googleMapURL,
                    document.querySelector('head'),
                    'google-maps',
                );
            }

            loaded.current = true;
        }
        autocompleteService.current = new window.google.maps.places.AutocompleteService();
        const fetch = useMemo(
            () =>
                throttle((request, callback) => {
                    autocompleteService.current.getPlacePredictions(request, callback);
                }, 200),
            [],
        );

        const [markers, setMarkers] = useState([]);
        const [hideCircle, setHideCircle] = useState(false);
        const [center, setCenter] = useState(null);
        const [carInfo, setCarInfo] = useState(null);
        const auth = useSelector(state => state.auth);
        const bookingdata = useSelector(state => state.bookingdata);
        const [markerSelected, setMarkerSelected] = useState(null);
        const [searchAddress, setSearchAddress] = useState(null);
        const [callCenterDetail, setCallCenterDetail] = useState(null);
        const [bookingObj, setBookingObj] = useState(null);
        const [booking, setBooking] = useState(null);
        const [driverActive, setDriverActive] = useState(null);
        const mapRef = useRef();
        const tripdata = useSelector(state => state.tripdata);
        const [zoom, setZoom] = useState(13);
        const mapType = useSelector(state => state.sessiondata.mapType);
        let cars = store.getState().cartypes.cars;
        const webMapZoom = useSelector(state => state.sessiondata.webMapZoom);
        const onMapLoad = useCallback((map) => {
            mapRef.current = map;
        }, []);
        const [result, setResult] = useState(null);

        const onMapClick = useCallback((mapsMouseEvent) => {
            let addressObjFull = fetchAddressFullfromCoords(mapsMouseEvent.latLng.lat() + "," + mapsMouseEvent.latLng.lng());
            addressObjFull.then(function (result) {
                if (props.isPickup && tripdata.selected == 'drop') {
                    dispatch(updateTripDrop({
                        lat: mapsMouseEvent.latLng.lat(),
                        lng: mapsMouseEvent.latLng.lng(),
                        add: result.formatted_address,
                        from: "map"
                    }));
                }

                if (props.isPickup && tripdata.selected == 'pickup') {
                    dispatch(updateTripPickup({
                        lat: mapsMouseEvent.latLng.lat(),
                        lng: mapsMouseEvent.latLng.lng(),
                        add: result.formatted_address,
                        from: "map"
                    }));
                }

                if (props.isPickup) {
                } else {
                    /*fetch({input: result.formatted_address}, (results) => {
                        if (results) {
                            results[0].coords = {
                                lat: result.geometry.location.lat,
                                lng: result.geometry.location.lng
                            }
                            results[0].placeDetails = result;
                            setHideCircle(!hideCircle);

                            setMarkers(() => [
                                {
                                    lat: mapsMouseEvent.latLng.lat(),
                                    lng: mapsMouseEvent.latLng.lng(),
                                    time: new Date(),
                                    address: results[0],
                                    address_string: result.formatted_address,
                                    circle: {
                                        radius: 3000,
                                        options: {
                                            strokeColor: "#ff0000"
                                        }
                                    }
                                }]);
                        }
                    });*/
                }
            });
        }, [tripdata.selected]);
        const [directions, setDirections] = useState(null);
        const [wayPoints, setWayPoints] = useState(null);
        const [currentLocation, setCurrentLocation] = useState(null);
        // Function to handle zoom changes
        var delayFactor = 0;

        const [markerIconP, setMarkerIconP] = useState(require('../assets/img/ico_start_13xd.png')); // Default icon

        const handleDragStartP = () => {
            //setMarkerIconP(require('../assets/img/ico_start_13xpd.png')); // Change to a different icon when dragging starts
        };
        const handleDragEndP = () => {
            //setMarkerIconP(require('../assets/img/ico_start_13xd.png')); // Change to a different icon when dragging starts
        };


        /*useEffect(() => {
            if (markers) {
                setMarkers(markers.map((m) => {
                        if (m.type == "pickup" && m.icon) {
                            m.icon.url = markerIconP;
                        }
                        return m;
                    })
                )
            }
        }, [markerIconP]);*/

        useEffect(() => {
            let markerList = [];
            if (props.isPickup && tripdata.pickup && tripdata.pickup.lat) {
                if (tripdata.selected == 'pickup') {
                    setCenter({lat: tripdata.pickup.lat, lng: tripdata.pickup.lng});
                }
                setZoom(17);

                markerList[0] = {
                    lat: tripdata.pickup.lat,
                    lng: tripdata.pickup.lng,
                    time: new Date(),
                    type: 'pickup',
                    //address_string: tripdata.pickup.add,
                    icon: {
                        url: markerIconP,
                        scaledSize: new window.google.maps.Size(25, 32)
                    },
                    circle: {
                        radius: 3000,
                        options: {
                            strokeColor: "#ff0000"
                        }
                    }
                }
            }

            if (props.isPickup && tripdata.drop && tripdata.drop.lat) {
                if (tripdata.selected == 'drop') {
                    setCenter({lat: tripdata.drop.lat, lng: tripdata.drop.lng});
                }
                markerList[1] = {
                    lat: tripdata.drop.lat,
                    lng: tripdata.drop.lng,
                    time: new Date(),
                    type: 'drop',
                    //address_string: tripdata.drop.add,
                    icon: {
                        url: (require('../assets/img/ico_stop_23x.png')),
                        scaledSize: new window.google.maps.Size(25, 25)
                    },
                    circle: {
                        radius: 3000,
                        options: {
                            strokeColor: "#ff0000"
                        }
                    }
                }

            }
            setMarkers(markerList);

            if (tripdata.drop && tripdata.pickup) {
                if (props.coords && props.coords.length > 0) {

                } else {
                    getDirections(
                        tripdata.pickup.lat + ',' + tripdata.pickup.lng,
                        tripdata.drop.lat + ',' + tripdata.drop.lng
                    );
                }

            } else {
                setDirections(null);
                setWayPoints(null)
            }

            if (tripdata && tripdata.carType) {
            } else {
                let listCar = cars ? cars.filter(c => c.type == 'taxi') : []
                dispatch(api.updateTripCar(listCar.filter(c => c.code == 'taxi')[0]));
            }

        }, [tripdata.carType, tripdata.drop, tripdata.pickup, tripdata.selected, webMapZoom]);

        //{ latitude: lat, longitude: lng }
        const getDirections = (startLoc, destinationLoc, wayPoints = []) => {
            const waypts = [];
            if (wayPoints && wayPoints.length > 0) {
                waypts.push({
                    location: new window.google.maps.LatLng(
                        wayPoints[0].lat,
                        wayPoints[0].lng
                    ),
                    stopover: true
                });
            }
            const directionService = new window.google.maps.DirectionsService();
            directionService.route(
                {
                    origin: startLoc,
                    destination: destinationLoc,
                    waypoints: waypts,
                    optimizeWaypoints: true,
                    travelMode: window.google.maps.TravelMode.DRIVING
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK && result && result.routes) {
                        if (result.routes[0] && result.routes[0].legs[0].distance.value
                            && result.routes[0].legs[0].duration.value) {
                            let prices = tripdata && tripdata.price ? tripdata.price : null;
                            if (prices) {
                                let fareCalculation = FareCalculator2(result.routes[0].legs[0].distance.value / 1000, parseFloat(result.routes[0].legs[0].duration.value), null, 0, 0, prices);
                                fareCalculation.push(result.routes[0].legs[0].distance.value / 1000);
                                fareCalculation.push(parseFloat(result.routes[0].legs[0].duration.value));
                                dispatch(api.updateEstimate(fareCalculation));
                            }
                        }

                        setDirections(result);
                        setWayPoints(result.routes[0].overview_path.filter((elem, index) => {
                            return index % 30 === 0;
                        }))

                    } else if (
                        status === window.google.maps.DirectionsStatus.OVER_QUERY_LIMIT
                    ) {
                        delayFactor += 0.2;
                        // if (this.delayFactor <= 10) this.delayFactor = 0.2;
                        setTimeout(() => {
                            getDirections(startLoc, destinationLoc, wayPoints);
                        }, delayFactor * 200);
                    } else {
                        console.error(`error fetching directions ${result}`);
                    }
                }
            );
        };

        useEffect(() => {
            if (props.center) {
                setCenter(props.center);
            }
        }, [props.center]);

        useEffect(() => {
            if (props.callDetail) {
                setCallCenterDetail(props.callDetail);
                if (props.callDetail.accept_driver) {
                    dispatch(userDetail(props.callDetail.accept_driver));
                }
                if (props.callDetail.booking_id) {
                    dispatch(bookingDetail(props.callDetail.booking_id));
                }
            }
        }, [props.callDetail]);


        useEffect(() => {
            if (props.bookingObject) {
                setBookingObj(props.bookingObject);
            }
        }, [props.bookingObject]);

        useEffect(() => {
            if (auth.user) {
                setDriverActive(auth.user);
            }
        }, [auth]);

        /*useEffect(() => {
            if (bookingdata.bookingDetail) {
                setBooking(bookingdata.bookingDetail);
            }
        }, [bookingdata]);*/

        const loadCarInfo = useCallback((driver) => {
            if (driver) {
                setCarInfo(driver);
                setCenter(driver.location);
            } else {
                setCarInfo(null);
            }
        }, []);

        useEffect(() => {
            if (driverActive && driverActive.location) {
                setCenter(driverActive.location);
            }
        }, [driverActive]);

        const onMarkerDragEnd = useCallback((e, markerPick, index) => {
            markerPick.lat = e.latLng.lat();
            markerPick.lng = e.latLng.lng();
            markerPick.time = new Date();
            markers[index] = markerPick;
            setMarkers(markers);
        }, []);

        const closeCallback = useCallback(
            () => {
                setDriverActive(null);
                setBooking(null);
                setBookingObj(null);
            },
            [],
        );

        return (
            <>
                {/*{props.isPickup && props.group ?
                    <GroupDrivers
                        loadCarInfo={loadCarInfo}
                    /> : null}
                {props.isPickup ? <Grid item xs={12}>
                        <GoogleMapsAutoComplete
                            apiOptions={{language: 'vi', region: 'vn'}}
                            variant={"outlined"}
                            placeholder={language.pickup_location}
                            value={searchAddress}
                            onChange={
                                (value) => {
                                    setSearchAddress(value);
                                    if (value && value.coords) {
                                        dispatch(updateTripPickup({
                                            lat: value.coords.lat,
                                            lng: value.coords.lng,
                                            add: value.description,
                                            source: "search"
                                        }));
                                    }
                                }
                            }
                        />
                    </Grid>
                    : null}
                <br/>*/}
                {center ?
                    <GoogleMap
                        ref={mapRef}
                        defaultZoom={props.zoom ? props.zoom : zoom}
                        defaultCenter={center}
                        zoom={props.zoom ? props.zoom : zoom}
                        center={center}
                        onClick={tripdata && (tripdata.isEditPickup || tripdata.isEditDrop) ? onMapClick : null}
                        onLoad={onMapLoad}
                        mapTypeId={mapType}
                        mapContainerStyle={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: colors.TRANSPARENT,
                            borderWidth: 1,
                            borderRadius: 10
                        }}
                    >
                        {/*<TrafficLayer autoUpdate/>*/}
                        {/*<View style={{
                            top: 0,
                            position: 'absolute',
                            width: '50%',
                            backgroundColor: colors.WHITE,
                            left: '26%',
                            height: 60,
                            padding: 10,
                            zIndex: 1,
                            borderBottomLeftRadius: 5,
                            borderBottomRightRadius: 5,
                            flexDirection: 'row', flexWrap: "wrap", justifyContent: "space-between"
                        }}>
                            <View style={{
                                marginTop: 10
                            }}>
                                <Text style={{
                                    fontSize: 17
                                }}>{"Chọn điểm đón:"}</Text>
                                <Text style={{
                                    fontSize: 11,
                                    color: 'rgba(35,35,35,0.68)'
                                }}>{"Thu phóng bản đồ để chọn"}</Text>
                            </View>
                            <TouchableOpacity style={{
                                backgroundColor: colors.LINK,
                                borderRadius: 10,
                                padding: 10,
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Text style={{color: colors.WHITE}}>{"Xác nhận"}</Text>
                            </TouchableOpacity>
                        </View>*/}
                        {props.isPickup && props.polygons ?
                            <Polygon
                                path={props.polygons}
                                editable={false}
                                options={{
                                    strokeColor: "rgba(61,146,132,0.40)",
                                    strokeOpacity: 0.8,
                                    strokeWeight: 2,
                                    fillColor: "rgba(61,146,132,0.40)",
                                    fillOpacity: 0.8,
                                    polygonKey: 1
                                }}
                            /> : null
                        }


                        {props.isPickup && props.coords && props.coords.length > 0 ?
                            <Polygon
                                path={props.coords.filter(c => c.type == "metter")}
                                editable={false}
                                options={{
                                    strokeColor: "rgba(7,117,97,0.83)",
                                    strokeOpacity: 1,
                                    strokeWeight: 2,
                                    fillColor: colors.TRANSPARENT,
                                    fillOpacity: 0,
                                    polygonKey: 1
                                }}
                            /> : null
                        }

                        {props.isPickup && props.coords && props.coords.length > 0 ?
                            <Polygon
                                path={props.coords.filter(c => c.type == "accepted")}
                                editable={false}
                                options={{
                                    strokeColor: "rgb(255,0,0)",
                                    strokeOpacity: 1,
                                    strokeWeight: 2,
                                    fillColor: colors.TRANSPARENT,
                                    fillOpacity: 0,
                                    polygonKey: 1
                                }}
                            /> : null
                        }

                        {/*{props.isPickup && props.coords && props.coords.length > 0 ?
                            <Marker
                                position={{lat: props.coords.filter(c => c.type == "accepted")[0].lat, lng: props.coords.filter(c => c.type == "accepted")[0].lng}}
                                key={props.coords.filter(c => c.type == "accepted")[0].lat}
                                onClick={async () => {

                                }}
                                label={
                                    {
                                        color: colors.WHITE,
                                        fontWeight: 'bold',
                                        fontSize: '12px',
                                        text: "Nhận khách",
                                    }
                                }
                            /> : null
                        }*/}



                        {markers ? markers.map((markerPick, index) => {
                            var icon = null;
                            if (markerPick.icon) {
                                icon = markerPick.icon;
                            } else {
                                if (props.isPickup && markerPick.type == 'pickup') {
                                    icon = {
                                        url: markerIconP,
                                        scaledSize: new window.google.maps.Size(25, 25)
                                    }
                                }
                                if (props.isPickup && markerPick.type == 'drop') {
                                    icon = {
                                        url: (require('../assets/img/ico_stop_23x.png')),
                                        scaledSize: new window.google.maps.Size(25, 25)
                                    }
                                }
                            }
                            return (
                                <>
                                    <React.Fragment
                                        key={markerPick.lat + markerPick.lng}
                                    >
                                        <Marker
                                            key={markerPick.time.toISOString()}
                                            position={{lat: markerPick.lat, lng: markerPick.lng}}
                                            icon={icon}
                                            onClick={() => {
                                                if (props.isPickup) {
                                                    if (markerPick.type && markerPick.type == 'pickup') {
                                                        dispatch(api.updatSelPointType('pickup'));
                                                    }
                                                    if (markerPick.type && markerPick.type == 'drop') {
                                                        dispatch(api.updatSelPointType('drop'));
                                                    }
                                                } else {
                                                    loadCarInfo(null);
                                                    setMarkerSelected(markerPick);
                                                    setHideCircle(!hideCircle);
                                                }
                                            }}
                                            onDragStart={handleDragStartP}
                                            draggable={tripdata && (tripdata.isEditPickup && tripdata.selected == 'pickup' && markerPick.type == 'pickup' || tripdata.isEditDrop && tripdata.selected == 'drop' && markerPick.type == 'drop') ? true : false}
                                            /*label={
                                                {
                                                    text: markerPick.type == 'pickup' ? 'Điểm đón' : "Điểm trả",
                                                    className: classes.labelMarker,
                                                    color: colors.WHITE
                                                }
                                            }*/
                                            onDragEnd={(e) => {
                                                handleDragEndP();
                                                markerPick.lat = e.latLng.lat();
                                                markerPick.lng = e.latLng.lng();
                                                let addressObjFull = fetchAddressFullfromCoords(markerPick.lat + "," + markerPick.lng);
                                                addressObjFull.then(function (result) {
                                                    console.log("result", result);
                                                    if (props.isPickup && markerPick.type == 'pickup') {
                                                        let pickupUpdate = tripdata.pickup;
                                                        pickupUpdate.lat = e.latLng.lat();
                                                        pickupUpdate.lng = e.latLng.lng();
                                                        pickupUpdate.add = result.formatted_address;
                                                        pickupUpdate.shortAdd = '';
                                                        dispatch(updateTripPickup(pickupUpdate));
                                                        console.log("data from map", pickupUpdate);
                                                        props.getPickupCallback(pickupUpdate);
                                                    }
                                                    if (props.isPickup && markerPick.type == 'drop') {
                                                        let dropUpdate = tripdata.drop;
                                                        dropUpdate.lat = e.latLng.lat();
                                                        dropUpdate.lng = e.latLng.lng();
                                                        dropUpdate.add = result.formatted_address;
                                                        dropUpdate.shortAdd = '';
                                                        dispatch(updateTripDrop(dropUpdate));
                                                        /*dispatch(updateTripDrop({
                                                            lat: e.latLng.lat(),
                                                            lng: e.latLng.lng(),
                                                            add: result.formatted_address,
                                                            from: "map",
                                                            shortAdd: result.structured_formatting ? result.structured_formatting.main_text : result.description ? result.description.split(',')[0] : '',
                                                            source: "map"

                                                        }));*/
                                                    }

                                                    fetch({input: result.formatted_address}, (results) => {
                                                        if (results) {
                                                            results[0].coords = {
                                                                lat: result.geometry.location.lat,
                                                                lng: result.geometry.location.lng
                                                            }
                                                            results[0].placeDetails = result;
                                                            let selectMk = {
                                                                lat: markerPick.lat,
                                                                lng: markerPick.lng,
                                                                type: markerPick.type ? markerPick.type : null,
                                                                time: new Date(),
                                                                address: results[0],
                                                                address_string: result.formatted_address,
                                                                circle: {
                                                                    radius: 3000,
                                                                    options: {
                                                                        strokeColor: "#ff0000"
                                                                    }
                                                                }
                                                            };

                                                            if (props.isPickup) {
                                                            } else {
                                                                markers[index] = selectMk;
                                                                setMarkers(markers);
                                                                setMarkerSelected(selectMk);
                                                            }
                                                        }
                                                    });
                                                });
                                            }}
                                            title={language.click_to_book}

                                        />
                                        {!props.isPickup && markerPick.circle && (
                                            <Circle
                                                defaultCenter={{
                                                    lat: parseFloat(markerPick.lat),
                                                    lng: parseFloat(markerPick.lng)
                                                }}
                                                visible={hideCircle}
                                                radius={markerPick.circle.radius}
                                                options={markerPick.circle.options}
                                                onClick={() => {
                                                    setHideCircle(!hideCircle);
                                                }}
                                            />
                                        )}
                                    </React.Fragment>
                                </>
                            );
                        }) : null}

                        {props.rows ? props.rows.map((row, index) => {
                            let image = '4x0.png';
                            if (row.location && row.location.heading) {
                                let images = listCar[row.carTypeCode];
                                let resultHeading = findSurroundingNumbers([0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330], row.location.heading);
                                let nameImage = images[0];//x7_270_288x
                                let image0 = nameImage.split("x")[0];
                                image = image0 + "x" + resultHeading.toString() + ".png";
                            }

                            let icon = {
                                url: (require('../assets/img/' + image)),
                                scaledSize: new window.google.maps.Size(30, 30),
                                anchor: {x: 6, y: 15},
                                rotation: 45
                            }

                            if (row.location && row.location.lat) {
                                return (
                                    <>
                                        <React.Fragment
                                            key={row.lat + row.lng}
                                        >
                                            <Marker
                                                position={{lat: row.location.lat, lng: row.location.lng}}
                                                key={row.codeDriver.toString()}
                                                icon={icon}
                                                onClick={async () => {

                                                }}
                                                label={
                                                    {
                                                        color: colors.WHITE,
                                                        fontWeight: 'bold',
                                                        fontSize: '12px',
                                                        text: row.codeDriver,
                                                        className: row.queue ? classes.labelMarkerQueue : row.driverActiveStatus ? classes.labelMarkerActive : classes.labelMarkerInActive
                                                    }
                                                }
                                            />
                                        </React.Fragment>
                                    </>
                                );
                            }

                        }) : null}


                        {/*{listNoteRadius ?
                        listNoteRadius.map((markerPick, index) => {
                            return (
                                <>
                                    <React.Fragment key={"" + (index + 1).toString + "f"}>
                                        <Circle
                                            key={"" + (index + 1).toString + "c"}
                                            defaultCenter={{
                                                lat: markerPick.lat,
                                                lng: markerPick.lng
                                            }}
                                            radius={60}
                                            options={{
                                                fillColor: "rgba(0,89,255,0.56)",
                                                fillOpacity: 0.1,
                                                strokeColor: "rgba(61,146,132,0.2)",
                                                strokeOpacity: 0.1,
                                                strokeWeight: 0.1,
                                            }}
                                        />
                                    </React.Fragment>
                                </>
                            )
                        })
                        : null}*/}

                        {!props.isPickup && markerSelected && markerSelected.address ? (
                            <InfoWindow
                                zIndex={30}
                                options={{marginTop: 101}}
                                position={{lat: Number(markerSelected.lat), lng: Number(markerSelected.lng)}}
                                onCloseClick={() => {
                                    setMarkerSelected(null);
                                }}
                            >
                                {(driverActive && booking) || bookingObj ?
                                    <UpdatePickupBooking {...props} address={markerSelected.address} driverActive={driverActive} booking={booking} bookingObj={bookingObj} closeCallback={closeCallback}/>
                                    : <AddBookings address={markerSelected.address}/>}
                            </InfoWindow>
                        ) : null}

                        {props.isPickup ?
                            <MarkerQueue loadCarInfo={loadCarInfo}/> : null}
                        {props.isPickup && props.groupApply ?
                            <AvailableCarQueue loadCarInfo={loadCarInfo} groupApply={props.groupApply} carType={tripdata.carType ? tripdata.carType : null}/> : null}
                        {props.isPickup && carInfo ?
                            <Marker
                                position={{lat: carInfo.location.lat, lng: carInfo.location.lng}}
                                key={carInfo.id}
                                options={
                                    {
                                        icon: require(`../assets/img/display_` + carInfo.carStatus),
                                        size: {width: 32, height: 32},
                                    }
                                }
                                onClick={async () => {
                                    setMarkerSelected(null);
                                    loadCarInfo(carInfo);
                                }}
                                label={
                                    {
                                        color: '#ffffff',
                                        fontWeight: 'bold',
                                        fontSize: '12px',
                                        text: carInfo.codeDriver,
                                        padding: 10,
                                    }
                                }
                            >
                            </Marker>
                            : null
                        }
                        {props.isPickup && carInfo ?
                            <InfoWindowDriver carInfo={carInfo} loadCarInfo={loadCarInfo}/> : null}

                        {props.openDialogBooking ?
                            <PopupBookingModal isOpen={props.openDialogBooking} handleCloseDialogBooking={props.handleCloseDialogBooking}/> : null}
                        {directions ?
                            <DirectionsRenderer
                                directions={directions}
                                options={{
                                    polylineOptions: {
                                        strokeColor: 'black',
                                        strokeOpacity: 0.8,
                                        strokeWeight: 3
                                    },
                                    preserveViewport: true,
                                    suppressMarkers: true,
                                    icon: {scale: 3}
                                }}
                            /> : null}
                    </GoogleMap> : null
                }
            </>
        )
            ;
    }
));

export default Map;
