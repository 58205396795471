import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import image from "assets/img/background.jpg";
import {useDispatch, useSelector} from "react-redux";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import AlertDialog from '../components/AlertDialog';
import CountrySelect from '../components/CountrySelect';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {cloud_function_server_url, cloud_function_server_url_asian, default_country_code, features, language} from 'config';
import {api, FirebaseContext} from 'common';
import {Paper} from "@material-ui/core";
import {useCookies} from 'react-cookie';
import {validPhoneVN} from "common/src/other/CommonFunctions";
import {colors} from "mobile-app/src/common/theme";
import {useLocation, useNavigate} from "react-router-dom";
import RegularButton from "components/CustomButtons/Button.js";

const useStyles = makeStyles(styles);
export default function LoginPage(props) {
    const {authRef, RecaptchaVerifier, signInWithPhoneNumber} = useContext(FirebaseContext);
    const {
        signIn,
        //facebookSignIn,
        clearLoginError,
        mobileSignIn,
        signOutL,
        sendResetMail,
        checkUserExists
    } = api;

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [loginType, setLoginType] = useState(0);
    const [activeReg, setActiveReg] = useState(false);
    const [openFPModal, setOpenFPModal] = useState(false);
    const [capatchaReady, setCapatchaReady] = useState(false);
    const [verifier, setVerifier] = useState(null);
    const [cookies, setCookie] = useCookies(['user']);
    const [cookieBooking, setCookieBooking, removeCookie] = useCookies(['booking']);
    const [data, setData] = useState({
        email: '',
        pass: '',
        country: default_country_code.phone,
        mobile: '',
        password: '',
        otp: '',
        verificationId: null,
        firstName: '',
        lastName: '',
        selectedcountry: default_country_code,
        usertype: 'rider',
        referralId: ''
    });

    const [tabDisabled, setTabDisabled] = useState(false);
    const [fpEmail, setFpEmail] = useState("");

    const [commonAlert, setCommonAlert] = useState({open: false, msg: ''});

    const classes = useStyles();
    const {...rest} = props;
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!window.recaptchaVerifier) {
            setCapatchaReady(true);
        }
        if (auth.info) {
            if (auth.user) {
                let role = auth.user.usertype;
                if (cookieBooking && cookieBooking.action_content) {
                    navigate('/');
                } else {
                    if (location && location.state && location.state.redirect) {
                        navigate(`/` + location.state.redirect, {
                            state: {step: 3}
                        });
                    } else {
                        if (role === 'admin' || role === 'fleetadmin') {
                            navigate('/callcenter');
                        } else if (role === 'driver') {
                            navigate('/bookings');
                        } else {
                            features.WebsitePagesEnabled ? navigate('/') : navigate('/bookings');
                        }
                    }
                }
            } else {
                if (!activeReg) {
                    setActiveReg(true);
                    if (auth.info.phoneNumber) {
                        setData({...data, mobile: auth.info.phoneNumber})
                        setLoginType(1);
                    } else {
                        setData({...data, email: auth.info.email})
                        setLoginType(0);
                    }
                    setTabDisabled(true);
                    setCommonAlert({open: true, msg: language.login_success});
                }
            }
        }
        if (auth.error && auth.error.flag && auth.error.msg.message !== language.not_logged_in) {
            setCommonAlert({open: true, msg: "Thông tin tài khoản không hợp lệ, vui lòng đăng nhập lại!"})
        }
        if (auth.verificationId) {
            setData({...data, verificationId: auth.verificationId});
        }
    }, [auth.info, auth.error, auth.verificationId, navigate, data, data.email, activeReg, capatchaReady, RecaptchaVerifier]);

    const handleTabChange = (event, newValue) => {
        setLoginType(newValue);
    };
    /*
        const handleFacebook = (e) => {
            e.preventDefault();
            dispatch(facebookSignIn());
        }*/

    const handleCommonAlertClose = (e) => {
        e.preventDefault();
        setCommonAlert({open: false, msg: ''});
        if (api && auth.error.flag) {
            setData({...data, email: '', pass: ''});
            dispatch(clearLoginError());
        }
    };

    const onInputChange = (event) => {
        setData({...data, [event.target.id]: event.target.value})
    }


    const handleZaloSubmit = async (e) => {
        try {
            /*let code_challenge = Base64.encode(sha256.hash(ASCII(code_verifier)))*/


            /*
              returns: {
                accessToken: "some value"
                refreshToken: "some value"
              }
            */
        } catch (error) {
            console.log(error)
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        //eslint-disable-next-line
        //if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(data.email) && data.pass.length > 0) {
        var phoneNumber = data.mobile;
        var password = data.pass;

        if (phoneNumber.length > 5) {
            if (password != '') {
                const response = await fetch(`${cloud_function_server_url_asian}/get_list_fleet_admin`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                var fleets = null;
                console.log("fleets", fleets);
                try {
                    const json = await response.json();
                    fleets = Object.values(json);
                } catch (e) {
                    console.log(e);
                }



                var email = '';
                let dataCheck = null;
                if (phoneNumber.length > 5 && phoneNumber.includes('@')) {
                    email = phoneNumber;
                    dataCheck = {email: phoneNumber};
                    checkUserExists(dataCheck).then(async (res) => {
                        if (res.users && res.users.length > 0) {
                            if (res.users[0].disabled == true) {
                                setCommonAlert({open: true, msg: language.user_does_disable});
                            } else {
                                if (fleets) {
                                    let totalEmpOnline = 0;
                                    let isFleetAdmin = 0;
                                    fleets.map((f) => {
                                        if (f.email !== email && f.isOnline) {
                                            totalEmpOnline = totalEmpOnline + 1;
                                            setCommonAlert({open: true, msg: "Nhân viên " + f.firstName + " " + f.lastName + " đang làm việc, Không thể đăng nhập!"});
                                        }
                                        if (f.email === email) {
                                            isFleetAdmin = isFleetAdmin + 1;
                                        }
                                    });
                                    if (isFleetAdmin > 0) {
                                        if (totalEmpOnline === 0) {
                                            email = res.users[0].email;
                                            dispatch(signIn(email, password));
                                            setCookie('email', email, {path: '/'});
                                            setCookie('pass', data.pass, {path: '/'});
                                            dispatch(signIn(email, data.pass));
                                        }
                                    } else {
                                        email = res.users[0].email;
                                        dispatch(signIn(email, password));
                                        setCookie('email', email, {path: '/'});
                                        setCookie('pass', data.pass, {path: '/'});
                                        dispatch(signIn(email, data.pass));
                                    }
                                } else {
                                    email = res.users[0].email;
                                    dispatch(signIn(email, password));
                                    setCookie('email', email, {path: '/'});
                                    setCookie('pass', data.pass, {path: '/'});
                                    dispatch(signIn(email, data.pass));
                                }
                            }
                        } else {
                            setCommonAlert({open: true, msg: language.user_does_not_exists});
                        }
                    });
                } else {
                    if (validPhoneVN(phoneNumber) === true) {
                        var formattedNum = phoneNumber.toString().replace(/ /g, '');
                        formattedNum = '+84' + formattedNum.toString().replace(/-/g, '');
                        dataCheck = {mobile: formattedNum};

                        checkUserExists(dataCheck).then((res) => {
                            if (res.users && res.users.length > 0) {
                                if (res.users[0].disabled == true) {
                                    setCommonAlert({open: true, msg: language.user_does_disable});
                                } else {
                                    if (fleets) {
                                        let totalEmpOnline = 0;
                                        let isFleetAdmin = 0;
                                        fleets.map((f) => {
                                            if (f.email !== email && f.isOnline) {
                                                totalEmpOnline = totalEmpOnline + 1;
                                                setCommonAlert({open: true, msg: "Nhân viên " + f.firstName + " " + f.lastName + " đang làm việc, Không thể đăng nhập!"});
                                            }
                                            if (f.email === email) {
                                                isFleetAdmin = isFleetAdmin + 1;
                                            }
                                        });
                                        if (isFleetAdmin > 0) {
                                            if (totalEmpOnline === 0) {
                                                email = res.users[0].email;
                                                dispatch(signIn(email, password));
                                                setCookie('email', email, {path: '/'});
                                                setCookie('pass', data.pass, {path: '/'});
                                                dispatch(signIn(email, data.pass));
                                            }
                                        } else {
                                            email = res.users[0].email;
                                            dispatch(signIn(email, password));
                                            setCookie('email', email, {path: '/'});
                                            setCookie('pass', data.pass, {path: '/'});
                                            dispatch(signIn(email, data.pass));
                                        }
                                    }

                                }
                            } else {
                                setCommonAlert({open: true, msg: language.user_does_not_exists});
                            }
                        });
                    } else {
                        setCommonAlert({open: true, msg: "Số điện thoại không hợp lệ, vui lòng kiểm tra lại!"})
                    }
                }
            }
        } else {
            setCommonAlert({open: true, msg: "Tài khoản không hợp lệ, vui lòng thay đổi!"});
        }

        setData({...data, email: '', pass: ''});
    }

    const handleGetOTP = (e) => {
        e.preventDefault();
        const phoneNumber = "+" + data.country + data.mobile;
        if (phoneNumber.length > 6) {

        }
        checkUserExists({mobile: phoneNumber}).then(async (res) => {
            if (res.users && res.users.length > 0) {
                if (!window.recaptchaVerifier || verifier === null) {
                    window.recaptchaVerifier = await new RecaptchaVerifier('sign-in-button', {
                        'size': 'invisible',
                        'callback': function (response) {
                            setCapatchaReady(true);
                        }
                    }, authRef());
                }
                const appVerifier = window.recaptchaVerifier;
                setVerifier(appVerifier);

                await signInWithPhoneNumber(authRef(), phoneNumber, appVerifier)
                    .then(res => {
                        console.log("res login", res);
                        setData({...data, verificationId: res.verificationId});
                        window.recaptchaVerifier.clear();
                    })
                    .catch(error => {
                        console.log("error", error);
                        //setCommonAlert({open: true, msg: error.code + ": " + error.message})
                        setCommonAlert({open: true, msg: "Lỗi đăng nhập, vui lòng liên hệ quản trị!"});
                    });
            } else {
                setCommonAlert({open: true, msg: language.user_does_not_exists});
            }
        });
    }

    const handleVerifyOTP = (e) => {
        e.preventDefault();
        if (data.otp.length === 6 && parseInt(data.otp) > 100000 && parseInt(data.otp) < 1000000) {
            dispatch(mobileSignIn(data.verificationId, data.otp));
        } else {
            setCommonAlert({open: true, msg: language.otp_validate_error})
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        dispatch(signOutL());
        setTabDisabled(false);
        setActiveReg(false);
    }

    const onCountryChange = (object, value) => {
        if (value && value.phone) {
            setData({...data, country: value.phone, selectedcountry: value});
        }
    };

    const handleRegister = (e) => {
        navigate('/register');
    };

    const handleForgotPass = (e) => {
        e.preventDefault();
        setOpenFPModal(true);
    };

    const onFPModalEmailChange = (e) => {
        e.preventDefault();
        setFpEmail(e.target.value);
    }

    const handleCloseFP = (e) => {
        e.preventDefault();
        setFpEmail('');
        setOpenFPModal(false);
    }

    const handleResetPassword = (e) => {
        e.preventDefault();
        dispatch(sendResetMail(fpEmail));
        setFpEmail('');
        setOpenFPModal(false);
    }

    return (
        <div>
            <Header
                absolute
                color="transparent"
                rightLinks={<HeaderLinks/>}
                {...rest}
            />
            <div
                className={classes.pageHeader}
                style={{
                    backgroundImage: "url(" + image + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "top center"
                }}
            >
                <div id="sign-in-button"/>
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={5}>
                            <Card>
                                {cookieBooking && cookieBooking.action_content ?
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <h4 style={{
                                            padding: 20, color: colors.RED, borderWidth: 2,
                                            borderColor: colors.GREEN.default
                                        }}>{"Bạn đang muốn đặt cuốc khách, vui lòng đăng nhập để tiếp tục!"}</h4>

                                    </GridItem> : null}
                                <form className={classes.form}>
                                    {/*{features.FacebookLoginEnabled ?
                                        <CardHeader color="info" className={classes.cardHeader}>
                                            <h4>{language.signIn}</h4>
                                            <div className={classes.socialLine}>
                                                {features.FacebookLoginEnabled ?
                                                    <Button
                                                        justIcon
                                                        href="#pablo"
                                                        target="_blank"
                                                        color="transparent"

                                                        onClick={handleFacebook}
                                                    >
                                                        <i className={"fab fa-facebook"}/>
                                                    </Button>
                                                    : null}
                                            </div>
                                        </CardHeader>
                                        : null}*/}
                                    <Paper square className={classes.root} style={!(features.FacebookLoginEnabled) ? {
                                        paddingTop: 20,
                                        borderTopLeftRadius: 10,
                                        borderTopRightRadius: 10
                                    } : null}>
                                        <Tabs
                                            value={loginType}
                                            onChange={handleTabChange}
                                            variant="fullWidth"
                                            indicatorColor="primary"
                                            textColor="inherit"
                                            aria-label="switch login type"
                                        >
                                            <Tab disabled={tabDisabled} icon={<PhoneIcon/>} label={"Đăng Nhập Mật Khẩu"}
                                                 aria-label="Phone"/>
                                            {features.MobileLoginEnabled ?
                                                <Tab disabled={tabDisabled} icon={<PhoneIcon/>}
                                                     label={language.login_otp} aria-label="Phone OTP"/>
                                                : null}
                                        </Tabs>
                                    </Paper>

                                    <CardBody>
                                        {loginType === 0 ?
                                            <CustomInput
                                                labelText={language.phone}
                                                id="mobile"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    required: true,
                                                    disabled: data.verificationId ? true : false,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <PhoneIcon className={classes.inputIconsColor}/>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                onChange={onInputChange}
                                                value={data.mobile}
                                            />
                                            : null}
                                        {loginType === 0 ?
                                            <CustomInput
                                                labelText={language.password}
                                                id="pass"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    type: "password",
                                                    required: true,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Icon className={classes.inputIconsColor}>
                                                                lock_outline
                                                            </Icon>
                                                        </InputAdornment>
                                                    ),
                                                    autoComplete: "off"
                                                }}
                                                onChange={onInputChange}
                                                value={data.pass}
                                            />
                                            : null}
                                        {loginType === 1 && features.AllowCountrySelection ?   // COUNTRY
                                            <CountrySelect
                                                value={data.selectedcountry}
                                                onChange={onCountryChange}
                                                style={{paddingTop: 20}}
                                                disabled={data.verificationId ? true : false}
                                            />
                                            : null}
                                        {loginType === 1 ?   //MOBILE
                                            <CustomInput
                                                labelText={language.phone}
                                                id="mobile"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    required: true,
                                                    disabled: data.verificationId ? true : false,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <PhoneIcon className={classes.inputIconsColor}/>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                onChange={onInputChange}
                                                value={data.mobile}
                                            />
                                            : null}
                                        {data.verificationId && loginType === 1 ?    // OTP
                                            <CustomInput
                                                labelText={language.otp}
                                                id="otp"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    type: "password",
                                                    required: true,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Icon className={classes.inputIconsColor}>
                                                                lock_outline
                                                            </Icon>
                                                        </InputAdornment>
                                                    ),
                                                    autoComplete: "off"
                                                }}
                                                onChange={onInputChange}
                                                value={data.otp}
                                            />
                                            : null}
                                        {loginType === 0 ?
                                            <RegularButton
                                                onClick={handleForgotPass}
                                                disableElevation={true}
                                                disableFocusRipple={true}
                                                disableRipple={true}
                                                className={classes.forgotButton}
                                                variant="text"
                                            >
                                                {language.forgot_password}
                                            </RegularButton>
                                            : null}
                                    </CardBody>
                                    <CardFooter className={classes.cardFooter}>
                                        {loginType === 0 ?
                                            <Button className={classes.normalButton} simple color="primary" size="lg"
                                                    onClick={handleZaloSubmit}>
                                                {"Đăng nhập zalo"}
                                            </Button>
                                            : null}
                                        {loginType === 0 ?
                                            <Button className={classes.normalButton} simple color="primary" size="lg"
                                                    onClick={handleSubmit}>
                                                {language.login}
                                            </Button>
                                            : null}
                                        {loginType === 0 ?
                                            <Button className={classes.normalButton} simple color="primary" size="lg"
                                                    onClick={handleRegister}>
                                                {language.register}
                                            </Button>
                                            : null}

                                        {loginType === 1 && !data.verificationId ?
                                            <Button className={classes.normalButton} simple color="primary" size="lg"
                                                    onClick={handleGetOTP}>
                                                {language.get_otp}
                                            </Button>
                                            : null}
                                        {loginType === 1 && data.verificationId ?
                                            <Button className={classes.normalButton} simple color="primary" size="lg"
                                                    onClick={handleVerifyOTP}>
                                                {language.verify_otp}
                                            </Button>
                                            : null}

                                        {loginType === 1 && data.verificationId ?
                                            <Button className={classes.normalButton} simple color="primary" size="lg"
                                                    onClick={handleCancel}>
                                                {language.cancel}
                                            </Button>
                                            : null}
                                    </CardFooter>
                                </form>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
                <Footer whiteFont/>
                <Dialog open={openFPModal} onClose={handleCloseFP} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{language.forgot_pass_title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {language.forgot_pass_description}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label={language.email}
                            type="email"
                            fullWidth
                            onChange={onFPModalEmailChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseFP} color="primary">
                            {language.cancel}
                        </Button>
                        <Button onClick={handleResetPassword} color="primary">
                            {language.reset_password}
                        </Button>
                    </DialogActions>
                </Dialog>
                <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
            </div>
        </div>
    );
}
