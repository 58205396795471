import {
    CLEAR_LOGIN_ERROR, FETCH_CATRUCDEM_DRIVER_SUCCESS, FETCH_PRICES_SUCCESS,
    FETCH_USER,
    FETCH_USER_DETAIL,
    FETCH_USER_DETAIL_FAILED,
    FETCH_USER_DETAIL_SUCCESS,
    FETCH_USER_FAILED, FETCH_USER_OFFLINE,
    FETCH_USER_SUCCESS,
    REQUEST_OTP_SUCCESS,
    SEND_RESET_EMAIL,
    SEND_RESET_EMAIL_FAILED,
    SEND_RESET_EMAIL_SUCCESS, UPDATE_SESSION_HAS_LOGOUT,
    UPDATE_SESSION_TOTAL_LOAD,
    UPDATE_USER_PROFILE,
    UPDATE_USER_PROFILE_FAILS,
    USER_DELETED,
    USER_SIGN_IN,
    USER_SIGN_IN_FAILED,
    USER_SIGN_IN_SUCCESS,
    USER_SIGN_OUT
} from "../store/types";

import {cloud_function_server_url, cloud_function_server_url_asian, language} from 'config';
import {onAuthStateChanged, sendPasswordResetEmail, signInWithCredential, signInWithEmailAndPassword, signInWithPopup, signOut} from "firebase/auth";
import {getDownloadURL, uploadBytesResumable} from "firebase/storage";
import store from "../store/store";
import {firebase} from '../config/configureFirebase';
import {get, goOffline, goOnline, off, onValue, remove, set, update} from "firebase/database";
import {useSelector} from "react-redux";
import {deleteBookingTracked, deleteLocal, saveDbFromFbToLocal} from "../other/StorageDbHelper";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const monitorProfileChanges = (uid = null) => (dispatch) => {
    const {
        auth,
        singleUserRef,
        allLocationsRef
    } = firebase;
    let uidCheck = uid ? uid : (auth.currentUser && auth.currentUser.uid ? auth.currentUser.uid : null);

    onAuthStateChanged(auth, user => {
        if (user) {
            onValue(singleUserRef(uidCheck), async snapshot => {
                const locationdata = await get(allLocationsRef);
                const locations = locationdata.val();
                if (snapshot.val()) {
                    let profile = snapshot.val();
                    profile.uid = uidCheck;
                    profile.id = uidCheck;
                    profile.location = locations && locations[uidCheck] ? locations[uidCheck] : null;
                    dispatch({
                        type: UPDATE_USER_PROFILE,
                        payload: profile
                    });

                    await saveDbFromFbToLocal(UPDATE_USER_PROFILE, profile);

                    const totalLoad = store.getState().sessiondata.totalLoadData ? parseInt(store.getState().sessiondata.totalLoadData) : 0;
                    dispatch({
                        type: UPDATE_SESSION_TOTAL_LOAD, payload: totalLoad + 1
                    });

                }
            });
        }
    });


    /*if (auth && uidCheck) {
        onValue(child(singleUserRef(uidCheck), 'queue'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'walletHistory'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'driver_favourite'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'isOnline'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'river_favourite'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'groupDriver'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'cr_bookingid'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'lock_driver_status'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'pushToken'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'group_info'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'vehicleNumberLeader'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'vehicleNumber'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'driverType'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'driverActiveStatus'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'driverVipStatus'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'enableBackgroundSound'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'gpsdt'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'totalBook'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'currentAreaId'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'is_rested_show'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'current_time'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'contermet'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'required_reload'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'timestampingroup'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'codeDriver'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'notifyTotal'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'per'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'perUse'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'password'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'approved'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'approvedDriver'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'approvedDriverReason'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'groupDcarTypeCoderiver'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'contact_limited'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'approvedDriver'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'groupDriver'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

        onValue(child(singleUserRef(uidCheck), 'signupViaReferral'), res => {
            onValue(singleUserRef(uidCheck), res => {
                dispatch({
                    type: UPDATE_USER_PROFILE,
                    payload: res.val()
                });
            }, {onlyOnce: true});
        });

    }*/
}

export const fetchUser = () => (dispatch) => {
    const {
        auth,
        singleUserRef,
        allLocationsRef
    } = firebase;

    dispatch({
        type: FETCH_USER,
        payload: null
    });
    const totalLoad = store.getState().sessiondata.totalLoadData ? parseInt(store.getState().sessiondata.totalLoadData) : 0;
    onAuthStateChanged(auth, async user => {
        if (user && user.uid) {
            onValue(singleUserRef(user.uid), async snapshot => {
                if (snapshot.val()) {
                    const locationdata = await get(allLocationsRef);
                    const locations = locationdata.val();
                    user.profile = snapshot.val();
                    user.profile.uid = user.uid;
                    user.profile.id = user.uid;
                    user.profile.location = locations && locations[user.uid] ? locations[user.uid] : null;

                    dispatch({
                        type: FETCH_USER_SUCCESS,
                        payload: user
                    });
                    await saveDbFromFbToLocal(FETCH_USER_OFFLINE, user.profile);
                    dispatch({
                        type: UPDATE_SESSION_TOTAL_LOAD, payload: totalLoad + 1
                    });

                } else {
                    dispatch({
                        type: FETCH_USER_FAILED,
                        payload: {code: language.auth_error, message: language.not_logged_in}
                    });
                    await deleteLocal(FETCH_USER_OFFLINE);
                }
            });
        } else {
            dispatch({
                type: FETCH_USER_FAILED,
                payload: {code: language.auth_error, message: language.not_logged_in}
            });
            await deleteLocal(FETCH_USER_OFFLINE);
        }
    });
};


export const userDetail = (uid) => (dispatch) => {
    const {
        singleUserRef
    } = firebase;

    dispatch({
        type: FETCH_USER_DETAIL,
        user: null
    });

    onValue(singleUserRef(uid), snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            dispatch({
                type: FETCH_USER_DETAIL_SUCCESS,
                user: data
            });
        } else {
            dispatch({
                type: FETCH_USER_DETAIL_FAILED,
                user: "No user available."
            });
        }
    }, {onlyOnce: true});
}

export const validateReferer = async (referralId) => {
    const response = await fetch(`${cloud_function_server_url_asian}/validate_referrer`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            referralId: referralId
        })
    })
    const json = await response.json();
    return json;
};

export const checkUserExists = async (regData) => {
    const {
        config
    } = firebase;
    let data = null;
    let settings = store.getState().settingsdata.settings;
    if (regData.email && regData.email.includes('@') && regData.mobile > 5 && regData.mobile.includes('+84')) {
        data = {
            email: regData.email,
            mobile: regData.mobile
        }
    } else if (regData.email && regData.email.includes('@')) {
        data = {
            email: regData.email
        }
    } else if (regData.mobile > 5 && regData.mobile.includes('+84')) {
        data = {
            mobile: regData.mobile
        }
    }

    if (regData.codeDriver) {
        data.codeDriver = regData.codeDriver
    }

    //let host = window && window.location && settings.CompanyWebsite === window.location.origin ? window.location.origin : `https://${config.projectId}.web.app`
    let url = `${cloud_function_server_url_asian}/check_user_exists`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });

    console.log("response login", response);

    const json = await response.json();

    return json;
};

export const resetPassword = async (uid) => {
    const response = await fetch(`${cloud_function_server_url_asian}/reset_password`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({uid: uid})
    });

    const json = await response.json();

    return json;
};


export const createTokenUID = async (uid) => {
    const response = await fetch(`${cloud_function_server_url_asian}/create_user_token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            uid: uid
        })
    })
    const json = await response.json();
    return json;
};

export const revokeTokenUID = async (uid) => {
    const response = await fetch(`${cloud_function_server_url_asian}/revoke_user_token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            uid: uid
        })
    })
    const json = await response.json();
    return json;
};

export const emailSignUp = async (regData) => {
    let url = `${cloud_function_server_url_asian}/user_signup`;
    let createDate = new Date();
    regData.createdAt = createDate.toISOString();
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({regData: regData})
    });
    let result = await response.json();
    return result;
};

export const requestPhoneOtpDevice = (verificationId) => (dispatch) => () => {
    dispatch({
        type: REQUEST_OTP_SUCCESS,
        payload: verificationId
    });
}

export const updateUserFromSv = async (profileData) => {
    try {
        let url = `${cloud_function_server_url_asian}/update_user`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(profileData)
        })

        const result = await response.json();
        if (result.error) {
            return {success: false, error: result.error}
        } else {
            return result;
        }
    } catch (error) {
        return {success: false, error: error}
    }
}


export const mobileSignIn = (verficationId, code) => (dispatch) => {
    const {
        auth,
        mobileAuthCredential,
    } = firebase;

    dispatch({
        type: USER_SIGN_IN,
        payload: null
    });
    signInWithCredential(auth, mobileAuthCredential(verficationId, code))
        .then((user) => {
            //OnAuthStateChange takes care of Navigation
        }).catch(error => {
        dispatch({
            type: USER_SIGN_IN_FAILED,
            payload: error
        });
    });
};


export const mobileSignInAndReg = async (verficationId, code) => {
    const {
        auth,
        mobileAuthCredential,
    } = firebase;
    let result = await signInWithCredential(auth, mobileAuthCredential(verficationId, code))
        .then((user) => {
            console.log('result mobileSignInAndReg', result)
            return user;
        }).catch(error => {
            console.log('error mobileSignInAndReg', error)
            return null;
        });
    return result;
};

export const signIn = (email, password) => (dispatch) => {

    const {authRef} = firebase;
    dispatch({
        type: USER_SIGN_IN,
        payload: null
    });
    signInWithEmailAndPassword(authRef(), email, password)
        .then(res => {
            dispatch({
                type: USER_SIGN_IN_SUCCESS,
                payload: res.user ? res.user : res
            });
        })
        .catch(error => {
            console.log('error', error);
            dispatch({
                type: USER_SIGN_IN_FAILED,
                payload: "Sai mật khẩu, vui lòng đăng nhập lại!"
            });
        });
};

export const facebookSignIn = (token) => (dispatch) => {

    const {
        auth,
        facebookProvider,
        facebookCredential,
        singleUserRef
    } = firebase;

    dispatch({
        type: USER_SIGN_IN,
        payload: null
    });
    if (token) {
        const credential = facebookCredential(token);
        signInWithCredential(auth, credential)
            .then((user) => {
                if (user.additionalUserInfo) {
                    onValue(singleUserRef(user.user.uid), snapshot => {
                        if (!snapshot.val()) {
                            let userData = {
                                createdAt: new Date().toISOString(),
                                firstName: user.additionalUserInfo.profile.first_name ? user.additionalUserInfo.profile.first_name : user.additionalUserInfo.profile.name ? user.additionalUserInfo.profile.name : ' ',
                                lastName: user.additionalUserInfo.profile.last_name ? user.additionalUserInfo.profile.last_name : ' ',
                                mobile: user.additionalUserInfo.profile.phoneNumber ? user.additionalUserInfo.profile.phoneNumber : ' ',
                                email: user.additionalUserInfo.profile.email ? user.additionalUserInfo.profile.email : ' ',
                                usertype: 'rider',
                                referralId: (user.additionalUserInfo.profile.first_name ? user.additionalUserInfo.profile.first_name.toLowerCase() : 'temp') + Math.floor(1000 + Math.random() * 9000).toString(),
                                approved: true,
                                touchEnable: false,
                                passwordtouchid: '',
                                walletBalance: 0,
                                loginType: 'facebook'
                            }
                            set(singleUserRef(user.user.uid), userData);
                            updateProfile({...user.user, profile: {}}, userData);
                        }
                    }, {onlyOnce: true});
                }
            })
            .catch(error => {
                    dispatch({
                        type: USER_SIGN_IN_FAILED,
                        payload: error
                    });
                }
            );
    } else {
        signInWithPopup(facebookProvider).then(function (result) {
            var token = result.credential.accessToken;
            const credential = facebookCredential(token);
            signInWithCredential(auth, credential)
                .then((user) => {
                    if (user.additionalUserInfo) {
                        onValue(singleUserRef(user.user.uid), snapshot => {
                            if (!snapshot.val()) {
                                let userData = {
                                    createdAt: new Date().toISOString(),
                                    firstName: user.additionalUserInfo.profile.first_name ? user.additionalUserInfo.profile.first_name : user.additionalUserInfo.profile.name ? user.additionalUserInfo.profile.name : ' ',
                                    lastName: user.additionalUserInfo.profile.last_name ? user.additionalUserInfo.profile.last_name : ' ',
                                    mobile: user.additionalUserInfo.profile.phoneNumber ? user.additionalUserInfo.profile.phoneNumber : ' ',
                                    email: user.additionalUserInfo.profile.email ? user.additionalUserInfo.profile.email : ' ',
                                    usertype: 'rider',
                                    referralId: (user.additionalUserInfo.profile.first_name ? user.additionalUserInfo.profile.first_name.toLowerCase() : 'temp') + Math.floor(1000 + Math.random() * 9000).toString(),
                                    approved: true,
                                    touchEnable: false,
                                    passwordtouchid: '',
                                    walletBalance: 0,
                                    loginType: 'facebook'
                                }
                                set(singleUserRef(user.user.uid), userData);
                                updateProfile({...user.user, profile: {}}, userData);
                            }
                        }, {onlyOnce: true});
                    }
                })
                .catch(error => {
                        dispatch({
                            type: USER_SIGN_IN_FAILED,
                            payload: error
                        });
                    }
                );
        }).catch(function (error) {
            dispatch({
                type: USER_SIGN_IN_FAILED,
                payload: error
            });
        });
    }
};

export const appleSignIn = (credentialData) => (dispatch) => {

    const {
        auth,
        appleProvider,
        singleUserRef
    } = firebase;

    dispatch({
        type: USER_SIGN_IN,
        payload: null
    });
    if (credentialData) {
        const credential = appleProvider.credential(credentialData);
        signInWithCredential(auth, credential)
            .then((user) => {
                if (user.additionalUserInfo) {
                    onValue(singleUserRef(user.user.uid), snapshot => {
                        if (!snapshot.val()) {
                            let userData = {
                                createdAt: new Date().toISOString(),
                                firstName: ' ',
                                lastName: ' ',
                                mobile: ' ',
                                email: user.additionalUserInfo.profile.email ? user.additionalUserInfo.profile.email : ' ',
                                usertype: 'rider',
                                referralId: 'rider' + Math.floor(1000 + Math.random() * 9000).toString(),
                                approved: true,
                                touchEnable: false,
                                passwordtouchid: '',
                                walletBalance: 0,
                                loginType: 'apple'
                            }
                            set(singleUserRef(user.user.uid), userData);
                            updateProfile({...user.user, profile: {}}, userData);
                        }
                    }, {onlyOnce: true});
                }
            })
            .catch((error) => {
                dispatch({
                    type: USER_SIGN_IN_FAILED,
                    payload: error
                });
            });
    } else {
        signInWithPopup(auth, appleProvider).then(function (result) {
            signInWithCredential(auth, result.credential)
                .then((user) => {
                    if (user.additionalUserInfo) {
                        onValue(singleUserRef(user.user.uid), snapshot => {
                            if (!snapshot.val()) {
                                let userData = {
                                    createdAt: new Date().toISOString(),
                                    firstName: ' ',
                                    lastName: ' ',
                                    mobile: ' ',
                                    email: user.additionalUserInfo.profile.email ? user.additionalUserInfo.profile.email : ' ',
                                    usertype: 'rider',
                                    referralId: 'rider' + Math.floor(1000 + Math.random() * 9000).toString(),
                                    approved: true,
                                    touchEnable: false,
                                    passwordtouchid: '',
                                    walletBalance: 0,
                                    loginType: 'apple'
                                }
                                set(singleUserRef(user.user.uid), userData);
                                updateProfile({...user.user, profile: {}}, userData);
                            }
                        }, {onlyOnce: true});
                    }
                })
                .catch(error => {
                        dispatch({
                            type: USER_SIGN_IN_FAILED,
                            payload: error
                        });
                    }
                );
        }).catch(function (error) {
            dispatch({
                type: USER_SIGN_IN_FAILED,
                payload: error
            });
        });
    }
};

export const googleSignIn = (token) => (dispatch) => {
    const {
        auth,
        googleProvider,
        googleCredential,
        singleUserRef
    } = firebase;

    dispatch({
        type: USER_SIGN_IN,
        payload: null
    });
    if (token) {
        const credential = googleCredential(token);
        signInWithCredential(auth, credential)
            .then((user) => {
                if (user.additionalUserInfo) {
                    onValue(singleUserRef(user.user.uid), snapshot => {
                        if (!snapshot.val()) {
                            let userData = {
                                createdAt: new Date().toISOString(),
                                firstName: user.additionalUserInfo.profile.first_name ? user.additionalUserInfo.profile.first_name : user.additionalUserInfo.profile.name ? user.additionalUserInfo.profile.name : ' ',
                                lastName: user.additionalUserInfo.profile.last_name ? user.additionalUserInfo.profile.last_name : ' ',
                                mobile: user.additionalUserInfo.profile.phoneNumber ? user.additionalUserInfo.profile.phoneNumber : ' ',
                                email: user.additionalUserInfo.profile.email ? user.additionalUserInfo.profile.email : ' ',
                                usertype: 'rider',
                                referralId: (user.additionalUserInfo.profile.first_name ? user.additionalUserInfo.profile.first_name.toLowerCase() : 'temp') + Math.floor(1000 + Math.random() * 9000).toString(),
                                approved: true,
                                touchEnable: false,
                                passwordtouchid: '',
                                walletBalance: 0,
                                loginType: 'facebook'
                            }
                            set(singleUserRef(user.user.uid), userData);
                            updateProfile({...user.user, profile: {}}, userData);
                        }
                    });
                }
            })
            .catch(error => {
                    dispatch({
                        type: USER_SIGN_IN_FAILED,
                        payload: error
                    });
                }
            );
    } else {
        signInWithPopup(auth, googleProvider).then(function (result) {
            var token = result.credential.accessToken;
            const credential = googleCredential(token);
            signInWithCredential(auth, credential)
                .then((user) => {
                    if (user.additionalUserInfo) {
                        onValue(singleUserRef(user.user.uid), snapshot => {
                            if (!snapshot.val()) {
                                let userData = {
                                    createdAt: new Date().toISOString(),
                                    firstName: user.additionalUserInfo.profile.first_name ? user.additionalUserInfo.profile.first_name : user.additionalUserInfo.profile.name ? user.additionalUserInfo.profile.name : ' ',
                                    lastName: user.additionalUserInfo.profile.last_name ? user.additionalUserInfo.profile.last_name : ' ',
                                    mobile: user.additionalUserInfo.profile.phoneNumber ? user.additionalUserInfo.profile.phoneNumber : ' ',
                                    email: user.additionalUserInfo.profile.email ? user.additionalUserInfo.profile.email : ' ',
                                    usertype: 'rider',
                                    referralId: (user.additionalUserInfo.profile.first_name ? user.additionalUserInfo.profile.first_name.toLowerCase() : 'temp') + Math.floor(1000 + Math.random() * 9000).toString(),
                                    approved: true,
                                    touchEnable: false,
                                    passwordtouchid: '',
                                    walletBalance: 0,
                                    loginType: 'google'
                                }
                                set(singleUserRef(user.user.uid), userData);
                                updateProfile({...user.user, profile: {}}, userData);
                            }
                        });
                    }
                })
                .catch(error => {
                        dispatch({
                            type: USER_SIGN_IN_FAILED,
                            payload: error
                        });
                    }
                );
        }).catch(function (error) {
            dispatch({
                type: USER_SIGN_IN_FAILED,
                payload: error
            });
        });
    }
};

export const signOutL = () => async (dispatch) => {
    const {
        auth,
        walletHistoryRef,
        singleUserRef
    } = firebase;

    const authState = store.getState().auth;
    const uid = auth && auth.currentUser && auth.currentUser ? auth.currentUser.uid : authState && authState.user ? authState.user.id : null;
    console.log("đăng xuất thành công 2", auth && auth.currentUser && uid ? true : false);
    off(singleUserRef(uid));
    off(walletHistoryRef(uid));
    if (auth && auth.currentUser && uid) {
        let updateData = {
            pushToken: '',
            isOnline: false,
            driverActiveStatus: false,
            sessionLogin: false
        };
        await update(singleUserRef(uid), updateData);
        console.log("đăng xuất thành công 1");
        console.log("đăng xuất thành công 3");
        await signOut(auth);
        console.log("đăng xuất thành công");
        await deleteLocal(FETCH_USER_OFFLINE);
        await deleteLocal(UPDATE_USER_PROFILE);
        await deleteLocal(FETCH_CATRUCDEM_DRIVER_SUCCESS);
        await deleteLocal(FETCH_PRICES_SUCCESS);
        dispatch({
            type: USER_SIGN_OUT,
            payload: null
        });
        dispatch({
            type: UPDATE_SESSION_HAS_LOGOUT, payload: true
        });
    }

};

export const deleteUser = (uid) => async (dispatch) => {
    const {
        singleUserRef,
        bookingListRef,
        auth
    } = firebase;

    remove(singleUserRef(uid)).then(() => {
        if (auth.currentUser && auth.currentUser.uid == uid) {
            signOut(auth);
            dispatch({
                type: USER_DELETED,
                payload: null
            });
        }
    });
};


export const updateProfileByUid = (uid, updateData) => {
    const {
        auth,
        singleUserRef
    } = firebase;

    let user = store.getState().auth.user;
    console.log('auth.currentUser', auth)
    if (uid && auth) {
        onValue(singleUserRef(uid), snapshot => {
            if (snapshot.val()) {
                update(singleUserRef(uid), updateData);
            }
        }, {onlyOnce: true});
        if (user) {
            const mergedObject = Object.assign({}, user, updateData);
            store.dispatch({
                type: FETCH_USER_OFFLINE, payload: mergedObject
            });
        }
    }
};

export const updateProfile = (userAuthData, updateData) => (dispatch) => {

    const {
        auth,
        singleUserRef
    } = firebase;
    let profile = userAuthData.profile;
    profile = {...profile, ...updateData}

    if (userAuthData.uid && userAuthData.uid != 'undefined') {
        update(singleUserRef(userAuthData.uid), updateData);
    }

    if (updateData.email && auth.currentUser) {
        auth.currentUser.updateEmail(updateData.email).then(function () {
            if (updateData.password) {
                auth.currentUser.updatePassword(profile.password).then(function () {
                    dispatch({
                        type: UPDATE_USER_PROFILE,
                        payload: profile
                    });
                }).catch(function (error) {
                    dispatch({
                        type: UPDATE_USER_PROFILE_FAILS,
                        payload: "Vui lòng đăng nhập lại để thay đổi thông tin lần đầu"
                    });
                });
            }
        }).catch(function (error) {
            dispatch({
                type: UPDATE_USER_PROFILE_FAILS,
                payload: "Vui lòng đăng nhập lại để thay đổi thông tin lần đầu"
            });
        });
    } else if (updateData.password && auth.currentUser) {
        auth.currentUser.updatePassword(profile.password).then(function () {
            dispatch({
                type: UPDATE_USER_PROFILE,
                payload: profile
            });
        }).catch(function (error) {
            dispatch({
                type: UPDATE_USER_PROFILE_FAILS,
                payload: "Vui lòng đăng nhập lại để thay đổi thông tin lần đầu"
            });
        });
    } else {
        dispatch({
            type: UPDATE_USER_PROFILE,
            payload: profile
        });
    }

};

export const updateProfileImage = (userAuthData, imageBlob) => (dispatch) => {

    const {
        singleUserRef,
        profileImageRef,
    } = firebase;

    uploadBytesResumable(profileImageRef(userAuthData.uid), imageBlob).then(() => {
        imageBlob.close()
        return getDownloadURL(profileImageRef(userAuthData.uid));
    }).then((url) => {
        let profile = userAuthData.profile;
        profile.profile_image = url;
        update(singleUserRef(userAuthData.uid), {
            profile_image: url
        });
        /*dispatch({
            type: UPDATE_USER_PROFILE,
            payload: profile
        });*/
    })
};

export const updateCmTImage = (userAuthData, imageBlob) => (dispatch) => {

    const {
        singleUserRef,
        cmndRef,
    } = firebase;

    uploadBytesResumable(cmndRef(userAuthData.uid), imageBlob).then(() => {
        imageBlob.close()
        return getDownloadURL(cmndRef(userAuthData.uid))
    }).then((url) => {
        let profile = userAuthData.profile;
        profile.cmnd = url;
        update(singleUserRef(userAuthData.uid), {
            cmnd: url
        });
        /*dispatch({
            type: UPDATE_USER_PROFILE,
            payload: profile
        });*/
    })
};

export const updateCmsImage = (userAuthData, imageBlob) => (dispatch) => {

    const {
        singleUserRef,
        cmndSRef,
    } = firebase;

    uploadBytesResumable(cmndSRef(userAuthData.uid), imageBlob).then(() => {
        imageBlob.close()
        return getDownloadURL(cmndSRef(userAuthData.uid));
    }).then((url) => {
        let profile = userAuthData.profile;
        profile.cmnds = url;
        update(singleUserRef(userAuthData.uid), {
            cmnds: url
        });
        /*dispatch({
            type: UPDATE_USER_PROFILE,
            payload: profile
        });*/
    })
};

export const updateLisenceImage = (userAuthData, imageBlob) => (dispatch) => {

    const {
        singleUserRef,
        licenseImageRef,
    } = firebase;

    uploadBytesResumable(licenseImageRef(userAuthData.uid), imageBlob).then(() => {
        imageBlob.close()
        return getDownloadURL(licenseImageRef(userAuthData.uid));
    }).then((url) => {
        let profile = userAuthData.profile;
        profile.license = url;
        update(singleUserRef(userAuthData.uid), {
            license: url
        });
        /*dispatch({
            type: UPDATE_USER_PROFILE,
            payload: profile
        });*/
    })
};

export const updateLisencesImage = (userAuthData, imageBlob) => (dispatch) => {

    const {
        singleUserRef,
        licenseSImageRef,
    } = firebase;

    uploadBytesResumable(licenseSImageRef(userAuthData.uid), imageBlob).then(() => {
        imageBlob.close()
        return getDownloadURL(licenseSImageRef(userAuthData.uid));
    }).then((url) => {
        let profile = userAuthData.profile;
        profile.licenses = url;
        update(singleUserRef(userAuthData.uid), {
            licenses: url
        });
        /*dispatch({
            type: UPDATE_USER_PROFILE,
            payload: profile
        });*/
    })
};


export const updateRegCarImage = (userAuthData, imageBlob) => (dispatch) => {

    const {
        singleUserRef,
        regCarImageRef,
    } = firebase;

    uploadBytesResumable(regCarImageRef(userAuthData.uid), imageBlob).then(() => {
        imageBlob.close()
        return getDownloadURL(regCarImageRef(userAuthData.uid));
    }).then((url) => {
        let profile = userAuthData.profile;
        profile.regcar = url;
        update(singleUserRef(userAuthData.uid), {
            regcar: url
        });
        /*dispatch({
            type: UPDATE_USER_PROFILE,
            payload: profile
        });*/
    })
};

export const updateRegCarsImage = (userAuthData, imageBlob) => (dispatch) => {

    const {
        singleUserRef,
        regCarsImageRef,
    } = firebase;

    uploadBytesResumable(regCarsImageRef(userAuthData.uid), imageBlob).then(() => {
        imageBlob.close()
        return getDownloadURL(regCarsImageRef(userAuthData.uid))
    }).then((url) => {
        let profile = userAuthData.profile;
        profile.regcars = url;
        update(singleUserRef(userAuthData.uid), {
            regcars: url
        });
        /*dispatch({
            type: UPDATE_USER_PROFILE,
            payload: profile
        });*/
    })
};

export const updateBSXImage = (userAuthData, imageBlob) => (dispatch) => {
    const {
        singleUserRef,
        anhBienSoXeRef,
    } = firebase;

    uploadBytesResumable(anhBienSoXeRef(userAuthData.uid), imageBlob).then(() => {
        imageBlob.close()
        return getDownloadURL(anhBienSoXeRef(userAuthData.uid))
    }).then((url) => {
        let profile = userAuthData.profile;
        profile.biensoxe = url;
        update(singleUserRef(userAuthData.uid), {
            biensoxe: url
        });
        /*dispatch({
            type: UPDATE_USER_PROFILE,
            payload: profile
        });*/
    })
};

export const updateAvatarImage = (userAuthData, imageBlob) => (dispatch) => {
    const {
        singleUserRef,
        avatarRef,
    } = firebase;

    uploadBytesResumable(avatarRef(userAuthData.uid), imageBlob).then(() => {
        imageBlob.close()
        return getDownloadURL(avatarRef(userAuthData.uid))
    }).then((url) => {
        let profile = userAuthData.profile;
        profile.avatar = url;
        update(singleUserRef(userAuthData.uid), {
            avatar: url
        });
        /*dispatch({
            type: UPDATE_USER_PROFILE,
            payload: profile
        });*/
    })
};

export const updateAudio = async (uid, imageBlob) => {
    const {
        audioRef,
    } = firebase;


    uploadBytesResumable(audioRef(uid), imageBlob).then(() => {
        imageBlob.close()
        return getDownloadURL(audioRef(uid))
    }).then(async (url) => {
        console.log("url", url);
        try {
            let data = {
                uid: uid,
                uri: "gs://eto-viet.appspot.com/tmp/users/" + uid + "/driverDocuments/audio.wav"
            }
            let url = `https://asia-east1-eto-viet.cloudfunctions.net/Google_Speech_to_Text`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            })

            console.log("result", response);
            const result = await response.json();
            if (result.error) {
                return {success: false, error: result.error}
            } else {
                return result;
            }
        } catch (error) {
            return {success: false, error: error}
        }
    })

};

export const updatePushToken = (token, platform) => {

    const {
        auth,
        singleUserRef,
    } = firebase;
    const authState = store.getState().auth;
    const uid = auth && auth.currentUser && auth.currentUser ? auth.currentUser.uid : authState && authState.user ? authState.user.id : null;
    update(singleUserRef(uid), {
        pushToken: token,
        userPlatform: platform
    });

};

export const updateGroupDriver = (userAuthData, uid, groupDriver) => (dispatch) => {
    const {
        singleUserRef
    } = firebase;

    let profile = userAuthData.profile;
    dispatch({
        type: UPDATE_USER_PROFILE,
        payload: profile
    });

    update(singleUserRef(uid), {
        groupDriver: groupDriver
    });

};

export const clearLoginError = () => (dispatch) => {
    dispatch({
        type: CLEAR_LOGIN_ERROR,
        payload: null
    });
};

export const sendResetMail = (email) => (dispatch) => {

    const {
        auth,
    } = firebase;

    dispatch({
        type: SEND_RESET_EMAIL,
        payload: email
    });
    sendPasswordResetEmail(email).then(function () {
        dispatch({
            type: SEND_RESET_EMAIL_SUCCESS,
            payload: {
                code: language.success,
                message: language.reset_pass_msg
            }
        });
    }).catch(function (error) {
        dispatch({
            type: SEND_RESET_EMAIL_FAILED,
            payload: error
        });
    });
};


