import {
    CANCEL_BOOKING, CONFIRM_BOOKING, CONFIRM_BOOKING_FAILED, CONFIRM_BOOKING_SUCCESS, FETCH_BOOKING_BY_ID, FETCH_BOOKING_BY_ID_CUSTOMER, FETCH_BOOKING_BY_ID_CUSTOMER_FAILED, FETCH_BOOKING_BY_ID_CUSTOMER_SUCCESS, FETCH_BOOKING_BY_ID_FAILED, FETCH_BOOKING_BY_ID_SUCCESS, FETCH_BOOKING_TRACKER, FETCH_BOOKING_TRACKER_SUCCESS,
    FETCH_BOOKINGS, FETCH_BOOKINGS_COMPLETED, FETCH_BOOKINGS_COMPLETED_SUCCESS, FETCH_BOOKINGS_CUSTOMER, FETCH_BOOKINGS_CUSTOMER_FAILED, FETCH_BOOKINGS_CUSTOMER_SUCCESS,
    FETCH_BOOKINGS_FAILED,
    FETCH_BOOKINGS_LATER, FETCH_BOOKINGS_LATER_FAILED,
    FETCH_BOOKINGS_LATER_SUCCESS,
    FETCH_BOOKINGS_SUCCESS,
    FETCH_BOOKINGS_TEAM,
    FETCH_BOOKINGS_TEAM_FAILED,
    FETCH_BOOKINGS_TEAM_SUCCESS, FETCH_NEWS_BOOKINGS, FETCH_NEWS_BOOKINGS_FAILED, FETCH_NEWS_BOOKINGS_SUCCESS,
    FETCH_NOTES,
    FETCH_NOTES_FAILED,
    FETCH_NOTES_SUCCESS, FETCH_ONCE_ALL_BOOKINGS, FETCH_ONCE_ALL_BOOKINGS_BY_CODE, FETCH_ONCE_ALL_BOOKINGS_BY_CODE_SUCCESS, FETCH_ONCE_ALL_BOOKINGS_BY_CUS, FETCH_ONCE_ALL_BOOKINGS_BY_CUS_FAILED, FETCH_ONCE_ALL_BOOKINGS_BY_CUS_SUCCESS, FETCH_ONCE_ALL_BOOKINGS_SUCCESS, FETCH_TOTAL_BOOKINGS_CANCEL, FETCH_TOTAL_BOOKINGS_CANCEL_SUCCESS,
    STOP_FETCH_BOOKINGS,
    UPDATE_BOOKING
} from "../store/types";

const INITIAL_STATE = {
    bookings: null,
    newbookings: null,
    booking_later: [],
    booking_driver_later: [],
    booking_teams: [],
    booking_news: [],
    booking_active: null,
    customer_bookings: [],
    booking_new: null,
    listBookAliasDriver: [],
    booking_active_customer: null,
    active: [],
    tracked: null,
    loading: false,
    notes: null,
    totalBookingCancel: null,
    loadingBk: false,
    totalBookingIgnore: null,
    listBookingByCusPhone: [],
    listBookingByCode: [],
    bookingTracked: null,
    error: {
        flag: false,
        msg: null
    },
    bookingCompleted: null
}

export const bookingslistreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case STOP_FETCH_BOOKINGS:
            return {
                ...state,
                bookings: null,
                active: [],
                tracked: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case FETCH_BOOKINGS:
            return {
                ...state,
                loading: true
            };
        case FETCH_BOOKINGS_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };

        case FETCH_BOOKINGS_COMPLETED:
            return {
                ...state,
                bookingCompleted: null,
                loading: true
            };
        case FETCH_BOOKINGS_COMPLETED_SUCCESS:
            return {
                ...state,
                bookingCompleted: action.payload.bookingCompleted,
                loading: false
            };

        case FETCH_BOOKINGS_FAILED:
            return {
                ...state,
                bookings: null,
                active: [],
                tracked: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };


        case FETCH_BOOKINGS_CUSTOMER:
            return {
                ...state,
                loading: true
            };
        case FETCH_BOOKINGS_CUSTOMER_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case FETCH_BOOKINGS_CUSTOMER_FAILED:
            return {
                ...state,
                customer_bookings: [],
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };


        case FETCH_NEWS_BOOKINGS:
            return {
                ...state,
                loading: true
            };
        case FETCH_NEWS_BOOKINGS_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case FETCH_NEWS_BOOKINGS_FAILED:
            return {
                ...state,
                booking_news: [],
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case CONFIRM_BOOKING_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case CONFIRM_BOOKING_FAILED:
            return {
                ...state,
                booking_new: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case FETCH_BOOKINGS_LATER:
            return {
                ...state,
                loading: true
            };
        case FETCH_BOOKINGS_LATER_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case FETCH_BOOKINGS_LATER_FAILED:
            return {
                ...state,
                Phone: [],
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case FETCH_ONCE_ALL_BOOKINGS_BY_CUS:
            return {
                ...state,
                loadingBk: true
            };
        case FETCH_ONCE_ALL_BOOKINGS_BY_CUS_SUCCESS:
            return {
                ...state,
                listBookingByCusPhone: action.payload.listBookingByCusPhone,
                loadingBk: false
            };

        case FETCH_ONCE_ALL_BOOKINGS_BY_CODE:
            return {
                ...state,
                loadingBk: true
            };
        case FETCH_ONCE_ALL_BOOKINGS_BY_CODE_SUCCESS:
            return {
                ...state,
                listBookingByCode: action.payload.listBookingByCode,
                loadingBk: false
            };

        case FETCH_ONCE_ALL_BOOKINGS_BY_CUS_FAILED:
            return {
                ...state,
                listBookingByCusPhone: [],
                loadingBk: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case FETCH_BOOKINGS_TEAM:
            return {
                ...state,
                loading: true
            };
        case FETCH_BOOKINGS_TEAM_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case FETCH_BOOKINGS_TEAM_FAILED:
            return {
                ...state,
                booking_teams: [],
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case FETCH_ONCE_ALL_BOOKINGS:
            return {
                ...state,
                loading: true
            };
        case FETCH_ONCE_ALL_BOOKINGS_SUCCESS:
            return {
                ...state,
                listBookAliasDriver: action.payload.listBookAliasDriver,
                loading: false
            };

        case FETCH_NOTES:
            return {
                ...state,
                loading: true
            };
        case FETCH_NOTES_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };

        case FETCH_TOTAL_BOOKINGS_CANCEL:
            return {
                ...state,
                loading: true
            };
        case FETCH_TOTAL_BOOKINGS_CANCEL_SUCCESS:
            return {
                ...state,
                totalBookingCancel: action.payload.totalBookingCancel,
                totalBookingIgnore: action.payload.totalBookingIgnore,
                loading: false
            };

        case FETCH_NOTES_FAILED:
            return {
                ...state,
                notes: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case UPDATE_BOOKING:
            return {
                ...state
            }
        case CANCEL_BOOKING:
            return {
                ...state
            };

        case CONFIRM_BOOKING:
            return {
                ...state,
                loading: true
            };
        case FETCH_BOOKING_BY_ID:
            return {
                ...state,
                loading: true
            };

        case FETCH_BOOKING_BY_ID_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case FETCH_BOOKING_BY_ID_FAILED:
            return {
                ...state,
                booking_active: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };


        case FETCH_BOOKING_BY_ID_CUSTOMER:
            return {
                ...state,
                loading: true
            };

        case FETCH_BOOKING_BY_ID_CUSTOMER_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case FETCH_BOOKING_BY_ID_CUSTOMER_FAILED:
            return {
                ...state,
                booking_active_customer: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case FETCH_BOOKING_TRACKER:
            return {
                ...state,
                bookingTracked: null,
                loading: true,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case FETCH_BOOKING_TRACKER_SUCCESS:
            return {
                ...state,
                bookingTracked: action.payload,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        default:
            return state;
    }
};