import React, {useCallback, useEffect, useState} from 'react';
import Map from '../components/Map';
import {Google_Map_Key} from "config";
import {Modal, Platform, SafeAreaView, View} from "react-native-web";
import {makeStyles} from "@material-ui/core/styles";
import {api, store} from "common";
import {useDispatch, useSelector} from "react-redux";
import {colors} from "../config/theme";
import {ConvertTimeStampToDate, GetCurrentMiniTimeStamp, GetCurrentTimeStamp} from "common/src/other/DateFunctions";
import CancelCallCenter from "../components/CallCenter/CancelCallCenter";
import {CALL_DETAIL_DATA, SELECT_BOOKING_DETAIL_SUCCESS} from "common/src/store/types";
import {useLocation} from "react-router-dom";
import RightDashboardComponent from "../components/RightDashboardComponent";
import {Dimensions} from "react-native";
import InfoBookingDialog from "../components/Dashboard/InfoBookingDialog";
import AddCallCenter from "../components/CallCenter/AddCallCenter";
import ThemXeCallCenter from "../components/CallCenter/ThemXeCallCenter";
import {fetchBookingsLater, stopFetchBookingsLater} from "common/src/actions/bookinglistactions";
import CallNowCenter from "../components/CallCenter/CallNowCenter";

var {height, width} = Dimensions.get('window');

const useStyles = makeStyles(theme => ({
    fullHeight: {
        height: Platform.OS === 'web' ? '100vh' : '100%'
    },
    container: {
        flex: 1,
        backgroundColor: "#ababab",
    },
    footer: {
        flexDirection: 'row',
        backgroundColor: 'white',
        borderBottomColor: '#666666',
    },
    input: {
        paddingHorizontal: 20,
        fontSize: 18,
        flex: 1
    },
    send: {
        alignSelf: 'center',
        color: 'lightseagreen',
        fontSize: 16,
        fontWeight: 'bold',
        padding: 20
    },
    diaglog: {
        right: 0
    }
}));

const Dashboard = (props) => {
    const {
        updateTripCallCenter,
        updateTripGroup,
        fetchBookingsLater,
        stopFetchBookingsLater
    } = api;
    const dispatch = useDispatch();

    const auth = useSelector((state) => state.auth);
    const [mylocation, setMylocation] = useState(null);
    const classes = useStyles();
    const [callCenterDetail, setCallCenterDetail] = useState(null);
    const [bookingObj, setBookingObj] = useState(null);
    const [soundAlert, setSoundAlert] = useState(null);
    const [soundAlert2, setSoundAlert2] = useState(null);
    const [soundAlertViPham, setSoundAlertViPham] = useState(null);
    const [soundAlertHuy, setSoundAlertHuy] = useState(null);
    const [soundAlertHoTro, setSoundAlertHoTro] = useState(null);
    const [soundAlertDoiCa, setSoundAlertDoiCa] = useState(null);
    const [showCallNow, setShowCallNow] = useState(null);
    const [showCallPopup, setShowCallPopup] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [showCallModal, setShowCallModal] = useState(false);
    const [searchAddress, setSearchAddress] = useState(null);
    const [polygons, setPolygons] = useState(null);
    const [group, setGroup] = useState(null);
    const [groupApply, setGroupApply] = useState(null);
    const [pickupFromMap, setPickupFromMap] = useState(null);
    const [dropFromMap, setDropFromMap] = useState(null);
    const [listGroup, setListGroup] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialogBooking, setOpenDialogBooking] = useState(false);
    const [showCancel, setShowCancel] = useState(false);
    const [openLeft, setOpenLeft] = useState(false);
    const [openUpdateBooking, setOpenUpdateBooking] = useState(false);
    const [openRight, setOpenRight] = useState(true);
    const [showThemXe, setShowThemXe] = useState(false);
    const [driverSelect, setDriverSelect] = useState(true);
    const [openBottom, setOpenBottom] = useState(true);
    const location = useLocation();
    const [rows, setRows] = useState([]);
    const [coords, setCoords] = useState([]);
    const callDetail = useSelector(state => state.sessiondata.callDetail);
    const webMapZoom = useSelector(state => state.sessiondata.webMapZoom);
    const tabIndex = useSelector(state => state.sessiondata.tabIndex);
    const [addBookingDialog, setAddBookingDialog] = useState(false);
    const [isEditPickup, setIsEditPickup] = useState(false);
    useEffect(() => {
        loadSoundHoTro();
        loadSound2();
        loadSoundViPham();
        loadSoundDoiCa();
        return () => {
        };
    }, []);

    useEffect(() => {
        if (tabIndex == 1) {
            navigator.geolocation.getCurrentPosition(
                position => setMylocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                }),
                err => console.log(err)
            );
        }
        if (tabIndex == 4) {
            console.log('khởi tạo cuốc khách');
            initCallCenter();
        } else {
            dispatch({
                type: CALL_DETAIL_DATA, payload: null,
            });
        }
    }, [tabIndex]);

    const initCallCenter = () => {
        let call = {
            action: "note_call",
            checksum: GetCurrentMiniTimeStamp(),
            destination: "101",
            msnv: auth.info.uid,
            note: "",
            source: "",
            status: "pending",
            time: GetCurrentTimeStamp(),
            type: "TT",
            uniqueid: GetCurrentMiniTimeStamp(),
            isEditPickup: true
        }
        dispatch({
            type: CALL_DETAIL_DATA, payload: call,
        });
    }

    /*useEffect(() => {
        if (groupDriverData && groupDriverData.length > 0) {
            setListGroup(
                groupDriverData.filter((g) => g.value != 11 && g.value != 10)
            );
        }
    }, [groupDriverData]);*/

    /*useEffect(() => {
        if (reportdriverdata.reports) {
            let listAlert = reportdriverdata.reports.filter(
                (report) =>
                    report.content.includes("hủy 1") ||
                    report.content.includes("hủy 0") ||
                    report.content.includes("hủy 2") ||
                    report.content.includes("hủy 3") ||
                    report.content.includes("hỗ trợ") ||
                    report.content.includes("vi phạm") ||
                    report.content.includes("nhận xe") ||
                    report.content.includes("đã đồng ý thay") ||
                    report.content.includes("hủy đặt xe") ||
                    report.content.includes("điểm đón cuốc khách") ||
                    report.content.includes("tự động hủy")
            );

            if (dataRpImportance) {
                if (listAlert && listAlert.length > dataRpImportance.length) {
                    if (
                        listAlert[0] &&
                        (listAlert[0].content.includes("hủy 0") ||
                            listAlert[0].content.includes("hủy 0") ||
                            listAlert[0].content.includes("hủy 2") ||
                            listAlert[0].content.includes("hủy 3") ||
                            listAlert[0].content.includes("tự động hủy"))
                    ) {
                        playSound2();
                    }

                    if (listAlert[0] && listAlert[0].content.includes("vi phạm")) {
                        playSoundViPham();
                    }

                    if (listAlert[0] && listAlert[0].content.includes("hủy đặt xe")) {
                        playSoundHuy();
                    }

                    if (listAlert[0] && listAlert[0].content.includes("đã đồng ý thay")) {
                        playSoundDoiCa();
                    }

                    if (listAlert[0] && listAlert[0].content.includes("hỗ trợ")) {
                        playSoundHoTro();
                    }
                }
            } else {
                playSound2();
            }
            setDataRpImportance(listAlert);
        } else {
            setDataRpImportance([]);
        }
    }, [reportdriverdata.reports]);*/

    /*useEffect(() => {
        if (group) {
            if (group != 'Vùng tự do') {
                let groupApplys = listGroup.filter((g) => g.label == group);
                if (groupApplys && groupApplys.length > 0) {
                    let listAreaApply = groupApplys[0].area_ids;
                    let listAreas = groupApplys[0].areas;
                    let polygonList = [];
                    if (listAreas && listAreas.length > 0) {
                        for (let i = 0; i < listAreas.length; i++) {
                            if (listAreas[i]) {
                                let coors = Object.values(listAreas[i]);
                                for (let j = 0; j < coors.length; j++) {
                                    if (coors[j][0] && coors[j][1]) {
                                        polygonList.push({
                                            lat: coors[j][1], lng: coors[j][0]
                                        });
                                    }
                                }
                            }
                        }
                        setPolygons(polygonList);
                    }
                    if (listAreaApply) {
                        if (groupApplys[0] && groupApplys[0].coord) {
                            setMylocation({
                                lat: parseFloat(groupApplys[0].coord.split(',')[0]),
                                lng: parseFloat(groupApplys[0].coord.split(',')[1])
                            });
                        }

                        setGroupApply(groupApplys[0]);
                    } else {
                        setGroupApply(null);
                    }
                } else {
                    setPolygons(null);
                    setGroupApply(null);
                }

                dispatch(updateTripPickup(null));
                dispatch(updateTripDrop(null));
                setDropFromMap(null);
                setPickupFromMap(null);
            } else {
                dispatch(updateTripPickup(null));
                dispatch(updateTripDrop(null));
                setDropFromMap(null);
                setPickupFromMap(null);
            }
        } else {
            dispatch(updateTripPickup(null));
            dispatch(updateTripDrop(null));
            setPolygons(null);
            setGroupApply(null);
        }
    }, [group]);*/

    useEffect(() => {
        if (groupApply) {
            dispatch(updateTripGroup(groupApply));
        }
    }, [groupApply]);

    const handleClickMenuGroup = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChangeGroup = useCallback((value) => {
        setGroup(value);
    }, []);

    const showCall = useCallback((rowData) => {
        setShowCallNow({
            time_call: rowData.time_call_out ? rowData.time_call_out : [],
            phone: rowData.source,
            uniqueid: rowData.uniqueid,
            firebase_id: rowData.firebase_id,
            time: rowData.time,
            audios: rowData.audios ? rowData.audios : [],
            source: rowData.source,
        });
        setModalVisible(true);
        setShowCallModal(true);
    }, []);

    const closeShowCall = useCallback(() => {
        setModalVisible(false);
        setShowCallNow(null);
        setShowCallModal(false);
    }, []);

    const loadSoundDoiCa = async () => {
        const sound = new Audio(
            require("../assets/sounds/xetduyetcadem.wav").default
        );
        sound.load();
        setSoundAlertDoiCa(sound);
    };

    const loadSoundHoTro = async () => {
        const sound = new Audio(
            require("../assets/sounds/hotrochiduong.wav").default
        );
        sound.load();
        setSoundAlertHoTro(sound);
    };

    const playSoundHoTro = async () => {
        if (soundAlertHoTro) {
            const audioPromise = soundAlertHoTro.play();
            if (audioPromise !== undefined) {
                audioPromise
                    .then((_) => {
                        // autoplay started
                    })
                    .catch((err) => {
                        // catch dom exception
                        console.info(err);
                    });
            }
        }
    };
    const loadSound2 = async () => {
        const sound = new Audio(
            require("../assets/sounds/cuockhachhuydatlai.wav").default
        );
        sound.load();
        setSoundAlert2(sound);
    };

    const playSound2 = async () => {
        if (soundAlert2) {
            const audioPromise = soundAlert2.play();
            if (audioPromise !== undefined) {
                audioPromise
                    .then((_) => {
                        // autoplay started
                    })
                    .catch((err) => {
                        // catch dom exception
                        console.info(err);
                    });
            }
        }
    };

    const loadSoundHuy = async () => {
        const sound = new Audio(
            require("../assets/sounds/cuokhachhuy.wav").default
        );
        sound.load();
        setSoundAlertHuy(sound);
    };

    const playSoundHuy = async () => {
        if (soundAlertHuy) {
            const audioPromise = soundAlertHuy.play();
            if (audioPromise !== undefined) {
                audioPromise
                    .then((_) => {
                        // autoplay started
                    })
                    .catch((err) => {
                        // catch dom exception
                        console.info(err);
                    });
            }
        }
    };

    useEffect(() => {
        if (!soundAlertHuy) {
            loadSoundHuy();
        }
    }, [soundAlertHuy, setSoundAlertHuy]);


    const playSoundDoiCa = async () => {
        if (soundAlertDoiCa) {
            const audioPromise = soundAlertDoiCa.play();
            if (audioPromise !== undefined) {
                audioPromise
                    .then((_) => {
                        // autoplay started
                    })
                    .catch((err) => {
                        // catch dom exception
                        console.info(err);
                    });
            }
        }
    };

    const loadSoundViPham = async () => {
        const sound = new Audio(require("../assets/sounds/loivipham.wav").default);
        sound.load();
        setSoundAlertViPham(sound);
    };

    const playSoundViPham = async () => {
        if (soundAlertViPham) {
            const audioPromise = soundAlertViPham.play();
            if (audioPromise !== undefined) {
                audioPromise
                    .then((_) => {
                        // autoplay started
                    })
                    .catch((err) => {
                        // catch dom exception
                        console.info(err);
                    });
            }
        }
    };

    useEffect(() => {
        if (mylocation == null) {

            if (location && location.state && location.state.bookingObject) {
                if (location.state.bookingObject.groupDetails && location.state.bookingObject.groupDetails.coord) {
                    setMylocation({
                        lat: parseFloat(location.state.bookingObject.groupDetails.coord.split(",")[0]),
                        lng: parseFloat(location.state.bookingObject.groupDetails.coord.split(",")[1].toString().trim())
                    });
                }
            } else {
                navigator.geolocation.getCurrentPosition(
                    position => setMylocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }),
                    err => console.log(err)
                );
            }
        }
    }, [mylocation]);


    useEffect(() => {
        if (callDetail) {
            let selectPoint = store.getState().tripdata.selected;
            if (callDetail.pickup && selectPoint == 'pickup') {
                setMylocation({
                    lat: parseFloat(callDetail.pickup.lat),
                    lng: parseFloat(callDetail.pickup.lng)
                });
            }

            if (callDetail.drop && selectPoint == 'drop') {
                setMylocation({
                    lat: parseFloat(callDetail.drop.lat),
                    lng: parseFloat(callDetail.drop.lng)
                });
            }
        } else {
            dispatch(api.updateTripPickup(null));
            dispatch(api.updateTripDrop(null));
        }
    }, [callDetail, webMapZoom]);

    useEffect(() => {
        if (searchAddress) {

        }
    }, [searchAddress]);

    useEffect(() => {
        if (location.state && location.state.callDetail) {
            setCallCenterDetail(location.state.callDetail);
        } else {
            setCallCenterDetail(null);
        }
        if (location.state && location.state.bookingObject) {
            setBookingObj(location.state.bookingObject);
        } else {
            setBookingObj(null);
        }
    }, [location.state]);

    useEffect(() => {
        if (!soundAlert) {
            loadSound();
        }
    }, [soundAlert, setSoundAlert]);
    const loadSound = async () => {
        const sound = new Audio(
            require("../assets/sounds/cokhachhangmoi.wav").default
        );
        sound.load();
        setSoundAlert(sound);
    };

    const playSound = async () => {
        if (soundAlert) {
            const audioPromise = soundAlert.play();
            if (audioPromise !== undefined) {
                audioPromise
                    .then((_) => {
                        // autoplay started
                    })
                    .catch((err) => {
                        // catch dom exception
                        console.info(err);
                    });
            }
        }
    };

    const closeCancelCallback = useCallback(
        () => {
            dispatch({
                type: 'SELECT_USER_SUCCESS',
                payload: null
            });
            dispatch({
                type: 'SELECT_BOOKING_DETAIL_SUCCESS',
                payload: null
            });
            setShowCallNow(null);
            setShowCancel(false);
            setModalVisible(false);
        },
        [],
    );


    const openCancelCallback = useCallback(
        (callDataObj) => {
            dispatch({
                type: 'SELECT_USER_SUCCESS',
                payload: null
            });
            dispatch({
                type: 'SELECT_BOOKING_DETAIL_SUCCESS',
                payload: null
            });
            setShowCallNow(callDataObj);
            setModalVisible(true);
            setShowCancel(true);
        },
        [],
    );

    const openCallPopupCallback = useCallback(
        (phone) => {
            setShowCallPopup(phone);
            setModalVisible(true);
        },
        [],
    );

    const closeCallPopupCallback = useCallback(
        () => {
            setShowCallPopup('');
            setModalVisible(false);
        },
        [],
    );

    const closeAddCallCallback = useCallback(
        () => {
            dispatch({
                type: 'SELECT_USER_SUCCESS',
                payload: null
            });
            dispatch({
                type: 'SELECT_BOOKING_DETAIL_SUCCESS',
                payload: null
            });
            setShowCallNow(null);
            setShowThemXe(false);
            setModalVisible(false);
        },
        [],
    );

    const openAddCallCallback = useCallback(
        (callDataObj) => {
            dispatch({
                type: 'SELECT_USER_SUCCESS',
                payload: null
            });
            dispatch({
                type: 'SELECT_BOOKING_DETAIL_SUCCESS',
                payload: null
            });
            setShowCallNow(callDataObj);
            setModalVisible(true);
            setShowThemXe(true);
        },
        [],
    );

    const listLyDo = [
        'Số bị trượt máy',
        'Lái xe gọi',
        'Khách hỏi thông tin',
        'Không có phương tiện hỗ trợ',
        'Vấn đề khác'
    ];

    const lineOptions = [
        {value: "101", label: "Line 101"},
        {value: "102", label: "Line 102"},
        {value: "103", label: "Line 103"},
        {value: "104", label: "Line 104"},
    ];

    const handleClickOpenDialogBooking = useCallback((callData) => {
        dispatch(updateTripCallCenter(callData));
        setOpenDialogBooking(true);
    }, []);

    const handleCloseDialogBooking = useCallback(() => {
        //dispatch(clearTripPoints());
        setOpenDialogBooking(false);
    }, []);

    const openMenuDashboardComponent = useCallback(() => {
        setOpenLeft(true);
    }, []);
    const closeMenuDashboardComponent = useCallback(() => {
        setOpenLeft(false);
    }, []);

    const toggleLeftDashboardComponent = useCallback((callDetail = null) => {
        if (openLeft) {
            setOpenLeft(false);
            setCallCenterDetail(null);
            dispatch({
                type: 'CLEAR_TRIP_POINTS'
            });
        } else {
            setOpenLeft(true);
            setCallCenterDetail(callDetail);
        }
    }, [openLeft]);

    const toggleUpdateBookingDashboardComponent = useCallback((driver) => {
        if (openUpdateBooking) {
            setOpenUpdateBooking(false);
            setDriverSelect(null);
            dispatch({
                type: 'CLEAR_TRIP_POINTS'
            });
        } else {
            setDriverSelect(driver);
            setOpenUpdateBooking(true);
        }
    }, [openUpdateBooking]);

    const openRightDashboardComponent = useCallback(() => {
        setOpenRight(true);
    }, []);
    const closeRightDashboardComponent = useCallback(() => {
        setOpenRight(false);
    }, []);
    const toggleRightDashboardComponent = useCallback(() => {
        if (openRight) {
            setOpenRight(false);
            dispatch({
                type: 'CLEAR_TRIP_POINTS'
            });
            setCoords([]);
        } else {
            setOpenRight(true);
        }
    }, [openRight]);
    const toggleBottomDashboardComponent = useCallback(() => {
        if (openBottom) {
            setOpenBottom(false);
        } else {
            setOpenBottom(true);
        }
    }, [openBottom]);

    const showDriver = useCallback((rows) => {
        setRows(rows);
    }, [rows]);

    const updateCoordsFromBooking = useCallback((listCoord) => {
        setCoords(listCoord);
    }, [rows]);

    const clickDriverCallback = useCallback((driver = null) => {
        if (driver) {
            setDriverSelect(driver);
            setMylocation({
                lat: driver.location.lat,
                lng: driver.location.lng
            });
            setOpenUpdateBooking(true);
        } else {
            setDriverSelect(null);
            setOpenUpdateBooking(false);
        }

    }, []);

    const selectPickupAddress = useCallback((addressInfo = null) => {
        if (addressInfo) {

        } else {

        }

    }, []);

    const closeCallback = useCallback(() => {
        setModalVisible(false);
        setShowCancel(false);
        setShowCallNow(null);
    }, []);

    const getPickupCallback = useCallback((pickupData) => {
        if (callDetail && pickupData) {
            let updateCallData = {...callDetail};
            if (updateCallData.pickup) {
                updateCallData.pickup.lat = pickupData.lat;
                updateCallData.pickup.lng = pickupData.lng;
                updateCallData.pickup.add = pickupData.add;
                updateCallData.pickup.shortAdd = pickupData.shortAdd ? pickupData.shortAdd : ''
            }
            dispatch({
                type: CALL_DETAIL_DATA, payload: updateCallData
            });
            if (callDetail.id) {
                dispatch(api.updateCallCancelById(callDetail.id, updateCallData));
            }
        }
    }, []);

    return (
        <SafeAreaView>
            {mylocation && webMapZoom ?
                <Map
                    loadingElement={<div className={classes.fullHeight}/>}
                    containerElement={<div className={classes.fullHeight}/>}
                    mapElement={<div className={classes.fullHeight}/>}
                    center={mylocation}
                    isPickup={callDetail ? true : false}
                    coords={coords}
                    zoom={webMapZoom}
                    getPickupCallback={getPickupCallback}
                    rows={rows}
                    googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + Google_Map_Key + "&v=3.exp&libraries=geometry,drawing,places"}
                />
                : null
            }
            {callDetail ?
                <InfoBookingDialog selectPickupAddress={selectPickupAddress} openCancelCallback={openCancelCallback} updateCoordsFromBooking={updateCoordsFromBooking}/> : null}
            <RightDashboardComponent
                showDriver={showDriver}
                openRight={openRight}
                openCancelCallback={openCancelCallback}
                selectDriver={clickDriverCallback}
                openCallPopupCallback={openCallPopupCallback}
                openAddCallCallback={openAddCallCallback}
                toggleRightDashboardComponent={toggleRightDashboardComponent}
                toggleUpdateBookingDashboardComponent={toggleUpdateBookingDashboardComponent}
                styleButton={{
                    zIndex: 99, position: 'fixed', top: 90, right: 20,
                    borderRadius: 5,
                    backgroundColor: colors.WHITE
                }}
            />
            <View style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                marginTop: 22
            }}>
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={modalVisible}
                    onPress={() => {
                        setModalVisible(false);
                    }}
                    titlle
                >
                    <View style={{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 22
                    }}>
                        <View style={{
                            margin: 20,
                            backgroundColor: "white",
                            borderRadius: 10,
                            borderWidth: 1,
                            padding: 10,
                            alignItems: "center",
                            shadowColor: "#000",
                            shadowOffset: {
                                width: 0,
                                height: 2
                            },
                            shadowOpacity: 0.25,
                            shadowRadius: 4,
                            elevation: 5
                        }}>
                            {showCallNow && showCancel === true ?
                                <CancelCallCenter
                                    destination={showCallNow.destination} time={ConvertTimeStampToDate(showCallNow.time * 1000)}
                                    fromTT={"TT"}
                                    phone={showCallNow.source} uniqueid={showCallNow.uniqueid} booking_id={showCallNow.booking_id ? showCallNow.booking_id : null}
                                    listLyDo={listLyDo} firebase_id={showCallNow.firebase_id} closeCallback={closeCancelCallback}/> : null
                            }


                            {addBookingDialog ? (
                                <AddCallCenter {...props} closeCallback={closeCallback}/>
                            ) : null}

                            {showCallNow && showThemXe === true ? (
                                <ThemXeCallCenter
                                    {...props}
                                    callDetail={showCallNow}
                                    closeCallback={closeAddCallCallback}
                                />
                            ) : null}

                            {showCallPopup && showThemXe === false ? (
                                <CallNowCenter
                                    lineOptions={lineOptions}
                                    //time={showCallNow.time}
                                    source={showCallPopup}
                                    //time_call={showCallNow.time_call ? showCallNow.time_call : []}
                                    phone={showCallPopup}
                                    //uniqueid={showCallNow.uniqueid}
                                    //listAudio={showCallNow.audios ? showCallNow.audios : []}
                                    //firebase_id={showCallNow.firebase_id}
                                    closeCallback={closeCallPopupCallback}
                                />
                            ) : null}

                        </View>
                    </View>
                </Modal>
            </View>
        </SafeAreaView>
    );
}
export default Dashboard;
