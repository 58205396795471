import {FETCH_TRIGGER_ACTION} from "../store/types";

const INITIAL_STATE = {
    trigger: [],
}

export const triggerreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_TRIGGER_ACTION:
            return {
                ...state,
                trigger: action.payload
            };
        default:
            return state;
    }
};