import {
    CALL_DETAIL_DATA,
    CLEAR_TRIP_POINTS,
    FETCH_BOOKING_BY_ID_FAILED,
    FETCH_BOOKING_BY_ID_SUCCESS,
    FETCH_CALL_ACTIVE_SUCCESS,
    IS_EDIT_DROP,
    IS_EDIT_PICKUP,
    UPDATE_ADD_DROP,
    UPDATE_BOOKING_ESTIMATE,
    UPDATE_BOOKING_ESTIMATE_TEMP,
    UPDATE_BOOKING_TIME,
    UPDATE_DRIVER_ACCEPT,
    UPDATE_SELECTED_POINT_TYPE,
    UPDATE_TRIP_AREA,
    UPDATE_TRIP_AUTO,
    UPDATE_TRIP_CALLCENTER,
    UPDATE_TRIP_CAR,
    UPDATE_TRIP_DROP,
    UPDATE_TRIP_GROUP,
    UPDATE_TRIP_NOTE,
    UPDATE_TRIP_PHONE,
    UPDATE_TRIP_PICKUP,
    UPDATE_TRIP_PRICE,
    UPDATE_TWO_WAY
} from "../store/types";
import {firebase} from "../config/configureFirebase";
import {off} from "firebase/database";


export const updateTripPickup = (pickupAddress) => (dispatch) => {
    dispatch({
        type: UPDATE_TRIP_PICKUP,
        payload: pickupAddress
    });
};

export const updateTripDrop = (dropAddress) => (dispatch) => {
    dispatch({
        type: UPDATE_TRIP_DROP,
        payload: dropAddress
    });
};

export const updateDriverAccept = (driver) => (dispatch) => {
    dispatch({
        type: UPDATE_DRIVER_ACCEPT,
        payload: driver
    });
};

export const updateTripCar = (selectedCar) => (dispatch) => {
    dispatch({
        type: UPDATE_TRIP_CAR,
        payload: selectedCar
    });
};

export const updatSelPointType = (selection = 'pickup') => (dispatch) => {
    dispatch({
        type: UPDATE_SELECTED_POINT_TYPE,
        payload: selection
    });
};

export const updateTime = (time) => (dispatch) => {
    dispatch({
        type: UPDATE_BOOKING_TIME,
        payload: time
    });
};

export const updateEstimate = (estimate) => (dispatch) => {
    dispatch({
        type: UPDATE_BOOKING_ESTIMATE,
        payload: estimate
    });
};

export const updateEstimateTemp = (estimate) => (dispatch) => {
    dispatch({
        type: UPDATE_BOOKING_ESTIMATE_TEMP,
        payload: estimate
    });
};

export const updateTwoWay = (isTwoWay) => (dispatch) => {
    dispatch({
        type: UPDATE_TWO_WAY,
        payload: isTwoWay
    });
};

export const clearTripPoints = () => (dispatch) => {
    dispatch({
        type: CLEAR_TRIP_POINTS,
        payload: null
    });
};

export const updateTripNote = (note) => (dispatch) => {
    dispatch({
        type: UPDATE_TRIP_NOTE,
        payload: note
    });
};

export const updateTripPhone = (phone) => (dispatch) => {
    dispatch({
        type: UPDATE_TRIP_PHONE,
        payload: phone
    });
};

export const updateTripAddDrop = (isAdd) => (dispatch) => {
    dispatch({
        type: UPDATE_ADD_DROP,
        payload: isAdd
    });
};


export const updateTripCallCenter = (callCenterObj) => (dispatch) => {
    dispatch({
        type: UPDATE_TRIP_CALLCENTER,
        payload: callCenterObj
    });
};


export const updateTripGroup = (group) => (dispatch) => {
    dispatch({
        type: UPDATE_TRIP_GROUP,
        payload: group
    });
};

export const updateTripPrice = (price) => (dispatch) => {
    dispatch({
        type: UPDATE_TRIP_PRICE,
        payload: price
    });
};

export const updateTripArea = (area) => (dispatch) => {
    dispatch({
        type: UPDATE_TRIP_AREA,
        payload: area
    });
};

export const updateTripAuto = (isKq) => (dispatch) => {
    dispatch({
        type: UPDATE_TRIP_AUTO,
        payload: isKq
    });
};

export const updateTripEditPickup = (isEdit) => (dispatch) => {
    dispatch({
        type: IS_EDIT_PICKUP,
        payload: isEdit
    });
};

export const updateTripEditDrop = (isEdit) => (dispatch) => {
    dispatch({
        type: IS_EDIT_DROP,
        payload: isEdit
    });
};

export const resetTripData = (callId = null, bookingId = null) => (dispatch) => {
    const {
        paCallByIdRef,
        bookingByIdRef
    } = firebase;

    dispatch(updateTripPickup(null));
    dispatch(updateTripDrop(null));
    dispatch(updateTripCar(null));
    dispatch(updateEstimateTemp(null));
    dispatch(updateTripNote(null));
    dispatch(updateTripPhone(null));
    dispatch(updateTripGroup(null));
    dispatch(updateTwoWay(false));
    dispatch(updateTripEditPickup(false));
    dispatch(updateEstimateTemp(null));
    dispatch({
        type: CALL_DETAIL_DATA, payload: null
    });

    dispatch({
        type: FETCH_BOOKING_BY_ID_FAILED,
        payload: {
            booking_active: null
        },
    });

    if (callId) {
        dispatch({
            type: FETCH_CALL_ACTIVE_SUCCESS, payload: null,
        });
        off(paCallByIdRef(callId));
    }

    if (bookingId) {
        off(bookingByIdRef(bookingId));
        dispatch({
            type: FETCH_BOOKING_BY_ID_SUCCESS,
            payload: null,
        });
    }
}