import AsyncStorage from "@react-native-async-storage/async-storage";
import {BOOKING_TRACKED_LOCAL, BOOKINGS_LOCAL, FETCH_BOOKING_BY_ID_SUCCESS, FETCH_BOOKINGS_SUCCESS} from "common/src/store/types";
import store from "common/src/store/store";

export const loadBookingTracked = async (uid) => {
    try {
        let booking = await AsyncStorage.getItem(BOOKING_TRACKED_LOCAL + uid).then(async (value) => {
            if (value) {
                let booking = await JSON.parse(value);
                store.dispatch({
                    type: FETCH_BOOKING_BY_ID_SUCCESS,
                    payload: {
                        booking_active: booking
                    },
                });
                store.dispatch({
                    type: FETCH_BOOKINGS_SUCCESS,
                    payload: {
                        tracked: booking
                    },
                });
                return booking;

            } else {
                return null;
            }
        }).then(res => {
            return res;
        });
        return booking;
    } catch (error) {
        return null;
        console.log(error);
    }
}

export async function setBookingTracked(bookingData) {
    try {
        if (bookingData) {
            store.dispatch({
                type: FETCH_BOOKING_BY_ID_SUCCESS,
                payload: {
                    booking_active: bookingData
                },
            });
            store.dispatch({
                type: FETCH_BOOKINGS_SUCCESS,
                payload: {
                    tracked: bookingData
                },
            });
            await AsyncStorage.setItem(BOOKING_TRACKED_LOCAL + bookingData.driver, JSON.stringify(bookingData));
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false;
        console.log(error);
    }
}

export async function updateBookingTracked(dataUpdate) {
    let booking = await loadBookingTracked();
    try {
        if (booking && dataUpdate) {
            Object.keys(dataUpdate).map(key => {
                booking[key] = dataUpdate[key];
            });
            store.dispatch({
                type: FETCH_BOOKING_BY_ID_SUCCESS,
                payload: {
                    booking_active: booking
                },
            });
            await setBookingTracked(booking);
            return false;
        } else {
            return true;
        }
    } catch (error) {
        return false;
        console.log(error);
    }
}

export async function deleteBookingTracked(uid) {
    try {
        await AsyncStorage.removeItem('tracking');
        await AsyncStorage.getItem(BOOKING_TRACKED_LOCAL + uid).then(async (value) => {
            if (value) {
                let booking = await JSON.parse(value);
                if (booking.status === "PAID" || booking.status === "COMPLETE") {
                    await AsyncStorage.getItem(BOOKINGS_LOCAL + uid).then(async (values) => {
                        if (values) {
                            let bookings = await JSON.parse(values);
                            bookings.concat(booking);
                            await AsyncStorage.setItem(BOOKINGS_LOCAL + uid, JSON.stringify(bookings));
                        } else {
                            await AsyncStorage.setItem(BOOKINGS_LOCAL + uid, JSON.stringify([booking]));
                        }
                    })
                }
            }
        });

        await AsyncStorage.removeItem(BOOKING_TRACKED_LOCAL + uid);
        store.dispatch({
            type: FETCH_BOOKING_BY_ID_SUCCESS,
            payload: {
                booking_active: null
            },
        });
        store.dispatch({
            type: FETCH_BOOKINGS_SUCCESS,
            payload: {
                tracked: null
            },
        });
        store.dispatch({
            type: 'UPDATE_TRACKING', payload: {
                tracking: null
            }
        });

        return true;
    } catch (error) {
        return false;
        console.log(error);
    }
}

export async function saveDbFromFbToLocal(key, data, payloadData = null, keyLoadKey = null,) {
    try {
        await AsyncStorage.setItem(key, JSON.stringify(data));
    } catch (error) {
        console.log(error);
    }
}

export async function loadDbFromLocalToState(key) {
    try {
        await AsyncStorage.getItem(key).then(async (value) => {
            if (value) {
                let dataJson = await JSON.parse(value);
                store.dispatch({
                    type: key,
                    payload: dataJson
                });
            }
        })
    } catch (error) {
        console.log(error);
    }
}

export async function deleteLocal(key, payloadKey = null) {
    try {
        await AsyncStorage.removeItem(key);
        store.dispatch({
            type: payloadKey ? payloadKey : key,
            payload: null
        });
        return true;
    } catch (error) {
        return false;
        console.log(error);
    }
}

