import {EDIT_CANCELLATION_REASON, FETCH_CANCEL_REASONS, FETCH_CANCEL_REASONS_FAILED, FETCH_CANCEL_REASONS_SUCCESS, FETCH_GROUP_DRIVERS_SUCCESS, STOP_FETCH_CANCEL_REASONS, UPDATE_SESSION_TOTAL_LOAD} from "../store/types";

import {language} from 'config';
import {firebase} from '../config/configureFirebase';
import {off, onValue, set} from "firebase/database";
import store from "../store/store";
import {deleteLocal, saveDbFromFbToLocal} from "../other/StorageDbHelper";

export const fetchCancelReasons = () => (dispatch) => {

    const {
        cancelreasonRef
    } = firebase;

    dispatch({
        type: FETCH_CANCEL_REASONS,
        payload: null,
    });
    off(cancelreasonRef);
    onValue(cancelreasonRef, async (snapshot) => {
        if (snapshot.val()) {
            let data = snapshot.val();
            let arr = [];
            for (let i = 0; i < data.length; i++) {
                arr.push(data[i].label);
            }
            dispatch({
                type: FETCH_CANCEL_REASONS_SUCCESS,
                payload: {
                    simple: arr,
                    complex: snapshot.val()
                }
            });

            await saveDbFromFbToLocal(FETCH_GROUP_DRIVERS_SUCCESS, {
                simple: arr,
                complex: snapshot.val()
            });

            const totalLoad = store.getState().sessiondata.totalLoadData ? parseInt(store.getState().sessiondata.totalLoadData) : 0;
            dispatch({
                type: UPDATE_SESSION_TOTAL_LOAD, payload: totalLoad + 1
            });
        } else {
            dispatch({
                type: FETCH_CANCEL_REASONS_FAILED,
                payload: language.no_cancel_reason,
            });
            await deleteLocal(FETCH_GROUP_DRIVERS_SUCCESS);
        }
    });
};

export const stopFetchCancelReasons = () => (dispatch) => {
    const {
        cancelreasonRef
    } = firebase;

    dispatch({
        type: STOP_FETCH_CANCEL_REASONS,
        payload: null,
    });
    off(cancelreasonRef);
};

export const editCancellationReason = (reasons, method) => (dispatch) => {
    const {
        cancelreasonRef
    } = firebase;

    dispatch({
        type: EDIT_CANCELLATION_REASON,
        payload: method
    });
    set(cancelreasonRef, reasons);
}



