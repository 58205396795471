import React, {useCallback, useContext, useEffect, useRef, useState,} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import CircularLoading from "../components/CircularLoading";
import Grid from "@material-ui/core/Grid";
import {FlatList, Modal, Text, View} from "react-native-web";
import Button from '@mui/material/Button';
import AddBookingsNew from "../views/AddBookingsNew";
import {ConvertSecondToHms, ConvertTimeStampPaUnique, ConvertTimeStampTimeForPa, ConvertTimeStampTimeForPaShort, ConvertTimeStampToDate, GetDateString, GetDateString00,} from "common/src/other/DateFunctions";
import {formatDisplayAddressSearch, formatDisplayDateSmart, formatPriceRound, HiddenPhone,} from "common/src/other/CommonFunctions";
import CancelCallCenter from "../components/CallCenter/CancelCallCenter";
import CallNowCenter from "../components/CallCenter/CallNowCenter";
import {api, FirebaseContext} from "common";
import ThemXeCallCenter from "../components/CallCenter/ThemXeCallCenter";
import {language} from "config";
import {colors} from "../config/theme";
import IconButton from "@material-ui/core/IconButton";
import ShowNotifyDialog from "../components/Notify/ShowNotifyDialog";
import {Box, Checkbox, List} from "@material-ui/core";
import AddCallCenter from "../components/CallCenter/AddCallCenter";
import MaterialTable from "material-table";
import NoteCallCenterComponent from "../components/CallCenter/NoteCallCenterComponent";
import ReactAudioPlayer from "react-audio-player";
import {getRecordAudio} from "common/src/other/PAApiFunctions.js";
import {AccountCircle, AddCircleOutline, CloseOutlined, PlayCircleFilledOutlined, Refresh} from "@material-ui/icons";
import CssBaseline from '@mui/material/CssBaseline';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import ButtonBookingDetailDialog from "../components/CallCenter/ButtonBookingDetailDialog";
import Tab from '@mui/material/Tab';
import {TabContext, TabList, TabPanel} from "@mui/lab";
import Link from '@mui/material/Link';
import ListCarComponent from "../components/CallCenter/ListCarComponent";
import FormControlLabel from "@mui/material/FormControlLabel";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EditIcon from '@mui/icons-material/Edit';
import WaitingCustomerComponent from "../components/CallCenter/WaitingCustomerComponent";
import {formatNumber} from "react-native-currency-input";
import ButtonBookingDetailReachedDialog from "../components/CallCenter/ButtonBookingDetailReachedDialog";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
});
const dashboardRoutes = [];
const API_PA_PHONE = "a3a799635add5feb5ad2f1cb1b1680e3";


const useStyles = makeStyles((theme) => ({
    "@global": {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    container: {
        zIndex: "12",
        color: "#FFFFFF",
        alignContent: "center",
    },
    gridcontainer: {
        alignContent: "center",
    },
    items: {
        margin: 0,
        width: "100%",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        width: 192,
        height: 192,
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
        backgroundColor: colors.BACKGROUND_DARK_WEB
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
        height: 39,
    },
    marginTop50: {
        marginTop: 50,
    },
    borderLeftCallCenter: {
        borderRightColor: "#0078ff",
        borderRightWidth: 2,
        backgroundColor: "rgba(255,255,255,0.2)",
    },
    backgroundRed: {
        backgroundColor: "rgba(255,179,179,0.74)",
        flexDirection: "column",
    },
    marginBottom50: {
        marginBottom: 50,
    },
    titleList: {
        fontSize: 14,
        marginTop: 10,
    },
    input: {
        height: 25,
        borderRadius: 5,
    },
    row: {
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
    },
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
}));

const CallCenter = (props) => {
        const {paCallByIdRef, refApi} = useContext(FirebaseContext);
        const {
            bookingDetailOn,
            fetchReportDrivers,
            stopFetchReportDrivers,
            addReport,
            fetchCallsPending,
            stopFetchCallsPending,
            fetchCallDetailAll,
            stopFetchCallDetailAll,
            fetchOnceDrivers,
            fetchAllDrivers,
            fetchNoteList,
            stopFetchNoteList,
            reportCallCenter
        } = api;
        const dispatch = useDispatch();
        const {setAudioCallCenterApi, setNoteCallCenterApi} = refApi;
        const settingsdata = useSelector((state) => state.settingsdata);
        const classes = useStyles();
        const [dataSetting, setDataSetting] = useState({
            note: "",
            reasons: "",
        });
        const [dataRp, setDataRp] = useState([]);

        const tableRef = useRef();
        const auth = useSelector((state) => state.auth);
        const [dataRpImportance, setDataRpImportance] = useState([]);
        const callDetailData = useSelector(
            (state) => state.pacallcenterdata.callDetail
        );
        const notemapaddressdata = useSelector(state => state.notemapaddressdata.notes);
        const reportdriverdata = useSelector((state) => state.reportdriverdata);
        const [modalShow, setModalShow] = useState(false);
        const notesdata = useSelector((state) => state.bookinglistdata.notes);
        const groupDriverData = useSelector(
            (state) => state.groupdriverdata.group_kd
        );
        const pacallcenterdatas = useSelector((state) => state.pacallcenterdata);
        const [listCall, setListCall] = useState([]);
        const [listCallApp, setListCallApp] = useState([]);
        const [listGroup, setListGroup] = useState(null);
        const cartypes = useSelector((state) => state.cartypes.cars);
        const [bookingdata, setBookingdata] = useState(null);
        const [notes, setNotes] = useState([]);
        const [popupData, setPopupData] = useState(null);
        const [callObj, setCallObj] = useState(null);
        const [modalVisible, setModalVisible] = useState(false);
        const [showCancel, setShowCancel] = useState(false);
        const [showThemXe, setShowThemXe] = useState(false);
        const [showCallNow, setShowCallNow] = useState(null);
        const [searchInit, setSearchInit] = useState(null);
        const [soundAlert, setSoundAlert] = useState(null);
        const [soundAlert2, setSoundAlert2] = useState(null);
        const [soundAlertViPham, setSoundAlertViPham] = useState(null);
        const [soundAlertHuy, setSoundAlertHuy] = useState(null);
        const [soundAlertDoiCa, setSoundAlertDoiCa] = useState(null);
        const [soundAlertHoTro, setSoundAlertHoTro] = useState(null);
        const [showModal, setShowModal] = useState(false);
        const [showReachedModal, setShowReachedModal] = useState(false);
        const [showCustomerCareDialog, setShowCustomerCareDialog] = useState(false);
        const [callDetail, setCallDetail] = useState(null);
        const [showChatModal, setShowChatModal] = useState(null);
        const [driverCode, setDriverCode] = useState(null);
        const [noteBooking, setNoteBooking] = useState(null);
        const [bookingDate, setBookingDate] = useState(null);
        const [addBookingDialog, setAddBookingDialog] = useState(false);
        const [loadingAudio, setLoadingAudio] = useState(null);
        const [greet, setGreet] = useState(null);
        const [endDateValue, setEndDateValue] = useState(GetDateString());
        const [startDate, setStartDate] = useState(new Date(GetDateString00()).getTime() / 1000);
        const [endDate, setEndDate] = useState(new Date(GetDateString()).getTime() / 1000);
        const [reportData, setReportData] = useState(null);
        const [isApp, setIsApp] = useState(false);
        const [openAddCoord, setOpenAddCoord] = useState(false);
        const [noteMap, setNoteMap] = useState(null);
        const cars = useSelector(state => state.cartypes.cars);
        const [value, setValue] = useState('1');
        const [selected, setSelected] = useState(null);
        const [getBookingLoading, setGetBookingLoading] = useState(null);
        const [bookingDetail, setBookingDetail] = useState(null);

        const [tabs, setTabs] = useState([
            {value: "1", name: "Chưa xử lý"},
            {value: "2", name: "Đã xử lý"},
            {value: "3", name: "Gọi đến"},
            {value: "4", name: "Gọi đi"},
            {value: "5", name: "Gọi nhỡ"},
            {value: "6", name: "Gọi bị huỷ"}
        ]);

        const {...rest} = props;

        useEffect(() => {
            dispatch(fetchAllDrivers());
            dispatch(fetchReportDrivers());
            dispatch(fetchCallsPending());
            dispatch(fetchCallDetailAll());
            //dispatch(fetchAllBookings());
            dispatch(fetchNoteList());
            dispatch(api.fetchNoteMapAddress());

            return () => {
                dispatch(api.fetchAllDriversOff());
                dispatch(stopFetchReportDrivers());
                dispatch(stopFetchCallsPending());
                dispatch(stopFetchCallDetailAll());
                dispatch(stopFetchNoteList());
                dispatch(api.stopFetchNoteMapAddress());
            };
        }, []);

        const handleChange = (event, newValue) => {
            setValue(newValue);
        };

        const loadRecordFile = async (
            source,
            from_date,
            to_date,
            id,
            time_call,
            time
        ) => {
            setLoadingAudio({[time]: true});
            let listAudio = [];

            if (source && from_date && to_date) {
                await getRecordAudio(source, from_date, to_date)
                    .then(async (dataResult) => {
                        if (dataResult && dataResult.data) {
                            let listAudioFiltered = JSON.parse(dataResult.data)
                                .filter((a) => a.inbound == "")
                                .map((am) => {
                                    listAudio.push(am.recording.split("/").at(-1));
                                });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });

                await getRecordAudio("102", from_date, to_date)
                    .then((dataResult) => {
                        if (dataResult && dataResult.data) {
                            let listAudioFiltered = JSON.parse(dataResult.data)
                                .filter((a) => a.inbound == "" && a.to == source)
                                .map((am) => {
                                    listAudio.push(am.recording.split("/").at(-1));
                                });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });

                await getRecordAudio("101", from_date, to_date)
                    .then(async (dataResult) => {
                        if (dataResult && dataResult.data) {
                            let listAudioFiltered = JSON.parse(dataResult.data)
                                .filter((a) => a.inbound == "" && a.to == source)
                                .map((am) => {
                                    listAudio.push(am.recording.split("/").at(-1));
                                });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });

                if (time_call) {
                    time_call.map(async (time) => {
                        let from_date1 = ConvertTimeStampTimeForPa((time - 600) * 1000);
                        let to_date1 = ConvertTimeStampTimeForPa((time + 1800) * 1000);
                        await getRecordAudio("101", from_date1, to_date1)
                            .then((dataResult) => {
                                if (dataResult && dataResult.data) {
                                    let listAudioFiltered = JSON.parse(dataResult.data)
                                        .filter((a) => a.inbound == "" && a.to == props.phone)
                                        .map((am) => {
                                            listAudio.push(am.recording.split("/").at(-1));
                                        });
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        await getRecordAudio("102", from_date1, to_date1)
                            .then((dataResult) => {
                                if (dataResult && dataResult.data) {
                                    let listAudioFiltered = JSON.parse(dataResult.data)
                                        .filter((a) => a.inbound == "" && a.to == props.phone)
                                        .map((am) => {
                                            listAudio.push(am.recording.split("/").at(-1));
                                        });
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    });
                }

                if (listAudio) {
                    setAudioCallCenterApi(id, listAudio);
                }
                setLoadingAudio(null);
            } else {
                setLoadingAudio(null);
                alert("Thiếu thông tin");
                return null;
            }
        };

        const loadSound = async () => {
            const sound = new Audio(
                require("../assets/sounds/cokhachhangmoi.wav").default
            );
            sound.load();
            setSoundAlert(sound);
        };

        const playSound = async () => {
            if (soundAlert) {
                const audioPromise = soundAlert.play();
                if (audioPromise !== undefined) {
                    audioPromise
                        .then((_) => {
                            // autoplay started
                        })
                        .catch((err) => {
                            // catch dom exception
                            console.info(err);
                        });
                }
            }
        };

        const loadSound2 = async () => {
            const sound = new Audio(
                require("../assets/sounds/cuockhachhuydatlai.wav").default
            );
            sound.load();
            setSoundAlert2(sound);
        };

        const playSound2 = async () => {
            if (soundAlert2) {
                const audioPromise = soundAlert2.play();
                if (audioPromise !== undefined) {
                    audioPromise
                        .then((_) => {
                            // autoplay started
                        })
                        .catch((err) => {
                            // catch dom exception
                            console.info(err);
                        });
                }
            }
        };

        const loadSoundHuy = async () => {
            const sound = new Audio(
                require("../assets/sounds/cuokhachhuy.wav").default
            );
            sound.load();
            setSoundAlertHuy(sound);
        };

        const playSoundHuy = async () => {
            if (soundAlertHuy) {
                const audioPromise = soundAlertHuy.play();
                if (audioPromise !== undefined) {
                    audioPromise
                        .then((_) => {
                            // autoplay started
                        })
                        .catch((err) => {
                            // catch dom exception
                            console.info(err);
                        });
                }
            }
        };

        useEffect(() => {
            if (!soundAlertHuy) {
                loadSoundHuy();
            }
        }, [soundAlertHuy, setSoundAlertHuy]);

        const loadSoundDoiCa = async () => {
            const sound = new Audio(
                require("../assets/sounds/xetduyetcadem.wav").default
            );
            sound.load();
            setSoundAlertDoiCa(sound);
        };

        const playSoundDoiCa = async () => {
            if (soundAlertDoiCa) {
                const audioPromise = soundAlertDoiCa.play();
                if (audioPromise !== undefined) {
                    audioPromise
                        .then((_) => {
                            // autoplay started
                        })
                        .catch((err) => {
                            // catch dom exception
                            console.info(err);
                        });
                }
            }
        };

        useEffect(() => {
            if (!soundAlertDoiCa) {
                loadSoundDoiCa();
            }
        }, [soundAlertDoiCa, setSoundAlertDoiCa]);

        const loadSoundHoTro = async () => {
            const sound = new Audio(
                require("../assets/sounds/hotrochiduong.wav").default
            );
            sound.load();
            setSoundAlertHoTro(sound);
        };

        const playSoundHoTro = async () => {
            if (soundAlertHoTro) {
                const audioPromise = soundAlertHoTro.play();
                if (audioPromise !== undefined) {
                    audioPromise
                        .then((_) => {
                            // autoplay started
                        })
                        .catch((err) => {
                            // catch dom exception
                            console.info(err);
                        });
                }
            }
        };

        useEffect(() => {
            if (!soundAlertHoTro) {
                loadSoundHoTro();
            }
        }, [soundAlertHoTro, setSoundAlertHoTro]);

        const loadSoundViPham = async () => {
            const sound = new Audio(require("../assets/sounds/loivipham.wav").default);
            sound.load();
            setSoundAlertViPham(sound);
        };

        const playSoundViPham = async () => {
            if (soundAlertViPham) {
                const audioPromise = soundAlertViPham.play();
                if (audioPromise !== undefined) {
                    audioPromise
                        .then((_) => {
                            // autoplay started
                        })
                        .catch((err) => {
                            // catch dom exception
                            console.info(err);
                        });
                }
            }
        };

        useEffect(() => {
            if (!soundAlertViPham) {
                loadSoundViPham();
            }
        }, [soundAlertViPham, setSoundAlertViPham]);

        useEffect(() => {
            if (!soundAlert) {
                loadSound();
            }
        }, [soundAlert, setSoundAlert]);

        useEffect(() => {
            if (!soundAlert2) {
                loadSound2();
            }
        }, [soundAlert2, setSoundAlert2]);

        useEffect(() => {
            if (notesdata) {
                setNotes(notesdata);
            }
        }, [notesdata]);

        useEffect(() => {
            if (groupDriverData && groupDriverData.length > 0) {
                setListGroup(
                    groupDriverData.filter((g) => g.value != 11 && g.value != 10)
                );
            }
        }, [groupDriverData]);

        useEffect(() => {
            var index = 0;
            var myDate = new Date();
            var hrs = myDate.getHours();

            if (hrs < 12)
                setGreet(
                    " Chào buổi sáng Tâm " +
                    auth.user.lastName +
                    ", chúc bạn ngày mới vui vẻ!"
                );
            else if (hrs >= 12 && hrs <= 18)
                setGreet(" Xin chào Tâm " + auth.user.lastName + " chúc Tâm buổi chiều nhiều năng lượng.");
            else if (hrs >= 18 && hrs <= 24)
                setGreet(" Xin chào Tâm " + auth.user.lastName + " chúc Tâm buổi tối nhiều niềm vui!");

            if (pacallcenterdatas.calls && pacallcenterdatas.calls.length > 0) {
                let resultCall = [];
                for (let i = 0; i < pacallcenterdatas.calls.length; i++) {
                    if (
                        i > 0 &&
                        pacallcenterdatas.calls[i].source ===
                        pacallcenterdatas.calls[i - 1].source &&
                        ConvertTimeStampToDate(pacallcenterdatas.calls[i].time * 1000) ===
                        ConvertTimeStampToDate(
                            pacallcenterdatas.calls[i - 1].time * 1000
                        ) &&
                        pacallcenterdatas.calls[i].status ===
                        pacallcenterdatas.calls[i - 1].status
                    ) {
                        continue;
                    } else {
                        pacallcenterdatas.calls[i].date_formated = ConvertTimeStampToDate(
                            pacallcenterdatas.calls[i].time * 1000
                        );
                        pacallcenterdatas.calls[i].date_format_pa = ConvertTimeStampPaUnique(
                            pacallcenterdatas.calls[i].time * 1000
                        );
                        resultCall.push(pacallcenterdatas.calls[i]);
                    }

                }

                let checkListCall1 = resultCall.filter(
                    (item) =>
                        (!item.note || (item.note && item.note != "Khách quen lái xe")) &&
                        (!item.type || (item.type && item.type != "Taxi Metter"))
                );
                let checkListCall2 = listCall.filter(
                    (item) =>
                        (!item.note || (item.note && item.note != "Khách quen lái xe")) &&
                        (!item.type || (item.type && item.type != "Taxi Metter"))
                );
                if (listCall) {
                    if (checkListCall1.length > checkListCall2.length) {
                        playSound();
                    }
                }

                setListCall(checkListCall1);
            } else {
                setListCall([]);
            }
            setEndDateValue(GetDateString());
            let uid = auth.info.uid;
            if (uid && startDate && new Date(GetDateString()).getTime() / 1000) {
                dispatch(reportCallCenter(uid, startDate, new Date(GetDateString()).getTime() / 1000));
            }
        }, [pacallcenterdatas.calls, searchInit]);

        useEffect(() => {
            if (pacallcenterdatas.apps && pacallcenterdatas.apps.length > 0) {
                let resultCall = [];
                for (let i = 0; i < pacallcenterdatas.apps.length; i++) {
                    if (
                        i > 0 &&
                        pacallcenterdatas.apps[i].source ===
                        pacallcenterdatas.apps[i - 1].source &&
                        ConvertTimeStampToDate(pacallcenterdatas.apps[i].time * 1000) ===
                        ConvertTimeStampToDate(
                            pacallcenterdatas.apps[i - 1].time * 1000
                        ) &&
                        pacallcenterdatas.apps[i].status ===
                        pacallcenterdatas.apps[i - 1].status
                    ) {
                        continue;
                    } else {
                        pacallcenterdatas.apps[i].date_formated = ConvertTimeStampToDate(
                            pacallcenterdatas.apps[i].time * 1000
                        );
                        pacallcenterdatas.apps[i].date_format_pa = ConvertTimeStampPaUnique(
                            pacallcenterdatas.apps[i].time * 1000
                        );
                        resultCall.push(pacallcenterdatas.apps[i]);
                    }
                }

                let checkListCall1 = resultCall.filter(
                    (item) =>
                        (!item.note || (item.note && item.note != "Khách quen lái xe")) &&
                        (!item.type || (item.type && item.type != "Taxi Metter"))
                );

                setListCallApp(checkListCall1);
            } else {
                setListCallApp([]);
            }


            setEndDateValue(GetDateString());
            let uid = auth.info.uid;
            if (uid && startDate && new Date(GetDateString()).getTime() / 1000) {
                dispatch(reportCallCenter(uid, startDate, new Date(GetDateString()).getTime() / 1000));
            }
        }, [pacallcenterdatas.apps, searchInit]);

        useEffect(() => {
            if (pacallcenterdatas.report_fleet) {
                setReportData(pacallcenterdatas.report_fleet);
            }
        }, [pacallcenterdatas.report_fleet]);

        useEffect(() => {
            if (reportdriverdata.reports) {
                setDataRp(
                    reportdriverdata.reports.filter((report) => report.content !== "")
                );
                let listAlert = reportdriverdata.reports.filter(
                    (report) =>
                        report.content.includes("hủy 1") ||
                        report.content.includes("hủy 0") ||
                        report.content.includes("hủy 2") ||
                        report.content.includes("hủy 3") ||
                        report.content.includes("hỗ trợ") ||
                        report.content.includes("vi phạm") ||
                        report.content.includes("nhận xe") ||
                        report.content.includes("đã đồng ý thay") ||
                        report.content.includes("hủy đặt xe") ||
                        report.content.includes("điểm đón cuốc khách") ||
                        report.content.includes("tự động hủy")
                );

                if (dataRpImportance) {
                    if (listAlert && listAlert.length > dataRpImportance.length) {
                        if (
                            listAlert[0] &&
                            (listAlert[0].content.includes("hủy 0") ||
                                listAlert[0].content.includes("hủy 0") ||
                                listAlert[0].content.includes("hủy 2") ||
                                listAlert[0].content.includes("hủy 3") ||
                                listAlert[0].content.includes("tự động hủy"))
                        ) {
                            playSound2();
                        }

                        if (listAlert[0] && listAlert[0].content.includes("vi phạm")) {
                            playSoundViPham();
                        }

                        if (listAlert[0] && listAlert[0].content.includes("hủy đặt xe")) {
                            playSoundHuy();
                        }

                        if (listAlert[0] && listAlert[0].content.includes("đã đồng ý thay")) {
                            playSoundDoiCa();
                        }

                        if (listAlert[0] && listAlert[0].content.includes("hỗ trợ")) {
                            playSoundHoTro();
                        }
                    }
                } else {
                    playSound2();
                }
                setDataRpImportance(listAlert);
            } else {
                setDataRp([]);
                setDataRpImportance([]);
            }
        }, [reportdriverdata.reports, searchInit]);

        useEffect(() => {
            if (settingsdata.settings) {
                setDataSetting(settingsdata.settings);
            }
        }, [settingsdata.settings]);

        const showModalCallback = useCallback((popupData, callObj) => {
            if (popupData) {
                setPopupData(popupData);
            }
            if (callObj) {
                setCallObj(callObj);
            }
            setShowModal(true);
        }, []);


        const showModalReachedCallback = useCallback((popupData, callObj) => {
            if (popupData) {
                setPopupData(popupData);
            }
            if (callObj) {
                setCallObj(callObj);
            }
            setShowReachedModal(true);
        }, []);

        const showDialogCoord = useCallback((isShow, note = null, rowData = null) => {
            setOpenAddCoord(isShow);
            if (isShow == true && note && rowData) {
                setNoteMap(note);
                setPopupData(rowData);
            } else {
                setNoteMap(null);
            }
        }, []);

        const showModalCustomerCareCallback = useCallback((rowDataInput, callObjInput) => {
            setTimeout(() => {
                if (rowDataInput) {
                    setPopupData(rowDataInput);
                }
                if (callObjInput) {
                    setCallObj(callObjInput);
                }
                setShowCustomerCareDialog(true);
            }, 1000);
        }, []);

        const hideModalCallback = useCallback(() => {
            setPopupData(null);
            setCallObj(null);
            setShowModal(false);
            setShowCustomerCareDialog(false);
            setShowReachedModal(false);
            //setNoteTemp(null);
        }, []);

        const hideChatModalCallback = useCallback(() => {
            setCallObj(null);
            setPopupData(null);
            setShowChatModal(false);
            setDriverCode(null);
            setNoteBooking(null);
            dispatch({
                type: 'SELECT_BOOKING_DETAIL_SUCCESS', payload: null
            });
        }, []);

        const closeCallback = useCallback(() => {
            setModalVisible(false);
            setPopupData(null);
            setShowCancel(false);
            setShowThemXe(false);
            setShowCallNow(null);
            setAddBookingDialog(false);
        }, []);

        const searchCallback = useCallback((searchText) => {
            setSearchInit(null);
            setListCall(null);
            setDataRp(null);
            if (searchText === searchInit) {
                searchText = "";
            }
            if (searchText) {
                //tableRef.current.options.searchText(searchText);
                setSearchInit(searchText);
            }
        }, []);

        const chatDriver = useCallback((driverCode, note, bookingDate) => {
            setDriverCode(driverCode);
            setNoteBooking(note);
            if (bookingDate) {
                setBookingDate(ConvertTimeStampToDate(bookingDate));
            }
            setShowChatModal(true);
        }, []);

        /* useEffect(()=>{
             if(isApp == true){
                 if(callcolumns){
                 }
             }
         },[isApp, callcolumns]);*/

        const MyVerticallyCenteredModal = (props) => {
            return (
                <Modal
                    animationType="slide"
                    visible={modalShow}
                    titlle
                    onRequestClose={() => {
                        setModalShow(false);
                    }}
                >
                    <AddBookingsNew phone={""}/>
                </Modal>
            );
        };

        const listLyDo = [
            "Số bị trượt máy",
            "Lái xe gọi",
            "Khách hỏi thông tin",
            "Không có phương tiện hỗ trợ",
            "Vấn đề khác",
        ];

        const InfoReport = (props) => {
            let content = props.content;
            var bookingKey = null;
            let contentArray = content.split("#");
            if (contentArray.length > 0) {
                bookingKey = contentArray[contentArray.length - 1];
                if (bookingKey) {
                    bookingKey = bookingKey.trim();
                }
            }
            var colorButton = "rgb(0, 147, 227)";
            if (content.includes("hủy")) {
                colorButton = "red";
            }
            if (content.includes("vi phạm")) {
                colorButton = "rgb(208, 188, 0)";
            }
            if (content.includes("hỗ trợ")) {
                colorButton = "rgb(208, 188, 0)";
            }
            return (
                <View
                    style={{
                        flex: 0.5,
                        justifyContent: "center",
                        marginHorizontal: 10,
                    }}
                >
                    <Text style={{fontSize: 13}}>{content}</Text>
                    {props.hasButton && props.search ? (
                        <Button
                            onPress={() => {
                                props.search(bookingKey);
                            }}
                            title={bookingKey}
                            titleStyle={{color: "#FFF"}}
                            color={colorButton}
                            containerStyle={{
                                flex: 1,
                                alignSelf: "flex-end",
                                paddingRight: 14,
                            }}
                        />
                    ) : null}
                </View>
            );
        };

        const formatPrice = (value) => {
            return formatPriceRound(value, formatNumber);
        }

        const columns = [
            {
                title: language.notification_title,
                field: "content",
                render: (rowData) => (
                    <InfoReport
                        content={rowData.content}
                        hasButton={true}
                        search={searchCallback}
                    />
                ),
            },
        ];
        const columns_rp = [
            {
                title: "Nhật ký cuốc khách",
                field: "content",
                render: (rowData) => (
                    <InfoReport content={rowData.content} hasButton={false}/>
                ),
            },
        ];
        const callcolumns = [
            {title: "Loại", field: 'type', render: rowData => rowData.type},//@TODO Check type call
            {
                title: "Thời gian",
                field: "date_formated",
                render: (rowData) => <label
                    style={{
                        fontSize: 13,
                        width: 110,
                        color: 'black',
                        textAlign: 'center'
                    }}
                >{formatDisplayDateSmart(rowData.date_formated)}</label>,
                width: "1%",
            },
            {
                title: "Số điện thoại",
                field: "source",
                render: (rowData) => (
                    <View
                        style={{
                            justifyContent: "center",
                            marginHorizontal: 10,
                        }}
                    >
                        <Link
                            style={{
                                fontSize: 13,
                                width: 90
                            }}
                            href="#"
                            onClick={() => {
                                setShowCallNow({
                                    time_call: rowData.time_call_out ? rowData.time_call_out : [],
                                    phone: rowData.source,
                                    uniqueid: rowData.uniqueid,
                                    firebase_id: rowData.firebase_id,
                                    time: rowData.time,
                                    audios: rowData.audios ? rowData.audios : [],
                                    source: rowData.source,
                                });
                                setModalVisible(true);
                            }
                            }>{"[" + rowData.destination + "] " + (auth && auth.user && auth.user.usertype && auth.user.usertype == "admin" ? rowData.source : HiddenPhone(rowData.source))}</Link>
                    </View>
                ),
            },
            {
                title: "Ghi âm",
                field: "time",
                render: (rowData) =>
                    isApp ? null :
                        rowData.audios ? (
                            <List
                                sx={{
                                    width: "100%",
                                    maxWidth: 100,
                                    bgcolor: "background.paper",
                                    position: "relative",
                                    overflow: "auto",
                                    maxHeight: 300,
                                    "& ul": {padding: 0},
                                }}
                            >
                                {rowData.audios.map((a, index) => {
                                    return (
                                        <li key={`section-${index}`}>
                                            <ReactAudioPlayer
                                                src={
                                                    "https://crm.pavietnam.vn/api/playRecording.php?api_key=" +
                                                    API_PA_PHONE +
                                                    "&recording_file=" +
                                                    a
                                                }
                                                style={{
                                                    backgroundColor: a.includes("out")
                                                        ? colors.YELLOW.primary
                                                        : colors.GREEN.prime,
                                                }}
                                                title={
                                                    "" + a.split("-").at(-3) + " : " + a.split("-").at(-2)
                                                }
                                                controls
                                            />
                                        </li>
                                    );
                                })}

                                <li key={`section-${rowData.audios.length}`}>
                                    <IconButton
                                        aria-label="Nghe ghi âm"
                                        color="secondary"
                                        size="medium"
                                        disabled={loadingAudio && loadingAudio[rowData.time]}
                                        onClick={async () => {
                                            await loadRecordFile(
                                                rowData.source,
                                                ConvertTimeStampTimeForPa(
                                                    (parseInt(rowData.time) - 600) * 1000
                                                ),
                                                ConvertTimeStampTimeForPa(
                                                    (parseInt(rowData.time) + 1800) * 1000
                                                ),
                                                rowData.id,
                                                rowData.time_call_out ? rowData.time_call_out : [],
                                                rowData.time
                                            );
                                        }}
                                    >
                                        {loadingAudio && loadingAudio[rowData.time] ? (
                                            <View>{"Đang tải"}</View>
                                        ) : (
                                            <Refresh fontSize="inherit" color={"secondary"}/>
                                        )}
                                    </IconButton>
                                </li>
                            </List>
                        ) : (
                            <IconButton
                                aria-label="Nghe ghi âm"
                                color="secondary"
                                size="medium"
                                disabled={loadingAudio && loadingAudio[rowData.time]}
                                onClick={async () => {
                                    await loadRecordFile(
                                        rowData.source,
                                        ConvertTimeStampTimeForPa(
                                            (parseInt(rowData.time) - 600) * 1000
                                        ),
                                        ConvertTimeStampTimeForPa(
                                            (parseInt(rowData.time) + 1800) * 1000
                                        ),
                                        rowData.id,
                                        rowData.time_call_out ? rowData.time_call_out : [],
                                        rowData.time
                                    );
                                }}
                            >
                                {loadingAudio && loadingAudio[rowData.time] ? (
                                    <View>{"Đang tải"}</View>
                                ) : (
                                    <PlayCircleFilledOutlined fontSize="inherit" color={"secondary"}/>
                                )}
                            </IconButton>
                        ),
            },
            /*{
                title: "SĐT Lái Xe",
                field: "booking_id",
                render: (rowData) =>
                    rowData.driver_phone ? (
                        <View
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                maxWidth: 150,
                            }}
                        >
                            <Button
                                onPress={() => {
                                    setShowCallNow({
                                        time_call: rowData.time_call_out ? rowData.time_call_out : [],
                                        phone: rowData.driver_phone,
                                        uniqueid: rowData.uniqueid,
                                        firebase_id: rowData.firebase_id,
                                        time: rowData.time,
                                        audios: rowData.audios ? rowData.audios : [],
                                        source: rowData.source,
                                    });
                                    setModalVisible(true);
                                }}
                                title={rowData.driver_phone}
                                titleStyle={{color: "#FFF"}}
                                color={colors.BACKGROUND_DARK}
                                containerStyle={{
                                    flex: 1,
                                    alignSelf: "flex-end",
                                    paddingRight: 14,
                                }}
                            />
                            {rowData.driver_code ? (
                                <IconButton
                                    aria-label="Gửi tin nhắn"
                                    color="secondary"
                                    size="medium"
                                    onClick={() => {
                                        setDriverCode(rowData.driver_code);
                                        setNoteBooking(rowData.note);
                                        if (rowData.bookingDate) {
                                            setBookingDate(ConvertTimeStampToDate(rowData.bookingDate));
                                        }
                                        setShowChatModal(true);
                                    }}
                                >
                                    <Chat fontSize="inherit" color={"inherit"}/>
                                </IconButton>
                            ) : null}
                        </View>
                    ) : (
                        <View
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                maxWidth: 150,
                            }}
                        >
                            <Text style={{color: colors.WHITE}}>{"Chưa có"}</Text>
                        </View>
                    ),
            },*/

            {
                title: "Đặt loại xe",
                field: "source",
                render: (rowData) => {
                    /*let arrayOptions =
                        notelistdata && notelistdata[rowData.source]
                            ? Object.keys(
                                Object.values(notelistdata[rowData.source]).reduce(
                                    (a, c) => ((a[c] = (a[c] || 0) + 1), a),
                                    Object.create(null)
                                )
                            )
                            : [];*/
                    if (notemapaddressdata) {
                        return (
                            <ListCarComponent arrayOptions={cars ? cars.filter(c => c.type == 'taxi') : []} rowData={rowData}/>
                        );
                    }

                },
                //rowData.booking_note ? rowData.booking_note : (rowData.note ? rowData.note : "Chưa có")
            },

            {
                title: "Ghi chú CK",
                field: "source",
                render: (rowData) => {
                    /*let arrayOptions =
                        notelistdata && notelistdata[rowData.source]
                            ? Object.keys(
                                Object.values(notelistdata[rowData.source]).reduce(
                                    (a, c) => ((a[c] = (a[c] || 0) + 1), a),
                                    Object.create(null)
                                )
                            )
                            : [];*/
                    if (notemapaddressdata) {
                        return (
                            <View
                                style={{
                                    justifyContent: "center",
                                    minWidth: 200,
                                }}
                            >
                                <NoteCallCenterComponent
                                    rowData={rowData}
                                    arrayOptions={Object.values(notemapaddressdata).filter(n => n.coords && n.id)}
                                    isApp={isApp}
                                    openDialogCoord={showDialogCoord}
                                />
                            </View>
                        );
                    }

                },
                //rowData.booking_note ? rowData.booking_note : (rowData.note ? rowData.note : "Chưa có")
            },
            /* {
                 title: "Đặt xe",
                 field: "source",
                 render: (rowData) => {
                     let source = rowData.source;
                     var callObj = {};
                     if (callDetailData && callDetailData[source]) {
                         callObj = callDetailData[source];
                     }

                     //props.callObj.alias

                     if (rowData.booking_id) {
                         /!*if (
                             rowData.driver_code &&
                             dataSetting &&
                             dataSetting.driverCodeList
                         ) {
                             let listDriverCodeArray = dataSetting.driverCodeList.split(",");
                             if (listDriverCodeArray.includes(rowData.driver_code)) {
                                 return (
                                     <View
                                         style={{
                                             justifyContent: "center",
                                             minWidth: 200,
                                             flexDirection: "row",
                                         }}
                                     >
                                         <CustomerCareCallCenterComponent rowData={rowData} callObj={callObj}
                                                                          showModalCustomerCareCallback={showModalCustomerCareCallback}
                                                                          hideModal={hideModalCallback}/>
                                     </View>
                                 );
                             } else {
                                 return callObj && callObj.alias
                                     ? callObj.alias
                                     : "Cập nhật bí danh";
                             }
                         } else {
                             return callObj && callObj.alias
                                 ? callObj.alias
                                 : "Cập nhật bí danh";
                         }*!/
                         if (rowData.status == 'accept') {
                             return (
                                 <View
                                     style={{
                                         justifyContent: "center",
                                         marginHorizontal: 10,
                                     }}
                                 >
                                     <ButtonCallCenterDetail
                                         callDetail={rowData}
                                         callObj={callObj}
                                         showModal={showModalCallback}
                                         popupData={rowData}
                                         hideModal={hideModalCallback}
                                         listGroup={listGroup}
                                         cartypes={cartypes}
                                     />
                                 </View>
                             );
                         }


                         //return callObj && callObj.alias ? callObj.alias : "Cập nhật bí danh";
                     } else {
                         return (
                             <View
                                 style={{
                                     justifyContent: "center",
                                     marginHorizontal: 10,
                                 }}
                             >
                                 <ButtonCallCenterDetail
                                     callDetail={rowData}
                                     callObj={callObj}
                                     showModal={showModalCallback}
                                     popupData={rowData}
                                     hideModal={hideModalCallback}
                                     listGroup={listGroup}
                                     cartypes={cartypes}
                                 />
                             </View>
                         );
                     }
                 },
             },*/
            {
                title: "Huỷ",
                field: "source",
                initialEditValue: false,
                render: (rowData) => {
                    if (rowData.status == 'pending') {
                        return (
                            <IconButton
                                onClick={() => {
                                    setPopupData(rowData);
                                    setTimeout(() => {
                                        setModalVisible(true);
                                        setShowCancel(true);
                                    }, 200);
                                }
                                }
                            >
                                <CloseOutlined
                                    style={{
                                        backgroundColor: 'black',
                                        color: 'white',
                                        borderRadius: 15,
                                        padding: 3
                                    }}
                                />
                            </IconButton>
                        )
                    }
                    if (rowData.status === "accept" &&
                        rowData.driver_code &&
                        rowData.booking_id) {
                        return (<IconButton
                            onClick={() => {
                                setShowCancel(true);
                                setModalVisible(true);
                                setCallDetail(rowData);
                            }
                            }
                        >
                            <CloseOutlined
                                style={{
                                    backgroundColor: 'black',
                                    color: 'white',
                                    borderRadius: 15,
                                    padding: 3
                                }}
                            />
                        </IconButton>)
                    }
                    if (rowData.status === "booked_cancel") {
                        return (
                            <IconButton
                                onClick={() => {
                                    setShowCancel(true);
                                    setModalVisible(true);
                                    setCallDetail(rowData);
                                }
                                }
                            >
                                <ErrorOutlineIcon
                                    style={{
                                        backgroundColor: 'black',
                                        color: 'white',
                                        borderRadius: 15,
                                        padding: 3
                                    }}
                                />
                            </IconButton>
                        )

                    }
                }
            },
            {
                title: "Thêm xe",
                field: "source",
                initialEditValue: false,
                render: (rowData) => (
                    isApp ? null :
                        <IconButton
                            onClick={() => {
                                setPopupData(rowData);
                                setShowThemXe(true);
                                setModalVisible(true);
                            }
                            }
                        >
                            <AddCircleOutline
                                fontSize={'large'}
                            />
                        </IconButton>
                )
            },
            {
                title: "Trạng thái",
                field: "status",
                render: (rowData) => {
                    return (
                        rowData.driver_code &&
                        rowData.booking_id ?
                            <IconButton
                                onClick={() => {
                                    /*setShowCancel(true);
                                    setModalVisible(true);
                                    setCallDetail(rowData);*/
                                }
                                }
                            >
                                {rowData.status == 'accept' ?
                                    <img src={require("../assets/img/chuyentai-icon.png")} alt="Tài xế nhận" style={{width: 30}}/> : null}
                                {rowData.status == 'cancel' ? <ErrorOutlineIcon fontSize={'large'}/> : null}
                                {rowData.status == 'paid' ? <CheckCircleIcon fontSize={'large'}/> : null}
                                {rowData.status == 'booked' ? <CircularProgress size={30}/> : null}
                            </IconButton>
                            : null
                    )
                    /*if (rowData.status !== "pending") {
                        return (
                            <View style={{flex: 1, flexDirection: "row"}}>
                                {rowData.status === "cancel" &&
                                !rowData.driver_code &&
                                rowData.reason ? (
                                    <Text style={{color: colors.WHITE}}>{rowData.reason}</Text>
                                ) : (
                                    <View
                                        style={{
                                            flex: 0.5,
                                            justifyContent: "center",
                                            marginHorizontal: 10,
                                        }}
                                    >
                                        {rowData &&
                                        rowData.status === "accept" &&
                                        rowData.driver_code &&
                                        rowData.booking_id ?
                                            rowData.paid_pending && rowData.paid_pending == "PENDING" ?
                                                (
                                                    <Button
                                                        onPress={() => {
                                                            setShowCancel(true);
                                                            setModalVisible(true);
                                                            setCallDetail(rowData);
                                                        }}
                                                        color={colors.BLUE.secondary}
                                                        title={"Thanh toán"}
                                                        titleStyle={{color: "#FFF"}}
                                                        containerStyle={{
                                                            flex: 1,
                                                            alignSelf: "flex-end",
                                                            paddingRight: 14,
                                                        }}
                                                    />
                                                )
                                                :
                                                (
                                                    <Button
                                                        onPress={() => {
                                                            setShowCancel(true);
                                                            setModalVisible(true);
                                                            setCallDetail(rowData);
                                                        }}
                                                        color={colors.BACKGROUND_DARK}
                                                        title={"Khách hủy 1"}
                                                        titleStyle={{color: "#FFF"}}
                                                        containerStyle={{
                                                            flex: 1,
                                                            alignSelf: "flex-end",
                                                            paddingRight: 14,
                                                        }}
                                                    />
                                                ) : null}

                                        {rowData.status === "booked_cancel" &&
                                        !rowData.old_booking_id &&
                                        rowData.type != "khachquen" &&
                                        !rowData.cancel_by ? (
                                            <View
                                                style={{
                                                    justifyContent: "center",
                                                    marginHorizontal: 10,
                                                }}
                                            >
                                                {rowData.type != "KH" && rowData.type != "LX" ? (
                                                    <ButtonCallCenterDetail
                                                        titleButton={"Đặt lại"}
                                                        callDetail={rowData}
                                                        callObj={
                                                            rowData.source &&
                                                            callDetailData &&
                                                            callDetailData[rowData.source]
                                                                ? callDetailData[rowData.source]
                                                                : null
                                                        }
                                                        showModal={showModalCallback}
                                                        popupData={rowData}
                                                        hideModal={hideModalCallback}
                                                        listGroup={listGroup}
                                                        cartypes={cartypes}
                                                    />
                                                ) : (
                                                    <Text style={{color: colors.WHITE}}>
                                                        {rowData.type == "KH"
                                                            ? "Khách đặt qua APP"
                                                            : "Tài xế đặt qua APP"}
                                                    </Text>
                                                )}
                                            </View>
                                        ) : null}

                                        {rowData.status === "booked_cancel" &&
                                        !rowData.old_booking_id &&
                                        rowData.type == "khachquen" ? (
                                            <Button
                                                onPress={() => {
                                                    alert(
                                                        "Taxi Metter " +
                                                        (rowData.codeDriver
                                                            ? "#" + rowData.codeDriver.trim() + ""
                                                            : "")
                                                    );
                                                }}
                                                color={colors.BACKGROUND_DARK}
                                                title={
                                                    rowData.codeDriver
                                                        ? "#" + rowData.codeDriver.trim() + " Khách quen"
                                                        : "Khách quen"
                                                }
                                                style={{padding: 20}}
                                            />
                                        ) : null}
                                        {rowData.status === "booked_cancel" &&
                                        rowData.old_booking_id ? (
                                            <Button
                                                onPress={() => {
                                                    setPopupData(rowData);
                                                    setTimeout(() => {
                                                        setModalVisible(true);
                                                        setShowCancel(false);
                                                    }, 200);
                                                }}
                                                color={colors.BACKGROUND_DARK}
                                                title={
                                                    rowData.codeDriver
                                                        ? "#" + rowData.codeDriver.trim() + " đã đặt lại"
                                                        : "Đã đặt lại"
                                                }
                                                style={{padding: 20}}
                                            />
                                        ) : null}

                                        {rowData.status === "BAO_TIN_HIEU_LAN_2_REQUEST" && rowData.type != 'LX' &&
                                        rowData.booking_id ? (
                                            // Đặt lại 3km
                                            <Button
                                                onPress={() => {
                                                    if (rowData.booking_id) {
                                                        dispatch(
                                                            addReport({
                                                                content:
                                                                    " #" + rowData.booking_id + " chuyển tín hiệu ",
                                                            })
                                                        );
                                                        if (rowData.id) {
                                                            update(paCallByIdRef(rowData.id), {
                                                                status: "BAO_TIN_HIEU_LAN_2_REQUEST_DONE",
                                                            });
                                                        }
                                                        setTimeout(() => {
                                                            dispatch(bookingDetailOn(rowData.booking_id));
                                                        }, 300);
                                                        alert("Cuốc khách đã được chuyển tín hiệu");
                                                    }
                                                }}
                                                title={"Đặt lại"}
                                                color={colors.BACKGROUND_DARK}
                                                titleStyle={{color: "#FFF"}}
                                                containerStyle={{
                                                    flex: 1,
                                                    alignSelf: "flex-end",
                                                    paddingRight: 14,
                                                }}
                                            />
                                        ) : null}

                                        {(rowData.status === "booked_mistake_1" ||
                                            rowData.status === "booked_mistake_2") &&
                                        rowData.booking_id &&
                                        rowData.type != "KH" &&
                                        rowData.type != "LX" ? (
                                            <Button
                                                onPress={() => {
                                                    if (rowData.booking_id) {
                                                        dispatch(
                                                            addReport({
                                                                content:
                                                                    " #" +
                                                                    rowData.booking_id +
                                                                    " vi phạm -> chuyển tín hiệu ",
                                                            })
                                                        );
                                                        dispatch(fetchOnceDrivers());
                                                        setTimeout(() => {
                                                            dispatch(
                                                                bookingDetailOn(
                                                                    rowData.booking_id,
                                                                    settingsdata && settingsdata.bankinh
                                                                        ? settingsdata.bankinh
                                                                        : 3
                                                                )
                                                            );
                                                        }, 300);
                                                    }
                                                }}
                                                title={"Đặt lại"}
                                                color={colors.BACKGROUND_DARK}
                                                titleStyle={{color: "#FFF"}}
                                                containerStyle={{
                                                    flex: 1,
                                                    alignSelf: "flex-end",
                                                    paddingRight: 14,
                                                }}
                                            />
                                        ) : null}

                                        {rowData &&
                                        rowData.status === "BAO_TIN_HIEU_LAN_2_REQUEST_DONE" ? (
                                            <Button
                                                onPress={() => {
                                                    alert("Đợt LX nhận");
                                                }}
                                                title={"Đợi LX nhận"}
                                                color={colors.BACKGROUND_DARK}
                                                titleStyle={{color: "#FFF"}}
                                                containerStyle={{
                                                    flex: 1,
                                                    alignSelf: "flex-end",
                                                    paddingRight: 14,
                                                }}
                                            />
                                        ) : null}
                                    </View>
                                )}
                                <View style={{height: 30, width: 5}}/>
                                {rowData.status &&
                                rowData.status === "cancel" &&
                                !rowData.driver_code &&
                                rowData.reason ? null : (
                                    <ButtonCallCenter {...props} callDetail={rowData}/>
                                )}
                            </View>
                        );
                    }*/
                },
            },
            {
                title: "Chọn",
                field: "id",
                render: (rowData) => {
                    if (getBookingLoading && getBookingLoading == rowData.id) {
                        return (
                            <CircularProgress size={30}/>
                        )
                    } else {
                        return (
                            <FormControlLabel
                                label="Chọn"
                                control={
                                    <Checkbox
                                        checked={selected && rowData.id == selected ? true : false}
                                        value={rowData.id}
                                        onChange={async (event, checked) => {
                                            setBookingdata(null);
                                            setGetBookingLoading(rowData.id);
                                            if (selected && selected == rowData.id) {
                                                setSelected(null);
                                                setCallDetail(null);
                                            } else {
                                                setCallDetail(rowData);
                                                let bookings = await api.getBookingByCusPhone(rowData.source);
                                                if (bookings) {
                                                    setBookingdata(bookings);
                                                }
                                                setSelected(event.target.value);
                                            }
                                            setGetBookingLoading(null);
                                        }}/>
                                }
                            />
                        )
                    }
                }
            }
        ];

        const lineOptions = [
            {value: "101", label: "Line 101"},
            {value: "102", label: "Line 102"},
            {value: "103", label: "Line 103"},
            {value: "104", label: "Line 104"},
        ];


        const callDriver = useCallback((rowData) => {
            setShowCallNow({
                time_call: rowData.time_call_out ? rowData.time_call_out : [],
                phone: rowData.source,
                uniqueid: rowData.uniqueid,
                firebase_id: rowData.firebase_id,
                time: rowData.time,
                audios: rowData.audios ? rowData.audios : [],
                source: rowData.source,
            });
            setModalVisible(true);
        }, []);

        return pacallcenterdatas.loading ? (
            <CircularLoading/>
        ) : (
            <ThemeProvider theme={lightTheme}>
                <CssBaseline/>
                {/*<View className={classes.form}>
                <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <GridContainer spacing={2}>
                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <Typography
                                    component="h3"
                                    variant="h5"
                                    align="center"
                                    className={classes.marginBottom50}
                                >
                                    {"Điều hành: " + greet}
                                </Typography>
                            </GridItem>

                            <GridItem xs={2} sm={2} md={2} lg={2}>
                                <Paper style={{margin: 10}}>
                                    <TouchableOpacity
                                        style={{
                                            backgroundColor: colors.GREEN.prime,
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: 10,
                                            elevation: 2,
                                            borderRadius: 2,
                                            shadowOffset: {
                                                height: 1,
                                                width: 0,
                                            },
                                            shadowColor: colors.BLACK,
                                            shadowOpacity: 0.35,
                                            shadowRadius: 5,
                                        }}
                                        onPress={() => {
                                            setAddBookingDialog(true);
                                            setModalVisible(true);
                                        }}
                                    >
                                        <Text
                                            style={{
                                                color: colors.WHITE,
                                                fontSize: 16,
                                            }}
                                        >
                                            {"Tạo cuộc gọi"}
                                        </Text>
                                    </TouchableOpacity>
                                </Paper>
                            </GridItem>

                            <GridItem xs={2} sm={2} md={2} lg={2}>
                                <Paper style={{margin: 10}}>
                                    <TouchableOpacity
                                        style={{
                                            backgroundColor: colors.MAIN3,
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: 10,
                                            elevation: 2,
                                            borderRadius: 2,
                                            shadowOffset: {
                                                height: 1,
                                                width: 0,
                                            },
                                            shadowColor: colors.BLACK,
                                            shadowOpacity: 0.35,
                                            shadowRadius: 5,
                                        }}
                                        onPress={() => {
                                            setIsApp(false);
                                        }}
                                    >
                                        <Text
                                            style={{
                                                color: colors.WHITE,
                                                fontSize: 16,
                                            }}
                                        >
                                            {"Khách tổng đài"}
                                        </Text>
                                    </TouchableOpacity>
                                </Paper>
                            </GridItem>

                            <GridItem xs={2} sm={2} md={2} lg={2}>
                                <Paper style={{margin: 10}}>
                                    <TouchableOpacity
                                        style={{
                                            backgroundColor: colors.MAIN5,
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: 10,
                                            elevation: 2,
                                            borderRadius: 2,
                                            shadowOffset: {
                                                height: 1,
                                                width: 0,
                                            },
                                            shadowColor: colors.BLACK,
                                            shadowOpacity: 0.35,
                                            shadowRadius: 5,
                                        }}
                                        onPress={() => {
                                            setIsApp(true);
                                        }}
                                    >
                                        <Text
                                            style={{
                                                color: colors.WHITE,
                                                fontSize: 16,
                                            }}
                                        >
                                            {"Khách APP"}
                                        </Text>
                                    </TouchableOpacity>
                                </Paper>
                            </GridItem>


                            {auth.info && auth.info.profile && auth.info.profile.usertype == 'fleetadmin' ?
                                <GridItem xs={6} sm={6} md={6} lg={6} style={{backgroundColor: colors.BACKGROUND_DARK_WEB}}>
                                    <View
                                        style={{flexDirection: 'row', width: '100%'}}
                                    >
                                        <TextField
                                            id="datetime-local-start"
                                            label={"Thời gian bắt đầu"}
                                            type="datetime-local"
                                            variant="outlined"
                                            value={startDateValue ? startDateValue : GetDateString00()}
                                            onChange={(event) => {
                                                setStartDateValue(event.target.value);
                                                setStartDate(new Date(event.target.value).getTime() / 1000);
                                            }
                                            }
                                        />
                                        <TextField
                                            id="datetime-local-end"
                                            label={"Thời gian kết thúc"}
                                            type="datetime-local"
                                            variant="outlined"
                                            value={endDateValue ? endDateValue : GetDateString()}
                                            onChange={(event) => {
                                                setEndDateValue(event.target.value);
                                                setEndDate(new Date(event.target.value).getTime() / 1000);
                                            }
                                            }
                                        />
                                        <Button
                                            onPress={() => {
                                                let uid = auth.info.uid;
                                                if (uid && startDate && endDate) {
                                                    dispatch(reportCallCenter(uid, startDate, endDate));
                                                }
                                            }}
                                            color={colors.BACKGROUND_DARK}
                                            title={"Thống kê"}
                                            style={{padding: 20, alignCenter: true}}
                                        />
                                        {!pacallcenterdatas.loading_report && reportData ?
                                            <Text>{"Thời gian hoạt động: " + ConvertSecondToHm(reportData.time_active) + "\n" + "Cuộc gọi đến: " + reportData.total_call + "\nCuốc khách đã xử lý: " + reportData.total_success}</Text>
                                            : null
                                        }
                                        {pacallcenterdatas.loading_report ?
                                            <Text>{"Đang tải thông tin"}</Text>
                                            : null
                                        }

                                    </View>

                                </GridItem> : null}

                        </GridContainer>

                        {isApp ?
                            listCallApp ? (
                                searchInit ? (
                                    <MaterialTable
                                        tableRef={tableRef}
                                        data={listCallApp}
                                        title={"Quản lý cuộc gọi"}
                                        columns={callcolumns}
                                        options={{
                                            columnsButton: true,
                                            exportButton: false,
                                            pageSize: 10,
                                            pageSizeOptions: [10, 25, 50, 100, 200, 500],
                                            searchText: searchInit ? searchInit : null,
                                        }}
                                        style={{
                                            flexGrow: 0,
                                            paddingLeft: 10,
                                            paddingRight: 10
                                        }}
                                    />
                                ) : null
                            ) : null
                            :
                            listCall ? (
                                searchInit ? (
                                    <MaterialTable
                                        tableRef={tableRef}
                                        data={listCall}
                                        title={"Quản lý cuộc gọi"}
                                        columns={callcolumns}
                                        options={{
                                            columnsButton: true,
                                            exportButton: false,
                                            pageSize: 10,
                                            pageSizeOptions: [10, 25, 50, 100, 200, 500],
                                            searchText: searchInit ? searchInit : null,
                                        }}
                                        style={{
                                            flexGrow: 0,
                                            paddingLeft: 10,
                                            paddingRight: 10
                                        }}
                                    />
                                ) : null
                            ) : null
                        }

                        {isApp ?
                            listCallApp && !searchInit ? (
                                <MaterialTable
                                    tableRef={tableRef}
                                    data={listCallApp}
                                    title={"Quản lý cuộc gọi"}
                                    columns={callcolumns}
                                    options={{
                                        columnsButton: true,
                                        exportButton: false,
                                        pageSize: 10,
                                        pageSizeOptions: [10, 25, 50, 100, 200, 500],
                                    }}
                                    style={{
                                        flexGrow: 0,
                                        paddingLeft: 10,
                                        paddingRight: 10
                                    }}
                                />
                            ) : null
                            :
                            listCall && !searchInit ? (
                                <MaterialTable
                                    tableRef={tableRef}
                                    data={listCall}
                                    title={"Quản lý cuộc gọi"}
                                    columns={callcolumns}
                                    options={{
                                        columnsButton: true,
                                        exportButton: false,
                                        pageSize: 10,
                                        pageSizeOptions: [10, 25, 50, 100, 200, 500],
                                    }}
                                    style={{
                                        flexGrow: 0,
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                    }}
                                />
                            ) : null
                        }
                    </Grid>
                </Grid>
                <FooterAdmin
                    color="transparent"
                    routes={dashboardRoutes}
                    fixed
                    changeColorOnScroll={{
                        height: 400,
                        color: "white",
                    }}
                    {...rest}
                />
                {popupData ? (
                    <ButtonCallCenterDetailDialog
                        notes={Object.values(notemapaddressdata).filter(n => n.coords && n.id)}
                        showModal={showModal}
                        hideModal={hideModalCallback}
                        callDetail={popupData}
                        callObj={callObj}
                        listGroup={listGroup}
                        cartypes={cartypes}
                    />
                ) : null}
                {popupData ? (
                    <ButtonBookingDetailDialog
                        showModal={showModal}
                        hideModal={hideModalCallback}
                        callDetail={popupData}
                    />
                ) : null}
                {
                    <Modal
                        animationType="slide"
                        transparent={true}
                        visible={modalVisible}
                        onPress={() => {
                            setModalVisible(false);
                            setPopupData(null);
                        }}
                        titlle
                    >
                        <View
                            style={{
                                flex: 1,
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: 22,
                            }}
                        >
                            <View
                                style={{
                                    margin: 20,
                                    backgroundColor: "white",
                                    borderRadius: 10,
                                    borderWidth: 1,
                                    padding: 10,
                                    alignItems: "center",
                                    shadowColor: "#000",
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                    shadowOpacity: 0.25,
                                    shadowRadius: 4,
                                    elevation: 5,
                                }}
                            >
                                {popupData &&
                                showCancel === false &&
                                !popupData.codeDriver && notemapaddressdata &&
                                showThemXe === false ? (
                                    <BookingCallCenter
                                        {...props}
                                        time={ConvertTimeStampToDate(popupData.time * 1000)}
                                        callDetail={popupData}
                                        notes={Object.values(notemapaddressdata).filter(n => n.coords && n.id)}
                                        listGroup={listGroup}
                                        listLyDo={listLyDo}
                                        cartypes={cartypes}
                                        closeCallback={closeCallback}
                                    />
                                ) : null}

                                {addBookingDialog ? (
                                    <AddCallCenter {...props} closeCallback={closeCallback}/>
                                ) : null}

                                {popupData && showThemXe === true ? (
                                    <ThemXeCallCenter
                                        {...props}
                                        callDetail={popupData}
                                        closeCallback={closeCallback}
                                    />
                                ) : null}

                                {popupData &&
                                showCancel === false &&
                                popupData.codeDriver && notemapaddressdata &&
                                showThemXe === false ? (
                                    <BookingCallCenter
                                        {...props}
                                        time={ConvertTimeStampToDate(popupData.time * 1000)}
                                        notes={Object.values(notemapaddressdata).filter(n => n.coords && n.id)}
                                        callDetail={popupData}
                                        listGroup={listGroup}
                                        listLyDo={listLyDo}
                                        cartypes={cartypes}
                                        closeCallback={closeCallback}
                                        driverCode={popupData.codeDriver.trim()}
                                    />
                                ) : null}
                                {popupData && showCancel === true && showThemXe === false ? (
                                    <CancelCallCenter
                                        destination={popupData.destination}
                                        time={ConvertTimeStampToDate(popupData.time * 1000)}
                                        phone={popupData.source}
                                        uniqueid={popupData.uniqueid}
                                        listLyDo={listLyDo}
                                        firebase_id={popupData.firebase_id}
                                        closeCallback={closeCallback}
                                    />
                                ) : null}
                                {popupData === null && showCallNow && showThemXe === false ? (
                                    <CallNowCenter
                                        lineOptions={lineOptions}
                                        time={showCallNow.time}
                                        source={showCallNow.source}
                                        time_call={showCallNow.time_call ? showCallNow.time_call : []}
                                        phone={showCallNow.phone}
                                        uniqueid={showCallNow.uniqueid}
                                        listAudio={showCallNow.audios ? showCallNow.audios : []}
                                        firebase_id={showCallNow.firebase_id}
                                        closeCallback={closeCallback}
                                    />
                                ) : null}

                                {callDetail && showCancel === true && callDetail.booking_id ? (
                                    <CancelCallCenter
                                        destination={callDetail.destination}
                                        time={ConvertTimeStampToDate(callDetail.time * 1000)}
                                        phone={callDetail.source}
                                        uniqueid={callDetail.uniqueid}
                                        booking_id={callDetail}
                                        listLyDo={listLyDo}
                                        firebase_id={callDetail.firebase_id}
                                        closeCallback={closeCallback}
                                        fromTT={true}
                                    />
                                ) : null}
                            </View>
                        </View>
                    </Modal>
                }
                {showChatModal && driverCode ? (
                    <ShowNotifyDialog
                        showModal={showChatModal}
                        bookingDate={bookingDate}
                        hideModal={hideChatModalCallback}
                        isChatOnce={true}
                        driverCode={driverCode}
                        noteBooking={noteBooking}
                    />
                ) : null}

                {showCustomerCareDialog && popupData ?
                    <ShowCustomerCareDialog showModal={showCustomerCareDialog} hideModal={hideModalCallback}
                                            rowData={popupData} callObj={callObj ? callObj : null}/> : null}
                {openAddCoord && noteMap && popupData ?
                    <AddCoordCallDialog noteMap={noteMap} rowData={popupData} isOpen={openAddCoord} openDialog={showDialogCoord}/> : null}
            </View>*/}
                <Box
                    sx={{flexGrow: 1, typography: 'body1'}}
                >
                    <Grid container spacing={2}>
                        <Grid xs={9} style={{marginTop: 80}}>
                            <TabContext value={value}>
                                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                    <TabList onChange={handleChange}
                                             aria-label="Quản lý cuộc gọi gọi">
                                        {tabs ? tabs.map(t => {
                                            return (
                                                <Tab label={t.name} value={t.value} key={'tab' + t.value}/>
                                            )
                                        }) : null}
                                    </TabList>
                                </Box>
                                {tabs ? tabs.map(t => {
                                    if (t.value == value) {
                                        return (
                                            <TabPanel value={value} key={'tabpanel' + value}>
                                                {isApp ?
                                                    listCallApp && !searchInit ? (
                                                        <MaterialTable
                                                            tableRef={tableRef}
                                                            data={listCallApp}
                                                            title={"Quản lý cuộc gọi"}
                                                            columns={callcolumns}
                                                            options={{
                                                                columnsButton: true,
                                                                exportButton: false,
                                                                pageSize: 10,
                                                                pageSizeOptions: [10, 25, 50, 100, 200, 500],
                                                            }}
                                                            style={{
                                                                flexGrow: 0,
                                                                paddingLeft: 10,
                                                                paddingRight: 10
                                                            }}
                                                        />
                                                    ) : null
                                                    :
                                                    listCall && !searchInit ? (
                                                        <MaterialTable
                                                            tableRef={tableRef}
                                                            data={listCall}
                                                            title={"Quản lý cuộc gọi"}
                                                            columns={callcolumns}
                                                            options={{
                                                                columnsButton: true,
                                                                exportButton: false,
                                                                pageSize: 10,
                                                                pageSizeOptions: [10, 25, 50, 100, 200, 500],
                                                            }}
                                                            style={{
                                                                flexGrow: 0,
                                                                paddingLeft: 10,
                                                                paddingRight: 10,
                                                            }}
                                                        />
                                                    ) : null
                                                }
                                            </TabPanel>
                                        )
                                    } else {
                                        return null;
                                    }
                                }) : null}
                            </TabContext>
                        </Grid>

                        {selected ?
                            <Grid xs={3} style={{marginTop: 80}}>
                                <View style={{
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    padding: 5,
                                    position: 'fixed', width: '24%'
                                }}>
                                    <View style={{flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <Text style={{fontSize: 20}}>{"Hoạt động"}</Text>
                                        <Text style={{fontSize: 16}}>{callDetail && callDetail.source ? auth.user && auth.user.usertype == "admin" ? callDetail.source : HiddenPhone(callDetail.source) : ''}</Text>
                                    </View>

                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            setBookingDetail(null);
                                            if (callDetail && callDetail.note) {
                                                let source = callDetail.source;
                                                var callObj = {};
                                                if (callDetailData && callDetailData[source]) {
                                                    callObj = callDetailData[source];
                                                }
                                                showModalCallback(callDetail, callObj);
                                            } else {
                                                alert("Vui lòng chọn ghi chú trước");
                                            }
                                        }
                                        }
                                        startIcon={
                                            <View>
                                                <Text style={{fontSize: 13, color: colors.WHITE, position: 'absolute', top: -5, right: -5}}>{callDetail && callDetail.car ? callDetail.car.seat : ''}</Text>
                                                <AccountCircle/>
                                            </View>
                                        }
                                        style={{backgroundColor: 'black', marginTop: 20, paddingTop: 10, paddingBottom: 10}}
                                    >
                                        <Text style={{fontSize: 16, color: colors.WHITE, marginLeft: 10}}>{"Hành động"}</Text>
                                    </Button>


                                    {bookingdata && bookingdata.length > 0 ?
                                        <FlatList
                                            data={bookingdata}
                                            ItemSeparatorComponent={() => <View style={{height: 10}}/>}
                                            scrollEnabled={true}
                                            style={{
                                                maxHeight: 600, width: '100%'
                                            }}
                                            renderItem={({item, index}) => {
                                                let booking = item;

                                                if (booking.status === "NEWV2") {
                                                    return (
                                                        <View style={{flexDirection: 'column', marginTop: 20, borderWidth: 1, borderColor: 'black', borderRadius: 10, width: '100%', justifyContent: 'flex-start', alignItems: 'center'}}>
                                                            <View style={{flexDirection: 'row', width: '90%', justifyContent: 'space-between', alignItems: 'center'}}>
                                                                <View>
                                                                    <Text style={{fontSize: 14, marginRight: 10}}>{ConvertTimeStampTimeForPaShort(booking.bookingDate)}</Text>
                                                                </View>

                                                                <View style={{
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    backgroundColor: 'rgba(218,218,218,0.96)',
                                                                    padding: 5, borderRadius: 5,
                                                                    minHeight: 50,
                                                                    width: '70%'
                                                                }}>
                                                                    <View style={{
                                                                        marginRight: 100
                                                                    }}>
                                                                        <Text style={{fontSize: 16, color: colors.BLACK, position: 'absolute', top: -5, left: 20}}>{callDetail && callDetail.car ? callDetail.car.seat : '4'}</Text>
                                                                        <AccountCircle fontSize={'medium'}/>
                                                                    </View>
                                                                    <View style={{
                                                                        flexDirection: 'row',
                                                                        alignItems: 'center',
                                                                    }}>
                                                                        <LocalOfferIcon fontSize={'medium'}/>
                                                                        <Text style={{marginLeft: 10}}>{"50%"}</Text>
                                                                    </View>
                                                                </View>
                                                            </View>

                                                            <View style={{flexDirection: 'row', marginTop: 20, width: '90%', justifyContent: 'space-between', alignItems: 'center'}}>
                                                                <View style={{width: '85%'}}>
                                                                    <View style={{flexDirection: 'row', alignItems: 'center', paddingLeft: 13}}>
                                                                        <img src={require("../assets/img/ico_start_13x.png")} alt="Điểm đón" style={{width: 20, height: 20}}/>
                                                                        <Text style={{marginLeft: 10}}>{booking.pickupAddress ? formatDisplayAddressSearch(booking.pickupAddress) : ''}</Text>
                                                                    </View>
                                                                    {booking.dropAddress ?
                                                                        <View style={{flexDirection: 'row', alignItems: 'center', paddingLeft: 13, marginTop: 10}}>
                                                                            <img src={require("../assets/img/ico_stop_23x.png")} alt="Điểm đến" style={{width: 20, height: 20}}/>
                                                                            <Text style={{marginLeft: 10}}>{formatDisplayAddressSearch(booking.dropAddress)}</Text>
                                                                        </View> : null}
                                                                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                                                        <IconButton>
                                                                            <EditIcon size={'medium'}/>
                                                                        </IconButton>
                                                                        <Text style={{fontSize: 13}}>{booking.customer_note ? booking.customer_note : ''}</Text>
                                                                    </View>
                                                                </View>
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setBookingDetail(booking);
                                                                        let source = callDetail.source;
                                                                        var callObj = {};
                                                                        if (callDetailData && callDetailData[source]) {
                                                                            callObj = callDetailData[source];
                                                                        }
                                                                        showModalCallback(callDetail, callObj);
                                                                    }
                                                                    }
                                                                >
                                                                    <ArrowForwardIosIcon size={'large'}/>
                                                                </IconButton>
                                                            </View>

                                                            <View style={{flexDirection: 'row', width: '80%', justifyContent: 'space-between', alignItems: 'center', padding: 10}}>
                                                                <CircularProgress size={30}/>
                                                                <Text>{"Đang tìm chuyến"}</Text>
                                                            </View>
                                                        </View>
                                                    )
                                                }

                                                if (booking.status === "ACCEPTED") {
                                                    return (
                                                        <View style={{flexDirection: 'column', marginTop: 20, borderWidth: 1, borderColor: 'black', borderRadius: 10, width: '100%', justifyContent: 'flex-start', alignItems: 'center'}}>
                                                            <View style={{flexDirection: 'row', width: '90%', justifyContent: 'space-between', alignItems: 'center'}}>
                                                                <View>
                                                                    <Text style={{fontSize: 14, marginRight: 10}}>{ConvertTimeStampTimeForPaShort(booking.bookingDate)}</Text>
                                                                </View>

                                                                <View style={{
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    backgroundColor: 'rgba(218,218,218,0.96)',
                                                                    padding: 5, borderRadius: 5,
                                                                    minHeight: 50,
                                                                    width: '70%'
                                                                }}>
                                                                    {/*<Text style={{fontSize: 20, marginRight: 10}}>{booking.carType}</Text>*/}
                                                                    <View style={{
                                                                        marginRight: 100
                                                                    }}>
                                                                        <Text style={{fontSize: 16, color: colors.BLACK, position: 'absolute', top: -5, left: 20}}>{callDetail && callDetail.car ? callDetail.car.seat : '4'}</Text>
                                                                        <AccountCircle fontSize={'medium'}/>
                                                                    </View>
                                                                    <View style={{
                                                                        flexDirection: 'row',
                                                                        alignItems: 'center',
                                                                    }}>
                                                                        <LocalOfferIcon fontSize={'medium'}/>
                                                                        <Text style={{marginLeft: 10}}>{"50%"}</Text>
                                                                    </View>
                                                                </View>
                                                            </View>

                                                            <View style={{flexDirection: 'row', marginTop: 20, width: '90%', justifyContent: 'space-between', alignItems: 'center'}}>
                                                                <View style={{width: '85%'}}>
                                                                    <View style={{flexDirection: 'row', alignItems: 'center', paddingLeft: 13}}>
                                                                        <img src={require("../assets/img/ico_start_13x.png").default} alt="Điểm đón" style={{width: 20, height: 20}}/>
                                                                        <Text style={{marginLeft: 10}}>{booking.pickupAddress ? formatDisplayAddressSearch(booking.pickupAddress) : ''}</Text>
                                                                    </View>
                                                                    {booking.dropAddress ?
                                                                        <View style={{flexDirection: 'row', alignItems: 'center', paddingLeft: 13, marginTop: 10}}>
                                                                            <img src={require("../assets/img/ico_stop_23x.png").default} alt="Điểm đến" style={{width: 20, height: 20}}/>
                                                                            <Text style={{marginLeft: 10}}>{formatDisplayAddressSearch(booking.dropAddress)}</Text>
                                                                        </View> : null}
                                                                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                                                        <IconButton>
                                                                            <EditIcon size={'medium'}/>
                                                                        </IconButton>
                                                                        <Text style={{fontSize: 13}}>{booking.customer_note ? booking.customer_note : ''}</Text>
                                                                    </View>
                                                                </View>
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setBookingDetail(booking);
                                                                        let source = callDetail.source;
                                                                        var callObj = {};
                                                                        if (callDetailData && callDetailData[source]) {
                                                                            callObj = callDetailData[source];
                                                                        }
                                                                        showModalCallback(callDetail, callObj);
                                                                    }
                                                                    }
                                                                >
                                                                    <ArrowForwardIosIcon size={'large'}/>
                                                                </IconButton>
                                                            </View>

                                                            <View style={{flexDirection: 'column', width: '90%', justifyContent: 'flex-start', alignItems: 'center'}}>
                                                                <WaitingCustomerComponent/>
                                                            </View>
                                                        </View>
                                                    )
                                                }

                                                if (booking.status === "STARTED" || booking.status === "REACHED") {
                                                    return (
                                                        <View style={{flexDirection: 'column', marginTop: 20, borderWidth: 1, borderColor: 'black', borderRadius: 10, width: '100%', justifyContent: 'flex-start', alignItems: 'center'}}>
                                                            <View style={{flexDirection: 'row', width: '90%', justifyContent: 'space-between', alignItems: 'center'}}>
                                                                <View>
                                                                    <Text style={{fontSize: 14, marginRight: 10}}>{ConvertTimeStampTimeForPaShort(booking.bookingDate)}</Text>
                                                                </View>

                                                                <View style={{
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    backgroundColor: 'rgba(218,218,218,0.96)',
                                                                    padding: 5, borderRadius: 5,
                                                                    minHeight: 50,
                                                                    width: '70%'
                                                                }}>
                                                                    {/*<Text style={{fontSize: 20, marginRight: 10}}>{booking.carType}</Text>*/}
                                                                    <View style={{
                                                                        marginRight: 100
                                                                    }}>
                                                                        <Text style={{fontSize: 16, color: colors.BLACK, position: 'absolute', top: -5, left: 20}}>{callDetail && callDetail.car ? callDetail.car.seat : '4'}</Text>
                                                                        <AccountCircle fontSize={'medium'}/>
                                                                    </View>
                                                                    <View style={{
                                                                        flexDirection: 'row',
                                                                        alignItems: 'center',
                                                                    }}>
                                                                        <LocalOfferIcon fontSize={'medium'}/>
                                                                        <Text style={{marginLeft: 10}}>{"50%"}</Text>
                                                                    </View>
                                                                </View>
                                                            </View>

                                                            <View style={{flexDirection: 'row', marginTop: 20, width: '90%', justifyContent: 'space-between', alignItems: 'center'}}>
                                                                <View style={{width: '85%'}}>
                                                                    <View style={{flexDirection: 'row', alignItems: 'center', paddingLeft: 13}}>
                                                                        <img src={require("../assets/img/ico_start_13x.png").default} alt="Điểm đón" style={{width: 20, height: 20}}/>
                                                                        <Text style={{marginLeft: 10}}>{booking.pickupAddress ? formatDisplayAddressSearch(booking.pickupAddress) : ''}</Text>
                                                                    </View>
                                                                    {booking.dropAddress ?
                                                                        <View style={{flexDirection: 'row', alignItems: 'center', paddingLeft: 13, marginTop: 10}}>
                                                                            <img src={require("../assets/img/ico_stop_23x.png").default} alt="Điểm đến" style={{width: 20, height: 20}}/>
                                                                            <Text style={{marginLeft: 10}}>{formatDisplayAddressSearch(booking.dropAddress)}</Text>
                                                                        </View> : null}
                                                                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                                                        <IconButton>
                                                                            <EditIcon size={'medium'}/>
                                                                        </IconButton>
                                                                        <Text style={{fontSize: 13}}>{booking.customer_note ? booking.customer_note : ''}</Text>
                                                                    </View>
                                                                </View>
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setBookingDetail(booking);
                                                                        let source = callDetail.source;
                                                                        var callObj = {};
                                                                        if (callDetailData && callDetailData[source]) {
                                                                            callObj = callDetailData[source];
                                                                        }
                                                                        showModalReachedCallback(callDetail, callObj);
                                                                    }
                                                                    }
                                                                >
                                                                    <ArrowForwardIosIcon size={'large'}/>
                                                                </IconButton>
                                                            </View>

                                                            <View style={{flexDirection: 'column', width: '90%', justifyContent: 'flex-start', alignItems: 'center'}}>
                                                                {/* <WaitingCustomerComponent/>*/}
                                                                <View style={{flexDirection: 'row', flexWrap: "wrap", justifyContent: "space-between", width: '100%', backgroundColor: colors.BLACK, padding: 10, borderRadius: 5}}>
                                                                    <View style={{width: '25%', justifyContent: "flex-start", alignItems: "center", borderRightWidth: 1, borderRightColor: colors.WHITE}}>
                                                                        <Text style={{color: colors.WHITE, fontSize: 14, marginBottom: 10, width: '100%', textAlign: 'left'}}>{"Kilomet"}</Text>
                                                                        <Text style={{
                                                                            color: colors.WHITE,
                                                                            fontSize: 20
                                                                        }}>{parseFloat(booking.metterDistance).toFixed(2)}</Text>
                                                                    </View>
                                                                    <View style={{width: '39%', justifyContent: "flex-start", alignItems: "center", borderRightWidth: 1, borderRightColor: colors.WHITE}}>
                                                                        <View style={{
                                                                            flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center'
                                                                        }}>
                                                                            <Text style={{color: colors.WHITE, fontSize: 14, marginBottom: 10, textAlign: 'left', marginLeft: 10}}>{"Total"}</Text>
                                                                            <Text style={{color: colors.WHITE, fontSize: 14, marginBottom: 10, textAlign: 'left', marginRight: 10}}>{formatPrice(booking.estimate)}</Text>
                                                                        </View>

                                                                        <Text style={{
                                                                            color: colors.WHITE,
                                                                            fontSize: 20
                                                                        }}>{formatPrice(booking.metterPrice)}</Text>
                                                                    </View>
                                                                    <View style={{width: '33%', justifyContent: "flex-start", alignItems: "center"}}>
                                                                        <Text style={{color: colors.WHITE, fontSize: 13, marginBottom: 10, width: '100%', textAlign: 'left', marginLeft: 10}}>{"Waiting times"}</Text>
                                                                        <Text style={{color: colors.WHITE, fontSize: 20}}>{ConvertSecondToHms(booking.total_wait_time)}</Text>
                                                                    </View>
                                                                </View>
                                                            </View>
                                                        </View>
                                                    )
                                                }

                                                if (booking.status === "PAID" || booking.status === "COMPLETE") {
                                                    return (
                                                        <View style={{flexDirection: 'column', marginTop: 20, borderWidth: 1, borderColor: 'black', borderRadius: 10, width: '100%', justifyContent: 'flex-start', alignItems: 'center'}}>
                                                            <View style={{flexDirection: 'row', width: '90%', justifyContent: 'space-between', alignItems: 'center'}}>
                                                                <View>
                                                                    <Text style={{fontSize: 14, marginRight: 10}}>{ConvertTimeStampTimeForPaShort(booking.bookingDate)}</Text>
                                                                </View>

                                                                <View style={{
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    backgroundColor: 'rgba(218,218,218,0.96)',
                                                                    padding: 5, borderRadius: 5,
                                                                    minHeight: 50,
                                                                    width: '70%'
                                                                }}>
                                                                    {/* <Text style={{fontSize: 20, marginRight: 10}}>{booking.carType}</Text>*/}
                                                                    <View style={{
                                                                        marginRight: 100
                                                                    }}>
                                                                        <Text style={{fontSize: 16, color: colors.BLACK, position: 'absolute', top: -5, left: 20}}>{callDetail && callDetail.car ? callDetail.car.seat : '4'}</Text>
                                                                        <AccountCircle fontSize={'medium'}/>
                                                                    </View>
                                                                    <View style={{
                                                                        flexDirection: 'row',
                                                                        alignItems: 'center',
                                                                    }}>
                                                                        <LocalOfferIcon fontSize={'medium'}/>
                                                                        <Text style={{marginLeft: 10}}>{"50%"}</Text>
                                                                    </View>
                                                                </View>
                                                            </View>

                                                            <View style={{flexDirection: 'row', marginTop: 20, width: '90%', justifyContent: 'space-between', alignItems: 'center'}}>
                                                                <View style={{width: '85%'}}>
                                                                    <View style={{flexDirection: 'row', alignItems: 'center', paddingLeft: 13}}>
                                                                        <img src={require("../assets/img/ico_start_13x.png").default} alt="Điểm đón" style={{width: 20, height: 20}}/>
                                                                        <Text style={{marginLeft: 10}}>{booking.pickupAddress ? formatDisplayAddressSearch(booking.pickupAddress) : ''}</Text>
                                                                    </View>
                                                                    {booking.dropAddress ?
                                                                        <View style={{flexDirection: 'row', alignItems: 'center', paddingLeft: 13, marginTop: 10}}>
                                                                            <img src={require("../assets/img/ico_stop_23x.png").default} alt="Điểm đến" style={{width: 20, height: 20}}/>
                                                                            <Text style={{marginLeft: 10}}>{formatDisplayAddressSearch(booking.dropAddress)}</Text>
                                                                        </View> : null}
                                                                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                                                        <IconButton>
                                                                            <EditIcon size={'medium'}/>
                                                                        </IconButton>
                                                                        <Text style={{fontSize: 13}}>{booking.customer_note ? booking.customer_note : ''}</Text>
                                                                    </View>
                                                                </View>
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setBookingDetail(booking);
                                                                        let source = callDetail.source;
                                                                        var callObj = {};
                                                                        if (callDetailData && callDetailData[source]) {
                                                                            callObj = callDetailData[source];
                                                                        }
                                                                        showModalReachedCallback(callDetail, callObj);
                                                                    }
                                                                    }
                                                                >
                                                                    <ArrowForwardIosIcon size={'large'}/>
                                                                </IconButton>
                                                            </View>

                                                            <View style={{flexDirection: 'column', width: '90%', justifyContent: 'flex-start', alignItems: 'center', padding: 10}}>
                                                                {/* <WaitingCustomerComponent/>*/}
                                                                <View style={{
                                                                    backgroundColor: colors.BLACK,
                                                                    flexDirection: 'row',
                                                                    width: '100%',
                                                                    justifyContent: 'space-between',
                                                                    borderRadius: 5,
                                                                    alignItems: 'center',
                                                                    padding: 10
                                                                }}>
                                                                    <View style={{
                                                                        flexDirection: 'column',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center'

                                                                    }}>
                                                                        <Text style={{color: colors.WHITE, fontSize: 16}}>{"Tổng thanh toán"}</Text>
                                                                    </View>
                                                                    <View style={{
                                                                        flexDirection: 'column',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center'
                                                                    }}>
                                                                        <Text style={{color: colors.WHITE, fontSize: 18}}>{booking.estimate ? formatPrice(booking.estimate) : 0}</Text>
                                                                    </View>
                                                                </View>
                                                            </View>
                                                        </View>
                                                    )
                                                }
                                            }
                                            }
                                            enableEmptySections={true}
                                            keyExtractor={(item, index) => index.toString()}
                                            key={"listbooking"}
                                        />
                                        : null}

                                </View>
                            </Grid> :
                            <Grid xs={3} style={{marginTop: 80}}>
                                <View>
                                    <Text>{"Vui lòng chọn cuốc khách để thao tác"}</Text>
                                </View>
                            </Grid>
                        }
                    </Grid>
                </Box>
                {
                    popupData && showModal ? (
                        <ButtonBookingDetailDialog
                            showModal={showModal}
                            hideModal={hideModalCallback}
                            callDetail={popupData}
                            closeCallback={closeCallback}
                        />
                    ) : null
                }

                {
                    popupData && showReachedModal && bookingDetail ? (
                        <ButtonBookingDetailReachedDialog
                            showModal={showReachedModal}
                            hideModal={hideModalCallback}
                            callDetail={popupData}
                            closeCallback={closeCallback}
                            bookingDetail={bookingDetail}
                            showChat={chatDriver}
                            callDriver={callDriver}
                        />
                    ) : null
                }

                {/*{popupData && !popupData.booking_id ? (
                <ButtonCallCenterDetailDialog
                    notes={Object.values(notemapaddressdata).filter(n => n.coords && n.id)}
                    showModal={showModal}
                    hideModal={hideModalCallback}
                    callDetail={popupData}
                    callObj={callObj}
                    listGroup={listGroup}
                    cartypes={cartypes}
                />
            ) : null}*/
                }
                {
                    <Modal
                        animationType="slide"
                        transparent={true}
                        visible={modalVisible}
                        onPress={() => {
                            setModalVisible(false);
                            setPopupData(null);
                        }}
                    >
                        <View
                            style={{
                                flex: 1,
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: 22,
                            }}
                        >
                            <View
                                style={{
                                    margin: 20,
                                    backgroundColor: "white",
                                    borderRadius: 10,
                                    borderWidth: 1,
                                    padding: 10,
                                    alignItems: "center",
                                    shadowColor: "#000",
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                    shadowOpacity: 0.25,
                                    shadowRadius: 4,
                                    elevation: 5,
                                }}
                            >
                                {/*{popupData &&
                            showCancel === false &&
                            !popupData.codeDriver && notemapaddressdata &&
                            showThemXe === false ? (
                                <BookingCallCenter
                                    {...props}
                                    time={ConvertTimeStampToDate(popupData.time * 1000)}
                                    callDetail={popupData}
                                    notes={Object.values(notemapaddressdata).filter(n => n.coords && n.id)}
                                    listGroup={listGroup}
                                    listLyDo={listLyDo}
                                    cartypes={cartypes}
                                    closeCallback={closeCallback}
                                />
                            ) : null}*/}

                                {addBookingDialog ? (
                                    <AddCallCenter {...props} closeCallback={closeCallback}/>
                                ) : null}

                                {popupData && showThemXe === true ? (
                                    <ThemXeCallCenter
                                        {...props}
                                        callDetail={popupData}
                                        closeCallback={closeCallback}
                                    />
                                ) : null}

                                {/*{popupData &&
                            showCancel === false &&
                            popupData.codeDriver && notemapaddressdata &&
                            showThemXe === false ? (
                                <BookingCallCenter
                                    {...props}
                                    time={ConvertTimeStampToDate(popupData.time * 1000)}
                                    notes={Object.values(notemapaddressdata).filter(n => n.coords && n.id)}
                                    callDetail={popupData}
                                    listGroup={listGroup}
                                    listLyDo={listLyDo}
                                    cartypes={cartypes}
                                    closeCallback={closeCallback}
                                    driverCode={popupData.codeDriver.trim()}
                                />
                            ) : null}*/}
                                {popupData && showCancel === true && showThemXe === false ? (
                                    <CancelCallCenter
                                        destination={popupData.destination}
                                        time={ConvertTimeStampToDate(popupData.time * 1000)}
                                        phone={popupData.source}
                                        uniqueid={popupData.uniqueid}
                                        listLyDo={listLyDo}
                                        firebase_id={popupData.firebase_id}
                                        closeCallback={closeCallback}
                                    />
                                ) : null}
                                {popupData === null && showCallNow && showThemXe === false ? (
                                    <CallNowCenter
                                        lineOptions={lineOptions}
                                        time={showCallNow.time}
                                        source={showCallNow.source}
                                        time_call={showCallNow.time_call ? showCallNow.time_call : []}
                                        phone={showCallNow.phone}
                                        uniqueid={showCallNow.uniqueid}
                                        listAudio={showCallNow.audios ? showCallNow.audios : []}
                                        firebase_id={showCallNow.firebase_id}
                                        closeCallback={closeCallback}
                                    />
                                ) : null}

                                {showChatModal && driverCode ? (
                                    <ShowNotifyDialog
                                        showModal={showChatModal}
                                        bookingDate={bookingDate}
                                        hideModal={hideChatModalCallback}
                                        isChatOnce={true}
                                        driverCode={driverCode}
                                        noteBooking={noteBooking}
                                    />
                                ) : null}

                            </View>
                        </View>
                    </Modal>
                }

            </ThemeProvider>
        )
            ;
    }
;

export default CallCenter;
