import React, {useContext, useEffect, useState} from 'react';
import {FirebaseContext} from "common";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    inputDark: {
        color: "#FFFFFF"
    },

}));

export default function ListCarComponent(props) {
    const {refApi} = useContext(FirebaseContext);
    const [car, setCar] = useState(null);

    useEffect(() => {
        if (car) {
        } else {
            if (props.rowData && props.rowData.car) {
                setCar(props.rowData.car);
            }
        }
    }, [props.rowData]);

    return (
        <Autocomplete
            onChange={(event, value) => {
                if (value && value.name) {
                    setCar(value);
                    refApi.updateCallCenterApi(props.rowData.firebase_id, {
                        car: value
                    });
                }
            }
            }
            /*disabled={props.rowData && props.rowData.status != 'pending'}*/
            id={"car_autocompleted" + props.rowData.uniqueid}
            options={props.arrayOptions}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option) => option.name}
            defaultValue={props.arrayOptions[0]}
            value={car}
            renderOption={(option) => (
                <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props} >
                    {option.name}
                </Box>
            )}
            renderInput={(params) =>
                <TextField
                    {...params}
                    id={"input-with-icon-textfield" + props.rowData.uniqueid}
                    InputProps={{
                        ...params.InputProps,
                        underlineColor: "transparent",
                        value: car ? car.name : props.arrayOptions[0].name,
                        style: {padding: 0, margin: 0, borderBottomWidth: 0}
                    }}
                />
            }
        />
    )
}
