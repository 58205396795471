import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Button from '@material-ui/core/Button';
import {api, FirebaseContext} from "common";
import Grid from '@material-ui/core/Grid';
import {makeStyles} from "@material-ui/core/styles";
import {Checkbox, TextField} from "@material-ui/core";
import {ConvertSecondToHm, ConvertTimeStampTimeForPaShort, GetCurrentMiniTimeStamp} from "common/src/other/DateFunctions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import {colors} from "../../config/theme";
import {ScrollView, Text, View} from "react-native-web";
import {Google_Map_Key} from "config";
import Map from "../Map";
import {formatNumber} from "react-native-currency-input";
import {Dimensions} from "react-native";
import {AccountCircle} from "@material-ui/icons";
import {formatDisplayAddressSearch, generateNoteKey, HiddenPhone} from "common/src/other/CommonFunctions";
import {alpha, styled} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import InputBase from "@mui/material/InputBase";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from "@mui/icons-material/Edit";
import FormControlLabel from "@mui/material/FormControlLabel";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import InputAdornment from "@material-ui/core/InputAdornment";
import {GetDistance} from "common/src/other/GeoFunctions";
import {fetchAddressfromCoords} from "common/src/other/GoogleAPIFunctions";

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'inherit', '& .MuiInputBase-input': {
        padding: 5, // vertical padding + font size from searchIcon
        paddingLeft: 10, transition: theme.transitions.create('width'), backgroundColor: '#b0b0b0', width: '100%', [theme.breakpoints.up('md')]: {
            width: '100%',
        },
    },
}));

const useStyles = makeStyles(theme => ({
    icon: {
        color: theme.palette.text.secondary, marginRight: theme.spacing(2),
    }, fullHeight: {
        height: '100%', width: "100%"
    }, inputColor: {
        color: colors.LINK
    }, titleList: {
        fontSize: 14, marginTop: 10
    }, submit: {
        backgroundColor: colors.MAIN, margin: theme.spacing(2, 0, 2), height: 39,
    }, backgroundRed: {
        backgroundColor: "rgba(255,179,179,0.74)", flexDirection: 'column'
    }, row: {
        borderBottomWidth: 1, borderBottomStyle: "solid", borderBottomColor: "rgba(255,0,0,0.74)"
    }, inputdimmed: {
        fontSize: 18, color: "#737373"
    }, carphoto: {
        height: '18px', marginRight: '10px'
    }, input: {
        borderRadius: 4, position: 'relative', backgroundColor: "#E8E8E8", fontSize: 16, padding: '10px 2px 10px 10px', color: "#000"
    }, commonInputStyle: {
        borderRadius: 4, backgroundColor: "#E8E8E8",
    }, dateTimePickerCss: {
        width: 150, borderWidth: 0, zIndex: 100, height: 40
    }, clockPickerCss: {
        zIndex: 100
    }, calendarPickerCss: {
        zIndex: 100, position: 'absolute', left: -140
    }

}));
var {height, width} = Dimensions.get('window');

export default function ButtonBookingDetailDialog(props) {
    const {refApi} = useContext(FirebaseContext);
    const dispatch = useDispatch();
    const classes = useStyles();
    const settings = useSelector(state => state.settingsdata.settings);
    const editPickup = useSelector(state => state.tripdata.isEditPickup);
    const bookingdata = useSelector((state) => state.bookingdata.bookingDetail);
    const [mylocation, setMylocation] = useState(null);
    const cars = useSelector(state => state.cartypes.cars);
    const [car, setCar] = useState(null);
    const [note, setNote] = useState(null);
    const [editNote, setEditNote] = useState(false);
    const [pickupAdd, setPickupAdd] = useState(null);
    const tripdata = useSelector(state => state.tripdata);
    const groupkddata = useSelector(state => state.groupdriverdata.group_kd);
    const [hasPin, setHasPin] = useState(false);
    const auth = useSelector((state) => state.auth.user);
    const [objectBooking, setObjectBooking] = useState(null);
    const [driverInGroup, setDriverInGroup] = useState(null);
    const [driverOutGroup, setDriverOutGroup] = useState(null);
    const driverOutInGroup = useSelector(state => state.usersdata.driverOutInGroup);
    const [driverInArea, setDriverInArea] = useState(null);
    const groups = useSelector(state => state.groupdriverdata.group_kd);
    const [polygons, setPolygons] = useState(null);

    useEffect(() => {
        if (driverOutInGroup) {
            if (driverOutInGroup.driverInGroup) {
                setDriverInGroup(driverOutInGroup.driverInGroup);
            }
            if (driverOutInGroup.driverOutGroup) {
                setDriverOutGroup(driverOutInGroup.driverOutGroup);
            }
            if (driverOutInGroup.driverInArea) {
                setDriverInArea(driverOutInGroup.driverInArea);
            }
        }
    }, [driverOutInGroup]);

    useEffect(() => {
        if (props.callDetail && props.callDetail.booking_id) {
            dispatch(api.bookingDetail(props.callDetail.booking_id));
        }
        if (!note) {
            if (props.callDetail && props.callDetail.note) {
                setNote(props.callDetail.note)
            }
        }

        if (tripdata && tripdata.groupApply) {
        } else {
            let group = groupkddata.filter(g => g.value == 4)[0];
            if (group) {
                dispatch(api.updateTripGroup(group));
                if (tripdata && tripdata.price) {
                } else {
                    dispatch(api.updateTripPrice(group.prices['taxi']));
                }
            }
        }

        if (!pickupAdd) {
            if (props.callDetail && props.callDetail.pickup) {
                if (tripdata && tripdata.pickup) {
                } else {
                    if (props.callDetail.pickup.add) {
                        setPickupAdd(props.callDetail.pickup.add);
                    }
                    let latlng = props.callDetail.pickup.lat + ',' + props.callDetail.pickup.lng;
                    let add = fetchAddressfromCoords(latlng).then(res => {
                        if (res) {
                            dispatch(api.updateTripPickup({
                                lat: props.callDetail.pickup.lat,
                                lng: props.callDetail.pickup.lng,
                                add: res,
                                source: 'search'
                            }));
                        } else {
                            dispatch(api.updateTripPickup({
                                lat: props.callDetail.pickup.lat,
                                lng: props.callDetail.pickup.lng,
                                add: "Không xác định",
                                source: 'search'
                            }));
                        }
                    });

                }
            }
        }
        if (props.callDetail && props.callDetail.car) {
            if (tripdata && tripdata.carType) {
            } else {
                setCar(props.callDetail.car);
                dispatch(api.updateTripCar(props.callDetail.car));
            }
        } else {
            if (tripdata && tripdata.carType) {
            } else {
                let carC = cars.filter(c => c.code === 'taxi')[0];
                setCar(carC);
                dispatch(api.updateTripCar(carC))
            }
        }
        if (tripdata && tripdata.is_two_way) {
        } else {
            dispatch(api.updateTwoWay(false));
        }
    }, [props.callDetail]);

    useEffect(() => {
        if (tripdata.pickup) {
            if (tripdata.pickup.add) {
            } else {
                let latlng = tripdata.pickup.lat + ',' + tripdata.pickup.pickup.lng;
                let add = fetchAddressfromCoords(latlng).then(res => {
                    if (res) {
                        dispatch(api.updateTripPickup({
                            lat: tripdata.pickup.lat,
                            lng: tripdata.pickup.lng,
                            add: res,
                            source: 'search'
                        }));
                    } else {
                        dispatch(api.updateTripPickup({
                            lat: tripdata.pickup.lat,
                            lng: tripdata.pickup.lng,
                            add: "Không xác định",
                            source: 'search'
                        }));
                    }
                });
            }

            let bankinhCheck = settings && settings.bankinh ? settings.bankinh : 3;
            var kmcheck = 0;
            var groupApplyCheck = null;

            groups.map(group => {
                if (group.coord && group.lock_status === false) {
                    let coords = group.group_coords;
                    let toado_tam = group.coord.split(",");
                    if (toado_tam.length > 0) {
                        let kc_tam = GetDistance(tripdata.pickup.lat, tripdata.pickup.lng, parseFloat(toado_tam[0].toString().trim()), parseFloat(toado_tam[1].toString().trim()));
                        if (kc_tam < 10) {
                            if (coords) {
                                coords.map(coord => {
                                    if (coord.coord && coord.coord.split(",").length > 0) {
                                        let toado_con = coord.coord.split(",");
                                        let kc_con = GetDistance(tripdata.pickup.lat, tripdata.pickup.lng, parseFloat(toado_con[0].toString().trim()), parseFloat(toado_con[1].toString().trim()));
                                        let bkc = coord.radius / 1000;
                                        if (kmcheck === 0) {
                                            kmcheck = kc_con;
                                            if (parseFloat(bkc) + parseFloat(bankinhCheck) >= parseFloat(kmcheck)) {
                                                groupApplyCheck = group;
                                            }
                                        } else {
                                            if (kc_con <= kmcheck) {
                                                kmcheck = kc_con;
                                                if (parseFloat(bkc) + parseFloat(bankinhCheck) >= parseFloat(kmcheck)) {
                                                    groupApplyCheck = group;
                                                }
                                            }
                                        }
                                    }
                                });
                            }
                        }
                    }
                }
            });
            if (groupApplyCheck) {
                if (groupApplyCheck.area_ids && groupApplyCheck.areas) {
                    let listAreaApply = groupApplyCheck.area_ids;
                    let listAreas = groupApplyCheck.areas;
                    let polygonList = [];
                    if (listAreas && listAreas.length > 0) {
                        for (let i = 0; i < listAreas.length; i++) {
                            if (listAreas[i]) {
                                let coors = Object.values(listAreas[i]);
                                for (let j = 0; j < coors.length; j++) {
                                    if (coors[j][0] && coors[j][1]) {
                                        polygonList.push({
                                            lat: coors[j][1], lng: coors[j][0]
                                        });
                                    }
                                }
                            }
                        }
                        setPolygons(polygonList);
                    }
                }
                console.log("groupApplyCheck.value",groupApplyCheck.value);
                dispatch(api.updateTripGroup(groupApplyCheck));
                dispatch(api.fetchDriversOutInGroup(settings.bankinh ? settings.bankinh : 3, tripdata.carType.code, tripdata.pickup, groupApplyCheck.value));
            } else {
                dispatch(api.updateTripGroup(null));
                dispatch(api.fetchDriversOutInGroup(settings.bankinh ? settings.bankinh : 3, tripdata.carType.code, tripdata.pickup));
            }
        }
    }, [tripdata.pickup]);

    useEffect(() => {
        if (mylocation == null) {
            if(tripdata && tripdata.pickup){
                setMylocation({
                    lat: tripdata.pickup.lat, lng: tripdata.pickup.lng
                });
            }else{
                navigator.geolocation.getCurrentPosition(position => setMylocation({
                    lat: position.coords.latitude, lng: position.coords.longitude
                }), err => console.log(err));
            }
        }
    }, [mylocation]);

    const formatPrice = (value) => {
        return formatNumber(value, {
            separator: ',', precision: 0, delimiter: '.', ignoreNegative: false,
        }) + ' ' + settings.symbol;
    }

    useEffect(() => {
        if (objectBooking) {
            if (driverInGroup) {
                objectBooking.driverInGroup = driverInGroup;
            }
            if (driverOutGroup) {
                objectBooking.driverOutGroup = driverOutGroup;
            }
            if (driverInArea) {
                objectBooking.driverInArea = driverInArea;
            }
        }
    }, [driverInGroup, driverOutGroup, objectBooking]);

    const loadBookingObj = (bookingNow) => {
        if (tripdata && tripdata.groupApply) {
        } else {
            alert("Chọn lại điểm kinh doanh");
            return
        }
        var bookingObject = {
            pickup: null,
            carDetails: null,
            userDetails: {
                uid: auth ? auth.uid : null,
                profile: {
                    firstName: "Trung Tâm",
                    lastName: "",
                    mobile: props.callDetail && props.callDetail.source ? props.callDetail.source : settings.panic,
                    note: note ? note : 'Hãy gọi cho tôi',
                    pushToken: auth ? auth.pushToken : '',
                }
            },
            groupDetails: null,
            settings: settings,
            tripdate: GetCurrentMiniTimeStamp(),
            customer_note: note,
            /* bookLater: bookingType == 'Book Later' && selectedDate ? true : false,
             bookingDate: bookingType == 'Book Later' && selectedDate ? Date.parse(selectedDate) : null,*/
            bookLater: false,
            bookingDate: null,
            booking_type_web: true,
            source: "TT",
            type: "TT",
            callId: props.callDetail && props.callDetail.firebase_id ? props.callDetail.firebase_id : null
        };

        if (tripdata.groupApply) {
            bookingObject.groupDetails = tripdata.groupApply;
            bookingObject.areaId = null;
            bookingObject.carDetails = tripdata && tripdata.carType ? tripdata.carType : null;
            bookingObject.carTypeCode = tripdata && tripdata.carType ? tripdata.carType.code : null;
            bookingObject.prices = tripdata && tripdata.price ? tripdata.price : null;

        } else {
            // Ko có bảng giá - Vùng Tự Do
            alert("Lỗi dữ liệu đầu vào! Vui lòng liên hệ Admin!");
        }

        if (tripdata.pickup) {
            bookingObject.pickup = tripdata.pickup;
        }

        if (tripdata.drop) {
            bookingObject.drop = tripdata.drop;
        }

        if (driverOutGroup) {
            bookingObject.driverOutGroup = driverOutGroup;
        }

        if (driverInArea) {
            bookingObject.driverInArea = driverInArea;
        }

        if (driverInGroup) {
            bookingObject.driverInGroup = driverInGroup;
        }
        if (tripdata && tripdata.trip_estimate) {
                bookingObject.tripEstimate = tripdata.trip_estimate;
            bookingObject.estimateTemp = tripdata.trip_estimate;
        }

        if (tripdata && tripdata.groupApply) {
            dispatch(api.fetchDriversOutInGroup(settings.bankinh ? settings.bankinh : 3, tripdata.carType.code, bookingObject.pickup, bookingObject.groupDetails.value));
        } else {
            dispatch(api.fetchDriversOutInGroup(settings.bankinh ? settings.bankinh : 3, tripdata.carType.code, bookingObject.pickup));
        }
        if (tripdata && tripdata.is_two_way) {
            bookingObject.is_two_way = tripdata.is_two_way;
        } else {
            bookingObject.is_two_way = false;
        }

        //setButtonDisable(true);
        if (bookingObject.driverInArea || bookingObject.driverInGroup) {
            setTimeout(() => {
                dispatch(api.addBookingV2(bookingObject));
                props.closeCallback();
            }, 1000)
        } else {
            alert("Không tìm thấy tài xế phù hợp");
        }
    }

    const closeCallback = useCallback(() => {
        props.hideModal();
    }, [],);

    return (<Dialog
        open={props.showModal}
        onClose={closeCallback}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={false}
        scroll={'body'}
    >
        <DialogTitle id="alert-dialog-title">
            <IconButton>
                <ArrowBackIcon/>
            </IconButton>
            <Text>{props.callDetail && props.callDetail.time ? ConvertTimeStampTimeForPaShort(props.callDetail.time * 1000) : ''}</Text>
            <Text style={{marginLeft: 20, fontSize: 20}}>{props.callDetail && props.callDetail.source ? HiddenPhone(props.callDetail.source) : ''}</Text>
            <Text style={{marginLeft: 20, fontSize: 20}}>{"["}{props.callDetail && props.callDetail.status ? props.callDetail.status : ''}{"]"}</Text>
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <Grid container spacing={1} style={{height: 650, width: width - 100}}>
                    <View style={{
                        width: '100%', height: '100%', flexDirection: 'row', flexWrap: "wrap", justifyContent: "space-between"
                    }}>
                        <View style={{width: '35%', flexDirection: 'column', justifyContent: 'flex-start'}}>
                            <View style={{
                                flexDirection: 'row', width: '99%', justifyContent: 'space-between', alignItems: 'center', borderWidth: 1, borderRadius: 5, paddingBottom: 10, paddingRight: 10
                            }}>
                                <View style={{width: '85%'}}>
                                    <View style={{flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                            <TextField
                                                id="input-with-icon-textfield-note"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <IconButton onClick={() => {
                                                                setEditNote(!editNote);
                                                            }
                                                            }>
                                                                <EditIcon size={'medium'}/>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    readOnly: !editNote,
                                                    disableUnderline: !editNote
                                                }}
                                                value={note ? note : ''}
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    setNote(e.target.value);
                                                }
                                                }
                                                style={{marginLeft: 10}}
                                                variant="standard"
                                            />
                                        </View>
                                        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: "flex-end"}}>
                                            <FormControlLabel control={<Checkbox onChange={(event) => {
                                                let checked = event.target.checked;
                                                setHasPin(checked);
                                            }
                                            }/>} label={<span style={{fontSize: 13}}>{"Ghim vị trí cho lần sau"}</span>}/>
                                        </View>
                                    </View>
                                    <View style={{flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between', paddingLeft: 13}}>
                                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                            <img src={require("../../assets/img/ico_start_13x.png")} alt="Điểm đón" style={{width: 20, height: 20}}/>
                                            <Text style={{marginLeft: 10, maxWidth: '90%'}}>{tripdata && tripdata.pickup ? formatDisplayAddressSearch(tripdata.pickup.add) : pickupAdd ? formatDisplayAddressSearch(pickupAdd) : ''}</Text>
                                            <Text style={{marginLeft: 10}}>{props.callDetail && props.callDetail.time ? ConvertTimeStampTimeForPaShort(props.callDetail.time * 1000).split(' ')[0] : ''}</Text>
                                        </View>

                                        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: "flex-end"}}>
                                            <FormControlLabel control={<Checkbox checked={editPickup} onChange={(event) => {
                                                let checked = event.target.checked;
                                                let value = event.target.value;
                                                dispatch(api.updateTripEditPickup(checked));
                                                dispatch(api.updatSelPointType('pickup'));
                                                if(tripdata.pickup){
                                                    setMylocation({
                                                        lat: tripdata.pickup.lat,
                                                        lng: tripdata.pickup.lng
                                                    })
                                                }
                                            }
                                            }/>} label={<span style={{fontSize: 13}}>{"Sửa"}</span>}/>
                                            <FormControlLabel control={<Checkbox/>} label={<span style={{fontSize: 13}}>{"Vị trí tài xế"}</span>}/>
                                        </View>
                                    </View>
                                </View>
                                <Button
                                    disabled={!hasPin}
                                    onClick={() => {
                                        if (tripdata && tripdata.pickup && props.callDetail && props.callDetail.note) {
                                            let newData = {
                                                coords: tripdata.pickup.lat + " " + tripdata.pickup.lng,
                                                note: note,
                                                add: tripdata.pickup.add,
                                                id: generateNoteKey(props.callDetail.note)
                                            }
                                            if (props.callDetail.id) {
                                                refApi.updateCallCenterApi(props.callDetail.id, {
                                                    note: note,
                                                    pickup: {
                                                        add: tripdata.pickup.add,
                                                        lat: tripdata.pickup.lat,
                                                        lng: tripdata.pickup.lng
                                                    }
                                                });
                                            }
                                            dispatch(api.editNoteMapAddress(newData, "Update"));
                                            setHasPin(false);
                                            alert("Lưu điểm thành công")
                                        }

                                    }} style={{backgroundColor: !hasPin ? colors.GREY.background : 'black', color: 'white', marginLeft: 10}}>
                                    <Text style={{fontSize: 13, color: '#ffffff', textAlign: 'center'}}>{"Cập nhật"}</Text>
                                </Button>
                            </View>

                            <SwapVertIcon style={{marginTop: 10, marginLeft: 10}}/>

                            <View style={{flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between', paddingLeft: 13, paddingRight: 10}}>
                                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                    <img src={require("../../assets/img/ico_stop_23x.png")} alt="Điểm đến" style={{width: 20, height: 20}}/>
                                    <Text style={{marginLeft: 10}}>{tripdata && tripdata.drop ? formatDisplayAddressSearch(tripdata.drop.add) : 'Chưa có điểm đến (Không bắt buộc) 1'}</Text>
                                    {tripdata && tripdata.drop ?
                                        <IconButton>
                                            <CancelIcon onClick={() => {
                                                dispatch(api.updateTripDrop(null))
                                            }
                                            }/>
                                        </IconButton> : null}
                                </View>

                                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: "flex-end"}}>
                                    <FormControlLabel control={<Checkbox onChange={(event) => {
                                        let checked = event.target.checked;
                                        let value = event.target.value;
                                        dispatch(api.updateTripEditDrop(checked));
                                        dispatch(api.updatSelPointType('drop'));
                                        if(tripdata.drop){
                                            setMylocation({
                                                lat: tripdata.drop.lat,
                                                lng: tripdata.pickup.lng
                                            })
                                        }
                                    }
                                    }/>} label={<span style={{fontSize: 13}}>{"Sửa"}</span>}/>
                                    <FormControlLabel control={<Checkbox/>} label={<span style={{fontSize: 13}}>{"Vị trí tài xế"}</span>}/>
                                </View>

                                <Button onClick={() => {
                                }} style={{backgroundColor: 'black', color: 'white', marginLeft: 10}}>
                                    <Text style={{fontSize: 13, color: '#ffffff', textAlign: 'center'}}>{"Cập nhật"}</Text>
                                </Button>
                            </View>

                            <View style={{
                                width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'row'
                            }}>
                                <Button onClick={() => {
                                    loadBookingObj(true);
                                }} style={{backgroundColor: 'black', color: 'white', marginLeft: 10, padding: 10, flexDirection: 'row'}}>
                                    <AccountCircle/><Text style={{position: 'absolute', fontSize: 13, color: '#ffffff', left: 30, top: 0}}>{props.callDetail && props.callDetail.car ? props.callDetail.car.seat : ''}</Text>
                                    <Text style={{fontSize: 13, color: '#ffffff', textAlign: 'center', marginLeft: 5}}>{"Đặt ngay"}</Text>
                                </Button>
                            </View>
                            <View style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%'}}>
                                <View style={{
                                    flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%'
                                }}>
                                    <View style={{
                                        flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', marginTop: 10
                                    }}>
                                        {tripdata && tripdata.drop ?
                                            <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
                                                <Checkbox
                                                    checked={tripdata && tripdata.is_two_way ? !tripdata.is_two_way : true}
                                                    color={"primary"}
                                                    onChange={(event) => {
                                                        let checked = event.target.checked;
                                                        dispatch(api.updateTwoWay(false));
                                                    }}
                                                    title={"Một chiều"}
                                                    name={'chk_1chieu'}
                                                    key={'chk_1chieu'}
                                                    inputProps={{'aria-label': 'controlled'}}
                                                /><Text>{"Một chiều"}</Text>

                                                <Checkbox
                                                    checked={tripdata && tripdata.is_two_way ? tripdata.is_two_way : false}
                                                    color={"primary"}
                                                    disabled={tripdata && tripdata.trip_estimate && tripdata.trip_estimate[2] && tripdata.trip_estimate[2] >= 20 ? false : true}
                                                    name={'chk_2chieu'}
                                                    key={'chk_2chieu'}
                                                    onChange={(event) => {
                                                        let checked = event.target.checked;
                                                        dispatch(api.updateTwoWay(true));
                                                    }}
                                                    title={"Hai chiều quay đầu"}
                                                    inputProps={{'aria-label': 'controlled'}}
                                                /><Text>{"Hai chiều quay đầu"}</Text>
                                            </View> : null}

                                        {tripdata && tripdata.drop ?
                                            <View style={{
                                                alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start', width: '98%',
                                                borderWidth: 1, paddingLeft: 10, paddingTop: 10, paddingBottom: 10, borderRadius: 10
                                            }}>
                                                <View style={{
                                                    alignItems: 'center', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', width: '100%', paddingRight: 10, paddingBottom: 10
                                                }}>
                                                    <Text>{"Giá mở cửa"}</Text>
                                                    <Text>{}</Text>
                                                    <Text>{tripdata && tripdata.price ? formatPrice(tripdata.price.open) : ''}</Text>
                                                </View>
                                                <View style={{
                                                    alignItems: 'center', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', width: '100%', paddingRight: 10, paddingBottom: 10
                                                }}>
                                                    <Text>{"Tổng quảng đường"}</Text>
                                                    {tripdata && tripdata.is_two_way != true ?
                                                        <>
                                                            <Text>{tripdata && tripdata.trip_estimate && tripdata.trip_estimate[0] ? parseFloat(tripdata.trip_estimate[0].distance).toFixed(2) : ''}{'(km)'}</Text>
                                                            <Text>{tripdata && tripdata.trip_estimate && tripdata.trip_estimate[0] ? formatPrice(tripdata.trip_estimate[0].totalCost) : 0}</Text>
                                                        </>
                                                        :
                                                        <>
                                                            <Text>{tripdata && tripdata.trip_estimate && tripdata.trip_estimate[1] ? parseFloat(tripdata.trip_estimate[1].distance).toFixed(2) : ''}{'(km)'}</Text>
                                                            <Text>{tripdata && tripdata.trip_estimate && tripdata.trip_estimate[1] ? formatPrice(tripdata.trip_estimate[1].totalCost) : 0}</Text>
                                                        </>}
                                                </View>

                                                {tripdata && tripdata.is_two_way == true && tripdata.trip_estimate && tripdata.trip_estimate[1] && tripdata.trip_estimate[1].timeLimit ?
                                                    <View style={{
                                                        alignItems: 'center', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', width: '100%', paddingRight: 10, paddingBottom: 10
                                                    }}>
                                                        <Text>{"Giới hạn tổng thời gian"}</Text>
                                                        <Text>{ConvertSecondToHm(tripdata.trip_estimate[1].timeLimit)}</Text>
                                                        <Text>{formatPrice(0)}</Text>
                                                    </View> : null}

                                                <View style={{
                                                    alignItems: 'flex-end', justifyContent: 'flex-end', width: '100%', paddingRight: 10
                                                }}>
                                                    {tripdata && tripdata.is_two_way == true ?
                                                        <Text style={{fontSize: 16, padding: 10, backgroundColor: 'rgba(180,180,180,0.71)'}}>{tripdata && tripdata.trip_estimate ? formatPrice(tripdata.trip_estimate[1].totalCost) : ''}</Text> :
                                                        <Text style={{fontSize: 16, padding: 10, backgroundColor: 'rgba(180,180,180,0.71)'}}>{tripdata && tripdata.trip_estimate ? formatPrice(tripdata.trip_estimate[0].totalCost) : ''}</Text>
                                                    }
                                                </View>
                                            </View> : null}
                                        {tripdata && tripdata.drop ?
                                            <View>
                                                <Text style={{fontSize: 14, marginTop: 10, marginBottom: 10}}>{"Phí phụ trội, cầu đường bến bãi ( nếu có ) "}</Text>
                                            </View> : null}
                                        {tripdata && tripdata.drop ?
                                            <ScrollView
                                                style={{
                                                    flexDirection: 'column', width: '98%', borderWidth: 1, paddingLeft: 10, paddingTop: 10, paddingBottom: 10, borderRadius: 10
                                                }}>
                                                <View style={{
                                                    alignItems: 'center', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', width: '100%', paddingRight: 10, paddingBottom: 10
                                                }}>
                                                    <Text>{"Vượt thời gian"}</Text>
                                                    <Text>{"45 (phút)"}</Text>
                                                    <Text>{formatPrice(0)}</Text>
                                                </View>
                                                <View style={{
                                                    alignItems: 'center', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', width: '100%', paddingRight: 10, paddingBottom: 10
                                                }}>
                                                    <Text>{"Thời gian chờ"}</Text>
                                                    <Text>{"0"}</Text>
                                                    <Text>{formatPrice(0)}</Text>
                                                </View>

                                                <View style={{
                                                    alignItems: 'center', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', width: '100%', paddingRight: 10, paddingBottom: 10
                                                }}>
                                                    <Text>{"Phí khác"}</Text>
                                                    <Text>{"Vé cao tốc"}</Text>
                                                    <Text>{formatPrice(150000)}</Text>
                                                </View>

                                                <View style={{
                                                    alignItems: 'flex-end', justifyContent: 'flex-end', width: '100%', paddingRight: 10
                                                }}>
                                                    <Text style={{fontSize: 16, padding: 10, backgroundColor: 'rgba(180,180,180,0.71)'}}>{formatPrice(121000)}</Text>
                                                </View>
                                                <div style={{
                                                    display: 'flex', flexDirection: 'column', border: 2, width: '98%', padding: 5
                                                }}>
                                                    <StyledInputBase
                                                        placeholder="Tên phụ phí …"
                                                        inputProps={{'aria-label': 'Tên phụ phí'}}
                                                        style={{
                                                            borderRadius: 5, color: 'black', backgroundColor: alpha("#a4a4a4", 0.15), '&:hover': {
                                                                backgroundColor: alpha("#a4a4a4", 0.25),
                                                            },
                                                        }}
                                                        onChange={(event) => {
                                                            /*setPermissionName(event.target.value);*/
                                                        }}
                                                    />
                                                    <StyledInputBase
                                                        placeholder="Thành tiền …"
                                                        inputProps={{'aria-label': 'Thành tiền'}}
                                                        style={{
                                                            borderRadius: 5, color: 'black', backgroundColor: alpha("#a4a4a4", 0.15), '&:hover': {
                                                                backgroundColor: alpha("#a4a4a4", 0.25),
                                                            }, marginTop: 10
                                                        }}
                                                        onChange={(event) => {
                                                            /*setPermissionCode(event.target.value);*/
                                                        }}
                                                    />

                                                    <div style={{
                                                        display: 'flex', flexDirection: 'row',
                                                    }}>
                                                        <IconButton
                                                            aria-label="Huỷ"
                                                            style={{
                                                                display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1, color: "#ff4612"
                                                            }}
                                                            color="inherit"
                                                            size="medium"
                                                            onClick={() => {

                                                            }}
                                                        >
                                                            <CancelIcon fontSize="inherit"/>
                                                        </IconButton>

                                                        <IconButton
                                                            aria-label="Lưu"
                                                            style={{
                                                                display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1, color: "#00a615"
                                                            }}
                                                            color="inherit"
                                                            size="medium"
                                                            onClick={() => {


                                                            }}
                                                        >
                                                            <SaveIcon fontSize="inherit"/>
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </ScrollView> : null}
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View style={{
                            flexDirection: 'column', width: '65%', height: '100%', borderRadius: 10, zIndex: 90
                        }}>
                            <View style={{width: '100%', height: '100%', borderRadius: 10, zIndex: 90}}>
                                {mylocation ? <Map
                                    loadingElement={<div className={classes.fullHeight}/>}
                                    containerElement={<div className={classes.fullHeight}/>}
                                    mapElement={<div className={classes.fullHeight}/>}
                                    center={mylocation}
                                    isPickup={true}
                                    zoom={13}
                                    polygons={polygons}
                                    groupApply={tripdata && tripdata.groupApply ? tripdata.groupApply : null}
                                    googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + Google_Map_Key + "&v=3.exp&libraries=geometry,drawing,places"}
                                /> : null}
                            </View>
                        </View>

                    </View>
                </Grid>
            </DialogContentText>
        </DialogContent>
    </Dialog>);
}
