import {FETCH_ALL_TRANSACTIONS, FETCH_ALL_TRANSACTIONS_FAILED, FETCH_ALL_TRANSACTIONS_SUCCESS,} from "../store/types";
import {firebase} from '../config/configureFirebase';
import {off, onValue} from "firebase/database";

export const fetchTransactionsReport = () => (dispatch) => {
    const {
        usersRef
    } = firebase;

    dispatch({
        type: FETCH_ALL_TRANSACTIONS,
        payload: null
    });
    var renderobj = {};
    off(usersRef);
    onValue(usersRef, snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data)
                .filter(i => data[i].usertype != 'admin')
                .map(i => {
                    data[i].id = i;
                    if (data[i].walletHistory) {
                        const walletHistorys = data[i].walletHistory;
                        walletHistorys.map(j => {
                            renderobj[j].id = j;
                            renderobj[j].uid = data[i].uid;
                            renderobj[j].amount = walletHistorys[j].amount ? walletHistorys[j].amount : 0;
                            renderobj[j].date = walletHistorys[j].date;
                            renderobj[j].txRef = walletHistorys[j].txRef;
                            renderobj[j].type = walletHistorys[j].type;
                        });

                    }
                });
            dispatch({
                type: FETCH_ALL_TRANSACTIONS_SUCCESS,
                payload: renderobj
            });
        } else {
            dispatch({
                type: FETCH_ALL_TRANSACTIONS_FAILED,
                payload: "No users available."
            });
        }
    });
};

