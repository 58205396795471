import {
    cloud_function_server_url_asian,
    FirebaseConfig
} from 'config';
import store from '../store/store';

export function RequestPushMsg(token, title, msg, data = null) {
    if (token && token != undefined) {
        const settings = store.getState().settingsdata.settings;
        let host = window && window.location && settings.CompanyWebsite === window.location.origin ? window.location.origin : `https://${FirebaseConfig.projectId}.web.app`

        console.log('window.location.origin', window.location.origin);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "token": token,
            "title": title,
            "msg": msg
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        console.log("host", host);
        fetch(`${host}/send_notification`, requestOptions)
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.error(error));
    }
}