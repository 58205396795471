/*eslint-disable*/
import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import styles from "assets/jss/material-kit-react/views/componentsSections/downloadStyle.js";
import {language} from "config";
import {useSelector} from 'react-redux';
import {View, Text} from "react-native-web";

const useStyles = makeStyles(styles);

export default function SectionDownload() {
    const settings = useSelector(state => state.settingsdata.settings);
    const classes = useStyles();
    return (
        <div className={classes.section}>
            <div className={classes.container}>
                <GridContainer className={classes.textCenter} justify="center">
                    <View style={{
                        width: '100%',
                        justifyContent: "center", alignItems: "center",
                    }}>
                        <View style={{
                            width: '90%',
                            flexDirection: 'column', justifyContent: "center", alignItems: "center"
                        }}>
                            <img className={classes.triobanner} src={require("../../assets/img/logomyson.png")} alt="Eto" style={{width: '60%', marginBottom: 50}}/>
                            <Text style={{color: '#484848', fontSize: 16}}>
                                {"Tên đơn vị (Company's name): CÔNG TY CỔ PHẦN DỊCH VỤ VẬN TẢI MY SƠN\n" +
                                    "Giấy chứng nhận ĐKDN: 0201903957. Cấp lần đầu: 24/09/2018. Cơ quan cấp: do Sở Kế Hoạch và Đầu tư Thành Phố Hải Phòng.\n" +
                                    "Đăng ký thay đổi lần 2: 15/08/2020. Cơ quan cấp: Sở Kế Hoạch và Đầu tư Thành Phố Hải Phòng.\n" +
                                    "Giấy phép kinh doanh vận tải bằng xe ô tô cấp lần đầu: số 6915, ngày 12/11/2018 bởi Sở Giao Thông Vận Tải Thành Phố Hải Phòng.\n" +
                                    "Địa chỉ trụ sở chính: Thôn My Sơn (nhà ông Nguyễn Văn Bản), xã Ngũ Lão, huyện Thuỷ Nguyên, thành phố Hải Phòng, Việt Nam.\n" +
                                    "Đại diện công ty: Ông Đoàn Văn Mưu. Chức vụ: Giám Đốc.\n" +
                                    "\n" +
                                    "Hỗ trợ, CSKH 24/7\n" +
                                    "\n" +
                                    "Số điện thoại chính : 02253 675 675\n" +
                                    "Hotline miễn cước : 1800 646 893"}
                            </Text>
                        </View>


                    </View>
                    {/*<View style={{
                        width: '100%',
                        flexDirection: 'row', flexWrap: "wrap", justifyContent: "space-between", alignItems: "center",
                        borderBottomWidth: 1,
                        paddingBottom: 20,
                        paddingTop: 20,
                        borderBottomColor: '#484848'
                    }}>
                        <Text style={{color: '#484848', textAlign: 'left'}}>
                            {"Chuyên dịch vụ"}
                        </Text>
                        <Text style={{color: '#484848', textAlign: 'center'}}>
                            {"XE TAXI - XE HỢP ĐỒNG"}
                        </Text>
                    </View>

                    <View style={{
                        width: '100%',
                        flexDirection: 'row', flexWrap: "wrap", justifyContent: "space-between", alignItems: "center",
                        paddingBottom: 20,
                        paddingTop: 20
                    }}>
                        <Text style={{color: '#484848', textAlign: 'left'}}>
                            {"Tải ứng dụng"}
                        </Text>
                        <View style={{
                            flexDirection: 'row', flexWrap: "wrap", justifyContent: "space-between", alignItems: "center",
                        }}>
                            {settings && settings.AppleStoreLink ?
                                <a href={settings.AppleStoreLink}><img src={require("../../assets/img/down-apple.png")} alt="Apple Store Link" style={{width: 100}}/></a>
                                : null}
                            <span style={{marginRight: '5px'}}></span>
                            {settings && settings.PlayStoreLink ?
                                <a href={settings.PlayStoreLink}><img src={require("../../assets/img/down-android.png")} alt="Playstore Link" style={{width: 100}}/></a>
                                : null}
                            <span style={{marginRight: '5px'}}></span>
                            {settings && settings.ApkLink ?
                                <a href={settings.ApkLink}><img src={require("../../assets/img/apkdownload.png")} alt="APK Download Link" style={{width: 100}}/></a>
                                : null}
                        </View>
                    </View>
*/}

                </GridContainer>

            </div>
        </div>
    );
}
