import * as React from 'react';
import {useCallback, useContext, useEffect, useState} from 'react';
import Checkbox from "@mui/material/Checkbox";
import {Text, TouchableOpacity, View} from "react-native-web";
import {colors} from "../../config/theme";
import NoteCallCenterComponent from "../CallCenter/NoteCallCenterComponent";
import {useDispatch, useSelector} from "react-redux";
import EditNoteIcon from "@mui/icons-material/EditNote";
import {IconButton} from "@material-ui/core";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AdjustIcon from '@mui/icons-material/Adjust';
import StopOutlinedIcon from '@mui/icons-material/StopOutlined';
import LocalTaxiOutlinedIcon from '@mui/icons-material/LocalTaxiOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import CloseIcon from '@mui/icons-material/Close';
import GoogleMapsAutoComplete from "../GoogleMapsAutoComplete";
import {language} from 'config';
import {api, FirebaseContext} from "common";
import store from "common/src/store/store";
import {GetDistance} from "common/src/other/GeoFunctions";
import {fetchAddressfromCoords, getDriveTime} from "common/src/other/GoogleAPIFunctions";
import {FareCalculator2} from "common/src/other/FareCalculator";
import {CALL_DETAIL_DATA, CHANGE_TAB_INDEX, UPDATE_ZOOM_MAP} from "common/src/store/types";
import {ConvertSecondToHmToBooking, GetCurrentMiniTimeStamp, GetDateString, GetMinBookingLaterDate} from "common/src/other/DateFunctions";
import {formatPriceRound, generateNoteKey, HiddenPhone, validPhoneVN} from "common/src/other/CommonFunctions";
import {formatNumber} from "react-native-currency-input";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";

export default function InfoBookingDialog(props) {
    const {refApi} = useContext(FirebaseContext);
    const dispatch = useDispatch();
    const [isEditNote, setIsEditNote] = useState(false);
    const notemapaddressdata = useSelector(state => state.notemapaddressdata.notes);
    const [pickupFromMap, setPickupFromMap] = useState(null);
    const [dropFromMap, setDropFromMap] = useState(null);
    const tripdata = useSelector(state => state.tripdata);
    const callDetail = useSelector(state => state.sessiondata.callDetail);
    let cars = store.getState().cartypes.cars;
    const settings = useSelector(state => state.settingsdata.settings);
    const groupkddata = useSelector(state => state.groupdriverdata.group_kd);
    const auth = useSelector((state) => state.auth.user);
    const [driverOutGroup, setDriverOutGroup] = useState(null);
    const driverOutInGroup = useSelector(state => state.usersdata.driverOutInGroup);
    const callDetailState = useSelector(state => state.pacallcenterdata.callCenter);
    const [bookingType, setBookingType] = useState('Book Now');//Book Now
    const [selectedDate, setSelectedDate] = useState(GetDateString());
    const tabIndex = useSelector(state => state.sessiondata.tabIndex);
    const bookingDataById = useSelector((state) => state.bookinglistdata.booking_active);
    const [sourceNewCall, setSourceNewCall] = useState(null);
    const [editPhone, setEditPhone] = useState(false);
    const [loadingCall, setLoadingCall] = useState(false);
    const [cancelCallState, setCancelCallState] = useState(false);
    const listUserLostConnect = useSelector((state) => state.sessiondata.listConnect);
    const [bookingStatus, setBookingStatus] = useState(false);
    //const lock_calls =  useSelector((state) => state.pacallcenterdata.lock_calls);

    const loadOptionsPickup = useCallback(
        (options) => {
        },
        [],
    );
    const loadOptionsDrop = useCallback(
        (options) => {
        },
        [],
    );
    useEffect(() => {
        return () => {
            dispatch(api.updateTripDrop(null));
            dispatch(api.updateTripPickup(null));
            dispatch(api.updateTripCar(null));
        }
    }, []);


    useEffect(() => {
        if (bookingDataById) {
            if (tripdata.trip_estimate_temp) {
            } else {
                if (bookingDataById.tripEstimate) {
                    dispatch(api.updateEstimateTemp(bookingDataById.tripEstimate));
                }

            }

            if (bookingDataById.is_two_way) {
                dispatch(api.updateTwoWay(bookingDataById.is_two_way));
            }

            if (bookingDataById.bookLater) {
                setBookingType("Book Later");
                if (bookingDataById.bookingDate) {
                    setSelectedDate(GetDateString(bookingDataById.bookingDate));
                }
            }

            if (bookingDataById.pickup) {
                dispatch(api.updateTripPickup(bookingDataById.pickup));
            }

            if (bookingDataById.drop) {
                dispatch(api.updateTripDrop(bookingDataById.drop));
            }


            if (bookingDataById.coords) {
                props.updateCoordsFromBooking(Object.values(bookingDataById.coords));
            } else {
                props.updateCoordsFromBooking(null);
            }
        }
    }, [bookingDataById]);

    useEffect(() => {
        if (tabIndex == 1) {

        }
    }, [tabIndex]);


    useEffect(() => {
        if (driverOutInGroup) {
            if (driverOutInGroup.driverOutGroup) {
                setDriverOutGroup(driverOutInGroup.driverOutGroup);
            }
        }
    }, [driverOutInGroup]);

    const closeEditNote = useCallback(() => {
        setIsEditNote(false);
    }, []);

    useEffect(() => {
        var startLoc = null;
        var destLoc = null;
        let prices = null;
        if (tripdata.carType) {
            if (groupkddata && groupkddata.length > 0) {
                let groups = groupkddata.filter((g) => g.value && g.value == 4);
                if (groups && groups[0] && groups[0].prices) {
                    if (tripdata.carType) {
                        dispatch(api.updateTripPrice(groups[0].prices[tripdata.carType.code]));
                        prices = groups[0].prices[tripdata.carType.code];
                    } else {
                        dispatch(api.updateTripPrice(groups[0].prices['taxi']));
                        prices = groups[0].prices['taxi'];
                    }
                }
            }
        }


        if (tripdata.pickup && tripdata.pickup.add) {
            let startLoc1 = '20.945759,106.740893';
            let destLoc1 = tripdata.pickup.lat + ',' + tripdata.pickup.lng;
            /*getRouteDetails(startLoc1, destLoc1).then((arriveTime2) => {
                if (arriveTime2 && arriveTime2.distance) {
                    //setEstimatePickupTemp(arriveTime2)
                }
            }).catch((error) => {
                console.log("error", error);
            });*/

            //setPickupFromMap(tripdata.pickup.add);
            startLoc = tripdata.pickup.lat + ',' + tripdata.pickup.lng;
            if (props.listGroup && props.listGroup.length > 0) {
                let groups = props.listGroup;
                let bankinhCheck = settings && settings.bankinh ? settings.bankinh : 3;
                var kmcheck = 0;
                var coordApplyCheck = null;
                var groupApplyCheck = null;
                groups.map(group => {
                    // Check toa do tam
                    if (group.coord && group.lock_status === false) {
                        let coords = group.group_coords;
                        let toado_tam = group.coord.split(",");
                        if (toado_tam.length > 0) {
                            let kc_tam = GetDistance(tripdata.pickup.lat, tripdata.pickup.lng, parseFloat(toado_tam[0].toString().trim()), parseFloat(toado_tam[1].toString().trim()));
                            if (kc_tam < 10) {
                                if (coords) {
                                    coords.map(coord => {
                                        if (coord.coord && coord.coord.split(",").length > 0) {
                                            let toado_con = coord.coord.split(",");
                                            let kc_con = GetDistance(tripdata.pickup.lat, tripdata.pickup.lng, parseFloat(toado_con[0].toString().trim()), parseFloat(toado_con[1].toString().trim()));
                                            let bkc = coord.radius / 1000;
                                            if (kmcheck === 0) {
                                                kmcheck = kc_con;
                                                if (parseFloat(bkc) + parseFloat(bankinhCheck) >= parseFloat(kmcheck)) {
                                                    //setCoordApply(coord);
                                                    coordApplyCheck = coord;
                                                    groupApplyCheck = group;
                                                }
                                            } else {
                                                if (kc_con <= kmcheck) {
                                                    kmcheck = kc_con;
                                                    if (parseFloat(bkc) + parseFloat(bankinhCheck) >= parseFloat(kmcheck)) {
                                                        //setCoordApply(coord);
                                                        coordApplyCheck = coord;
                                                        groupApplyCheck = group;
                                                    }
                                                }
                                            }
                                        }
                                    });
                                }
                            }
                        }
                    }
                });

            }
            if (groupApplyCheck && groupApplyCheck.value) {
                if (tripdata.carType && tripdata.pickup) {
                    dispatch(api.fetchDriversOutInGroup(settings.bankinh ? settings.bankinh : 3, tripdata.carType.code, tripdata.pickup, groupApplyCheck.value));
                    dispatch(api.updateTripGroup(groupApplyCheck));
                }
            } else {
                if (tripdata.carType && tripdata.pickup) {
                    dispatch(api.fetchDriversOutInGroup(settings.bankinh ? settings.bankinh : 3, tripdata.carType.code, tripdata.pickup));
                    dispatch(api.updateTripGroup(null));
                }
            }

        } else {
            if (tripdata.drop && tripdata.drop.add) {
                dispatch(api.updateTripDrop(null));
            }
            if (tripdata.selected && tripdata.selected != 'pickup') {
                dispatch(api.updatSelPointType('pickup'));
            }
        }


        if (tripdata.drop) {
            destLoc = tripdata.drop.lat + ',' + tripdata.drop.lng;
            setDropFromMap(tripdata.drop.add ? tripdata.drop.add : destLoc);
        } else {
            if (dropFromMap) {
                setDropFromMap(null);
            }
        }

        if (startLoc && destLoc && prices) {

            let startLoc = tripdata.pickup.lat + ',' + tripdata.pickup.lng;
            let destLoc = tripdata.drop.lat + ',' + tripdata.drop.lng;
            getDriveTime(startLoc, destLoc).then((arriveTime2) => {
                if (arriveTime2 && arriveTime2.distance_in_km) {
                    if (prices) {
                        let fareCalculation = FareCalculator2(arriveTime2.distance_in_km, parseFloat(arriveTime2.time_in_secs), prices, 0, 0, prices, true);
                        fareCalculation.push(arriveTime2.distance_in_km);
                        fareCalculation.push(parseFloat(arriveTime2.time_in_secs));
                        dispatch(api.updateEstimateTemp(fareCalculation));
                    }
                } else {
                    var distanceEstimate2 = GetDistance(tripdata.pickup.lat, tripdata.pickup.lng, tripdata.drop.lat, tripdata.drop.lng);
                    var timeEstimate2 = (distanceEstimate2 / 35) * 60;
                    if (prices) {
                        let fareCalculation = FareCalculator2(distanceEstimate2, parseFloat(timeEstimate2), prices, 0, 0, prices, true);
                        fareCalculation.push(distanceEstimate2);
                        fareCalculation.push(parseFloat(timeEstimate2));
                        dispatch(api.updateEstimateTemp(fareCalculation));
                    }
                }
            }).catch((error) => {
                console.log("Error", error);
            });
        } else {
            dispatch(api.updateEstimateTemp(null));
        }

    }, [tripdata.pickup, tripdata.drop, tripdata.carType, tripdata.is_two_way]);

    useEffect(() => {
        if (callDetail && callDetail.isEditPickup) {
            if (callDetail.pickup && callDetail.pickup.add) {
                if (callDetail.pickup.add != pickupFromMap) {
                    setPickupFromMap(callDetail.pickup.add);
                }
            } else {
                if (tripdata.pickup) {
                    dispatch(api.updateTripPickup(null));
                }
                if (pickupFromMap) {
                    setPickupFromMap(null);
                }
            }

        }
        if (callDetail) {
            if (tripdata.pickup) {
            } else {
                dispatch(api.updateTripPickup(callDetail.pickup));
            }
            if (callDetailState && (callDetailState.status === 'cancel' || callDetailState.status == 'booked_cancel' || callDetailState.status == 'pending')) {
                if (!tripdata.isEditPickup) {
                    dispatch(api.updateTripEditPickup(true))
                    dispatch(api.updateTripEditDrop(true))
                }
            } else {
                if (callDetailState == null) {
                    dispatch(api.updateTripEditPickup(true))
                    dispatch(api.updateTripEditDrop(true))
                } else {
                    if (tripdata.isEditPickup) {
                        dispatch(api.updateTripEditPickup(false))
                        dispatch(api.updateTripEditDrop(false))
                    }
                }
            }
        } else {
            dispatch({
                type: CHANGE_TAB_INDEX, payload: 0,
            });
        }

        if (callDetail && callDetail.car) {
            dispatch(api.updateTripCar(callDetail.car));
        }

    }, [callDetail, callDetailState]);

    const onDateChange = (event) => {
        setSelectedDate(event.target.value);
    };
    const loadBookingObj = async (bookingNow) => {
        if (tripdata && tripdata.pickup) {
        } else {
            alert("Chọn lại vị trí");
            return
        }
        var bookingObject = {
            pickup: null,
            carDetails: null,
            userDetails: {
                uid: auth ? auth.uid : null,
                profile: {
                    firstName: "Trung Tâm",
                    lastName: "",
                    mobile: callDetail && callDetail.source ? callDetail.source : settings.panic,
                    note: callDetail && callDetail.note ? callDetail.note : 'Hãy gọi cho tôi',
                    pushToken: auth ? auth.pushToken : '',
                }
            },
            groupDetails: null,
            settings: settings,
            tripdate: GetCurrentMiniTimeStamp(),
            customer_note: callDetail && callDetail.note ? callDetail.note : 'Hãy gọi cho tôi',
            bookLater: bookingType == 'Book Later' && selectedDate ? true : false,
            bookingDate: bookingType == 'Book Later' && selectedDate ? Date.parse(selectedDate) : null,
            booking_type_web: true,
            source: "TT",
            type: "TT",
            callId: callDetail && callDetail.firebase_id ? callDetail.firebase_id : null
        };

        if (tripdata.groupApply) {
            bookingObject.groupDetails = tripdata.groupApply;
        }

        bookingObject.areaId = null;
        bookingObject.carDetails = tripdata && tripdata.carType ? tripdata.carType : null;
        bookingObject.carTypeCode = tripdata && tripdata.carType ? tripdata.carType.code : null;
        bookingObject.prices = tripdata && tripdata.price ? tripdata.price : null;

        if (tripdata.pickup) {
            bookingObject.pickup = tripdata.pickup;
            bookingObject.pickupFrom = tripdata.pickup;
        }

        /*if (tripdata.drop) {
            bookingObject.drop = tripdata.drop;
        }*/

        if (tripdata && tripdata.trip_estimate) {
            bookingObject.tripEstimate = tripdata.trip_estimate;
            bookingObject.estimateTemp = tripdata.trip_estimate;
        }

        if (driverOutGroup) {
            if (listUserLostConnect) {
                let newDrivers = [];
                for (let i = 0; i < driverOutGroup.length; i++) {
                    let u = driverOutGroup[i];
                    if (!listUserLostConnect.includes(u.codeDriver)) {
                        newDrivers.push(u);
                    }
                }
                bookingObject.driverOutGroup = newDrivers;
            } else {
                bookingObject.driverOutGroup = driverOutGroup;
            }

        }

        //dispatch(api.fetchDriversOutInGroup(settings.bankinh ? settings.bankinh : 3, tripdata.carType.code, bookingObject.pickup));

        if (tripdata && tripdata.is_two_way) {
            bookingObject.is_two_way = tripdata.is_two_way;
        } else {
            bookingObject.is_two_way = false;
        }
        //setButtonDisable(true);
        if (bookingType == "Book Now") {
            if (driverOutGroup) {
                setTimeout(() => {
                    dispatch(api.addBookingV2(bookingObject));
                    const callCenter = store.getState().pacallcenterdata.callCenter;
                    const bookingActive = store.getState().bookinglistdata.booking_active;
                    dispatch(api.resetTripData(callCenter && callCenter.id ? callCenter.id : null, bookingActive && bookingActive.id ? bookingActive.id : null));
                }, 1000)
            } else {
                alert("Không tìm thấy tài xế phù hợp");
            }
        } else {
            let startLoc = settings.CompanyLocation ? settings.CompanyLocation.replaceAll(" ", "") : '';
            if (startLoc && bookingObject.pickup) {
                let destLoc = bookingObject.pickup.lat + ',' + bookingObject.pickup.lng;
                let arriveTime2 = await getDriveTime(startLoc, destLoc);
                if (arriveTime2) {
                    bookingObject.bookingDateLaterMin = ((parseInt(arriveTime2.time_in_secs) * 1000) + ((settings.minuteBookingLater ? settings.minuteBookingLater : 15) * 60000));
                }
            }


            setTimeout(() => {
                dispatch(api.addBookingV2(bookingObject));
                const callCenter = store.getState().pacallcenterdata.callCenter;
                const bookingActive = store.getState().bookinglistdata.booking_active;
                dispatch(api.resetTripData(callCenter && callCenter.id ? callCenter.id : null, bookingActive && bookingActive.id ? bookingActive.id : null));
            }, 1000)
        }

    }

    return (
        callDetail ?
            <View
                style={{
                    backgroundColor: colors.WHITE,
                    position: 'absolute',
                    borderRadius: 20,
                    left: 15, // Di chuyển sang bên trái màn hình
                    top: 115, // Có thể điều chỉnh top nếu cần
                }}
            >
                <View
                    style={{
                        width: '100%',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end'
                    }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={() => {
                            let updateCallSession = {...callDetail};
                            if (callDetail.id) {
                                dispatch(api.updateCallCancelById(callDetail.id, updateCallSession));
                                // Fetch CallCenter
                            }
                            const callCenter = store.getState().pacallcenterdata.callCenter;
                            const bookingActive = store.getState().bookinglistdata.booking_active;
                            dispatch(api.resetTripData(callCenter && callCenter.id ? callCenter.id : null, bookingActive && bookingActive.id ? bookingActive.id : null));
                        }
                        }
                    >
                        <CloseIcon color={"action"}/>
                    </IconButton>
                </View>
                <View style={{padding: 10, paddingTop: 0}}>
                    <View style={{
                        flexDirection: 'row'
                    }}>
                        {callDetail.source ?
                            callDetail.id ?
                                <Text style={{
                                    backgroundColor: colors.input_bg,
                                    padding: 5,
                                    borderRadius: 10,
                                    fontWeight: 'bold',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>{auth && auth.usertype == 'admin' ? callDetail.source : HiddenPhone(callDetail.source)}</Text> :
                                editPhone ?
                                    <TextField
                                        variant="outlined"
                                        label="Nhập số điện thoại"
                                        size="small"
                                        type="tel" // Sets the input type to "tel"
                                        inputProps={{
                                            pattern: "[0-9]*", // Allow only numbers (if needed)
                                            maxLength: 10, // Optional: Restrict to 10 digits (customizable)
                                        }}
                                        onBlur={() => {
                                            if (sourceNewCall) {
                                                if (validPhoneVN(sourceNewCall)) {
                                                    setEditPhone(false);
                                                }
                                            }

                                        }
                                        }
                                        value={sourceNewCall}
                                        onChange={(event) => {
                                            setSourceNewCall(event.target.value);
                                            if (validPhoneVN(event.target.value)) {
                                                let callUpdate = {...callDetail};
                                                callUpdate.source = event.target.value;
                                                dispatch({
                                                    type: CALL_DETAIL_DATA, payload: callUpdate,
                                                });
                                                setEditPhone(false);
                                            }
                                        }
                                        }/>

                                    :
                                    <TouchableOpacity
                                        onPress={() => {
                                            setEditPhone(!editPhone);
                                        }
                                        }
                                    >
                                        <Text style={{
                                            backgroundColor: colors.input_bg,
                                            padding: 5,
                                            borderRadius: 10,
                                            fontWeight: 'bold',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>{auth && auth.usertype == 'admin' ? callDetail.source : HiddenPhone(callDetail.source)}</Text>
                                    </TouchableOpacity>
                            :
                            <TextField
                                variant="outlined"
                                label="Nhập số điện thoại"
                                size="small"
                                type="tel" // Sets the input type to "tel"
                                inputProps={{
                                    pattern: "[0-9]*", // Allow only numbers (if needed)
                                    maxLength: 10, // Optional: Restrict to 10 digits (customizable)
                                }}
                                value={sourceNewCall}
                                onBlur={() => {
                                    if (sourceNewCall) {
                                        if (validPhoneVN(sourceNewCall)) {
                                            setEditPhone(false);
                                        }
                                    }
                                }
                                }
                                onChange={(event) => {
                                    setSourceNewCall(event.target.value);
                                    if (validPhoneVN(event.target.value)) {
                                        let callUpdate = {...callDetail};
                                        callUpdate.source = event.target.value;
                                        dispatch({
                                            type: CALL_DETAIL_DATA, payload: callUpdate,
                                        });
                                        setEditPhone(false);
                                    }
                                }
                                }/>
                        }
                        {/*{callDetail.driver_code ?
                            <Text style={{
                                marginLeft: 6,
                                fontWeight: 'bold',
                                padding: 5
                            }}>{"[" + callDetail.driver_code + "]"}</Text> : null
                        }*/}
                        {callDetail.status === 'cancel' || callDetail.status === 'booked_cancel' ?
                            <Text style={{
                                marginLeft: 6,
                                fontWeight: 'bold',
                                padding: 5,
                                maxWidth: 280
                            }}>{"[" + (bookingDataById ? bookingDataById.reason : callDetail.reason ? callDetail.reason : '') + "]"}</Text> : <Text style={{
                                marginLeft: 6,
                                fontWeight: 'bold',
                                padding: 5
                            }}>{"[" + callDetail.driver_code ? callDetail.driver_code : '' + "]"}</Text>
                        }
                    </View>

                    <View style={{
                        flexDirection: 'row', flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: 10
                    }}>
                        <View style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            backgroundColor: colors.input_bg,
                            padding: 5, borderRadius: 10, width: 350
                        }}>
                            <Text
                                style={{fontWeight: 'bold'}}>{bookingDataById ? bookingDataById.id : ''}</Text>
                            <View style={{
                                flexDirection: 'row', flexWrap: 'wrap',
                                alignItems: 'center',
                                justifyContent: 'flex-start'
                            }}>
                                <View style={{flexDirection: 'row'}}>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        edge="start"
                                        onClick={() => {
                                            setIsEditNote(!isEditNote);
                                        }
                                        }
                                    >
                                        <EditNoteIcon color={"action"}/>
                                    </IconButton>
                                </View>
                                <View style={{
                                    flexDirection: 'row'
                                }}>
                                    {isEditNote ?
                                        <View
                                            style={{
                                                justifyContent: "center",
                                                width: 295,
                                            }}
                                        >
                                            <NoteCallCenterComponent
                                                rowData={callDetail}
                                                arrayOptions={Object.values(notemapaddressdata).filter(n => n.coords && n.id)}
                                                closeEditNote={closeEditNote}
                                            />
                                        </View>
                                        :
                                        <View
                                            style={{
                                                justifyContent: "center",
                                                width: 295,
                                            }}
                                        >
                                            <TouchableOpacity
                                                onPress={() => {
                                                    setIsEditNote(!isEditNote);
                                                }
                                                }
                                            >
                                                <Text style={{paddingRight: 5}}>
                                                    {callDetail.note ? callDetail.note : (callDetail.booking_note ? callDetail.booking_note : "Ghi chú đón khách")}
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                    }
                                </View>
                            </View>

                            <View style={{
                                flexDirection: 'row', flexWrap: 'wrap',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                zIndex: 2,
                                evaluate: 2
                            }}>
                                <View style={{flexDirection: 'row'}}>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        edge="start"
                                        onClick={() => {
                                            dispatch(api.updatSelPointType('pickup'));
                                            dispatch({
                                                type: UPDATE_ZOOM_MAP, payload: 16
                                            });
                                        }
                                        }
                                    >
                                        <AccountCircleIcon color={"action"}/>
                                    </IconButton>
                                </View>
                                <View style={{
                                    flexDirection: 'column'
                                }}>
                                    <Text style={{fontWeight: 'bold'}}>{tripdata && tripdata.pickup && tripdata.pickup.shortAdd ? tripdata.pickup.shortAdd : (callDetail && callDetail.pickup && callDetail.pickup.shortAdd ? callDetail.pickup.shortAdd : '')}</Text>

                                    <GoogleMapsAutoComplete
                                        placeholder={language.pickup_location}
                                        variant={"outlined"}
                                        value={tripdata && tripdata.pickup ? tripdata.pickup.add : (callDetail && callDetail.pickup ? callDetail.pickup.add : '')}
                                        style={{width: 300, borderBottomWidth: 0, zIndex: 9, elevation: 9}}
                                        textFieldStyle={{
                                            width: '100%',
                                            fontSize: 11,
                                            borderRadius: 5,
                                            padding: 5,
                                            paddingTop: 1,
                                            paddingBottom: 1
                                        }}
                                        inputStyle={{fontSize: 12}}
                                        renderOption={() => {
                                            return null;
                                        }}
                                        hasNotRenderOption={true}
                                        loadOptions={loadOptionsPickup}
                                        onChange={
                                            (value) => {
                                                if (value && value.coords) {
                                                    setPickupFromMap(value);
                                                    dispatch(api.updatSelPointType("pickup"));
                                                    let updateCallSession = {...callDetail};
                                                    if (value && value.coords) {
                                                        dispatch(api.updateTripPickup({
                                                            lat: parseFloat(value.coords.lat),
                                                            lng: parseFloat(value.coords.lng),
                                                            shortAdd: value.structured_formatting ? value.structured_formatting.main_text : value.description.split(',')[0],
                                                            add: value.description,
                                                            source: "Tìm kiếm"
                                                        }));

                                                        updateCallSession.pickup = {
                                                            lat: parseFloat(value.coords.lat),
                                                            lng: parseFloat(value.coords.lng),
                                                            shortAdd: value.structured_formatting ? value.structured_formatting.main_text : value.description.split(',')[0],
                                                            add: value.description,
                                                            source: "Tìm kiếm"
                                                        };
                                                    }


                                                    dispatch(api.updateCallCancelById(callDetail.id, updateCallSession));
                                                    dispatch({
                                                        type: CALL_DETAIL_DATA, payload: updateCallSession,
                                                    });
                                                    dispatch({
                                                        type: UPDATE_ZOOM_MAP, payload: 16,
                                                    });
                                                }
                                            }
                                        }
                                    />
                                    {/*{listAddressPickup ? <DisplayAddressSearchMap listAddressPickup={listAddressPickup}/> : null}*/}

                                </View>
                            </View>

                        </View>
                        <View style={{}}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={() => {
                                    dispatch(api.updatSelPointType('pickup'));
                                    dispatch({
                                        type: UPDATE_ZOOM_MAP, payload: 16
                                    });
                                    if (callDetail.note) {
                                        if (tripdata.pickup) {
                                            let noteSet = {
                                                add: tripdata.pickup.add,
                                                shortAdd: tripdata.pickup.shortAdd ? tripdata.pickup.shortAdd : "",
                                                coords: tripdata.pickup.lat.toString() + ", " + tripdata.pickup.lng.toString(),
                                                lat: tripdata.pickup.lat,
                                                lng: tripdata.pickup.lng,
                                                note: callDetail.note,
                                            }
                                            dispatch(api.editNoteMapAddress(noteSet, "Set"));
                                            alert("Cập nhật thành công");
                                        } else {
                                            alert("Vui lòng xác nhận vị trí trên bản đồ");
                                        }
                                    } else {
                                        alert("Vui lòng tồn tại ghi chú");
                                    }
                                }
                                }
                            >
                                <AddCircleIcon color={"action"}/>
                            </IconButton>

                            {bookingDataById && bookingDataById.driver && !bookingDataById.customer_wish ? //
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="end"
                                    onClick={async () => {
                                        //@TODO
                                        let location = await api.getDriverLocations(bookingDataById.driver); //YFTyYWR9D8WDepVUV5jGnJEVMrB3

                                        if (location && location.lat) {
                                            let latlng = location.lat + ',' + location.lng;
                                            let add = await fetchAddressfromCoords(latlng);
                                            console.log('add', add)
                                            let noteSet = {
                                                coords: location.lat.toString() + ", " + location.lng.toString(),
                                                lat: location.lat,
                                                lng: location.lng,
                                                note: callDetail.note,
                                                add: add,
                                                shortAdd: '',
                                                id: generateNoteKey(callDetail.note)
                                            }
                                            //dispatch(api.editNoteMapAddress(noteSet, "Update"));
                                            setPickupFromMap(add);
                                            let updateCallSession = {...callDetail};
                                            if (updateCallSession.pickup) {
                                                updateCallSession.pickup.lat = location.lat;
                                                updateCallSession.pickup.lng = location.lng;
                                                updateCallSession.pickup.add = add;
                                                updateCallSession.pickup.shortAdd = '';
                                            } else {
                                                updateCallSession.pickup = {
                                                    lat: location.lat,
                                                    lng: location.lng,
                                                    add: add,
                                                    shortAdd: ''
                                                }
                                            }

                                            dispatch(api.updateTripPickup({
                                                lat: location.lat,
                                                lng: location.lng,
                                                add: add,
                                                shortAdd: '',
                                                source: "Driver"
                                            }));

                                            dispatch({
                                                type: CALL_DETAIL_DATA, payload: updateCallSession,
                                            });
                                            if (callDetail.id) {
                                                dispatch(api.updateCallCancelById(callDetail.id, {pickup: updateCallSession.pickup}));
                                            }

                                            if (bookingDataById && bookingDataById.id) {
                                                let bookingUpdate = {...bookingDataById}
                                                bookingUpdate.pickupFrom = {
                                                    lat: location.lat,
                                                    lng: location.lng,
                                                    add: add ? add : '',
                                                    shortAdd: '',
                                                    source: "TT"
                                                }
                                                api.updateBookingApi(bookingDataById.id, bookingUpdate).then(r => {
                                                    let obj = {
                                                        at: GetCurrentMiniTimeStamp(),
                                                        by: bookingDataById.customer,
                                                        bookingId: bookingDataById.id,
                                                        type: 'GET_BOOKING',
                                                        status: 'PENDING',
                                                        from: "TT",
                                                        objectUpdate: {
                                                            pickupFrom: {
                                                                lat: location.lat,
                                                                lng: location.lng,
                                                                add: add ? add : '',
                                                                shortAdd: '',
                                                                source: "TT"
                                                            }
                                                        }
                                                    }
                                                    refApi.pushTriggerActionByUidApi(bookingDataById.driver, obj);
                                                });

                                            }
                                        }

                                    }
                                    }
                                >
                                    <GpsFixedIcon color={"action"}/>
                                </IconButton> : null}
                        </View>

                    </View>


                    <View style={{
                        flexDirection: 'row', flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: 10,
                        zIndex: 1,
                        evaluate: 1
                    }}>
                        <View style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            backgroundColor: colors.input_bg,
                            padding: 5, borderRadius: 10, width: 350
                        }}>
                            <View style={{
                                flexDirection: 'row', flexWrap: 'wrap',
                                alignItems: 'center',
                                justifyContent: 'flex-start'
                            }}>
                                <View style={{flexDirection: 'row'}}>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        edge="start"
                                        onClick={() => {

                                        }
                                        }
                                    >
                                        <AdjustIcon color={"action"}/>
                                    </IconButton>
                                </View>
                                <View style={{
                                    flexDirection: 'column'
                                }}>
                                    <Text
                                        style={{fontWeight: 'bold'}}>{bookingDataById && bookingDataById.customer_wish && bookingDataById.pickup && bookingDataById.pickup.shortAdd ? bookingDataById.pickup.shortAdd : ''}</Text>
                                    <GoogleMapsAutoComplete
                                        placeholder={language.pickup_location}
                                        variant={"outlined"}
                                        readOnly={true}
                                        value={bookingDataById && bookingDataById.customer_wish && bookingDataById.pickup ? bookingDataById.pickup.add : ''}
                                        style={{width: 300, borderBottomWidth: 0, zIndex: 9, elevation: 9}}
                                        textFieldStyle={{
                                            width: '100%',
                                            fontSize: 11,
                                            borderRadius: 5,
                                            padding: 5,
                                            paddingTop: 1,
                                            paddingBottom: 1
                                        }}
                                        inputStyle={{fontSize: 12}}
                                        renderOption={() => {
                                            return null;
                                        }}
                                        hasNotRenderOption={true}
                                        loadOptions={loadOptionsPickup}
                                        /*onChange={
                                            (value) => {
                                                if (value && value.coords) {
                                                    setPickupFromMap(value);
                                                    dispatch(api.updatSelPointType("pickup"));
                                                    dispatch(api.updateTripPickup({
                                                        lat: parseFloat(value.coords.lat),
                                                        lng: parseFloat(value.coords.lng),
                                                        shortAdd: value.structured_formatting ? value.structured_formatting.main_text : value.description.split(',')[0],
                                                        add: value.description,
                                                        source: "Tìm kiếm"
                                                    }));
                                                }
                                            }
                                        }*/
                                    />
                                </View>
                            </View>

                            {/*<View style={{
                                flexDirection: 'row', flexWrap: 'wrap',
                                alignItems: 'center',
                                justifyContent: 'flex-start'
                            }}>
                                <View style={{flexDirection: 'row'}}>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        edge="start"
                                        onClick={() => {
                                            setIsEditNote(true);
                                        }
                                        }
                                    >
                                        <PauseOutlinedIcon color={"action"}/>
                                    </IconButton>
                                </View>
                                <View style={{
                                    flexDirection: 'column'
                                }}>
                                    <View style={{
                                        flexDirection: 'column'
                                    }}>
                                        <Text
                                            style={{fontWeight: 'bold'}}>{tripdata.drop && tripdata.drop.shortAdd ? tripdata.drop.shortAdd : ''}</Text>
                                        <GoogleMapsAutoComplete
                                            placeholder={language.drop_location}
                                            variant={"outlined"}
                                            value={dropFromMap}
                                            style={{width: 300, borderBottomWidth: 0, zIndex: 9, elevation: 9}}
                                            textFieldStyle={{
                                                width: '100%',
                                                fontSize: 11,
                                                borderRadius: 5,
                                                padding: 5,
                                                paddingTop: 1,
                                                paddingBottom: 1
                                            }}
                                            inputStyle={{fontSize: 12}}
                                            renderOption={() => {
                                                return null;
                                            }}
                                            hasNotRenderOption={true}
                                            loadOptions={loadOptionsDrop}
                                            onChange={
                                                (value) => {
                                                    setDropFromMap(value);
                                                    dispatch(api.updatSelPointType("drop"))
                                                    dispatch(api.updateTripDrop({
                                                        lat: parseFloat(value.coords.lat),
                                                        lng: parseFloat(value.coords.lng),
                                                        shortAdd: value.structured_formatting ? value.structured_formatting.main_text : value.description.split(',')[0],
                                                        add: value.description,
                                                        source: "Tìm kiếm"
                                                    }));
                                                }
                                            }
                                        />
                                    </View>
                                </View>
                            </View>*/}

                            <View style={{
                                flexDirection: 'row', flexWrap: 'wrap',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                marginTop: 10
                            }}>
                                <View style={{flexDirection: 'row'}}>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        edge="start"
                                        onClick={() => {
                                            dispatch(api.updatSelPointType('drop'));
                                            dispatch({
                                                type: UPDATE_ZOOM_MAP, payload: 16
                                            });
                                        }
                                        }
                                    >
                                        <StopOutlinedIcon color={"action"}/>
                                    </IconButton>
                                </View>
                                <View style={{
                                    flexDirection: 'column'
                                }}>
                                    <Text
                                        style={{fontWeight: 'bold'}}>{tripdata.drop && tripdata.drop.shortAdd ? tripdata.drop.shortAdd : ''}</Text>
                                    <GoogleMapsAutoComplete
                                        placeholder={language.drop_location}
                                        variant={"outlined"}
                                        value={dropFromMap}
                                        style={{width: 300, borderBottomWidth: 0, zIndex: 9, elevation: 9}}
                                        textFieldStyle={{
                                            width: '100%',
                                            fontSize: 11,
                                            borderRadius: 5,
                                            padding: 5,
                                            paddingTop: 1,
                                            paddingBottom: 1
                                        }}
                                        inputStyle={{fontSize: 12}}
                                        renderOption={() => {
                                            return null;
                                        }}
                                        hasNotRenderOption={true}
                                        loadOptions={loadOptionsDrop}
                                        onChange={
                                            (value) => {
                                                setDropFromMap(value);
                                                dispatch(api.updatSelPointType("drop"))
                                                dispatch(api.updateTripDrop({
                                                    lat: parseFloat(value.coords.lat),
                                                    lng: parseFloat(value.coords.lng),
                                                    shortAdd: value.structured_formatting ? value.structured_formatting.main_text : value.description.split(',')[0],
                                                    add: value.description,
                                                    source: "Tìm kiếm"
                                                }));
                                            }
                                        }
                                    />
                                </View>
                            </View>
                        </View>
                        <View>

                        </View>
                    </View>
                    <View style={{
                        flexDirection: 'row', flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        marginTop: 10
                    }}>
                        <View style={{
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            borderRadius: 10,
                            backgroundColor: colors.input_bg,
                        }}>
                            {callDetail && callDetail.id ?
                                bookingDataById && bookingDataById.status != "COMPLETE" && bookingDataById.status != "PAID" ?
                                    <Checkbox
                                        icon={
                                            <View style={{
                                                padding: 20, borderRadius: 10,
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                <RemoveIcon color="action"/>
                                            </View>

                                        }
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                padding: 0,
                                                margin: 0,
                                                borderRadius: 0, // Remove rounded corners for square shape
                                            },
                                        }}
                                        checkedIcon={
                                            <View style={{
                                                padding: 20, borderRadius: 10, backgroundColor: colors.BACKGROUND_DARK,
                                                justifyContent: 'center',
                                                alignItems: 'center'

                                            }}>
                                                <RemoveIcon sx={{color: colors.WHITE}}/>
                                            </View>
                                        } // Icon tùy chỉnh khi đã chọn
                                        checked={cancelCallState}
                                        onClick={() => {
                                            //props.openCancelCallback(callDetail);
                                            dispatch(api.updateTripCar(null));
                                            setCancelCallState(true);
                                        }
                                        }
                                    /> : null
                                : null}

                            {
                                cars.filter(c1 => c1.type == 'taxi').map(car => {
                                    return (
                                        <Checkbox
                                            icon={
                                                /*<Badge badgeContent={car.seat} color="action">
                                                    <LocalTaxiOutlinedIcon color="action"/>
                                                </Badge>*/
                                                <View style={{
                                                    padding: 20, borderRadius: 10,
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                    <Text style={{position: 'absolute', top: 5, right: 10, fontSize: 14}}>{car.seat}</Text>
                                                    <LocalTaxiOutlinedIcon color="action"/>
                                                </View>

                                            }
                                            sx={{
                                                '& .MuiSvgIcon-root': {
                                                    padding: 0,
                                                    margin: 0,
                                                    borderRadius: 0, // Remove rounded corners for square shape
                                                },
                                            }}
                                            checkedIcon={
                                                /*<Badge badgeContent={car.seat} color="action" style={{
                                                    backgroundColor: colors.BACKGROUND_DARK,
                                                    padding: 5,
                                                    borderRadius: 10
                                                }}>
                                                    <LocalTaxiOutlinedIcon sx={{color: colors.WHITE}}/>
                                                </Badge>*/
                                                <View style={{
                                                    padding: 20, borderRadius: 10, backgroundColor: colors.BACKGROUND_DARK,
                                                    justifyContent: 'center',
                                                    alignItems: 'center'

                                                }}>
                                                    <Text style={{position: 'absolute', top: 5, right: 10, fontSize: 14, color: colors.WHITE}}>{car.seat}</Text>
                                                    <LocalTaxiOutlinedIcon sx={{color: colors.WHITE}}/>
                                                </View>
                                            } // Icon tùy chỉnh khi đã chọn
                                            checked={!cancelCallState ? tripdata.carType && tripdata.carType.code && tripdata.carType.code === car.code ? true : false : false}
                                            onClick={() => {
                                                let updateCallData = {...callDetail};
                                                updateCallData.car = car;
                                                dispatch({
                                                    type: CALL_DETAIL_DATA, payload: updateCallData
                                                });
                                                if (callDetail.id) {
                                                    dispatch(api.updateCallCancelById(callDetail.id, {car: car}));
                                                }
                                                dispatch(api.updateTripCar(car));
                                                setCancelCallState(false);
                                            }
                                            }

                                            disabled={callDetail && callDetail.booking_id && bookingDataById && bookingDataById.id == callDetail.booking_id && bookingDataById.carTypeCode && bookingDataById.carTypeCode != car.code && !callDetail.isEditPickup ? true : false}
                                        />
                                    )
                                })
                            }

                        </View>

                    </View>


                    {bookingDataById && bookingDataById.customer_wish ?
                        <View style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 10
                        }}>
                            {tripdata.trip_estimate_temp ?
                                <View style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    backgroundColor: colors.BLUE.secondary,
                                    padding: 5, borderRadius: 10, width: '100%'
                                }}>
                                    <View>
                                        <Text style={{
                                            color: colors.WHITE,
                                            fontWeight: 'bold',
                                            fontSize: 12
                                        }}>{`${ConvertSecondToHmToBooking(bookingDataById.metterTime)} (${parseFloat(bookingDataById.metterDistance).toFixed(1)} Km) - ${formatPriceRound(bookingDataById.metterPrice, formatNumber)} VNĐ`}</Text>
                                        {tripdata.is_two_way ?
                                            <Text style={{
                                                fontSize: 11,
                                                color: colors.WHITE
                                            }}>{`Giới hạn thời gian : ${ConvertSecondToHmToBooking(tripdata.trip_estimate_temp[1].timeLimit)}`}</Text> : null}
                                    </View>
                                    <View style={{marginLeft: 10}}>
                                        <TouchableOpacity
                                            onPress={() => {
                                                dispatch(api.updateTwoWay(tripdata.is_two_way ? false : true))
                                            }
                                            }
                                            disabled={parseFloat(bookingDataById.metterDistance) < 10}
                                            style={{
                                                padding: 8,
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                                borderRadius: 5,
                                                backgroundColor: "#cbcbcb",
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Text style={{color: colors.BACKGROUND_DARK, fontSize: 11}}>{tripdata.is_two_way ? "Khứ hồi" : "Một chiều"}</Text>
                                        </TouchableOpacity>
                                    </View>
                                </View> : null}
                        </View>
                        :
                        <View style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 10
                        }}>
                            {tripdata.trip_estimate_temp ?
                                <View style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    backgroundColor: colors.BLUE.secondary,
                                    padding: 5, borderRadius: 10, width: '100%'
                                }}>
                                    <View>
                                        {tripdata.is_two_way ?
                                            <Text style={{
                                                color: colors.WHITE,
                                                fontWeight: 'bold',
                                                fontSize: 12
                                            }}>{`${ConvertSecondToHmToBooking(tripdata.trip_estimate_temp[3] * 2)} (${parseFloat((tripdata.trip_estimate_temp[2] * 2)).toFixed(1)} Km) - ${formatPriceRound(tripdata.trip_estimate_temp[1].totalCost, formatNumber)} VNĐ`}</Text>
                                            :
                                            <Text style={{
                                                color: colors.WHITE,
                                                fontWeight: 'bold',
                                                fontSize: 12
                                            }}>{`${ConvertSecondToHmToBooking(tripdata.trip_estimate_temp[3])} (${parseFloat(tripdata.trip_estimate_temp[2]).toFixed(1)} Km) - ${formatPriceRound(tripdata.trip_estimate_temp[0].totalCost, formatNumber)} VNĐ`}</Text>}
                                        {tripdata.is_two_way ?
                                            <Text style={{
                                                fontSize: 11,
                                                color: colors.WHITE
                                            }}>{`Giới hạn thời gian : ${ConvertSecondToHmToBooking(tripdata.trip_estimate_temp[1].timeLimit)}`}</Text> : null}
                                    </View>
                                    <View style={{marginLeft: 10}}>
                                        <TouchableOpacity
                                            onPress={() => {
                                                dispatch(api.updateTwoWay(tripdata.is_two_way ? false : true))
                                            }
                                            }
                                            style={{
                                                padding: 8,
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                                borderRadius: 5,
                                                backgroundColor: "#cbcbcb",
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Text style={{color: colors.BACKGROUND_DARK, fontSize: 11}}>{tripdata.is_two_way ? "Khứ hồi" : "Một chiều"}</Text>
                                        </TouchableOpacity>

                                        {/*<TouchableOpacity
                                            onPress={() => {
                                                let bookingUpdate = {...bookingDataById}
                                                bookingUpdate.status = "PAID";
                                                api.updateBookingApi(bookingDataById.id, bookingUpdate).then(r => {
                                                    let obj = {
                                                        at: GetCurrentMiniTimeStamp(),
                                                        by: bookingDataById.customer,
                                                        bookingId: bookingDataById.id,
                                                        type: 'GET_BOOKING',
                                                        status: 'PENDING',
                                                        from: "TT"
                                                    }

                                                    refApi.pushTriggerActionByUidApi(bookingDataById.driver, obj);
                                                });

                                            }
                                            }
                                            style={{
                                                marginTop: 5,
                                                padding: 8,
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                                borderRadius: 5,
                                                backgroundColor: "#cbcbcb",
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Text style={{color: colors.BACKGROUND_DARK, fontSize: 11}}>{"Thanh toán"}</Text>
                                        </TouchableOpacity>*/}
                                    </View>
                                </View> : null}
                        </View>}


                    <View style={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 10
                    }}>
                        <View style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderRadius: 10,
                            width: '100%',
                            backgroundColor: '#cbcbcb'
                        }}>
                            <TouchableOpacity
                                style={{
                                    padding: 10,
                                    borderRadius: 10,
                                    backgroundColor: bookingType == 'Book Now' ? "#000000" : '#cbcbcb',
                                    width: '49%',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}

                                onPress={() => {
                                    setBookingType("Book Now")
                                }
                                }
                                disabled={callDetail && callDetail.booking_id && bookingDataById && bookingDataById.id == callDetail.booking_id && !callDetail.isEditPickup ? true : false}

                            >
                                <Text style={{
                                    color: bookingType == 'Book Now' ? colors.WHITE : colors.BACKGROUND_DARK,
                                    fontSize: 12,
                                    fontWeight: 'bold'
                                }}>{"Ngay bây giờ"}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={{
                                    padding: 10,
                                    borderRadius: 10,
                                    backgroundColor: bookingType == 'Book Later' ? "#000000" : '#cbcbcb',
                                    width: '49%',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                                disabled={callDetail && callDetail.booking_id && bookingDataById && bookingDataById.id == callDetail.booking_id && !callDetail.isEditPickup ? true : false}
                                onPress={() => {
                                    setBookingType("Book Later")
                                }
                                }
                            >
                                <Text
                                    style={{color: bookingType == 'Book Later' ? colors.WHITE : colors.BACKGROUND_DARK, fontSize: 12, fontWeight: 'bold'}}>{"Đặt trước"}</Text>
                            </TouchableOpacity>
                        </View>
                        {bookingType === 'Book Later' ?
                            <View style={{flexDirection: 'row', alignItems: 'flex-end', marginTop: 10}}>
                                <View style={{flexDirection: 'column', alignItems: 'flex-end', width: '100%'}}>
                                    <TextField
                                        id="datetime-local"
                                        label={"Chọn ngày giờ đặt trước"}
                                        type="datetime-local"
                                        variant="filled"
                                        fullWidth
                                        //className={styles.commonInputStyle}
                                        InputProps={{
                                            //className: styles.input,
                                            style: {textAlignLast: 'start'},
                                            min: GetMinBookingLaterDate(settings.minuteBookingLater ? settings.minuteBookingLater : 15),
                                            max: GetMinBookingLaterDate(6000)
                                        }}
                                        value={selectedDate}
                                        onChange={onDateChange}
                                    />
                                </View>
                            </View> : null}

                    </View>
                    <View style={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 10
                    }}>
                        {callDetail && callDetail.id && !bookingStatus ?
                            <TouchableOpacity
                                onPress={async () => {
                                    setBookingStatus(true);
                                    if (bookingDataById && bookingDataById.prepareBookingNow && bookingDataById.prepareBookingNow == true) {
                                        bookingDataById.prepareBookingNow = false;
                                        if (bookingDataById.carTypeCode && bookingDataById.pickup) {
                                            let driverInOut = await api.getDriversOutInGroupV2(settings.bankinh ? settings.bankinh : 3, bookingDataById.carTypeCode, bookingDataById.pickup);
                                            if (driverInOut && driverInOut.driverOutGroup) {
                                                bookingDataById.driverOutGroup = driverInOut.driverOutGroup;
                                                bookingDataById.status = "NEWV2";
                                                dispatch(api.addBookingV2(bookingDataById, true));
                                                const callCenter = store.getState().pacallcenterdata.callCenter;
                                                const bookingActive = store.getState().bookinglistdata.booking_active;
                                                dispatch(api.resetTripData(callCenter && callCenter.id ? callCenter.id : null, bookingActive && bookingActive.id ? bookingActive.id : null));
                                            } else {
                                                alert("Hiện tại chưa tìm được lái xe phù hợp");
                                            }
                                        }
                                        setBookingStatus(false);
                                        return;
                                    }

                                    if (callDetail && bookingDataById) {
                                        if (cancelCallState) {
                                            props.openCancelCallback(callDetail);
                                        } else {
                                            if (bookingDataById.status == "REACHED" || bookingDataById.status == "PAID") {
                                                let bookingUpdate = {...bookingDataById}
                                                bookingUpdate.status = "PAID";
                                                api.updateBookingApi(bookingDataById.id, bookingUpdate).then(r => {
                                                    let obj = {
                                                        at: GetCurrentMiniTimeStamp(),
                                                        by: bookingDataById.customer,
                                                        bookingId: bookingDataById.id,
                                                        type: 'GET_BOOKING',
                                                        status: 'PENDING',
                                                        from: "TT"
                                                    }

                                                    refApi.pushTriggerActionByUidApi(bookingDataById.driver, obj);
                                                    const callCenter = store.getState().pacallcenterdata.callCenter;
                                                    const bookingActive = store.getState().bookinglistdata.booking_active;
                                                    dispatch(api.resetTripData(callCenter && callCenter.id ? callCenter.id : null, bookingActive && bookingActive.id ? bookingActive.id : null));
                                                });
                                            } else {
                                                if (bookingDataById.carTypeCode) {
                                                    if (callDetail && bookingDataById && !callDetail.isEditPickup) {
                                                        let updateCallSession = {...callDetail};
                                                        if (callDetail.id) {
                                                            dispatch(api.updateCallCancelById(callDetail.id, updateCallSession));
                                                            // Fetch CallCenter
                                                        }
                                                        const callCenter = store.getState().pacallcenterdata.callCenter;
                                                        const bookingActive = store.getState().bookinglistdata.booking_active;
                                                        dispatch(api.resetTripData(callCenter && callCenter.id ? callCenter.id : null, bookingActive && bookingActive.id ? bookingActive.id : null));
                                                    } else {
                                                        if (!bookingDataById.pickup) {
                                                            bookingDataById.pickup = tripdata.pickup;
                                                        }
                                                        let driverInOut = await api.getDriversOutInGroupV2(settings.bankinh ? settings.bankinh : 3, bookingDataById.carTypeCode, bookingDataById.pickup);
                                                        if (driverInOut && driverInOut.driverOutGroup) {
                                                            if (listUserLostConnect) {
                                                                let newDrivers = [];
                                                                for (let i = 0; i < driverInOut.driverOutGroup.length; i++) {
                                                                    let u = driverInOut.driverOutGroup[i];
                                                                    if (!listUserLostConnect.includes(u.codeDriver)) {
                                                                        newDrivers.push(u);
                                                                    }
                                                                }
                                                                bookingDataById.driverOutGroup = newDrivers;
                                                            } else {
                                                                bookingDataById.driverOutGroup = driverInOut.driverOutGroup;
                                                            }
                                                            bookingDataById.status = "NEWV2";
                                                            dispatch(api.addBookingV2(bookingDataById, true));
                                                            const callCenter = store.getState().pacallcenterdata.callCenter;
                                                            const bookingActive = store.getState().bookinglistdata.booking_active;
                                                            dispatch(api.resetTripData(callCenter && callCenter.id ? callCenter.id : null, bookingActive && bookingActive.id ? bookingActive.id : null));
                                                        } else {
                                                            alert("Hiện tại chưa tìm được lái xe phù hợp");
                                                        }
                                                    }

                                                }
                                                setBookingStatus(false);
                                                return;
                                            }
                                        }

                                    } else {
                                        if (cancelCallState) {
                                            props.openCancelCallback(callDetail);
                                            setBookingStatus(false);
                                            return;
                                        } else {
                                            if (callDetail && bookingDataById && !callDetail.isEditPickup) {
                                                let updateCallSession = {...callDetail};
                                                if (callDetail.id) {
                                                    dispatch(api.updateCallCancelById(callDetail.id, updateCallSession));
                                                    // Fetch CallCenter
                                                }
                                                const callCenter = store.getState().pacallcenterdata.callCenter;
                                                const bookingActive = store.getState().bookinglistdata.booking_active;
                                                dispatch(api.resetTripData(callCenter && callCenter.id ? callCenter.id : null, bookingActive && bookingActive.id ? bookingActive.id : null));
                                            } else {
                                                await loadBookingObj(true);
                                                setBookingStatus(false);
                                                return;
                                            }
                                        }
                                    }
                                }
                                }
                                disabled={bookingStatus || (!cancelCallState ? callDetailState && callDetailState.status === "booked" && (bookingDataById && !bookingDataById.customer_wish || !bookingDataById) : false)}
                                style={{
                                    padding: 10,
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                    width: '100%',
                                    borderRadius: 10,
                                    backgroundColor: "#000000",
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                {bookingDataById && bookingDataById.customer_wish && (bookingDataById.status == "REACHED" || bookingDataById.status == "PAID") ?
                                    <Text style={{color: colors.WHITE, fontSize: 12, fontWeight: 'bold'}}>{"THANH TOÁN"}</Text>
                                    :
                                    callDetailState && callDetailState.status === "booked" ?
                                        bookingDataById && !bookingDataById.customer_wish ?
                                            <Text style={{color: colors.WHITE, fontSize: 12, fontWeight: 'bold'}}>{"Huỷ"}</Text> :
                                            <Box sx={{display: 'flex'}}>
                                                <CircularProgress/>
                                            </Box>
                                        :
                                        <Text style={{color: colors.WHITE, fontSize: 12, fontWeight: 'bold'}}>{cancelCallState ? "Huỷ cuộc gọi" : callDetail && bookingDataById && !callDetail.isEditPickup ? "Đóng" : (bookingType === 'Book Later' ? (callDetail.booking_id && bookingDataById && bookingDataById.id == callDetail.booking_id ? "ĐẶT LẠI" : "ĐẶT TRƯỚC") : "ĐẶT NGAY")}</Text>
                                }
                            </TouchableOpacity> :

                            <TouchableOpacity
                                onPress={() => {
                                    setLoadingCall(true);
                                    refApi.pushPaCallCenterApi(callDetail).then((res) => {
                                        if (res.key) {
                                            let callId = res.key;
                                            let updateCallData = {...callDetail};
                                            updateCallData.id = callId;
                                            updateCallData.firebase_id = callId;
                                            dispatch({
                                                type: CALL_DETAIL_DATA, payload: updateCallData
                                            });
                                            // Fetch CallCenter
                                            dispatch(api.fetchCallActive(callId));
                                            setLoadingCall(false);
                                        }
                                    }).catch(e => {
                                        alert("Lỗi khởi tạo cuộc gọi");
                                    });
                                }
                                }
                                disabled={!callDetail.source || !callDetail.note || !callDetail.pickup || editPhone}
                                style={{
                                    padding: 10,
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                    width: '100%',
                                    borderRadius: 10,
                                    backgroundColor: "#000000",
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                {loadingCall ?
                                    <Box sx={{display: 'flex'}}>
                                        <CircularProgress/>
                                    </Box>
                                    :
                                    <Text style={{color: colors.WHITE, fontSize: 12, fontWeight: 'bold'}}>{"Xác nhận"}</Text>
                                }
                            </TouchableOpacity>
                        }

                    </View>
                </View>
            </View> :
            null
    )
        ;
}