import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {ConvertTimeStampTimeForPaShort} from "common/src/other/DateFunctions";

export default function BookingLaterSnackbarComponent(props) {
    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={() => {
                if (props.booking) {
                    props.callbackSnackbar(props.booking);
                }
            }
            }>
                Đặt trước
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="error"
                onClick={props.closeCalbackSnackbar}
            >
                <CloseIcon fontSize="small"/>
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>
            <Snackbar
                key={props.booking ? props.booking.id : ''}
                open={props.open}
                autoHideDuration={6000}
                onClose={props.closeCalbackSnackbar}
                message={props.booking ? props.booking.customer_note + " " + ConvertTimeStampTimeForPaShort(props.booking.bookingDate) : ''}
                action={action}
            />
        </div>
    );
}
