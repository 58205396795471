import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Marker} from "react-google-maps";
import * as geolib from "geolib";

export default function AvailableCarQueue(props) {
    const drivers = useSelector(state => state.usersdata.drivers);
    const [markers, setMarkers] = useState([]);
    useEffect(() => {
        if (props.carType && drivers) {
            setMarkers(drivers.filter(d => d && d.location && d.location.lat && !d.queue && d.driverActiveStatus && !d.lock_driver_status && d.carTypeCode && d.carTypeCode == props.carType.code))
        }
    }, [props, drivers]);
    return (
        markers && markers.map(marker => {
            if (marker) {
                let resultPickup = geolib.isPointInPolygon({
                    latitude: marker.location.lat,
                    longitude: marker.location.lng
                }, props.groupApply.areas[0]);
                if (resultPickup) {
                    return (
                        <Marker
                            position={{lat: marker.location.lat, lng: marker.location.lng}}
                            key={marker.id}
                            options={
                                {
                                    icon: require(`../../assets/img/display_` + marker.carStatus),
                                    size: {width: 32, height: 32},
                                }
                            }
                            onClick={() => {
                                //props.loadCarInfo(marker);
                            }}
                            label={
                                {
                                    color: '#ffffff',
                                    fontWeight: 'bold',
                                    fontSize: '12px',
                                    text: marker.codeDriver,
                                    padding: 10,
                                }
                            }
                        >
                        </Marker>
                    )
                }

            }

        })
    );
}
