import {EDIT_BOOKING_NOTI_DETAIL, FETCH_BOOKING_NOITI_DETAIL_FAILED, FETCH_BOOKING_NOTI_DETAIL, FETCH_BOOKING_NOTI_DETAIL_SUCCESS} from "../store/types";
import {firebase} from '../config/configureFirebase';
import {equalTo, limitToLast, limitToFirst, off, onValue, orderByChild, push, query, set} from "firebase/database";
import {GetCurrentTimeStamp} from "../other/DateFunctions";

export const fetchBookingNotiOn = () => (dispatch) => {
    const {
        bookingNotiRef
    } = firebase;

    dispatch({
        type: FETCH_BOOKING_NOTI_DETAIL, notis: null
    });
    off(bookingNotiRef());
    onValue(query(bookingNotiRef(), orderByChild("to"), equalTo("TT"), limitToFirst(100)), snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const notis = Object.keys(data)
                .map((i) => {
                    data[i].id = i;
                    return data[i];
                });
            dispatch({
                type: FETCH_BOOKING_NOTI_DETAIL_SUCCESS, notis: notis
            });
        } else {
            dispatch({
                type: FETCH_BOOKING_NOITI_DETAIL_FAILED, notis: null
            });
        }
    });
}

export const stopFetchBookingOn = () => (dispatch) => {
    const {
        bookingNotiRef
    } = firebase;
    off(bookingNotiRef());
    dispatch({
        type: FETCH_BOOKING_NOITI_DETAIL_FAILED, notis: null
    });

}

export const editNoti = (data, method) => {
    const {
        bookingNotiIdRef
    } = firebase;
    if (method === 'Add') {
        let dataAdd = {...data};
        dataAdd.date_add = GetCurrentTimeStamp();
        dataAdd.status = "pending";
        push(bookingNotiIdRef, dataAdd);
    } else if (method === 'Update') {
        if (data.id) {
            set(bookingNotiIdRef(data.id), {...data, processDate: new Date().getTime()});
        }
    }
}