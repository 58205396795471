import {FETCH_POINT, FETCH_POINT_FAILED, FETCH_POINT_SUCCESS, INIT_POINT, INIT_POINT_FAILED, INIT_POINT_SUCCESS, PUSH_POINT, PUSH_POINT_FAILED, PUSH_POINT_SUCCESS} from "../store/types";
import {GetCurrentTimeStamp} from "../other/DateFunctions";
import {validPhoneVN} from "../other/CommonFunctions";
import {RequestPushMsg} from "../other/NotificationFunctions";
import store from "../store/store";
import {language} from "config";
import {firebase} from '../config/configureFirebase';
import {off, onValue, push, set} from "firebase/database";

export const fetchPoints = (uid) => (dispatch) => {
    const {
        pointsRef
    } = firebase;

    dispatch({
        type: FETCH_POINT,
        payload: null,
    });
    off(pointsRef(uid));
    onValue(pointsRef(uid), (snapshot) => {
        if (snapshot.val()) {
            let data = snapshot.val();
            dispatch({
                type: FETCH_POINT_SUCCESS,
                payload: {
                    point: data
                }
            });
        } else {
            dispatch({
                type: FETCH_POINT_FAILED,
                payload: "Không tìm thấy dữ liệu điểm ETO",
            });
        }
    });
};

export const initPoint = (uid, data) => (dispatch) => {
    const {
        pointsRef,
        pointHistoryRef
    } = firebase;

    dispatch({
        type: INIT_POINT,
        payload: null,
    });
    let dataObj = {};
    if (data.uid) {
        dataObj.uid = data.uid;
    }
    if (data.bookingId) {
        dataObj.bookingId = data.bookingId;
    }
    if (data.areaId) {
        dataObj.areaId = data.areaId;
    }
    if (data.referralId) {
        dataObj.referralId = data.referralId;
    }

    let history = {
        point: data.point ? parseInt(data.point) : 1,
        date_add: GetCurrentTimeStamp(),
        status: data.status ? data.status : 'pending', // pending, success, failed
        type: data.type ? data.type : 'share_ck', // share_ck, share_app, buy, sell
        txRef: "point_" + GetCurrentTimeStamp(),
        data: dataObj
    };

    let dataAdd = {};
    dataAdd.points = data.point ? parseInt(data.point) : 1;
    dataAdd.status = 'active';

    set(pointsRef(uid), dataAdd)
        .then(() => {
            push(pointHistoryRef(uid), history);
            dispatch({
                type: INIT_POINT_SUCCESS,
                payload: {
                    point: dataAdd
                }
            });
        })
        .catch(err => {
            dispatch({
                type: INIT_POINT_FAILED,
                payload: {
                    point: dataAdd
                }
            });
        });
};


export const pushPoint = (uid, historyData, driverCode = null) => (dispatch) => {
    const {
        pointHistoryRef,
        pointsRef
    } = firebase;

    dispatch({
        type: PUSH_POINT,
        payload: null,
    });
    let userInfo = store.getState().auth ? store.getState().auth.info : null;

    let txRef = "point_" + GetCurrentTimeStamp();

    if (driverCode) {
        if (historyData.type == 'sell') {
            let driverActive = store.getState().usersdata.drivers;
            var drivers = null;
            var driver = null;
            if (validPhoneVN(driverCode)) {
                drivers = driverActive.filter((d) => d.mobile.trim() === '+84' + driverCode.trim());
            } else {
                drivers = driverActive.filter((d) => d.codeDriver.trim() === driverCode.trim());
            }

            onValue(pointsRef(uid), (snapshot) => {
                if (snapshot.val()) {
                    let dataPoint = snapshot.val();
                    let oldPoint = parseInt(dataPoint.points);
                    if (historyData.point) {
                        if (parseInt(historyData.point) > oldPoint) {
                            dispatch({
                                type: PUSH_POINT_FAILED,
                                payload: {
                                    point: "Bạn không đủ điểm để thực hiện!"
                                }
                            });
                        } else {
                            oldPoint = oldPoint - parseInt(historyData.point);
                            if (historyData.status && historyData.status === 'success') {
                                console.log('is Update')
                                set(pointsRef(uid + '/points'), oldPoint);
                            }

                            // push history
                            let dataObj = {};
                            if (historyData.uid) {
                                dataObj.uid = historyData.uid;
                            }
                            if (historyData.bookingId) {
                                dataObj.bookingId = historyData.bookingId;
                            }
                            if (historyData.areaId) {
                                dataObj.areaId = historyData.areaId;
                            }
                            if (historyData.referralId) {
                                dataObj.referralId = historyData.referralId;
                            }
                            driver = drivers ? drivers[0] : null;
                            let history = {
                                point: historyData.point ? parseInt(historyData.point) : 1,
                                date_add: GetCurrentTimeStamp(),
                                status: historyData.status ? historyData.status : 'success', // pending, success, fail
                                type: 'sell', // share_ck, share_app, buy, sell
                                toDriverCode: driverCode.trim(),
                                toUid: driver && driver.id ? driver.id : null,
                                fromUid: uid,
                                fromCode: historyData.fromDriver ? historyData.fromDriver : null,
                                note: historyData.note,
                                txRef: txRef,
                                data: dataObj
                            };

                            push(pointHistoryRef(uid), history)
                                .then(() => {
                                    if (historyData.status === 'success') {
                                        if (driver && driver.id) {
                                            onValue(pointsRef(driver.id), (snapshot) => {
                                                if (snapshot.val()) {
                                                    let dataPoint = snapshot.val();
                                                    let oldPoint = parseInt(dataPoint.points);
                                                    if (historyData.point) {
                                                        oldPoint = oldPoint + parseInt(historyData.point);
                                                        if (historyData.status && historyData.status === 'success') {
                                                            set(pointsRef(driver.id + '/points'), oldPoint);
                                                        }
                                                        // push history
                                                        let history = {
                                                            point: historyData.point ? parseInt(historyData.point) : 1,
                                                            date_add: GetCurrentTimeStamp(),
                                                            status: historyData.status ? historyData.status : 'success', // pending, success, fail
                                                            type: 'buy', // share_ck, share_app, buy, sell
                                                            toDriverCode: driverCode.trim(),
                                                            toUid: driver && driver.id ? driver.id : null,
                                                            fromUid: uid,
                                                            fromCode: historyData.fromDriver ? historyData.fromDriver : null,
                                                            note: historyData.note,
                                                            txRef: txRef,
                                                            data: {
                                                                uid: driver.id
                                                            }
                                                        };
                                                        push(pointHistoryRef(driver.id), history);
                                                    }
                                                } else {
                                                    let dataAdd = {};
                                                    dataAdd.points = historyData.point ? parseInt(historyData.point) : 1;
                                                    dataAdd.status = 'active';
                                                    set(pointsRef(driver.id), dataAdd)
                                                        .then(() => {
                                                            let history = {
                                                                point: historyData.point ? parseInt(historyData.point) : 1,
                                                                date_add: GetCurrentTimeStamp(),
                                                                status: historyData.status ? historyData.status : 'success', // pending, success, fail
                                                                type: 'buy', // share_ck, share_app, buy, sell
                                                                fromDriver: historyData.fromDriver,
                                                                note: historyData.note,
                                                                txRef: txRef,
                                                                data: {
                                                                    uid: driver.id
                                                                }
                                                            };
                                                            push(pointHistoryRef(driver.id), history);
                                                        })
                                                        .catch(err => {
                                                            console.log(err);
                                                        });

                                                }
                                            }, {onlyOnce: true});
                                        }
                                    }
                                    if (userInfo && userInfo.profile && userInfo.profile.pushToken) {
                                        RequestPushMsg(userInfo.profile.pushToken, language.notification_title, "Số điểm ETO đã được cập nhật");
                                    }

                                    dispatch({
                                        type: PUSH_POINT_SUCCESS,
                                        payload: {
                                            point: history
                                        }
                                    });
                                })
                                .catch(err => {
                                    console.log(err);
                                });
                        }
                    } else {
                        dispatch({
                            type: PUSH_POINT_FAILED,
                            payload: {
                                point: "Không xác định dữ liệu điểm ETO"
                            }
                        });
                    }
                } else {
                    dispatch({
                        type: PUSH_POINT_FAILED,
                        payload: {
                            point: "Không tìm thấy dữ liệu điểm ETO"
                        }
                    });
                }
            }, {onlyOnce: true});
        } else {
            onValue(pointsRef(uid), (snapshot) => {
                if (snapshot.val()) {
                    let dataPoint = snapshot.val();
                    let oldPoint = parseInt(dataPoint.points);
                    if (historyData.point) {
                        if (historyData.type === 'buy' || historyData.type === 'share_app' || historyData.type === 'share_ck') {
                            let oldPointUpdate = oldPoint + parseInt(historyData.point);
                            set(pointsRef(uid + '/points'), oldPointUpdate);
                            // push history
                            let dataObj = {};
                            if (historyData.uid) {
                                dataObj.uid = historyData.uid;
                            }
                            if (historyData.bookingId) {
                                dataObj.bookingId = historyData.bookingId;
                            }
                            if (historyData.areaId) {
                                dataObj.areaId = historyData.areaId;
                            }
                            if (historyData.referralId) {
                                dataObj.referralId = historyData.referralId;
                            }

                            let history = {
                                point: historyData.point ? parseInt(historyData.point) : 1,
                                date_add: GetCurrentTimeStamp(),
                                status: historyData.status ? historyData.status : 'pending',  // pending, active, cancel
                                type: historyData.type ? historyData.type : 'share_ck', // share_ck, share_app, buy, sell
                                txRef: "point_" + GetCurrentTimeStamp(),
                                data: dataObj
                            };

                            push(pointHistoryRef(uid), history)
                                .then(() => {
                                    if (userInfo && userInfo.profile && userInfo.profile.pushToken) {
                                        RequestPushMsg(userInfo.profile.pushToken, language.notification_title, "Số điểm ETO đã được cập nhật");
                                    }
                                    dispatch({
                                        type: PUSH_POINT_SUCCESS,
                                        payload: {
                                            point: history
                                        }
                                    });
                                })
                                .catch(err => {
                                    console.log(err);
                                });
                        } else {
                            if (parseInt(historyData.point) > oldPoint) {
                                dispatch({
                                    type: PUSH_POINT_FAILED,
                                    payload: {
                                        point: "Bạn không đủ điểm để thực hiện!"
                                    }
                                });
                            } else {
                                oldPoint = oldPoint - parseInt(historyData.point);
                                if (historyData.status && historyData.status === 'success') {
                                    set(pointsRef(uid + '/points'), oldPoint);
                                }

                                // push history
                                let dataObj = {};
                                if (historyData.uid) {
                                    dataObj.uid = historyData.uid;
                                }
                                if (historyData.bookingId) {
                                    dataObj.bookingId = historyData.bookingId;
                                }
                                if (historyData.areaId) {
                                    dataObj.areaId = historyData.areaId;
                                }
                                if (historyData.referralId) {
                                    dataObj.referralId = historyData.referralId;
                                }

                                let history = {
                                    point: historyData.point ? parseInt(historyData.point) : 1,
                                    date_add: GetCurrentTimeStamp(),
                                    status: historyData.status ? historyData.status : 'pending',  // pending, active, cancel
                                    type: historyData.type ? historyData.type : 'share_ck', // share_ck, share_app, buy, sell
                                    txRef: "point_" + GetCurrentTimeStamp(),
                                    data: dataObj
                                };

                                push(pointHistoryRef(uid), history)
                                    .then(() => {
                                        if (userInfo && userInfo.profile && userInfo.profile.pushToken) {
                                            RequestPushMsg(userInfo.profile.pushToken, language.notification_title, "Số điểm ETO đã được cập nhật");
                                        }
                                        dispatch({
                                            type: PUSH_POINT_SUCCESS,
                                            payload: {
                                                point: history
                                            }
                                        });
                                    })
                                    .catch(err => {
                                        console.log(err);
                                    });
                            }

                        }
                    } else {
                        dispatch({
                            type: PUSH_POINT_FAILED,
                            payload: {
                                point: "Không xác định dữ liệu điểm ETO"
                            }
                        });
                    }
                } else {
                    dispatch({
                        type: PUSH_POINT_FAILED,
                        payload: {
                            point: "Không tìm thấy dữ liệu điểm ETO"
                        }
                    });
                }
            }, {onlyOnce: true});
        }
    } else {
        onValue(pointsRef(uid), (snapshot) => {
            if (snapshot.val()) {
                let dataPoint = snapshot.val();
                let oldPoint = parseInt(dataPoint.points);
                if (historyData.point) {
                    if (historyData.type === 'buy' || historyData.type === 'share_app' || historyData.type === 'share_ck') {
                        oldPoint = oldPoint + parseInt(historyData.point);
                        if (historyData.status && historyData.status === 'success') {
                            set(pointsRef(uid + '/points'), oldPoint);
                        }

                        // push history
                        let dataObj = {};
                        if (historyData.uid) {
                            dataObj.uid = historyData.uid;
                        }
                        if (historyData.bookingId) {
                            dataObj.bookingId = historyData.bookingId;
                        }
                        if (historyData.areaId) {
                            dataObj.areaId = historyData.areaId;
                        }
                        if (historyData.referralId) {
                            dataObj.referralId = historyData.referralId;
                        }

                        let history = {
                            point: historyData.point ? parseInt(historyData.point) : 1,
                            date_add: GetCurrentTimeStamp(),
                            status: historyData.status ? historyData.status : 'pending',  // pending, active, cancel
                            type: historyData.type ? historyData.type : 'share_ck', // share_ck, share_app, buy, sell
                            txRef: "point_" + GetCurrentTimeStamp(),
                            data: dataObj
                        };

                        push(pointHistoryRef(uid), history)
                            .then(() => {
                                if (userInfo && userInfo.profile && userInfo.profile.pushToken) {
                                    RequestPushMsg(userInfo.profile.pushToken, language.notification_title, "Số điểm ETO đã được cập nhật");
                                }
                                dispatch({
                                    type: PUSH_POINT_SUCCESS,
                                    payload: {
                                        point: history
                                    }
                                });
                            })
                            .catch(err => {
                                console.log(err);
                            });
                    } else {
                        if (parseInt(historyData.point) > oldPoint) {
                            dispatch({
                                type: PUSH_POINT_FAILED,
                                payload: {
                                    point: "Bạn không đủ điểm để thực hiện!"
                                }
                            });
                        } else {
                            oldPoint = oldPoint - parseInt(historyData.point);
                            if (historyData.status && historyData.status === 'success') {
                                set(pointsRef(uid + '/points'), oldPoint);
                            }

                            // push history
                            let dataObj = {};
                            if (historyData.uid) {
                                dataObj.uid = historyData.uid;
                            }
                            if (historyData.bookingId) {
                                dataObj.bookingId = historyData.bookingId;
                            }
                            if (historyData.areaId) {
                                dataObj.areaId = historyData.areaId;
                            }
                            if (historyData.referralId) {
                                dataObj.referralId = historyData.referralId;
                            }

                            let history = {
                                point: historyData.point ? parseInt(historyData.point) : 1,
                                date_add: GetCurrentTimeStamp(),
                                status: historyData.status ? historyData.status : 'pending',  // pending, active, cancel
                                type: historyData.type ? historyData.type : 'share_ck', // share_ck, share_app, buy, sell
                                txRef: "point_" + GetCurrentTimeStamp(),
                                data: dataObj
                            };

                            push(pointHistoryRef(uid), history)
                                .then(() => {
                                    if (userInfo && userInfo.profile && userInfo.profile.pushToken) {
                                        RequestPushMsg(userInfo.profile.pushToken, language.notification_title, "Số điểm ETO đã được cập nhật");
                                    }
                                    dispatch({
                                        type: PUSH_POINT_SUCCESS,
                                        payload: {
                                            point: history
                                        }
                                    });
                                })
                                .catch(err => {
                                    console.log(err);
                                });
                        }
                    }
                } else {
                    dispatch({
                        type: PUSH_POINT_FAILED,
                        payload: {
                            point: "Không xác định dữ liệu điểm ETO"
                        }
                    });
                }
            } else {
                dispatch({
                    type: PUSH_POINT_FAILED,
                    payload: {
                        point: "Không tìm thấy dữ liệu điểm ETO"
                    }
                });
            }
        }, {onlyOnce: true});
    }

};


