import React, {useContext, useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {useDispatch, useSelector} from "react-redux";
import {features, language} from 'config';
import {api, FirebaseContext} from 'common';
import {Button} from "react-native-web";
import {makeStyles} from "@material-ui/core/styles";
import CircularLoading from "../components/CircularLoading";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {getDaysInMonth} from "common/src/other/CommonFunctions";
import {GetCurrentMiniTimeStamp} from "common/src/other/DateFunctions";

const useStyles = makeStyles(theme => ({
    titleList: {
        fontSize: 14,
        marginTop: 10
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
        height: 39,
    },
    backgroundRed: {
        backgroundColor: "rgba(255,179,179,0.74)",
        flexDirection: 'column'
    },
    row: {
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: "rgba(255,0,0,0.74)"
    },
    inputdimmed: {
        fontSize: 18,
        color: "#737373"
    },
    input: {
        fontSize: 18,
        color: "#000"
    },
    carphoto: {
        height: '18px',
        marginRight: '10px'
    },
}));
export default function CaTrucDemConfig() {
    const classes = useStyles();
    const {
        editCaTrucDemConfig,
        fetchCaTrucDemConfig,
        stopFetchCaTrucDemConfig,
        setCaTrucDem,
        fetchCaTrucDem,
        stopFetchCaTrucDem,
    } = api;
    const styles = useStyles();
    const columns = [
        {title: "Biển số", field: 'bs'},
        {title: "MSNV", field: 'msd'},
        {title: "Họ Tên", field: 'hvt'},
        {title: "Kiểu LX", field: 'type', lookup: {'CP Đóng đàm': "CP Đóng đàm", 'Công Ty': "Công Ty", 'Đóng đàm': "Đóng đàm"}},
        {title: "Không trực", field: 'is_out', type: 'boolean', initialEditValue: false}
    ];
    const [data, setData] = useState([]);
    const catrucdemsconfig = useSelector(state => state.catrucdemsconfig);
    const dispatch = useDispatch();
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);
    const [listDriver, setListDriver] = useState(null);
    const [totalDriver, setTotalDriver] = useState(null);
    const [totalCtyAdd, setTotalCtyAdd] = useState(null);
    const [resultC, setResultC] = useState(null);
    const auth = useSelector(state => state.auth);
    const catrucdems = useSelector(state => state.catrucdems);

    const columnsResult = [
        {title: "Ngày", field: 'date'},
        {title: "Danh sách", field: 'list'}
    ];

    useEffect(() => {
        dispatch(fetchCaTrucDemConfig());
        dispatch(fetchCaTrucDem());
        return () => {
            dispatch(stopFetchCaTrucDemConfig());
            dispatch(stopFetchCaTrucDem());
        }
    }, []);

    useEffect(() => {
        if (catrucdemsconfig && catrucdemsconfig.catrucdems_config) {
            let dataTemp = Object.values(catrucdemsconfig.catrucdems_config);
            setData(dataTemp);

            let dataK = dataTemp.filter((d) => d.is_out);
            let listDriverTemp = [];
            for (let i = 0; i < dataK.length; i++) {
                listDriverTemp.push(dataK[i].msd.toString());
            }
            setListDriver(listDriverTemp.join(','));
        } else {
            setData([]);
        }
    }, [catrucdemsconfig]);

    const generateDriver = (monthI, yearI, totalCarInI, totalCtyI, listReject) => {
        let month = parseInt(monthI);
        let year = parseInt(yearI);
        let totalCarIn = parseInt(totalCarInI);
        var totalCty = parseInt(totalCtyI);

        var listDriver = data;
        var listCarD = {};
        var listCarCty = {};
        var listCarHanChe = {};

        var listCarDUpdate = {};
        var listCarCtyUpdate = {};
        var listCarGhepUpdate = {};
        var listCarHanCheUpdate = {};

        var listCar = [];
        var resultDate = {};
        var resultDateResult = {};


        // Check % thang trc
        let month_check = (parseInt(month) - 1).toString() + "_" + year;
        let listCd = catrucdems.catrucdems ? Object.values(catrucdems.catrucdems) : [];
        let listCd2 = catrucdems.catrucdems ? listCd.filter((c) => c.id && c.id.includes(month_check)) : [];

        let listDriver70Break = [];
        let listDriver50Break = [];
        let listDriverChanged = {};
        if (listCd2.length > 0) {
            for (let i = 0; i < listCd2.length; i++) {
                let list1 = listCd2[i].prime_driver.split(',');
                let list2 = listCd2[i].second_driver.split(',');
                //let histories = Object.values(listCd2[i].histories);
                for (let j = 0; j < list1.length; j++) {
                    let driverCode1 = list1[j];
                    if (driverCode1 == "29" || driverCode1 == "79") {
                        continue;
                    }
                    if (listDriverChanged[driverCode1]) {
                        listDriverChanged[driverCode1].total_work = listDriverChanged[driverCode1].total_work + 1;
                        if (!list2.includes(driverCode1)) {
                            if (listDriverChanged[driverCode1]) {
                                listDriverChanged[driverCode1].total_change = listDriverChanged[driverCode1].total_change + 1;
                                if (listCd2[i].id) {
                                    listDriverChanged[driverCode1].date_change = listDriverChanged[driverCode1].date_change + ', ' + listCd2[i].id;
                                }
                            }
                        }

                    } else {
                        listDriverChanged[driverCode1] = {total_work: 1, total_change: 0, date_change: ''};
                        if (!list2.includes(driverCode1)) {
                            if (listDriverChanged[driverCode1]) {
                                listDriverChanged[driverCode1].total_change = listDriverChanged[driverCode1].total_change + 1;
                                if (listCd2[i].id) {
                                    listDriverChanged[driverCode1].date_change = listDriverChanged[driverCode1].date_change + ', ' + listCd2[i].id;
                                }
                            }
                        }
                    }
                }
            }
            if (listDriverChanged) {
                let listDriverChangedArray = Object.keys(listDriverChanged);
                for (let i = 0; i < listDriverChangedArray.length; i++) {
                    continue;
                    if (listDriverChanged[listDriverChangedArray[i]]) {
                        let per = listDriverChanged[listDriverChangedArray[i]].total_change * 100 / listDriverChanged[listDriverChangedArray[i]].total_work;
                        listDriverChanged[listDriverChangedArray[i]].per = per;
                        if (per >= 70) {
                            listDriver70Break.push(listDriverChangedArray[i]);
                        } else {
                            if (per >= 50) {
                                listDriver50Break.push(listDriverChangedArray[i]);
                            }
                        }
                    }
                }
            }
        }
        // Check % thang trc Ket Thuc
        var listCarGhepCheck = {};
        if (listDriver) {
            let listDriverArray = Object.values(listDriver);
            for (let i = 0; i < listDriverArray.length; i++) {
                let driver = listDriverArray[i];

                if (listCarGhepCheck && listCarGhepCheck[driver.bs] && Array.isArray(listCarGhepCheck[driver.bs])) {
                    listCarGhepCheck[driver.bs].push(driver);
                } else {
                    listCarGhepCheck[driver.bs] = [driver];
                }

                if (listReject.includes(driver.msd.toString())) {
                    continue;
                }
                if (listDriver70Break.includes(driver.msd.toString())) {
                    continue;
                }

                if (listDriver50Break.includes(driver.msd.toString())) {
                    if (listCarHanChe && listCarHanChe[driver.bs] && Array.isArray(listCarHanChe[driver.bs])) {
                        listCarHanChe[driver.bs].push(driver);
                    } else {
                        listCarHanChe[driver.bs] = [driver];
                    }
                } else {
                    if (driver.type == 'Công Ty' || driver.type == 'CP Đóng đàm') {
                        if (listCarCty && listCarCty[driver.bs] && Array.isArray(listCarCty[driver.bs])) {
                            listCarCty[driver.bs].push(driver);
                        } else {
                            listCarCty[driver.bs] = [driver];
                        }
                    } else {
                        if (listCarD && listCarD[driver.bs] && Array.isArray(listCarD[driver.bs])) {
                            listCarD[driver.bs].push(driver);
                        } else {
                            listCarD[driver.bs] = [driver];
                        }
                    }
                }
            }
        }

        console.log("listDriver70Break", listDriver70Break);
        console.log("listReject", listReject);
        console.log("listDriver50Break", listDriver50Break);

        let listCarGhepCheckArray = Object.values(listCarGhepCheck);
        for (let i = 0; i < listCarGhepCheckArray.length; i++) {
            let carDetail = listCarGhepCheckArray[i];
            if (carDetail.length > 1) {
                let totalMem = carDetail.length;
                do {
                    carDetail = carDetail.concat(carDetail);
                    totalMem = carDetail.length;
                } while (totalMem <= 31);
                listCarGhepUpdate[carDetail[0].bs] = carDetail;
            }
        }

        let listCarHanCheArray = Object.values(listCarHanChe);
        for (let i = 0; i < listCarHanCheArray.length; i++) {
            let carDetail = listCarHanCheArray[i];
            if (carDetail.length > 1) {
                /*let totalMem = carDetail.length;
                do {
                    carDetail = carDetail.concat(carDetail);
                    totalMem = carDetail.length;
                } while (totalMem <= 31);
                listCarGhepUpdate[carDetail[0].bs] = carDetail;*/
            } else {
                listCarHanCheUpdate[carDetail[0].bs] = carDetail;
            }
        }

        let listCarCtyArray = Object.values(listCarCty);
        for (let i = 0; i < listCarCtyArray.length; i++) {
            let carDetail = listCarCtyArray[i];
            if (carDetail.length > 1) {
                /*let totalMem = carDetail.length;
                do {
                    carDetail = carDetail.concat(carDetail);
                    totalMem = carDetail.length;
                } while (totalMem <= 31);
                listCarGhepUpdate[carDetail[0].bs] = carDetail;*/
            } else {
                if (listCarGhepCheck && listCarGhepCheck[carDetail[0].bs] && listCarGhepCheck[carDetail[0].bs].length > 1) {
                } else {
                    listCarCtyUpdate[carDetail[0].bs] = carDetail;
                }
            }
        }

        let listCarDArray = Object.values(listCarD);
        for (let i = 0; i < listCarDArray.length; i++) {
            let carDetail = listCarDArray[i];
            if (carDetail.length > 1) {
                /*let totalMem = carDetail.length;
                do {
                    carDetail = carDetail.concat(carDetail);
                    totalMem = carDetail.length;
                } while (totalMem <= 31);
                listCarGhepUpdate[carDetail[0].bs] = carDetail;*/
            } else {
                listCarDUpdate[carDetail[0].bs] = carDetail;
            }
        }

        var listCarCtyX30 = [];
        var listCarDX30 = [];
        var listCarHanCheX30 = [];
        var listCarGhep30 = Object.values(listCarGhepUpdate);

        let listDate = getDaysInMonth(month, year);
        for (let i = 0; i < listDate.length; i++) {
            listCarDX30 = listCarDX30.concat(Object.values(listCarDUpdate));
            listCarCtyX30 = listCarCtyX30.concat(Object.values(listCarCtyUpdate));
            listCarHanCheX30 = listCarHanCheX30.concat(Object.values(listCarHanCheUpdate));
        }

        var listCtyWorkingIndex = 0;
        var listDWorkingIndex = 0;
        var listHanCheWorkingIndex = 0;

        let totalCarInWithoutGhep = totalCarIn - listCarGhep30.length;
        let totalCarCty = totalCty;
        let totalCarDD = totalCarInWithoutGhep - totalCty;

        for (let j = 0; j < listDate.length; j++) {
            var newTotalD = totalCarDD;
            var newTotalCty = totalCarCty;
            let date = listDate[j];
            let dateKey = date.toLocaleDateString("vi-VN").toString().replaceAll('/', '-');

            let listXeGhepReUpdate = [];
            for (let k = 0; k < listCarGhep30.length; k++) {
                //let listDriver70Break = [];
                //let listDriver50Break = [];
                var hasContinue = false;
                if (listDriver70Break && listDriver70Break.includes(listCarGhep30[k][j].msd.toString())) {
                    if (newTotalD >= newTotalCty) {
                        newTotalCty = newTotalCty + 1;
                    } else {
                        newTotalD = newTotalD + 1;
                    }
                    hasContinue = true;
                }
                if (listDriver50Break && listDriver50Break.includes(listCarGhep30[k][j].msd.toString())) {
                    if (newTotalD >= newTotalCty) {
                        newTotalCty = newTotalCty + 1;
                    } else {
                        newTotalD = newTotalD + 1;
                    }
                    hasContinue = true;
                }
                if (hasContinue) {
                    continue;
                }
                //06,91,394,905
                /*if (listCarGhep30[k][j].msd.toString() == "13") {
                    listXeGhepReUpdate.push('394');
                } else {
                    if (listCarGhep30[k][j].msd.toString() == "394") {
                        listXeGhepReUpdate.push('13');
                    } else {
                        listXeGhepReUpdate.push(listCarGhep30[k][j].msd.toString());
                    }
                }*/
                listXeGhepReUpdate.push(listCarGhep30[k][j].msd.toString());
                /*if (listCarGhep30[k][j].msd.toString() == "06") {
                    listXeGhepReUpdate.push('95');
                } else {
                    if (listCarGhep30[k][j].msd.toString() == "95") {
                        listXeGhepReUpdate.push('06');
                    }else{
                        if (listCarGhep30[k][j].msd.toString() == "394") {
                            listXeGhepReUpdate.push('13');
                        } else {
                            if (listCarGhep30[k][j].msd.toString() == "13") {
                                listXeGhepReUpdate.push('394');
                            }else{
                                if (listCarGhep30[k][j].msd.toString() == "905") {
                                    listXeGhepReUpdate.push('911');
                                } else {
                                    if (listCarGhep30[k][j].msd.toString() == "911") {
                                        listXeGhepReUpdate.push('905');
                                    }else{
                                        if (listCarGhep30[k][j].msd.toString() == "29") {
                                            listXeGhepReUpdate.push('91');
                                        } else {
                                            if (listCarGhep30[k][j].msd.toString() == "91") {
                                                listXeGhepReUpdate.push('29');
                                            }else{
                                                listXeGhepReUpdate.push(listCarGhep30[k][j].msd.toString());
                                            }
                                        }
                                    }

                                }
                            }

                        }
                    }
                }*/
            }


            let listHanCheWorkingReUpdate = [];
            /*if (j % 7 == 0 && listDriver50Break) {
                let totalCarHanChe = listDriver50Break.length;
                let nestD = Math.ceil(totalCarHanChe / 2);
                if (newTotalD >= nestD) {
                    newTotalD = newTotalD - nestD;
                }

                if (newTotalCty >= (totalCarHanChe - nestD)) {
                    newTotalCty = newTotalCty - (totalCarHanChe - nestD);
                }

                let listHanCheWorking = listCarHanCheX30.slice(listHanCheWorkingIndex, listHanCheWorkingIndex + totalCarHanChe);
                listHanCheWorkingIndex = listHanCheWorkingIndex + totalCarHanChe;
                for (let k = 0; k < listHanCheWorking.length; k++) {
                    listHanCheWorkingReUpdate.push(listHanCheWorking[k][0].msd.toString());
                }

            }*/
            let listCtyWorking = listCarCtyX30.slice(listCtyWorkingIndex, listCtyWorkingIndex + newTotalCty);
            listCtyWorkingIndex = listCtyWorkingIndex + totalCarCty;
            let listCtyWorkingReUpdate = [];
            for (let k = 0; k < listCtyWorking.length; k++) {
                listCtyWorkingReUpdate.push(listCtyWorking[k][0].msd.toString());
            }

            let listCtyWorkingReUpdate2 = [];
            for (let t = 0; t < listCtyWorkingReUpdate.length; t++) {
                /*if (listCtyWorkingReUpdate[t] == "69") {
                    listCtyWorkingReUpdate2.push('33');
                } else {
                    if (listCtyWorkingReUpdate[t] == "33") {
                        listCtyWorkingReUpdate2.push('69');
                    } else {
                        if (listCtyWorkingReUpdate[t] == "93") {
                            listCtyWorkingReUpdate2.push('611');
                        } else {
                            if (listCtyWorkingReUpdate[t] == "611") {
                                listCtyWorkingReUpdate2.push('93');
                            } else {
                                listCtyWorkingReUpdate2.push(listCtyWorkingReUpdate[t]);
                            }
                        }
                    }
                }*/
                listCtyWorkingReUpdate2.push(listCtyWorkingReUpdate[t]);
            }

            let listDWorking = listCarDX30.slice(listDWorkingIndex, listDWorkingIndex + newTotalD);
            listDWorkingIndex = listDWorkingIndex + totalCarDD;
            let listDWorkingReUpdate = [];
            for (let k = 0; k < listDWorking.length; k++) {
                /*if (listDWorking[k][0].msd.toString() == "07") {
                    listDWorkingReUpdate.push('83');
                } else {
                    if (listDWorking[k][0].msd.toString() == "83") {
                        listDWorkingReUpdate.push('07');
                    } else {
                        listDWorkingReUpdate.push(listDWorking[k][0].msd.toString());
                    }
                }*/
                listDWorkingReUpdate.push(listDWorking[k][0].msd.toString());
            }

            resultDate[dateKey] = {
                cty: listCtyWorkingReUpdate.length,
                d: listDWorkingReUpdate.length
            };
            resultDateResult[dateKey] = listCtyWorkingReUpdate2.concat(listDWorkingReUpdate).concat(listXeGhepReUpdate).concat(listHanCheWorkingReUpdate);
        }

        return resultDateResult;
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography component="h1" variant="h5" align="center" className={classes.marginBottom50}>
                    {"Cấu hình ca trực đêm"}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} style={{marginTop: 50}}>
                {catrucdemsconfig.loading ? <CircularLoading/> :
                    <MaterialTable
                        title={"Cấu hình nhân viên"}
                        columns={columns}
                        data={data}
                        options={{
                            exportButton: true,
                            sorting: true,
                            filtering: true,
                            pageSize: 25,
                            grouping: true,
                            showGroupingCount: true,
                            pageSizeOptions: [10, 25, 50, 100]
                        }}
                        editable={{
                            onRowAdd: newData =>
                                new Promise((resolve, reject) => {
                                    if (features.AllowCriticalEditsAdmin) {
                                        setTimeout(() => {
                                            resolve();
                                            const tblData = data;
                                            tblData.push(newData);
                                            dispatch(editCaTrucDemConfig(tblData), "Add");
                                            dispatch(fetchCaTrucDemConfig());
                                        }, 600);
                                    } else {
                                        alert(language.demo_mode);
                                        reject();
                                    }
                                }),
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    if (features.AllowCriticalEditsAdmin) {
                                        setTimeout(() => {
                                            resolve();
                                            const tblData = data;
                                            tblData[tblData.indexOf(oldData)] = newData;
                                            dispatch(editCaTrucDemConfig(tblData), "Update");
                                            dispatch(fetchCaTrucDemConfig());
                                        }, 600);
                                    } else {
                                        alert(language.demo_mode);
                                        reject();
                                    }
                                }),
                            onRowDelete: oldData =>
                                new Promise((resolve, reject) => {
                                    if (features.AllowCriticalEditsAdmin) {
                                        setTimeout(() => {
                                            resolve();
                                            const tblData = data;
                                            tblData.splice(tblData.indexOf(oldData), 1);
                                            dispatch(editCaTrucDemConfig(tblData), "Delete");
                                            dispatch(fetchCaTrucDemConfig());
                                        }, 600);
                                    } else {
                                        alert(language.demo_mode);
                                        reject();
                                    }
                                }),
                        }}
                    />}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} style={{marginTop: 50}}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                        <TextField
                            id="text_month"
                            label={"tháng"}
                            variant="filled"
                            fullWidth
                            value={month}
                            onChange={(event) => {
                                let text = event.target.value;
                                setMonth(text);
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                        <TextField
                            id="text_year"
                            label={"Nhập Năm"}
                            variant="filled"
                            fullWidth
                            value={year}
                            onChange={(event) => {
                                let text = event.target.value;
                                setYear(text);
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <TextField
                            id="text_listDriver"
                            label={"Danh sách lái xe loại trừ"}
                            variant="filled"
                            fullWidth
                            disabled={true}
                            value={listDriver}
                            readOn
                            onChange={(event) => {
                                let text = event.target.value;
                                setListDriver(text);
                            }}
                        />
                    </Grid>
                </Grid>


                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                        <TextField
                            id="text_tong_lx"
                            label={"Tổng lx ca"}
                            variant="filled"
                            fullWidth
                            value={totalDriver}
                            onChange={(event) => {
                                let text = event.target.value;
                                setTotalDriver(text);
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                        <TextField
                            id="so_lx_cty_them"
                            label={"Số lx cty thêm"}
                            variant="filled"
                            fullWidth
                            value={totalCtyAdd}
                            onChange={(event) => {
                                let text = event.target.value;
                                setTotalCtyAdd(text);
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Button
                            onPress={() => {
                                if (month && year) {
                                    if (totalDriver) {
                                        let result = generateDriver(month, year, totalDriver, totalCtyAdd ? totalCtyAdd : 0, listDriver ? listDriver.split(",") : []);
                                        console.log(JSON.stringify(result));

                                        if (result) {
                                            let listDate = Object.keys(result);
                                            let listResultTemp = [];
                                            for (let i = 0; i < listDate.length; i++) {
                                                listResultTemp.push({
                                                    date: listDate[i],
                                                    list: result[listDate[i]].join(",")
                                                });
                                            }
                                            setResultC(listResultTemp);
                                        }
                                    } else {
                                        alert("Vui lòng nhập tổng số lái xe trực đêm");
                                    }
                                } else {
                                    alert("Vui lòng chọn tháng, năm");
                                }
                            }}
                            title={"Tạo danh sách"}
                            titleStyle={{color: "#FFF"}}
                            containerStyle={{
                                flex: 1,
                                alignSelf: 'flex-end',
                                paddingRight: 14
                            }}
                        />
                    </Grid>
                </Grid>
                {resultC ?
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <MaterialTable
                                title={"Tháng " + month + " năm " + year}
                                columns={columnsResult}
                                data={resultC}
                                options={{
                                    pageSize: 31,
                                    pageSizeOptions: [31, 50, 100]
                                }}
                            />
                        </Grid>
                    </Grid> : null}

                {resultC ?
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <Button
                                onPress={() => {
                                    let currentDateFomated = new Date();
                                    let date_import = GetCurrentMiniTimeStamp();
                                    for (let i = 0; i < resultC.length; i++) {
                                        let monthYear = resultC[i].date.split('-');
                                        let month = parseInt(monthYear[1]) < 10 ? monthYear[1] : monthYear[1];
                                        let year = monthYear[2];
                                        let day = parseInt(monthYear[0]) < 10 ? "0" + monthYear[0] : monthYear[0];
                                        let date = day + "/" + month + "/" + year;
                                        let date_key = day + "_" + month + "_" + year;
                                        let dateFormated = new Date(month + "/" + day + "/" + year + " 20:30:00");

                                        if (dateFormated < currentDateFomated) {
                                            continue;
                                        }

                                        let admin = auth.user.firstName + " " + auth.user.lastName;
                                        let prime_driver = resultC[i].list;

                                        let data_insert = {
                                            date_add: date,
                                            admin: admin,
                                            prime_driver: prime_driver,
                                            second_driver: prime_driver,
                                            date_import: date_import
                                        }
                                        dispatch(setCaTrucDem(date_key, data_insert));
                                    }
                                    alert("Nạp dữ liệu ca đêm thành công!");
                                }}
                                title={"Nạp dữ liệu cho lái xe"}
                                titleStyle={{color: "#FFF"}}
                                containerStyle={{
                                    flex: 1,
                                    alignSelf: 'flex-end',
                                    paddingRight: 14
                                }}
                            />
                        </Grid>
                    </Grid> : null}


            </Grid>
        </Grid>
    );
}