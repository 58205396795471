import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {api} from "common";
import {useDispatch} from "react-redux";
import store from "common/src/store/store";
import {useEffect} from "react";
import * as Speech from 'expo-speech';
import {firebase} from "common/src/config/configureFirebase";

export default function NotifyCenterSnackbarComponent(props) {
    const {
        refApi
    } = firebase;
    const dispatch = useDispatch();

    useEffect(() => {
        Speech.speak(props.noti ? props.noti.title : '', {
            language: 'vi-VN',
            //name: 'vi-vn-x-gft-network',
            rate: 1,
            //identifier: "vi-vn-x-gft-network",
            quality: "Enhanced",
        });
    }, []);

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={() => {
                if (props.noti) {
                    const callCenter = store.getState().pacallcenterdata.callCenter;
                    const bookingActive = store.getState().bookinglistdata.booking_active;
                    dispatch(api.resetTripData(callCenter && callCenter.id ? callCenter.id : null, bookingActive && bookingActive.id ? bookingActive.id : null));
                    refApi.updateBookingNotiApi(props.noti.id, {to: "LX"});
                    props.callbackSnackbar(props.noti);
                }
            }
            }>
                Xem
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="error"
                onClick={(event, reason) => {
                    if (props.noti) {
                        refApi.updateBookingNotiApi(props.noti.id, {to: "LX"});
                    }
                    props.closeCalbackSnackbar(event, reason);
                }
                }
            >
                <CloseIcon fontSize="small"/>
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>
            <Snackbar
                key={props.noti ? props.noti.id : ''}
                open={props.open}
                autoHideDuration={20000}
                onClose={props.closeCalbackSnackbar}
                message={props.noti ? props.noti.title : ''}
                action={action}
            />
        </div>
    );
}
