import {
    EDIT_NOTE_MAP_ADDRESS, EDIT_NOTE_MAP_ADDRESS_FAILED, EDIT_NOTE_MAP_ADDRESS_SUCCESS,
    FETCH_NOTE_LIST,
    FETCH_NOTE_LIST_FAILED,
    FETCH_NOTE_LIST_SUCCESS, FETCH_NOTE_MAP_ADDRESS, FETCH_NOTE_MAP_ADDRESS_FAILED, FETCH_NOTE_MAP_ADDRESS_SUCCESS,
} from "../store/types";

const INITIAL_STATE = {
    loading: false,
    notes: [],
    note: null,
    error: {
        flag: false,
        msg: null
    }
}


export const notemapaddressreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_NOTE_MAP_ADDRESS:
            return {
                ...state,
                loading: true
            };
        case FETCH_NOTE_MAP_ADDRESS_SUCCESS:
            return {
                ...state,
                notes: action.payload,
                loading: false
            };
        case FETCH_NOTE_MAP_ADDRESS_FAILED:
            return {
                ...state,
                notes: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case EDIT_NOTE_MAP_ADDRESS:
            return {
                ...state,
                loading: true
            };
        case EDIT_NOTE_MAP_ADDRESS_SUCCESS:
            return {
                ...state,
                note: action.payload,
                loading: false
            };
        case EDIT_NOTE_MAP_ADDRESS_FAILED:
            return {
                ...state,
                note: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        default:
            return state;
    }
};
