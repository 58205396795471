import {configureStore} from '@reduxjs/toolkit';
import thunk from "redux-thunk";


//import logger from "redux-logger";

import {authreducer} from "../reducers/authreducer";
import {cartypesreducer} from "../reducers/cartypesreducer";
import {catrucdemreducer} from "../reducers/catrucdemreducer";
import {catrucdemconfigreducer} from "../reducers/catrucdemconfigreducer";
import {groupdriverreducer} from "../reducers/groupdriverreducer";
import {areasreducer} from "../reducers/areasreducer";
import {drivergroupreducer} from "../reducers/drivergroupreducer";
import {notifyimportancereducer} from "../reducers/notifyimportancereducer";
import {votesreducer} from "../reducers/votesreducer";
import {reportdriverreducer} from "../reducers/reportdriverreducer";
import {bookingslistreducer} from "../reducers/bookingslistreducer";
import {estimatereducer} from "../reducers/estimatereducer";
import {bookingreducer} from "../reducers/bookingreducer";
import {cancelreasonreducer} from "../reducers/cancelreasonreducer";
import {promolocationreducer} from "../reducers/promolocationreducer";
import {groupcoordreducer} from "../reducers/groupcoordreducer";
import {promoreducer} from "../reducers/promoreducer";
import {banneduidsreducer} from "../reducers/banneduidsreducer";
import {usersreducer} from "../reducers/usersreducer";
import {notificationreducer} from "../reducers/notificationreducer";
import {mistakereducer} from "../reducers/mistakereducer";
import {mistakeconfigreducer} from "../reducers/mistakeconfigreducer";
import {pacallcenterreducer} from "../reducers/pacallcenterreducer";
import {notelistreducer} from "../reducers/notelistreducer";
import {driverearningreducer} from '../reducers/driverearningreducer';
import {walletmanagereducer} from '../reducers/walletmanagereducer';
import {earningreportsreducer} from '../reducers/earningreportsreducer';
import {settingsreducer} from '../reducers/settingsreducer';
import {paymentreducer} from '../reducers/paymentreducer';
import {tripreducer} from '../reducers/tripreducer';
import {tasklistreducer} from '../reducers/tasklistreducer';
import {locationreducer} from '../reducers/locationreducer';
import {bookingtrackingreducer} from '../reducers/bookingtrackingreducer';
import {chatreducer} from '../reducers/chatreducer';
import {withdrawreducer} from '../reducers/withdrawreducer';
import {gpsreducer} from '../reducers/gpsreducer';
import {datacarreducer} from '../reducers/datacarreducer';
import {routenamereducer} from '../reducers/routenamereducer';
import {sessionreducer} from '../reducers/sessionreducer';
import {trackingreducer} from '../reducers/trackingreducer';
import {giaoxereducer} from "../reducers/giaoxereducer";
import {taxiteamsreducer} from "../reducers/taxiteamsreducer";
import {forcenotifyreducer} from "../reducers/forcenotifyreducer";
import {polygonreducer} from '../reducers/polygonreducer';
import {newsreducer} from "../reducers/newsreducer";
import {pricesreducer} from "../reducers/pricesreducer";
import {driverfaviouritereducer} from "../reducers/driverfaviouritereducer";
import {pointsreducer} from "../reducers/pointsreducer";
import {tradesreducer} from "../reducers/tradesreducer";
import {marketsreducer} from "../reducers/marketsreducer";
import {walletsreducer} from "../reducers/walletsreducer";
import {reportsreducer} from "../reducers/reportsreducer";
import {localreportsreducer} from "../reducers/localreportsreducer";
import {friendsreducer} from "../reducers/friendsreducer";
import {rolepermissionreducer} from "../reducers/rolepermissionreducer";
import {triggerreducer} from "../reducers/triggerreducer";
import {notemapaddressreducer} from "../reducers/notemapaddressreducer";
import {bookingnotireducer} from "../reducers/bookingnotireducer";

const rootReducer = {
    auth: authreducer,
    cartypes: cartypesreducer,
    catrucdems: catrucdemreducer,
    catrucdemsconfig: catrucdemconfigreducer,
    groupdriverdata: groupdriverreducer,
    areasdata: areasreducer,
    drivergroupdata: drivergroupreducer,
    notifyimportancedata: notifyimportancereducer,
    votesdata: votesreducer,
    reportdriverdata: reportdriverreducer,
    bookinglistdata: bookingslistreducer,
    estimatedata: estimatereducer,
    bookingdata: bookingreducer,
    cancelreasondata: cancelreasonreducer,
    promolocationdata: promolocationreducer,
    groupcoorddata: groupcoordreducer,
    promodata: promoreducer,
    banneduidsdata: banneduidsreducer,
    usersdata: usersreducer,
    notificationdata: notificationreducer,
    mistakedata: mistakereducer,
    mistakeconfigdata: mistakeconfigreducer,
    pacallcenterdata: pacallcenterreducer,
    notelistdata: notelistreducer,
    driverearningdata: driverearningreducer,
    walletmanagedata: walletmanagereducer,
    earningreportsdata: earningreportsreducer,
    settingsdata: settingsreducer,
    paymentmethods: paymentreducer,
    tripdata: tripreducer,
    taskdata: tasklistreducer,
    locationdata: locationreducer,
    bookingtrackingdata: bookingtrackingreducer,
    chatdata: chatreducer,
    withdrawdata: withdrawreducer,
    gpsdata: gpsreducer,
    datacar: datacarreducer,
    routenamedata: routenamereducer,
    sessiondata: sessionreducer,
    trackingdata: trackingreducer,
    giaoxedata: giaoxereducer,
    taxiteamsdata: taxiteamsreducer,
    forcenotifydata: forcenotifyreducer,
    polygondata: polygonreducer,
    newsdata: newsreducer,
    pricesdata: pricesreducer,
    driverfaviouritedata: driverfaviouritereducer,
    pointsdata: pointsreducer,
    tradesdata: tradesreducer,
    marketsdata: marketsreducer,
    walletsdata: walletsreducer,
    reportsdata: reportsreducer,
    localreportsdata: localreportsreducer,
    friendsdata: friendsreducer,
    rolepermissiondata: rolepermissionreducer,
    triggerdata: triggerreducer,
    notemapaddressdata: notemapaddressreducer,
    bookingnotidata: bookingnotireducer
}


export default configureStore({
    middleware: [thunk],
    reducer: rootReducer
});
