import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Button from '@material-ui/core/Button';
import {api, FirebaseContext} from "common";
import Grid from '@material-ui/core/Grid';
import {makeStyles} from "@material-ui/core/styles";
import {Checkbox, TextField} from "@material-ui/core";
import {ConverTimeStampToHHMM, ConvertSecondToHm, ConvertTimeStampTimeForPaShort} from "common/src/other/DateFunctions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import {colors} from "../../config/theme";
import {ScrollView, Text, View} from "react-native-web";
import {Google_Map_Key} from "config";
import Map from "../Map";
import {formatNumber} from "react-native-currency-input";
import {Dimensions} from "react-native";
import {formatDisplayAddressSearch, generateNoteKey, HiddenPhone} from "common/src/other/CommonFunctions";
import {alpha, styled} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import InputBase from "@mui/material/InputBase";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from "@mui/icons-material/Edit";
import FormControlLabel from "@mui/material/FormControlLabel";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import InputAdornment from "@material-ui/core/InputAdornment";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ChatIcon from '@mui/icons-material/Chat';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {FareCalculator2} from "common/src/other/FareCalculator";
import {AccountCircle} from "@material-ui/icons";

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'inherit', '& .MuiInputBase-input': {
        padding: 5, // vertical padding + font size from searchIcon
        paddingLeft: 10, transition: theme.transitions.create('width'), backgroundColor: '#b0b0b0', width: '100%', [theme.breakpoints.up('md')]: {
            width: '100%',
        },
    },
}));

const useStyles = makeStyles(theme => ({
    icon: {
        color: theme.palette.text.secondary, marginRight: theme.spacing(2),
    }, fullHeight: {
        height: '100%', width: "100%"
    }, inputColor: {
        color: colors.LINK
    }, titleList: {
        fontSize: 14, marginTop: 10
    }, submit: {
        backgroundColor: colors.MAIN, margin: theme.spacing(2, 0, 2), height: 39,
    }, backgroundRed: {
        backgroundColor: "rgba(255,179,179,0.74)", flexDirection: 'column'
    }, row: {
        borderBottomWidth: 1, borderBottomStyle: "solid", borderBottomColor: "rgba(255,0,0,0.74)"
    }, inputdimmed: {
        fontSize: 18, color: "#737373"
    }, carphoto: {
        height: '18px', marginRight: '10px'
    }, input: {
        borderRadius: 4, position: 'relative', backgroundColor: "#E8E8E8", fontSize: 16, padding: '10px 2px 10px 10px', color: "#000"
    }, commonInputStyle: {
        borderRadius: 4, backgroundColor: "#E8E8E8",
    }, dateTimePickerCss: {
        width: 150, borderWidth: 0, zIndex: 100, height: 40
    }, clockPickerCss: {
        zIndex: 100
    }, calendarPickerCss: {
        zIndex: 100, position: 'absolute', left: -140
    }

}));
var {height, width} = Dimensions.get('window');

export default function ButtonBookingDetailReachedDialog(props) {
    const {refApi} = useContext(FirebaseContext);
    const dispatch = useDispatch();
    const classes = useStyles();
    const settings = useSelector(state => state.settingsdata.settings);
    const editPickup = useSelector(state => state.tripdata.isEditPickup);
    const bookingdata = props.bookingDetail ? props.bookingDetail : null;
    const [mylocation, setMylocation] = useState(null);
    const [note, setNote] = useState(null);
    const [editNote, setEditNote] = useState(false);
    const tripdata = useSelector(state => state.tripdata);
    const [hasPin, setHasPin] = useState(false);
    const [metterPrice, setMetterPrice] = useState(null);
    const [surchargeName, setSurchargeName] = useState('');
    const [surchargePrice, setSurchargePrice] = useState(0);

    useEffect(() => {
        if (bookingdata) {
            if (bookingdata.pickup) {
                dispatch(api.updateTripPickup(bookingdata.pickup));
                if (mylocation == null) {
                    setMylocation({
                        lat: bookingdata.pickup.lat, lng: bookingdata.pickup.lng
                    });
                }
            }
            if (bookingdata.drop) {
                dispatch(api.updateTripDrop(bookingdata.drop));
            }
        }
    }, [bookingdata]);

    const formatPrice = (value) => {
        return formatNumber(value, {
            separator: ',', precision: 0, delimiter: '.', ignoreNegative: false,
        }) + ' ' + settings.symbol;
    }

    const closeCallback = useCallback(() => {
        props.hideModal();
    }, [],);

    return (<Dialog
        open={props.showModal}
        onClose={closeCallback}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={false}
        scroll={'body'}
    >
        <DialogTitle id="alert-dialog-title">
            <IconButton>
                <ArrowBackIcon/>
            </IconButton>
            <Text>{bookingdata && bookingdata.bookingDate ? ConvertTimeStampTimeForPaShort(bookingdata.bookingDate) : ''}</Text>
            <Text style={{marginLeft: 20, fontSize: 20}}>{bookingdata && bookingdata.customer_contact ? HiddenPhone(bookingdata.customer_contact) : ''}</Text>
            <Text style={{marginLeft: 20, fontSize: 20}}>{"["}{bookingdata && bookingdata.status ? bookingdata.status : ''}{"]"}</Text>
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <Grid container spacing={1} style={{height: 650, width: width - 100}}>
                    <View style={{
                        width: '100%', height: '100%', flexDirection: 'row', flexWrap: "wrap", justifyContent: "space-between"
                    }}>
                        <View style={{width: '35%', flexDirection: 'column', justifyContent: 'flex-start'}}>
                            <View style={{
                                flexDirection: 'row', width: '99%', justifyContent: 'space-between', alignItems: 'center', borderWidth: 1, borderRadius: 5, paddingBottom: 10, paddingRight: 10
                            }}>
                                <View style={{width: '85%'}}>
                                    <View style={{flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                            <TextField
                                                id="input-with-icon-textfield-note"
                                                disabled={true}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <IconButton onClick={() => {
                                                                setEditNote(!editNote);
                                                            }
                                                            }>
                                                                <EditIcon size={'medium'}/>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    readOnly: !editNote,
                                                    disableUnderline: !editNote
                                                }}
                                                value={bookingdata && bookingdata.customer_note ? bookingdata.customer_note : ''}
                                                style={{marginLeft: 10}}
                                                variant="standard"
                                            />
                                        </View>
                                        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: "flex-end"}}>
                                            <FormControlLabel control={<Checkbox onChange={(event) => {
                                                let checked = event.target.checked;
                                                setHasPin(checked);
                                            }
                                            }/>} label={<span style={{fontSize: 13}}>{"Ghim vị trí cho lần sau"}</span>}/>
                                        </View>
                                    </View>
                                    <View style={{flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between', paddingLeft: 13}}>
                                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                            <img src={require("../../assets/img/ico_start_13x.png")} alt="Điểm đón" style={{width: 20, height: 20}}/>
                                            <Text style={{marginLeft: 10, maxWidth: '90%'}}>{bookingdata && bookingdata.pickup ? formatDisplayAddressSearch(bookingdata.pickup.add) : ''}</Text>
                                            <Text style={{marginLeft: 10}}>{bookingdata && bookingdata.startTime ? ConvertTimeStampTimeForPaShort(bookingdata.startTime).split(' ')[0] : ''}</Text>
                                        </View>

                                        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: "flex-end"}}>
                                            <FormControlLabel control={
                                                <Checkbox
                                                    checked={editPickup} onChange={(event) => {
                                                    let checked = event.target.checked;
                                                    let value = event.target.value;
                                                    dispatch(api.updateTripEditPickup(checked));
                                                    dispatch(api.updatSelPointType('pickup'));
                                                    if (tripdata.pickup) {
                                                        setMylocation({
                                                            lat: tripdata.pickup.lat,
                                                            lng: tripdata.pickup.lng
                                                        })
                                                    }
                                                }
                                                }
                                                    disabled={true}
                                                />} label={<span style={{fontSize: 13}}>{"Sửa"}</span>}/>
                                            <FormControlLabel control={<Checkbox/>} label={<span style={{fontSize: 13}}>{"Vị trí tài xế"}</span>}/>
                                        </View>
                                    </View>
                                </View>
                                <Button
                                    disabled={!hasPin}
                                    onClick={() => {
                                        if (tripdata && tripdata.pickup && props.callDetail && props.callDetail.note) {
                                            let newData = {
                                                coords: tripdata.pickup.lat + " " + tripdata.pickup.lng,
                                                note: note,
                                                add: tripdata.pickup.add,
                                                id: generateNoteKey(props.callDetail.note)
                                            }
                                            if (props.callDetail.id) {
                                                refApi.updateCallCenterApi(props.callDetail.id, {
                                                    note: note,
                                                    pickup: {
                                                        add: tripdata.pickup.add,
                                                        lat: tripdata.pickup.lat,
                                                        lng: tripdata.pickup.lng
                                                    }
                                                });
                                            }
                                            dispatch(api.editNoteMapAddress(newData, "Update"));
                                            setHasPin(false);
                                            alert("Lưu điểm thành công")
                                        }

                                    }} style={{backgroundColor: !hasPin ? colors.GREY.background : 'black', color: 'white', marginLeft: 10}}>
                                    <Text style={{fontSize: 13, color: '#ffffff', textAlign: 'center'}}>{"Cập nhật"}</Text>
                                </Button>
                            </View>

                            <SwapVertIcon style={{marginTop: 10, marginLeft: 10}}/>

                            <View style={{flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between', paddingLeft: 13, paddingRight: 10}}>
                                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                    <img src={require("../../assets/img/ico_stop_23x.png")} alt="Điểm đến" style={{width: 20, height: 20}}/>
                                    <Text style={{marginLeft: 10}}>{bookingdata && bookingdata.drop ? formatDisplayAddressSearch(bookingdata.drop.add) : 'Chưa có điểm đến (Không bắt buộc)'}</Text>
                                </View>

                                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: "flex-end"}}>
                                    <FormControlLabel control={
                                        <Checkbox
                                            onChange={(event) => {
                                                let checked = event.target.checked;
                                                let value = event.target.value;
                                                dispatch(api.updateTripEditDrop(checked));
                                                dispatch(api.updatSelPointType('drop'));
                                                if (tripdata.drop) {
                                                    setMylocation({
                                                        lat: tripdata.drop.lat,
                                                        lng: tripdata.pickup.lng
                                                    })
                                                }
                                            }
                                            }
                                            disabled={true}
                                        />} label={<span style={{fontSize: 13}}>{"Sửa"}</span>}/>
                                    <FormControlLabel control={<Checkbox/>} label={<span style={{fontSize: 13}}>{"Vị trí tài xế"}</span>}/>
                                </View>

                                <Button onClick={() => {
                                }} style={{backgroundColor: 'black', color: 'white', marginLeft: 10}}>
                                    <Text style={{fontSize: 13, color: '#ffffff', textAlign: 'center'}}>{"Cập nhật"}</Text>
                                </Button>
                            </View>

                            <View style={{
                                width: '99%', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row',
                                borderRadius: 10, borderWidth: 1, flexWrap: 'wrap',
                                padding: 5, marginTop: 10
                            }}>
                                <img src={require("../../assets/img/chuyentai-icon.png")} alt="Tài xế nhận" style={{width: 30}}/>
                                <View style={{
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <Text style={{fontSize: 16}}>{bookingdata && bookingdata.driver_name ? bookingdata.driver_name : ''}</Text>
                                    <Text style={{fontSize: 13, color: colors.GREY.secondary}}>{bookingdata && bookingdata.vehicle_number ? bookingdata.vehicle_number : ''}{" - "}{"Kia morning"}</Text>
                                </View>
                                <IconButton
                                    onClick={() => {
                                        props.callDriver(props.callDetail);
                                    }
                                    }
                                >
                                    <LocalPhoneIcon/>
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        props.showChat(bookingdata.codeDriver, bookingdata.customer_note, bookingdata.bookingDate);
                                    }
                                    }
                                >
                                    <ChatIcon/>
                                </IconButton>
                                <IconButton>
                                    {bookingdata && bookingdata.driver_image ?
                                        <img src={bookingdata.driver_image} alt="Tài xế nhận" style={{width: 30, height: 30, borderRadius: 5}}/> :
                                        <AccountCircleIcon/>}
                                </IconButton>
                            </View>

                            <View style={{
                                width: '99%', flexDirection: 'column',
                                borderRadius: 10, borderWidth: 1,
                                padding: 5, marginTop: 10
                            }}>

                                <Text style={{color: colors.BACKGROUND_DARK, fontSize: 20, fontWeight: 'bold'}}>{"Đồng hồ Meter"}</Text>
                                <View
                                    style={{
                                        width: '100%', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        marginTop: 10
                                    }}
                                >
                                    <View style={{
                                        backgroundColor: colors.BACKGROUND_DARK, flexDirection: 'column',
                                        borderRadius: 10, padding: 10, width: '70%'
                                    }}>
                                        <View style={{flexDirection: 'row', flexWrap: "wrap", justifyContent: "space-between", width: '100%'}}>
                                            <Text style={{color: colors.WHITE, fontSize: 14, minWidth: 100}}>{"Kilomet"}</Text>
                                            <Text style={{color: colors.WHITE, fontSize: 14, minWidth: 100}}>{"Total"}</Text>
                                            <Text style={{color: colors.WHITE, fontSize: 14, minWidth: 100}}>{"Waiting time"}</Text>
                                        </View>
                                        <View style={{flexDirection: 'row', flexWrap: "wrap", justifyContent: "space-between", width: '100%', marginTop: 20}}>
                                            <Text style={{color: colors.WHITE, fontSize: 14, minWidth: 100}}>{bookingdata && bookingdata.metterDistance ? parseFloat(bookingdata.metterDistance).toFixed(2) : '0'}</Text>
                                            <Text style={{color: colors.WHITE, fontSize: 14, minWidth: 100}}>{bookingdata && bookingdata.metterPrice ? formatPrice(bookingdata.metterPrice) : '0'}</Text>
                                            <Text style={{color: colors.WHITE, fontSize: 14, minWidth: 100}}>{bookingdata && bookingdata.waitingTime ? ConverTimeStampToHHMM(bookingdata.waitingTime) : '0'}</Text>
                                        </View>
                                    </View>

                                    <FormControlLabel control={
                                        <Checkbox
                                            onChange={(event) => {
                                                let checked = event.target.checked;
                                                setMetterPrice(checked);
                                            }
                                            }
                                        />} label={<span style={{fontSize: 13}}>{"Metter"}</span>}/>
                                </View>
                            </View>

                            <View style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%'}}>
                                <View style={{
                                    flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%'
                                }}>
                                    <View style={{
                                        flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', marginTop: 10
                                    }}>
                                        {bookingdata && bookingdata.drop ?
                                            <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
                                                <Checkbox
                                                    checked={bookingdata && bookingdata.is_two_way ? !bookingdata.is_two_way : true}
                                                    color={"primary"}
                                                    onChange={(event) => {
                                                        let checked = event.target.checked;
                                                        refApi.updateBookingApi(bookingdata.id, {
                                                            is_two_way: false
                                                        });
                                                    }}
                                                    title={"Một chiều"}
                                                    name={'chk_1chieu'}
                                                    key={'chk_1chieu'}
                                                    inputProps={{'aria-label': 'controlled'}}
                                                /><Text>{"Một chiều"}</Text>

                                                <Checkbox
                                                    checked={tripdata && tripdata.is_two_way ? tripdata.is_two_way : false}
                                                    color={"primary"}
                                                    disabled={bookingdata && bookingdata.metterDistance && bookingdata.metterDistance >= 20 ? false : true}
                                                    name={'chk_2chieu'}
                                                    key={'chk_2chieu'}
                                                    onChange={(event) => {
                                                        let checked = event.target.checked;
                                                        var fareCalculation = bookingdata.tripEstimate ? bookingdata.tripEstimate : null;
                                                        if (checked == true && !bookingdata.tripEstimate) {
                                                            fareCalculation = FareCalculator2(bookingdata.metterDistance, bookingdata.metterTime, null, 0, bookingdata.waitingTime, bookingdata.prices, true);
                                                            fareCalculation.push(parseFloat(bookingdata.metterDistance).toFixed(2));
                                                            fareCalculation.push(parseFloat(bookingdata.metterTime).toFixed(2));
                                                        }
                                                        refApi.updateBookingApi(bookingdata.id, {
                                                            is_two_way: true,
                                                            tripEstimate: fareCalculation
                                                        });
                                                    }}
                                                    title={"Hai chiều quay đầu"}
                                                    inputProps={{'aria-label': 'controlled'}}
                                                /><Text>{"Hai chiều quay đầu"}</Text>
                                            </View> : null}

                                        {bookingdata && bookingdata.drop ?
                                            <View style={{
                                                alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start', width: '98%',
                                                borderWidth: 1, paddingLeft: 10, paddingTop: 10, paddingBottom: 10, borderRadius: 10
                                            }}>
                                                <View style={{
                                                    alignItems: 'center', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', width: '100%', paddingRight: 10, paddingBottom: 10
                                                }}>
                                                    <Text>{"Giá mở cửa"}</Text>
                                                    <Text>{}</Text>
                                                    <Text>{bookingdata && bookingdata.prices ? formatPrice(bookingdata.prices.open) : ''}</Text>
                                                </View>
                                                <View style={{
                                                    alignItems: 'center', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', width: '100%', paddingRight: 10, paddingBottom: 10
                                                }}>
                                                    <Text>{"Tổng quảng đường"}</Text>
                                                    {bookingdata && bookingdata.is_two_way != true ?
                                                        <>
                                                            <Text>{bookingdata && bookingdata.metterDistance ? parseFloat(bookingdata.metterDistance).toFixed(2) : ''}{'(km)'}</Text>
                                                            <Text>{bookingdata && bookingdata.metterPrice && bookingdata.prices ? formatPrice(bookingdata.metterPrice - bookingdata.prices.open) : 0}</Text>
                                                        </>
                                                        :
                                                        <>
                                                            <Text>{bookingdata && bookingdata.tripEstimate && bookingdata.tripEstimate[1] ? parseFloat(bookingdata.tripEstimate[1].distance).toFixed(2) : ''}{'(km)'}</Text>
                                                            <Text>{bookingdata && bookingdata.tripEstimate && bookingdata.tripEstimate[1] ? formatPrice(bookingdata.tripEstimate[1].totalCost) : 0}</Text>
                                                        </>}
                                                </View>

                                                {bookingdata && bookingdata.is_two_way == true && bookingdata.tripEstimate && bookingdata.tripEstimate[1] && bookingdata.tripEstimate[1].timeLimit ?
                                                    <View style={{
                                                        alignItems: 'center', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', width: '100%', paddingRight: 10, paddingBottom: 10
                                                    }}>
                                                        <Text>{"Giới hạn tổng thời gian"}</Text>
                                                        <Text>{ConvertSecondToHm(bookingdata.tripEstimate[1].timeLimit)}</Text>
                                                        <Text>{formatPrice(0)}</Text>
                                                    </View> : null}

                                                <View style={{
                                                    alignItems: 'flex-end', justifyContent: 'flex-end', width: '100%', paddingRight: 10
                                                }}>
                                                    {bookingdata && bookingdata.is_two_way == true ?
                                                        <Text style={{fontSize: 16, padding: 10, backgroundColor: 'rgba(180,180,180,0.71)'}}>{bookingdata && bookingdata.tripEstimate ? formatPrice(bookingdata.tripEstimate[1].totalCost) : ''}</Text> :
                                                        <Text style={{fontSize: 16, padding: 10, backgroundColor: 'rgba(180,180,180,0.71)'}}>{bookingdata && bookingdata.tripEstimate ? formatPrice(bookingdata.tripEstimate[0].totalCost) : ''}</Text>
                                                    }
                                                </View>
                                            </View> : null}
                                        {bookingdata && bookingdata.drop ?
                                            <View>
                                                <Text style={{fontSize: 14, marginTop: 10, marginBottom: 10}}>{"Phí phụ trội, cầu đường bến bãi ( nếu có ) "}</Text>
                                            </View> : null}
                                        {bookingdata && bookingdata.drop ?
                                            <ScrollView
                                                style={{
                                                    flexDirection: 'column', width: '99%', borderWidth: 1, paddingLeft: 10, paddingTop: 10, paddingBottom: 10, borderRadius: 10
                                                }}>
                                                <View style={{
                                                    alignItems: 'center', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', width: '100%', paddingRight: 10, paddingBottom: 10
                                                }}>
                                                    <Text>{"Vượt thời gian"}</Text>
                                                    <Text>{"45 (phút)"}</Text>
                                                    <Text>{formatPrice(0)}</Text>
                                                </View>
                                                <View style={{
                                                    alignItems: 'center', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', width: '100%', paddingRight: 10, paddingBottom: 10
                                                }}>
                                                    <Text>{"Thời gian chờ"}</Text>
                                                    <Text>{"0"}</Text>
                                                    <Text>{formatPrice(0)}</Text>
                                                </View>

                                                {bookingdata && bookingdata.surcharges && bookingdata.surcharges.length > 0 ?
                                                    bookingdata.surcharges.map((sur) => {
                                                        return (
                                                            <View style={{
                                                                alignItems: 'center', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', width: '100%', paddingRight: 10, paddingBottom: 10
                                                            }}>
                                                                <Text>{"Phí khác"}</Text>
                                                                <Text>{sur.name}</Text>
                                                                <Text>{formatPrice(sur.price)}</Text>
                                                            </View>
                                                        )
                                                    })
                                                    : null
                                                }
                                                <View style={{
                                                    alignItems: 'flex-end', justifyContent: 'flex-end', width: '100%', paddingRight: 10
                                                }}>
                                                    <Text style={{fontSize: 16, padding: 10, backgroundColor: 'rgba(180,180,180,0.71)'}}>{formatPrice(121000)}</Text>
                                                </View>
                                                <div style={{
                                                    display: 'flex', flexDirection: 'column', border: 2, width: '98%', padding: 5
                                                }}>
                                                    <StyledInputBase
                                                        placeholder="Tên phụ phí …"
                                                        inputProps={{'aria-label': 'Tên phụ phí'}}
                                                        style={{
                                                            borderRadius: 5, color: 'black', backgroundColor: alpha("#a4a4a4", 0.15), '&:hover': {
                                                                backgroundColor: alpha("#a4a4a4", 0.25),
                                                            },
                                                        }}
                                                        value={surchargeName}
                                                        onChange={(event) => {
                                                            setSurchargeName(event.target.value);
                                                        }}
                                                    />
                                                    <StyledInputBase
                                                        placeholder="Thành tiền …"
                                                        inputProps={{'aria-label': 'Thành tiền'}}
                                                        style={{
                                                            borderRadius: 5, color: 'black', backgroundColor: alpha("#a4a4a4", 0.15), '&:hover': {
                                                                backgroundColor: alpha("#a4a4a4", 0.25),
                                                            }, marginTop: 10
                                                        }}
                                                        value={surchargePrice}
                                                        onChange={(event) => {
                                                            setSurchargePrice(event.target.value);
                                                        }}
                                                    />

                                                    <div style={{
                                                        display: 'flex', flexDirection: 'row',
                                                    }}>
                                                        <IconButton
                                                            aria-label="Huỷ"
                                                            style={{
                                                                display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1, color: "#ff4612"
                                                            }}
                                                            color="inherit"
                                                            size="medium"
                                                            onClick={() => {

                                                            }}
                                                        >
                                                            <CancelIcon fontSize="inherit"/>
                                                        </IconButton>

                                                        <IconButton
                                                            aria-label="Lưu"
                                                            style={{
                                                                display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1, color: "#00a615"
                                                            }}
                                                            color="inherit"
                                                            size="medium"
                                                            onClick={() => {


                                                            }}
                                                        >
                                                            <SaveIcon fontSize="inherit"/>
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </ScrollView> : null}

                                        <View style={{
                                            alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', width: '98%', marginTop: 10
                                        }}>
                                            <View style={{backgroundColor: 'black', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', width: '60%', padding: 10, borderRadius: 10}}>
                                                <View>
                                                    <Text style={{color: colors.WHITE, fontSize: 20}}>{"Tổng thanh toán"}</Text>
                                                    <Text style={{color: colors.WHITE}}>{"Total prices"}</Text>
                                                </View>
                                                <View>
                                                    <Text style={{color: colors.WHITE, fontSize: 25}}>{"123.000"}</Text>
                                                    <Text style={{color: colors.WHITE}}>{"VNĐ"}</Text>
                                                </View>
                                            </View>

                                            <Button
                                                variant="contained"
                                                onClick={() => {

                                                }
                                                }
                                                style={{backgroundColor: 'black', width: "30%", paddingTop: 20, paddingBottom: 20}}
                                            >
                                                <Text style={{fontSize: 16, color: colors.WHITE}}>{"Cập nhật giá"}</Text>
                                            </Button>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View style={{
                            flexDirection: 'column', width: '65%', height: '100%', borderRadius: 10, zIndex: 90
                        }}>
                            <View style={{width: '100%', height: '100%', borderRadius: 10, zIndex: 90}}>
                                {mylocation ? <Map
                                    loadingElement={<div className={classes.fullHeight}/>}
                                    containerElement={<div className={classes.fullHeight}/>}
                                    mapElement={<div className={classes.fullHeight}/>}
                                    center={mylocation}
                                    isPickup={true}
                                    zoom={13}
                                    googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + Google_Map_Key + "&v=3.exp&libraries=geometry,drawing,places"}
                                /> : null}
                            </View>
                        </View>

                    </View>
                </Grid>
            </DialogContentText>
        </DialogContent>
    </Dialog>);
}
