import {FETCH_AREAS, FETCH_AREAS_FAILED, FETCH_AREAS_ONCE, FETCH_AREAS_ONCE_FAILED, FETCH_AREAS_ONCE_SUCCESS, FETCH_AREAS_SUCCESS, FETCH_GROUP_AREA_BY_ID_FAILED, FETCH_GROUP_AREA_BY_ID_SUCCESS, REG_LEAD_AREA, REG_LEAD_AREA_FAILED, REG_LEAD_AREA_SUCCESS, REG_MEMBER_AREA, REG_MEMBER_AREA_FAILED, REG_MEMBER_AREA_SUCCESS, STOP_FETCH_AREAS} from "../store/types";
import {GetCurrentTimeStamp} from "../other/DateFunctions";
import store from "../store/store";
import {firebase} from '../config/configureFirebase';
import {off, onValue, set} from "firebase/database";
import {pushNotifyImportance} from "./notifyimportanceaction";

export const fetchAreas = () => (dispatch) => {
    const {
        areasRef,
    } = firebase;

    dispatch({
        type: FETCH_AREAS,
        payload: null,
    });

    let listF = [];
    let listPolygon = [];
    off(areasRef());
    onValue(areasRef(), (snapshot) => {
        if (snapshot.val()) {
            let data = snapshot.val();
            const arr = Object.keys(data)
                .map(i => {
                    let area = data[i].details;
                    let feature = {
                        "type": "Feature",
                        "properties": {
                            "letter": data[i].name + ' ' + i,
                            "color": 'red'
                        },
                        "geometry": {
                            "type": "Polygon",
                            "coordinates": [area]
                        }
                    };
                    listF.push(feature);
                    listPolygon.push(area);
                    data[i].id = i;
                    return data[i];
                });
            const geojson = {
                "type": "FeatureCollection",
                "features": listF
            };

            dispatch({
                type: 'UPDATE_POLYGON',
                payload: {
                    polygons: listPolygon,
                    geojson: geojson
                }
            });
            dispatch({
                type: FETCH_AREAS_SUCCESS,
                payload: {
                    areas: arr
                }
            });
        } else {
            dispatch({
                type: FETCH_AREAS_FAILED,
                payload: "Bạn chưa đăng ký vùng kinh doanh",
            });
        }
    }, {onlyOnce: true});
};

export const fetchAreasOnce = () => (dispatch) => {
    const {
        areasRef,
    } = firebase;

    dispatch({
        type: FETCH_AREAS_ONCE,
        payload: null,
    });
    off(areasRef());
    onValue(areasRef(), (snapshot) => {
        if (snapshot.val()) {
            let data = snapshot.val();
            const arr = Object.keys(data)
                .map(i => {
                    data[i].id = i;
                    return data[i];
                });
            dispatch({
                type: FETCH_AREAS_ONCE_SUCCESS,
                payload: {
                    areas_once: arr
                }
            });
        } else {
            dispatch({
                type: FETCH_AREAS_ONCE_FAILED,
                payload: "Chưa có vùng kinh doanh",
            });
        }
    }, {onlyOnce: true});
};

export const stopFetchAreas = () => (dispatch) => {
    const {
        areasRef,
    } = firebase;

    dispatch({
        type: STOP_FETCH_AREAS,
        payload: null,
    });

    off(areasRef());
};


export const regAreaLeader = (uid, areaId, data) => (dispatch) => {
    const {
        getAreaByIdRef,
        getDriverGroupAreaByIdRef,
        setGroupdriverByIdRef
    } = firebase;

    let leader = {
        role: 'leader',
        date_add: GetCurrentTimeStamp(),
        status: data.status ? data.status : 'pending',
        base_prices: 0,
        codeDriver: data.codeDriver ? data.codeDriver : '',
        type: data.type ? data.type : '',
        uid: uid
    };

    if (data.role) {
        leader.role = data.role;
    }

    let baseData = {
        status: false,
        leaders: {
            [uid]: leader
        }
    }
    if (data && data.name) {
        baseData.name = data.name;
    }

    dispatch({
        type: REG_LEAD_AREA,
        payload: null,
    });
    onValue(getAreaByIdRef(areaId), (snapshot) => {
        if (snapshot.val()) {
            let data = snapshot.val();
            if (data.status) {
                if (data.role && data.role == 'subleader') {
                    set(getAreaByIdRef(areaId + '/leaders/' + uid), leader);
                    dispatch({
                        type: REG_LEAD_AREA_SUCCESS,
                        payload: {
                            area: baseData
                        }
                    });
                } else {
                    dispatch({
                        type: REG_LEAD_AREA_FAILED,
                        payload: "Nhóm đã được đăng ký và hoạt động!",
                    });
                }
            } else {
                set(getAreaByIdRef(areaId + '/leaders/' + uid), leader);
                dispatch({
                    type: REG_LEAD_AREA_SUCCESS,
                    payload: {
                        area: baseData
                    }
                });
            }
        } else {
            onValue(getDriverGroupAreaByIdRef(areaId), (snapshot) => {
                if (snapshot.val()) {
                    if (snapshot.val().geometry && snapshot.val().geometry.coordinates[0] && snapshot.val().geometry.coordinates[0][0]) {
                        baseData.details = snapshot.val().geometry.coordinates[0][0];
                        baseData.group_coords = data.group_coords ? data.group_coords : null;
                        set(getAreaByIdRef(areaId), baseData)
                            .then(() => {
                                if (data.type && data.type === 'admin') {
                                } else {
                                    const groupdriverdata = store.getState().groupdriverdata.complex;
                                    var id = 0;
                                    if (groupdriverdata) {
                                        id = groupdriverdata.length;
                                    }
                                    let dataGroup = {
                                        area_ids: [areaId],
                                        areas: [baseData.details],
                                        codes: '',
                                        coord: "",
                                        group_coords: '',
                                        label: snapshot.val().display_area,
                                        limit: 100,
                                        lock_status: false,
                                        totalDriver: 0,
                                        value: id
                                    }
                                    set(setGroupdriverByIdRef(id), dataGroup);
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                            });

                        dispatch({
                            type: REG_LEAD_AREA_SUCCESS,
                            payload: {
                                area: baseData
                            }
                        });
                    }
                }
            }, {onlyOne: true});
        }
    }, {onlyOne: true});
};

export const regAreaMember = (uid, areaId, data) => (dispatch) => {
    const {
        getAreaByIdRef
    } = firebase;
    let member = {
        role: 'member',
        date_add: GetCurrentTimeStamp(),
        status: 'pending',
        uid: uid,
        codeDriver: data.codeDriver,
        type: data.type ? data.type : '',
        info: data
    };

    dispatch({
        type: REG_MEMBER_AREA,
        payload: null,
    });
    onValue(getAreaByIdRef(areaId), (snapshot) => {
        if (snapshot.val()) {
            let dataArea = snapshot.val();
            dataArea.id = areaId;
            let leader = Object.values(dataArea.leaders).filter((l) => l.role == 'leader' && l.status == 'active')[0];
            let free = dataArea.prices && dataArea.prices.free ? dataArea.prices.free : '0'

            if (leader && leader.type && leader.type == 'admin') {
                member.status = 'active';
                set(getAreaByIdRef(areaId + '/members/' + uid), member);
            } else {
                set(getAreaByIdRef(areaId + '/members/' + uid), member);
                if (data.isDriverReg) {
                    if (leader) {
                        let dataNotify = {
                            date_add: GetCurrentTimeStamp(),
                            status: 'pending',
                            from: data.from_uid,
                            type: 'reg_driver',
                            title: "Lái xe # " + data.codeDriver + " đăng ký tham gia " + dataArea.name,
                            content: "Bạn nhận được yêu cầu tham gia kinh doanh vùng " + dataArea.name + " từ #" + data.fromDriverCode + ".",
                            screen: 'GroupDetailSettingAreaScreen',
                            to: leader.uid,
                            free: data.free ? data.free : '0',
                            params: {
                                area: dataArea
                            }
                        };
                        dispatch(pushNotifyImportance(dataNotify));
                    }
                } else {
                    let dataNotify = {
                        date_add: GetCurrentTimeStamp(),
                        status: 'pending',
                        from: data.from_uid,
                        type: 'invite_driver',
                        title: "Lời mời tham gia " + dataArea.name,
                        content: "Bạn nhận được lời mời tham gia kinh doanh vùng " + dataArea.name + " từ #" + data.fromDriverCode + "." +
                            "\n Phí tham gia: " + free + "(đ)",
                        screen: 'GroupDetailSettingAreaScreen',
                        to: uid,
                        params: {
                            area: dataArea
                        }
                    };
                    dispatch(pushNotifyImportance(dataNotify));
                }
            }
            dispatch({
                type: REG_MEMBER_AREA_SUCCESS,
                payload: {
                    area: member
                }
            });
        } else {
            dispatch({
                type: REG_MEMBER_AREA_FAILED,
                payload: {
                    payload: "Vùng kinh doanh không tồn tại",
                }
            });
        }
    }, {onlyOnce: true});
};

export const removeAreaMember = (areaId, uid) => {
    const {
        getAreaByIdRef,
        driverGroupByIdRef,
        refApi
    } = firebase;

    onValue(getAreaByIdRef(areaId), (snapshot) => {
        if (snapshot.val()) {
            let dataArea = snapshot.val();
            dataArea.id = areaId;
            onValue(driverGroupByIdRef(areaId), (snapshotG) => {
                if (snapshotG.val()) {
                    let dataG = snapshot.val();
                    let groupCodes = [];
                    if (dataG.area_ids) {
                        dataG.area_ids.map((areaId) => {

                        });
                    }
                    if (dataArea.members) {
                        Object.values(dataArea.members).map((member) => {
                            if (member.uid != uid) {
                                groupCodes.push(member.codeDriver);
                            }
                        });
                    }
                    refApi.setGroupCodesApi(dataG.value, groupCodes.join(','));
                }
            }, {onlyOnce: true});
            refApi.removeMemberAreaApi(areaId, uid);
        }
    }, {onlyOnce: true});
};

export const acceptAreaMember = (areaId, uid) => {
    const {
        refApi,
        getAreaByIdRef,
        driverGroupByIdRef
    } = firebase;
//setMemberDateAddApi(props.notifySelect.params.area.id, auth.info.uid, GetCurrentTimeStamp());
//                 setMemberStatusJoinApi(props.notifySelect.params.area.id, auth.info.uid, "active");

    onValue(getAreaByIdRef(areaId), (snapshot) => {
        if (snapshot.val()) {
            let dataArea = snapshot.val();
            dataArea.id = areaId;

            onValue(driverGroupByIdRef(areaId), (snapshotG) => {
                if (snapshotG.val()) {
                    let dataG = snapshot.val();
                    let groupCodes = [];
                    if (dataArea.members) {
                        Object.values(dataArea.members).map((member) => {
                            if (member.uid != uid) {
                                groupCodes.push(member.codeDriver);
                            }
                        });
                    }
                    refApi.setGroupCodesApi(dataG.value, groupCodes.join(','));
                }
            }, {onlyOne: true});
            refApi.removeMemberAreaApi(areaId, uid);
        }
    }, {onlyOnce: true});
};

