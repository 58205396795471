import {EDIT_NOTE_MAP_ADDRESS, EDIT_NOTE_MAP_ADDRESS_FAILED, EDIT_NOTE_MAP_ADDRESS_SUCCESS, FETCH_NOTE_MAP_ADDRESS, FETCH_NOTE_MAP_ADDRESS_FAILED, FETCH_NOTE_MAP_ADDRESS_SUCCESS,} from "../store/types";
import {firebase} from '../config/configureFirebase';
import {off, onValue, remove, set} from "firebase/database";
import {generateNoteKey} from "../other/CommonFunctions";
import {GetCurrentMiniTimeStamp} from "../other/DateFunctions";

export const fetchNoteMapAddress = () => (dispatch) => {
    const {
        noteMapAddressRef
    } = firebase;

    dispatch({
        type: FETCH_NOTE_MAP_ADDRESS,
        payload: null
    });
    off(noteMapAddressRef());
    onValue(noteMapAddressRef(), snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data).map(i => {
                data[i].id = i;
                return data[i]
            });
            dispatch({
                type: FETCH_NOTE_MAP_ADDRESS_SUCCESS,
                payload: arr
            });
        } else {
            dispatch({
                type: FETCH_NOTE_MAP_ADDRESS_FAILED,
                payload: "No data available."
            });
        }
    });
};

export const stopFetchNoteMapAddress = () => (dispatch) => {
    const {
        auth,
        noteMapAddressRef
    } = firebase;

    dispatch({
        type: FETCH_NOTE_MAP_ADDRESS_SUCCESS,
        payload: []
    });
    off(noteMapAddressRef())
};

export const editNoteMapAddress = (noteData, method) => async (dispatch) => {
    const {
        auth,
        getAddressMapFromNoteRef
    } = firebase;

    dispatch({
        type: EDIT_NOTE_MAP_ADDRESS,
        payload: noteData
    });
    if (method === 'Set') {
        if (noteData.coords) {
            let coordArray = noteData.coords.split(',');
            if (coordArray.length > 1) {
                noteData.lat = parseFloat(coordArray[0].trim());
                noteData.lng = parseFloat(coordArray[1].trim());
            }
            noteData.create_at = GetCurrentMiniTimeStamp();
            noteData.uid = auth.currentUser && auth.currentUser.uid ? auth.currentUser.uid : null
        }
        let result = await set(getAddressMapFromNoteRef(generateNoteKey(noteData.note)), noteData);
        dispatch({
            type: EDIT_NOTE_MAP_ADDRESS_SUCCESS,
            payload: noteData
        });
    } else if (method === 'Delete') {
        var key = null;
        if (noteData.id) {
            key = noteData.id;
        } else {
            if (noteData.note) {
                key = generateNoteKey(noteData.note);
            }
        }
        if (key) {
            await remove(getAddressMapFromNoteRef(noteData.id));
            dispatch({
                type: EDIT_NOTE_MAP_ADDRESS_SUCCESS,
                payload: noteData
            });
        } else {
            dispatch({
                type: EDIT_NOTE_MAP_ADDRESS_FAILED,
                payload: noteData
            });
        }
    } else if (method === 'Update') {
        var key = null;
        if (noteData.id) {
            key = noteData.id;
        }
        if (key) {
            await remove(getAddressMapFromNoteRef(noteData.id));

            let coordArray = noteData.coords.split(',');
            if (coordArray.length > 1) {
                noteData.lat = parseFloat(coordArray[0].trim());
                noteData.lng = parseFloat(coordArray[1].trim());
            }
            noteData.update_at = GetCurrentMiniTimeStamp();
            noteData.uid = auth.currentUser && auth.currentUser.uid ? auth.currentUser.uid : null

            await set(getAddressMapFromNoteRef(generateNoteKey(noteData.note)), noteData);

            console.log("update success");

            dispatch({
                type: EDIT_NOTE_MAP_ADDRESS_SUCCESS,
                payload: noteData
            });
        } else {
            dispatch({
                type: EDIT_NOTE_MAP_ADDRESS_FAILED,
                payload: noteData
            });
        }
    }
}

