import * as React from 'react';
import {useEffect, useState} from 'react';
import {language} from "config/language";
import {useDispatch, useSelector} from "react-redux";
import {api} from 'common';
import MaterialTable from "material-table";
import {View} from "react-native-web";
import {features} from "config";
import {fromAddress} from "react-geocode";
import {GoogleMapApiConfig} from "../config/GoogleMapApiConfig";

export default function NoteMapAddress() {
    const dispatch = useDispatch();
    const notemapaddressdata = useSelector(state => state.notemapaddressdata);
    const [notes, setNotes] = useState([]);
    const [note, setNote] = useState(null);

    useEffect(() => {
        dispatch(api.fetchNoteMapAddress());
        return () => {
            dispatch(api.stopFetchNoteMapAddress());
        }
    }, []);

    const getUrl = (coord) => {
        try {
            const url = 'https://www.google.com/maps/place/' + coord.replaceAll(" ", '');
            return url;
        } catch (error) {
            return null;
        }
    };

    const getCoord = async (address) => {
        try {
            const obj = await fromAddress(address, GoogleMapApiConfig, 'vi', 'vn');
            //return url;
        } catch (error) {
            return null;
        }
    };

    useEffect(() => {
        if (notemapaddressdata.notes) {
            setNotes(notemapaddressdata.notes);
        } else {
            setNotes([]);
        }
    }, [notemapaddressdata]);

    const columns = [
        {title: "Name", field: 'note'},
        {
            title: "Toạ độ", field: 'coords', render: rowData => {
                if (rowData.coords) {
                    let addressFormat = getUrl(rowData.coords);
                    if (addressFormat) {
                        return (<a href={addressFormat} target={'_blank'}>{"Xem bản đồ"}</a>)
                    } else {
                        return (<i>{"Lỗi địa chỉ"}</i>)
                    }
                } else {
                    return (<i onClick={() => {
                        getCoord(rowData.note);
                    }
                    }>{'Chưa cập nhật'}</i>)
                }
            },
            filtering: false
        }
    ];


    return (
        <View>
            <MaterialTable
                title={"Danh sách ghi chú"}
                columns={columns}
                data={notes}
                options={{
                    filtering: true,
                    sorting: true,
                    search: false,
                    exportButton: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 25, 50, 100]
                }}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                resolve();
                                dispatch(api.editNoteMapAddress(newData, "Set"));
                            }, 600);
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                resolve();
                                newData.id = oldData.id;
                                dispatch(api.editNoteMapAddress(newData, "Update"));
                            }, 600);
                        }),
                    onRowDelete: oldData =>
                        features.AllowCriticalEditsAdmin ?
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    dispatch(api.editNoteMapAddress(oldData, "Delete"));
                                }, 600);
                            })
                            :
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    alert(language.demo_mode);
                                }, 600);
                            })
                    ,
                }}
            />
        </View>

    );
}