import {FETCH_BA_BOOKING_LOCATION, FETCH_BA_BOOKING_LOCATION_FAILED, FETCH_BA_BOOKING_LOCATION_SUCCESS, FETCH_BOOKING_LOCATION, FETCH_BOOKING_LOCATION_FAILED, FETCH_BOOKING_LOCATION_SUCCESS, FETCH_NEWS_BOOKINGS_FAILED, FETCH_NEWS_BOOKINGS_SUCCESS, STOP_BA_LOCATION_FETCH, STOP_LOCATION_FETCH, UPDATE_SESSION_TOTAL_LOAD} from "../store/types";
import {language} from 'config';
import {firebase} from '../config/configureFirebase';
import {equalTo, get, limitToLast, off, onValue, orderByChild, push, query, set} from "firebase/database";
import store from "../store/store";


export const saveTracking = (bookingId, location) => {
    const {
        trackingRef
    } = firebase;
    push(trackingRef(bookingId), location);
}

export const fetchBookingLocations = (bookingId) => (dispatch) => {
    const {
        trackingRef
    } = firebase;

    dispatch({
        type: FETCH_BOOKING_LOCATION,
        payload: bookingId,
    });
    off(query(trackingRef(bookingId)));
    onValue(query(trackingRef(bookingId), limitToLast(1)), (snapshot) => {
            if (snapshot.val()) {
                let data = snapshot.val();
                const locations = Object.keys(data)
                    .map((i) => {
                        return data[i]
                    });
                if (locations.length == 1) {
                    dispatch({
                        type: FETCH_BOOKING_LOCATION_SUCCESS,
                        payload: locations[0]
                    });
                } else {
                    dispatch({
                        type: FETCH_BOOKING_LOCATION_FAILED,
                        payload: language.location_fetch_error,
                    });
                }
            } else {
                dispatch({
                    type: FETCH_BOOKING_LOCATION_FAILED,
                    payload: language.location_fetch_error,
                });
            }
        }
    );
};


export const getDriverLocations = async (uid) => {
    const {
        userLocationRef
    } = firebase;

    return await get(userLocationRef(uid)).then((snapshot) => {
        if (snapshot.val()) {
            const data = snapshot.val();
            return data;
        } else {
            return [];
        }
    });

};

export const fetchLastBaLocations = (biensoxe = null) => (dispatch) => {
    const {
        binhanhTrackingCarApiRef,
        binhanhTrackingDataApiRef
    } = firebase;
    dispatch({
        type: FETCH_BA_BOOKING_LOCATION,
        payload: biensoxe ? biensoxe : '',
    });
    if (biensoxe) {
        off(binhanhTrackingCarApiRef(biensoxe));
        onValue(binhanhTrackingCarApiRef(biensoxe), (snapshot) => {
            if (snapshot.val()) {
                let dataCar = snapshot.val();
                dispatch({
                    type: FETCH_BA_BOOKING_LOCATION_SUCCESS,
                    payload: dataCar
                });
            } else {
                dispatch({
                    type: FETCH_BA_BOOKING_LOCATION_FAILED,
                    payload: "Lỗi nạp thông tin xe",
                });
            }
        });
    } else {
        off(binhanhTrackingDataApiRef());
        onValue(binhanhTrackingDataApiRef(), (snapshot) => {
            if (snapshot.val()) {
                let dataCar = snapshot.val();
                dispatch({
                    type: FETCH_BA_BOOKING_LOCATION_SUCCESS,
                    payload: dataCar
                });
            } else {
                dispatch({
                    type: FETCH_BA_BOOKING_LOCATION_FAILED,
                    payload: "Lỗi nạp thông tin xe",
                });
            }
        });
    }
};

export const stopLocationFetch = (bookingId) => (dispatch) => {
    const {
        trackingRef
    } = firebase;

    dispatch({
        type: STOP_LOCATION_FETCH,
        payload: bookingId,
    });
    off(trackingRef(bookingId));
}

export const saveUserLocation = async (location, id = null) => {
    const {
        auth,
        userLocationRef,
    } = firebase;
    const authState = store.getState().auth;
    var uid = 'id';
    if (id) {
        uid = id;
    } else {
        uid = auth && auth.currentUser && auth.currentUser && auth.currentUser.uid ? auth.currentUser.uid : authState && authState.user ? authState.user.id : null;
    }

    let userUpdate = {...authState.user};
    userUpdate.location = location;
    store.dispatch({
        type: 'FETCH_USER_OFFLINE', payload: userUpdate
    });
    if (uid) {
        await set(userLocationRef(uid), location);
    }
}


export const stopLastBaLocationsFetch = (biensoxe = null) => (dispatch) => {

    const {
        binhanhTrackingCarApiRef,
        binhanhTrackingDataApiRef
    } = firebase;

    dispatch({
        type: STOP_BA_LOCATION_FETCH,
        payload: biensoxe ? biensoxe : '',
    });
    if (biensoxe) {
        off(binhanhTrackingCarApiRef(biensoxe));
    } else {
        off(binhanhTrackingDataApiRef());
    }

}