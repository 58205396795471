import {
    EDIT_USER,
    EDIT_USER_FAILED,
    EDIT_USER_SUCCESS,
    FETCH_ALL_DRIVERS,
    FETCH_ALL_DRIVERS_FAILED,
    FETCH_ALL_DRIVERS_QUEUE,
    FETCH_ALL_DRIVERS_QUEUE_FAILED,
    FETCH_ALL_DRIVERS_QUEUE_SUCCESS,
    FETCH_ALL_DRIVERS_SUCCESS,
    FETCH_ALL_FLEETADMIN,
    FETCH_ALL_FLEETADMIN_FAILED,
    FETCH_ALL_FLEETADMIN_SUCCESS,
    FETCH_ALL_ONCE_DRIVER,
    FETCH_ALL_ONCE_DRIVER_FAILED,
    FETCH_ALL_ONCE_DRIVER_SUCCESS,
    FETCH_ALL_ONCE_USERS,
    FETCH_ALL_ONCE_USERS_FAILED,
    FETCH_ALL_ONCE_USERS_SUCCESS,
    FETCH_ALL_USERS,
    FETCH_ALL_USERS_FAILED,
    FETCH_ALL_USERS_SUCCESS,
    FETCH_DRIVER_ACTIVE,
    FETCH_DRIVER_ACTIVE_FAILED,
    FETCH_DRIVER_ACTIVE_SUCCESS,
    FETCH_DRIVER_NEAR,
    FETCH_DRIVER_NEAR_SUCCESS,
    FETCH_DRIVER_OUT_IN_GROUP,
    FETCH_DRIVER_OUT_IN_GROUP_FAILED,
    FETCH_DRIVER_OUT_IN_GROUP_SUCCESS, FETCH_USER_OFFLINE,
    GET_ALL_DRIVERS,
    GET_ALL_DRIVERS_SUCCESS,
    SELECT_USER,
    SELECT_USER_FAILED,
    SELECT_USER_SUCCESS,
    STOP_FETCH_ALL_DRIVERS_SUCCESS,
    UPDATE_USER_GROUP,
    UPDATE_USER_LOCK_STATUS,
    UPDATE_USER_LOCK_STATUS_FAILED,
    UPDATE_USER_LOCK_STATUS_SUCCESS
} from "../store/types";
import store from "../store/store";
import {getUserValidSign, getUserValidSignV2} from "../other/CommonFunctions";
import {GetCurrentTimeStamp} from "../other/DateFunctions";
import {firebase} from '../config/configureFirebase';
import {equalTo, get, off, onValue, orderByChild, push, query, set, update} from "firebase/database";
import {saveDbFromFbToLocal} from "../other/StorageDbHelper";

export const GetDistance = (lat1, lon1, lat2, lon2) => {
    if ((lat1 === lat2) && (lon1 === lon2)) {
        return 0;
    } else {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        dist = dist * 1.609344;
        return dist;
    }
};

export const fetchUsers = () => (dispatch) => {

    const {
        usersRef,
        allLocationsRef
    } = firebase;

    dispatch({
        type: FETCH_ALL_USERS,
        payload: null
    });
    off(query(usersRef, orderByChild('order_number')));
    onValue(query(usersRef, orderByChild('order_number')), async snapshot => {
        if (snapshot.val()) {
            const locationdata = await get(allLocationsRef);
            const locations = locationdata.val();
            const data = snapshot.val();
            const arr = Object.keys(data)
                .filter(i => data[i] && data[i].usertype && data[i].usertype != 'admin')
                .map(i => {
                    data[i].id = i;
                    data[i].location = locations && locations[i] ? locations[i] : null;
                    return data[i];
                });
            dispatch({
                type: FETCH_ALL_USERS_SUCCESS,
                payload: arr
            });
        } else {
            dispatch({
                type: FETCH_ALL_USERS_FAILED,
                payload: "No users available."
            });
        }
    });
};

export const stopFetchUsers = () => {

    const {
        usersRef
    } = firebase;
    off(query(usersRef, orderByChild('order_number')));
};

export const fetchFleetUsersOnline = () => (dispatch) => {

    const {
        usersRef
    } = firebase;

    dispatch({
        type: FETCH_ALL_FLEETADMIN,
        payload: null
    });
    off(query(usersRef, orderByChild('usertype')));
    onValue(query(usersRef, orderByChild('usertype'), equalTo('fleetadmin')), snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data)
                .filter(i => data[i].approved)
                .map(i => {
                    data[i].id = i;
                    return data[i];
                });
            dispatch({
                type: FETCH_ALL_FLEETADMIN_SUCCESS,
                payload: arr
            });
        } else {
            dispatch({
                type: FETCH_ALL_FLEETADMIN_FAILED,
                payload: "No users available."
            });
        }
    });
};

export const fetchDriversOn = () => (dispatch) => {

    const {
        usersRef,
        allLocationsRef
    } = firebase;

    dispatch({
        type: FETCH_DRIVER_ACTIVE,
        payload: null
    });
    onValue(query(usersRef, orderByChild("usertype"), equalTo("driver")), async snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const locationdata = await get(allLocationsRef);
            const locations = locationdata.val();
            const arr = Object.keys(data)
                .filter(i => data[i].approved && data[i].approved === true && locations && locations[i])
                .map(i => {
                    data[i].id = i;
                    data[i].location = locations && locations[i] ? locations[i] : null;
                    return data[i];
                });
            dispatch({
                type: FETCH_DRIVER_ACTIVE_SUCCESS,
                payload: arr
            });
        } else {
            dispatch({
                type: FETCH_DRIVER_ACTIVE_FAILED,
                payload: "No users available."
            });
        }
    });

};


export const stopFetchDriversOn = () => (dispatch) => {

    const {
        usersRef,
        caTrucDemsRef
    } = firebase;
    dispatch({
        type: FETCH_DRIVER_ACTIVE_FAILED,
        payload: "No users available."
    });
    off(query(usersRef, orderByChild('vehicleNumberLeader')));
};

export const fetchOnceUsers = () => (dispatch) => {

    const {
        usersRef,
        allLocationsRef
    } = firebase;

    dispatch({
        type: FETCH_ALL_ONCE_USERS,
        payload: null
    });
    onValue(query(usersRef, orderByChild('order_number')), async snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const locationdata = await get(allLocationsRef);
            const locations = locationdata.val();
            const arr = Object.keys(data)
                .filter(i => data[i].usertype && data[i].usertype != 'admin')
                .map(i => {
                    data[i].id = i;
                    data[i].location = locations && locations[i] ? locations[i] : null;
                    return data[i];
                });
            dispatch({
                type: FETCH_ALL_ONCE_USERS_SUCCESS,
                payload: arr
            });
        } else {
            dispatch({
                type: FETCH_ALL_ONCE_USERS_FAILED,
                payload: "No users available."
            });
        }
    }, {onlyOnce: true});
};

export const fetchOnceDrivers = () => (dispatch) => {

    const {
        usersRef,
        groupdriverRef,
        groupdriverTotalRef,
        groupdriverCoordTotalRef,
        allLocationsRef
    } = firebase;

    dispatch({
        type: FETCH_ALL_ONCE_DRIVER,
        payload: null
    });

    get(query(usersRef, orderByChild("usertype"), equalTo('driver'))).then(async (snapshot) => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const locationdata = await get(allLocationsRef);
            const locations = locationdata.val();
            const arr = Object.keys(data)
                .filter(i => data[i].approved == true && locations && locations[i])
                .map(i => {
                    data[i].id = i;
                    data[i].location = locations && locations[i] ? locations[i] : null;
                    return data[i];
                });

            onValue(groupdriverRef, (snapshot) => {
                if (snapshot.val()) {
                    let data = snapshot.val();
                    for (let i = 0; i < data.length; i++) {
                        if (data[i] && data[i].value) {
                            if (data[i].value != '0' && data[i].value != '1' && data[i].value != '9' && data[i].value != '12' && data[i].lock_status == false) {
                                let drivers = arr.filter((d) => d.groupDriver == data[i].value);
                                if (data[i].group_coords) {
                                    let listKey = Object.keys(data[i].group_coords);
                                    if (listKey) {
                                        listKey.map((keyId) => {
                                            if (data[i].group_coords[keyId]) {
                                                let coord = data[i].group_coords[keyId];
                                                let driverCoords = arr.filter((d) => d.coordData && parseInt(d.coordData.coordId) === parseInt(keyId) && parseInt(d.coordData.groupId) === parseInt(data[i].value) && parseInt(d.coordData.groupId) === parseInt(d.groupDriver));
                                                if (driverCoords && coord) {
                                                    set(groupdriverCoordTotalRef(data[i].value, keyId), driverCoords.length);
                                                }
                                            }
                                        });
                                    }
                                }
                                if (drivers) {
                                    set(groupdriverTotalRef(data[i].value), drivers.length);
                                }
                            }
                        }
                    }
                }
            }, {onlyOnce: true});

            dispatch({
                type: FETCH_ALL_ONCE_DRIVER_SUCCESS,
                payload: arr
            });
        } else {
            dispatch({
                type: FETCH_ALL_ONCE_DRIVER_FAILED,
                payload: "No users available."
            });
        }
    });
};


export const getDriversCenter = async (location = null) => {

    const {
        usersRef,
        allLocationsRef,
        database
    } = firebase;

    store.dispatch({
        type: GET_ALL_DRIVERS,
        payload: null
    });
    const snapshot = await get(query(usersRef, orderByChild("approvedDriver"), equalTo('approved')));
    if (snapshot && snapshot.exists()) {
        const data = snapshot.val();
        const locationdata = await get(allLocationsRef);
        const locations = locationdata.val();
        const arr = Object.keys(data)
            .filter(i => {
                if (data && data[i].approved == true && data[i].codeDriver && data[i].vehicleNumber && data[i].timestampingroup) {
                    if (location != null && location.lat) {
                        let distance = GetDistance(location.lat, location.lng, locations[i].lat, locations[i].lng);
                        if (distance < 3 && data[i].driverActiveStatus == true && locations[i] && data[i].queue == false) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return true;
                    }
                } else {
                    return false;
                }
            })
            .map(i => {
                return {
                    id: i,
                    location: locations && locations[i] ? locations[i] : (data[i].location && data[i].location.lat ? data[i].location : null),
                    carType: data[i].carType ? data[i].carType : null,
                    vehicleNumber: data[i].vehicleNumber ? data[i].vehicleNumber : null,
                    firstName: data[i].firstName,
                    driverActiveStatus: data[i].driverActiveStatus,
                    codeDriver: data[i].codeDriver,
                    carTypeCode: data[i].carTypeCode,
                    pushToken: data[i].pushToken,
                    mobile: data[i].mobile,
                    statusLostConnect: data[i].statusLostConnect,
                    timestampingroup: data[i].timestampingroup,
                    indexGroup: data[i].indexGroup ? data[i].indexGroup : 0,
                    lock_driver_status: data[i].lock_driver_status,
                    lastName: data[i].lastName,
                    queue: data[i].queue,
                    timeUpdate: locations && locations[i] ? locations[i].at : (data[i].location && data[i].location.lat ? data[i].location.at : null)
                };
            });

        store.dispatch({
            type: GET_ALL_DRIVERS_SUCCESS,
            payload: arr
        });
    }
};


export const fetchDriversOutInGroup = (bankinh, carType = null, pickup, group = null, currentAreaId = null) => (dispatch) => {

    const {
        usersRef,
        allLocationsRef
    } = firebase;

    dispatch({
        type: FETCH_DRIVER_OUT_IN_GROUP,
        payload: null
    });
    var resultOut = [];
    var resultIn = [];
    var resultInArea = [];
    let cars = store.getState().cartypes.cars;
    const groups = store.getState().groupdriverdata.group_kd;
    const profile = store.getState().auth.user;
    let settings = store.getState().settingsdata.settings;
    var group_info = null;
    var driver_favourite = null;
    var currentPhone = null;
    const listUserLostConnect = store.getState().sessiondata.listConnect;
    if (profile && profile.driver_favourite) {
        driver_favourite = store.getState().auth.user.driver_favourite;
    }
    if (profile && profile.mobile) {
        currentPhone = profile.mobile.replace("+840", "0");
    }
    if (store.getState().groupdriverdata && store.getState().groupdriverdata.group_kd && group) {
        group_info = store.getState().groupdriverdata.group_kd.filter((g) => g.value == group)[0];
    }

    if (cars && cars.length > 0) {
        let carSelect = cars.filter((car) => car.code === carType);
        var listDrivers = [];
        if (carSelect[0]) {
            let carSelected = carSelect[0];
            if (carSelected.driverCodes) {
                listDrivers = carSelected.driverCodes.split(",");
            }
        }

        var groupValid = null;
        if (group && groups) {
            groupValid = groups.filter((g) => {
                if (g.value && g.value == group) {
                    return true;
                }
            })[0];
        }
        const promises = [];

        get(query(usersRef, orderByChild("usertype"), equalTo('driver'))).then(async (snapshot) => {
            if (snapshot.val()) {
                const data = snapshot.val();
                const locationdata = await get(allLocationsRef);
                const locations = locationdata.val();

                /* if (!array.includes(item)) {
                     console.log("Item is not in the array");
                 } else {
                     console.log("Item is in the array");
                 }*/
                var users = Object.keys(data)
                    .filter(i =>
                        data[i].pushToken && data[i].codeDriver && data[i].groupDriver
                        && data[i].queue === false && data[i].driverActiveStatus === true && data[i].approved === true
                        && data[i].usertype && !data[i].lock_driver_status
                        && data[i].approvedDriver === 'approved'
                        && locations && locations[i]
                        && data[i].carTypeCode.trim() === carType
                        && data[i].codeDriver.trim() != profile.codeDriver.trim()
                        && !listUserLostConnect.includes(data[i].codeDriver)
                    )
                    .map(i => {
                        data[i].id = i;
                        data[i].location = locations && locations[i] ? locations[i] : null;
                        return data[i];
                    });

                /*if (driver_favourite !== null) {
                    users = users.filter(u2 => driver_favourite[u2.id] && driver_favourite[u2.id].status !== false);
                }

                if (currentPhone) {
                    users = users.filter(u2 => !u2.river_favourite || !u2.river_favourite[currentPhone]);
                }*/
                console.log("users", users);
                const promises = users.map(usr => getUserValidSign(usr, bankinh, pickup, resultOut));
                await Promise.all(promises);
            }

            if (group_info !== null && group_info.codes) {
                let listDriverCode = group_info.codes.split(',');
                let newResultIn = [];
                if (resultIn.length > 0) {
                    resultIn.map((driver) => {
                        if (driver.codeDriver && listDriverCode.includes(driver.codeDriver.toString().trim())) {
                            newResultIn.push(driver);
                        }
                    });
                }
                //{id: usr.id, codeDriver: usr.codeDriver.toString().trim(), pushToken: usr.pushToken, location: usr.location}
                if (settings && settings.approve_by_px) {
                    dispatch({
                        type: FETCH_DRIVER_OUT_IN_GROUP_SUCCESS,
                        payload: {driverInGroup: newResultIn, driverOutGroup: resultOut, driverInArea: resultInArea}
                    });
                } else {
                    dispatch({
                        type: FETCH_DRIVER_OUT_IN_GROUP_SUCCESS,
                        payload: {driverInGroup: newResultIn, driverOutGroup: resultOut, driverInArea: []}
                    });
                }

            } else {
                if (settings && settings.approve_by_px) {
                    dispatch({
                        type: FETCH_DRIVER_OUT_IN_GROUP_SUCCESS,
                        payload: {driverInGroup: resultIn, driverOutGroup: resultOut, driverInArea: resultInArea}
                    });
                } else {
                    dispatch({
                        type: FETCH_DRIVER_OUT_IN_GROUP_SUCCESS,
                        payload: {driverInGroup: resultIn, driverOutGroup: resultOut, driverInArea: []}
                    });
                }

            }
            console.log("result12121Out", resultOut);
        }).catch(error => console.log("error 12", error));
    }
};

export const getDriversOutInGroupV2 = async (bankinh, carType = null, pickup) => {

    const {
        usersRef,
        allLocationsRef
    } = firebase;

    var resultOut = [];
    var resultIn = [];
    var resultInArea = [];
    let cars = store.getState().cartypes.cars;
    const groups = store.getState().groupdriverdata.group_kd;
    const profile = store.getState().auth.user;
    let settings = store.getState().settingsdata.settings;
    var group_info = null;
    const listUserLostConnect = store.getState().sessiondata.listConnect;
    if (cars && cars.length > 0) {
        let snapshot = await get(query(usersRef, orderByChild("usertype"), equalTo('driver')));
        if (snapshot && snapshot.val()) {
            const data = snapshot.val();
            const locationdata = await get(allLocationsRef);
            const locations = locationdata.val();
            var users = Object.keys(data)
                .filter(i =>
                    data[i].pushToken && data[i].codeDriver && data[i].groupDriver
                    && data[i].queue === false && data[i].driverActiveStatus === true && data[i].approved === true
                    && data[i].usertype && !data[i].lock_driver_status
                    && data[i].approvedDriver === 'approved'
                    && locations && locations[i]
                    && data[i].carTypeCode.trim() === carType
                    && data[i].codeDriver.trim() != profile.codeDriver.trim()
                    && !listUserLostConnect.includes(data[i].codeDriver)
                )
                .map(i => {
                    data[i].id = i;
                    data[i].location = locations && locations[i] ? locations[i] : null;
                    return data[i];
                });
            for (let i = 0; i < users.length; i++) {
                let usr = users[i];
                let u = getUserValidSignV2(usr, bankinh, pickup);
                if (u) {
                    resultOut.push(u);
                }
            }
        }

        if (group_info !== null && group_info.codes) {
            let listDriverCode = group_info.codes.split(',');
            let newResultIn = [];
            if (resultIn.length > 0) {
                resultIn.map((driver) => {
                    if (driver.codeDriver && listDriverCode.includes(driver.codeDriver.toString().trim())) {
                        newResultIn.push(driver);
                    }
                });
            }
            //{id: usr.id, codeDriver: usr.codeDriver.toString().trim(), pushToken: usr.pushToken, location: usr.location}
            if (settings && settings.approve_by_px) {
                return {driverInGroup: newResultIn, driverOutGroup: resultOut, driverInArea: resultInArea};
            } else {
                return {driverInGroup: newResultIn, driverOutGroup: resultOut, driverInArea: []}
            }

        } else {
            if (settings && settings.approve_by_px) {
                return {driverInGroup: resultIn, driverOutGroup: resultOut, driverInArea: resultInArea}
            } else {
                return {driverInGroup: resultIn, driverOutGroup: resultOut, driverInArea: []}
            }
        }
    } else {
        return {driverInGroup: resultIn, driverOutGroup: resultOut, driverInArea: []}
    }
};

export const fetchNearDriver = (bankinh) => (dispatch) => {
    const {
        usersRef,
        allLocationsRef
    } = firebase;

    dispatch({
        type: FETCH_DRIVER_NEAR,
        payload: null
    });
    const profile = store.getState().auth.info.profile;
    get(query(usersRef, orderByChild("driverActiveStatus"), equalTo(true))).then(async (snapshot) => {
        if (snapshot.val()) {
            const locationdata = await get(allLocationsRef);
            const locations = locationdata.val();

            const data = snapshot.val();
            var users = Object.keys(data)
                .filter(i => {
                    if (data[i].pushToken && data[i].codeDriver
                        && data[i].approved === true
                        && data[i].usertype && !data[i].lock_driver_status && data[i].usertype == 'driver'
                        && data[i].approvedDriver == 'approved'
                        && data[i].codeDriver.trim() != profile.codeDriver.trim()
                        && locations && locations[i]
                    ) {
                        let driverLocation = locations && locations[i] ? locations[i] : data[i].location;
                        if (driverLocation && driverLocation.lat) {
                            let distance = GetDistance(profile.location.lat, profile.location.lng, driverLocation.lat, driverLocation.lng);
                            if (distance <= bankinh) {
                                return true;
                            }
                        }
                        return false;
                    } else {
                        return false;
                    }
                })
                .map(i => {
                    let usr = data[i];
                    let acceptObj = {
                        code: usr.codeDriver,
                        phone: usr.mobile.replace("+84", ''),
                        name: usr.firstName + " " + usr.firstName,
                        vehicle_number: usr.vehicleNumber ? usr.vehicleNumber : '',
                        avatar: usr.avatar ? usr.avatar : '',
                        queue: usr.queue,
                        vehicleModel: usr.vehicleModel ? usr.vehicleModel : '',
                        totalBook: usr.totalBook ? usr.totalBook : 0,
                        per: usr.per && usr.driverVipStatus ? usr.per : 0,
                        perUse: usr.perUse && usr.perUse ? usr.perUse : 0,
                        contact_limited: usr.contact_limited ? usr.contact_limited : 0,
                        vip_limit: usr.vip_limit ? usr.vip_limit : 0,
                        signupViaReferral: usr.signupViaReferral ? usr.signupViaReferral : '',
                        rate: usr.ratings && usr.ratings.userrating ? usr.ratings.userrating : 0,
                        carType: usr.type ? usr.type : 'taxi',
                        wallet: usr.walletBalance ? usr.walletBalance : 0,
                        uid: usr.uid,
                        driverVipStatus: usr.driverVipStatus ? usr.driverVipStatus : false,
                        timestamp: usr.timestampingroup ? usr.timestampingroup : GetCurrentTimeStamp(),
                        id: i, codeDriver: usr.codeDriver.toString().trim(), pushToken: usr.pushToken,
                        location: locations && locations[i] ? locations[i] : usr.location,
                        carTypeCode: usr.carTypeCode
                    }

                    return acceptObj;
                });
            dispatch({
                type: FETCH_DRIVER_NEAR_SUCCESS,
                payload: users
            });
        }
    }).catch(error => console.log("error 12", error));
};


export const fetchAllDrivers = (isOnly = false) => (dispatch) => {

    const {
        driversRef,
        allLocationsRef
    } = firebase;

    dispatch({
        type: FETCH_ALL_DRIVERS,
        drivers: null
    });

    onValue(driversRef, snapshot => {
        if (snapshot.val()) {
            onValue(allLocationsRef, locres => {
                const locations = locres.val();
                const data = snapshot.val();
                const arr = Object.keys(data)
                    .filter(i => data && data[i].approved == true && data[i].codeDriver && data[i].vehicleNumber && data[i].timestampingroup)
                    .map(i => {
                        return {
                            id: i,
                            location: locations && locations[i] ? locations[i] : (data[i].location && data[i].location.lat ? data[i].location : null),
                            carType: data[i].carType ? data[i].carType : null,
                            vehicleNumber: data[i].vehicleNumber ? data[i].vehicleNumber : null,
                            firstName: data[i].firstName,
                            driverActiveStatus: data[i].driverActiveStatus,
                            codeDriver: data[i].codeDriver,
                            carTypeCode: data[i].carTypeCode,
                            mobile: data[i].mobile,
                            timestampingroup: data[i].timestampingroup,
                            indexGroup: data[i].indexGroup ? data[i].indexGroup : 0,
                            lastName: data[i].lastName,
                            queue: data[i].queue
                        };
                    });
                dispatch({
                    type: FETCH_ALL_DRIVERS_SUCCESS,
                    payload: arr
                });
            },)
        } else {
            dispatch({
                type: FETCH_ALL_DRIVERS_FAILED,
                payload: "No users available."
            });
        }
    }, {onlyOnce: isOnly});
};

export const fetchAllDriversOff = () => (dispatch) => {
    const {
        usersRef
    } = firebase;
    dispatch({
        type: STOP_FETCH_ALL_DRIVERS_SUCCESS,
        drivers: null,
    });
    off(query(usersRef, orderByChild("usertype"), equalTo("driver")));
}


export const fetchAllDriversQueue = () => (dispatch) => {

    const {
        usersRef,
        allLocationsRef
    } = firebase;

    dispatch({
        type: FETCH_ALL_DRIVERS_QUEUE,
        driversqueue: null
    });
    off(query(usersRef, orderByChild("usertype"), equalTo("driver")));
    onValue(query(usersRef, orderByChild("queue"), equalTo(true)), async snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const locationdata = await get(allLocationsRef);
            const locations = locationdata.val();
            const arr = Object.keys(data)
                .filter(i => data[i].groupDriver !== '' && data[i].approved === true && locations && locations[i])
                .map(i => {
                    data[i].id = i;
                    data[i].location = locations && locations[i] ? locations[i] : null;
                    if (data[i].queue) {
                        data[i].carStatus = 'xe341.png';
                    } else {
                        if (data[i].driverActiveStatus === false) {
                            data[i].carStatus = 'xe040.png';
                        } else {
                            data[i].carStatus = 'xe042.png';
                        }
                    }
                    return data[i];
                });
            dispatch({
                type: FETCH_ALL_DRIVERS_QUEUE_SUCCESS,
                driversqueue: arr
            });
        } else {
            dispatch({
                type: FETCH_ALL_DRIVERS_QUEUE_FAILED,
                driversqueue: "No users available."
            });
        }
    });
};


export const fetchAllDriversQueueOff = () => (dispatch) => {

    const {
        usersRef
    } = firebase;
    off(query(usersRef, orderByChild("queue"), equalTo(true)));
};


export const addUser = (userdata) => (dispatch) => {
    const {
        usersRef
    } = firebase;

    dispatch({
        type: EDIT_USER,
        payload: userdata
    });
    let countUser = 0;
    onValue(usersRef, snapshot => {
        countUser = Object.keys(snapshot.val()).length;
    });

    userdata.order_number = countUser + 1;
    push(usersRef, userdata).then(() => {
        dispatch({
            type: EDIT_USER_SUCCESS,
            payload: null
        });
    }).catch((error) => {
        dispatch({
            type: EDIT_USER_FAILED,
            payload: error
        });
    });
}

export const editUser = (id, userData) => (dispatch) => {

    const {
        singleUserRef,
        usersRef
    } = firebase;

    dispatch({
        type: EDIT_USER,
        payload: userData
    });

    if (!userData.order_number) {
        let countUser = 0;
        onValue(usersRef, snapshot => {
            countUser = Object.keys(snapshot.val()).length;
        });
        userData.order_number = countUser + 1;
    }

    update(singleUserRef(id), userData).then((res) => {
        dispatch({
            type: EDIT_USER_SUCCESS,
            payload: null
        });
    }).catch((error) => {
        dispatch({
            type: EDIT_USER_FAILED,
            payload: error
        });
    });
}


export const updateUserGroup = (uid, groupDriver) => (dispatch) => {
    const {
        singleUserRef,
    } = firebase;

    dispatch({
        type: UPDATE_USER_GROUP,
        payload: null
    });

    onValue(singleUserRef(uid), snapshot => {
        if (snapshot.val()) {
            var userDetail = snapshot.val();
            userDetail.groupDriver = groupDriver;
            set(singleUserRef(uid), userDetail).then(() => {
                dispatch({
                    type: EDIT_USER_SUCCESS,
                    payload: "Cập nhật nhóm thành công"
                });
            }).catch(error => {
                dispatch({
                    type: EDIT_USER_FAILED,
                    payload: "Có lỗi xảy ra trong khi cập nhật nhóm"
                });
            });
        }
    }, {onlyOnce: true});


};

export const updateUserLockStatus = (uid, status) => (dispatch) => {
    const {
        singleUserRef,
    } = firebase;

    dispatch({
        type: UPDATE_USER_LOCK_STATUS,
        payload: null
    });

    onValue(singleUserRef(uid), snapshot => {
        if (snapshot.val()) {
            var userDetail = snapshot.val();
            userDetail.lock_driver_status = status ? status : false;
            if (status) {
                userDetail.lock_driver_time = GetCurrentTimeStamp();
            } else {
                userDetail.lock_driver_time = 0;
            }
            set(singleUserRef(uid), userDetail).then(() => {
                dispatch({
                    type: UPDATE_USER_LOCK_STATUS_SUCCESS,
                    payload: "Cập nhật Trạng thái thành công"
                });

            }).catch(error => {
                dispatch({
                    type: UPDATE_USER_LOCK_STATUS_FAILED,
                    payload: "Có lỗi xảy ra trong khi cập nhật Trạng thái"
                });
            });
        }
    }, {onlyOnce: true});
};

export const updateUserLocation = async (uid, data) => {
    const {
        refApi
    } = firebase;

    await refApi.saveUserLocation(uid, data);
};


export const getUserDetail = (id) => (dispatch) => {
    const {
        singleUserRef
    } = firebase;

    dispatch({
        type: SELECT_USER,
        user_detail: null
    });
    query(singleUserRef(id));
    onValue(singleUserRef(id), function (snapshot) {
        if (snapshot.val()) {
            const data = snapshot.val();
            data.id = id;
            dispatch({
                type: SELECT_USER_SUCCESS,
                user_detail: data
            });
        } else {
            dispatch({
                type: SELECT_USER_FAILED,
                user_detail: "No users available."
            });
        }
    });
}

export const removeSignupViaReferralCloud = async (uid) => {
    let result = await fetch(`https://asia-east1-eto-viet.cloudfunctions.net/remove_signup_via_referral`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "uid": uid
        })
    });
    return result;
}


export const getSignupViaReferral = async (mobileS) => {
    const {
        usersRef
    } = firebase;


    return await get(query(usersRef, orderByChild("mobile"), equalTo(mobileS))).then((snapshot) => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data)
                .map(i => {
                    data[i].id = i;
                    return data[i];
                });

            return arr[0];
        } else {
            return [];
        }
    });
}

export const getSignupViaReferralReg = async (mobileS) => {
    const {
        usersRef
    } = firebase;
    return await get(query(usersRef, orderByChild("signupViaReferral"), equalTo(mobileS))).then((snapshot) => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data)
                .map(i => {
                    data[i].id = i;
                    return data[i];
                });
            return arr
        } else {
            return [];
        }
    });
}


export const updatePer = (uid, totalBook) => (dispatch) => {
    const {
        singleUserRef
    } = firebase;
    if (uid && uid != 'undefined') {
        update(singleUserRef(uid), {per: totalBook ? totalBook : 0});
    }
};

export const updateQueueAccept = async (uid, value) => {
    const {
        singleUserRef
    } = firebase;
    if (uid && uid != 'undefined') {
        await update(singleUserRef(uid), {queueAccepted: value ? value : false});
    }
};


