import {EDIT_CATRUCDEM_CONFIG, FETCH_CATRUCDEM_CONFIG, FETCH_CATRUCDEM_CONFIG_FAILED, FETCH_CATRUCDEM_CONFIG_SUCCESS, STOP_FETCH_CATRUCDEM_CONFIG} from "../store/types";
import {language} from 'config';
import {firebase} from '../config/configureFirebase';
import {off, onValue, set} from "firebase/database";

export const fetchCaTrucDemConfig = () => (dispatch) => {
    const {
        caTrucDemsConfigRef
    } = firebase;

    dispatch({
        type: FETCH_CATRUCDEM_CONFIG,
        payload: null
    });

    off(caTrucDemsConfigRef);
    onValue(caTrucDemsConfigRef, snapshot => {
        if (snapshot.val()) {
            dispatch({
                type: FETCH_CATRUCDEM_CONFIG_SUCCESS,
                payload: snapshot.val()
            });
        } else {
            dispatch({
                type: FETCH_CATRUCDEM_CONFIG_FAILED,
                payload: language.no_ca_truc_dem
            });
        }
    });
};

export const stopFetchCaTrucDemConfig = () => (dispatch) => {
    const {
        caTrucDemsConfigRef
    } = firebase;

    dispatch({
        type: STOP_FETCH_CATRUCDEM_CONFIG,
        payload: null
    });

    off(caTrucDemsConfigRef);
};

export const editCaTrucDemConfig = (catrucdemconfigs, method) => (dispatch) => {

    const {
        caTrucDemsConfigRef
    } = firebase;

    dispatch({
        type: EDIT_CATRUCDEM_CONFIG,
        payload: method
    });
    set(caTrucDemsConfigRef, catrucdemconfigs);
}

