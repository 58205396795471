import {CLEAR_SETTINGS_ERROR, EDIT_SETTINGS, FETCH_SETTINGS, FETCH_SETTINGS_FAILED, FETCH_SETTINGS_SUCCESS, UPDATE_SESSION_TOTAL_LOAD} from "../store/types";

import {AppDetails, FirebaseConfig, language, mainUrl,} from 'config';
import {firebase} from '../config/configureFirebase';
import {get, off, onValue, set} from "firebase/database";
import store from "../store/store";
import {deleteLocal, saveDbFromFbToLocal} from "../other/StorageDbHelper";

export const fetchSettings = () => (dispatch) => {

    const {
        settingsRef
    } = firebase;

    dispatch({
        type: FETCH_SETTINGS,
        payload: null,
    });
    off(settingsRef);
    onValue(settingsRef, async (snapshot) => {
        if (snapshot.val()) {

            const totalLoad = store.getState().sessiondata.totalLoadData ? parseInt(store.getState().sessiondata.totalLoadData) : 0;
            dispatch({
                type: UPDATE_SESSION_TOTAL_LOAD, payload: totalLoad + 1
            });

            dispatch({
                type: FETCH_SETTINGS_SUCCESS,
                payload: snapshot.val(),
            });
            await saveDbFromFbToLocal(FETCH_SETTINGS_SUCCESS, snapshot.val());
        } else {
            dispatch({
                type: FETCH_SETTINGS_FAILED,
                payload: language.settings_error,
            });
            await deleteLocal(FETCH_SETTINGS_SUCCESS);
        }
    });
};

export const editSettings = (settings) => (dispatch) => {

    const {
        settingsRef
    } = firebase;

    if (settings.license) {
        try {
            fetch(`https://us-central1-seradd.${mainUrl}/baseset`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    license: settings.license ? settings.license : ' ',
                    contact_email: settings.license ? settings.contact_email : ' ',
                    app_name: AppDetails.app_name,
                    app_identifier: AppDetails.app_identifier,
                    projectId: FirebaseConfig.projectId,
                    createTime: new Date().toISOString(),
                    reqType: 'settings'
                })
            }).then(response => response.json())
                .then((res) => {
                    if (res.success) {
                        dispatch({
                            type: EDIT_SETTINGS,
                            payload: settings
                        });
                        set(settingsRef, settings);
                        alert(language.updated);
                    } else {
                        alert(language.wrong_code);
                    }
                }).catch(error => {
                console.log(error);
            })
        } catch (error) {
            console.log(error);
        }
    } else {
        dispatch({
            type: EDIT_SETTINGS,
            payload: settings
        });
        set(settingsRef, settings);
        alert(language.updated);
    }
};

export const upCountViewSettings = () => (dispatch) => {
    const {
        settingsCountViewRef
    } = firebase;

    try {
        onValue(settingsCountViewRef, (snapshot) => {
            if (snapshot.val()) {
                let count = snapshot.val();
                set(settingsCountViewRef, count + 1);
            } else {
                let count = 0;
                set(settingsCountViewRef, count + 1);
            }
        }, {onlyOnce: true});
    } catch (error) {
        console.log(error);
    }
};

export const getSettingData = async () => {
    const {
        settingsRef
    } = firebase;

    try {
        const settingdata = await get(settingsRef);
        return settingdata.val();
    } catch (error) {
        console.log('error 1', error);
        return null;
    }
};


export const clearSettingsViewError = () => (dispatch) => {
    dispatch({
        type: CLEAR_SETTINGS_ERROR,
        payload: null
    });
};
