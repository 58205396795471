import {CLEAR_PAYMENT_MESSAGES, FETCH_PAYMENT_METHODS, FETCH_PAYMENT_METHODS_FAILED, FETCH_PAYMENT_METHODS_SUCCESS, UPDATE_REFUND_BALANCE, UPDATE_REFUND_BALANCE_FAILED, UPDATE_REFUND_BALANCE_SUCCESS, UPDATE_WALLET_BALANCE, UPDATE_WALLET_BALANCE_FAILED, UPDATE_WALLET_BALANCE_SUCCESS} from "../store/types";

import {cloud_function_server_url, cloud_function_server_url_asian, language} from 'config';
import {RequestPushMsg} from '../other/NotificationFunctions';
import {generateTripCode, GetCurrentMiniTimeStamp} from "../other/DateFunctions";
import {firebase} from '../config/configureFirebase';
import {onValue, push, set} from "firebase/database";

export const fetchPaymentMethods = () => (dispatch) => {

    dispatch({
        type: FETCH_PAYMENT_METHODS,
        payload: null,
    });
    fetch(cloud_function_server_url_asian + '/get_providers', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json())
        .then((responseJson) => {
            if (responseJson.length > 0) {
                dispatch({
                    type: FETCH_PAYMENT_METHODS_SUCCESS,
                    payload: responseJson,
                });
            } else {
                dispatch({
                    type: FETCH_PAYMENT_METHODS_FAILED,
                    payload: language.no_provider_found,
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: FETCH_PAYMENT_METHODS_FAILED,
                payload: language.provider_fetch_error + ": " + error.toString(),
            });
        });
};

export const clearMessage = () => (dispatch) => {
    dispatch({
        type: CLEAR_PAYMENT_MESSAGES,
        payload: null,
    });
};


export const addToWallet = (uid, amount) => (dispatch) => {
    const {
        walletBalRef,
        walletHistoryRef,
        singleUserRef
    } = firebase;

    dispatch({
        type: UPDATE_WALLET_BALANCE,
        payload: null
    });

    onValue(singleUserRef(uid), snapshot => {
        if (snapshot.val()) {
            let walletBalance = snapshot.val().walletBalance ? parseFloat(snapshot.val().walletBalance) : 0;
            walletBalance = walletBalance + amount;
            let details = {
                type: 'Credit',
                amount: amount ? amount : 0,
                date: GetCurrentMiniTimeStamp(),
                txRef: 'AdminCredit'
            }
            let balanceUpdate = walletBalance ? walletBalance : 0;
            set(walletBalRef(uid), balanceUpdate).then(() => {
                push(walletHistoryRef(uid), details).then(() => {
                    dispatch({
                        type: UPDATE_WALLET_BALANCE_SUCCESS,
                        payload: language.wallet_updated
                    });
                }).catch(error => {
                    dispatch({
                        type: UPDATE_WALLET_BALANCE_FAILED,
                        payload: error.code + ": " + error.message,
                    });
                })
                RequestPushMsg(snapshot.val().pushToken, language.notification_title, language.wallet_updated);
            }).catch(error => {
                dispatch({
                    type: UPDATE_WALLET_BALANCE_FAILED,
                    payload: error.code + ": " + error.message,
                });
            });

        }
    }, {onlyOnce: true});
};


export const updateWalletBalance = (balance, details, uid = null) => (dispatch) => {

    const {
        walletBalRef,
        walletHistoryRef,
        auth,
        singleUserRef,
        withdrawRef
    } = firebase;

    if (uid) {
    } else {
        uid = auth.currentUser.uid;
    }

    dispatch({
        type: UPDATE_WALLET_BALANCE,
        payload: null
    });
    let balanceValue = balance ? balance : 0;
    set(walletBalRef(uid), balanceValue).then(() => {
        push(walletHistoryRef(uid), details).then(() => {
            onValue(singleUserRef(uid), snapshot => {
                if (snapshot.val()) {
                    let profile = snapshot.val();

                    dispatch({
                        type: UPDATE_WALLET_BALANCE_SUCCESS,
                        payload: language.wallet_updated
                    });

                    RequestPushMsg(snapshot.val().pushToken, language.notification_title, language.wallet_updated);
                    if (details.type == 'Withdraw') {
                        push(withdrawRef, {
                            uid: uid,
                            name: profile.firstName + ' ' + profile.lastName,
                            amount: details.amount ? parseFloat(details.amount) : 0,
                            date: details.date,
                            bankName: profile.bankName ? profile.bankName : '',
                            bankCode: profile.bankCode ? profile.bankCode : '',
                            bankAccount: profile.bankAccount ? profile.bankAccount : '',
                            processed: false
                        });
                    }
                }
            }, {onlyOnce: true});
        }).catch(error => {
            dispatch({
                type: UPDATE_WALLET_BALANCE_FAILED,
                payload: error.code + ": " + error.message,
            });
        })
    }).catch(error => {
        dispatch({
            type: UPDATE_WALLET_BALANCE_FAILED,
            payload: error.code + ": " + error.message,
        });
    });
};

export const refundMoney = (money, uid, bookingId) => (dispatch) => {
    const {
        walletBalRef,
        walletHistoryRef,
        singleUserRef
    } = firebase;
    dispatch({
        type: UPDATE_REFUND_BALANCE,
        payload: null
    });
    onValue(singleUserRef(uid), snapshot => {
        if (snapshot.val()) {
            let profile = snapshot.val();
            let currentBalance = parseFloat(profile.walletBalance);
            set(walletBalRef(uid), currentBalance + money).then(() => {
                let details = {
                    type: 'Refund',
                    amount: money ? money : 0,
                    date: GetCurrentMiniTimeStamp(),
                    txRef: bookingId ? bookingId : GetCurrentMiniTimeStamp(),
                    transaction_id: generateTripCode(10)
                }

                push(walletHistoryRef(uid), details).then(() => {
                    dispatch({
                        type: UPDATE_REFUND_BALANCE_SUCCESS,
                        payload: null
                    });
                    RequestPushMsg(profile.pushToken, language.notification_title, "Tiền tạm giữ cuốc khách đã được hoàn trả");
                })
            })
        } else {
            dispatch({
                type: UPDATE_REFUND_BALANCE_FAILED,
                payload: null
            });
        }
    }, {onlyOnce: true});
}

