import React, {useContext, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from "@material-ui/core/FormControl";
import {HiddenPhone} from "common/src/other/CommonFunctions";
import {Text} from "react-native-web";
import {useDispatch, useSelector} from "react-redux";
import {FirebaseContext, api, store} from "common";
import {onValue, set, update, get, equalTo, orderByChild, query, remove} from "firebase/database";
import {CALL_DETAIL_DATA} from "common/src/store/types";

const useStyles = makeStyles(theme => ({
    titleList: {
        fontSize: 14,
        marginTop: 10
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
        height: 39,
    },
    backgroundRed: {
        backgroundColor: "rgba(255,179,179,0.74)",
        flexDirection: 'column'
    },
    row: {
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: "rgba(255,0,0,0.74)"
    },
    inputdimmed: {
        fontSize: 18,
        color: "#737373"
    },
    input: {
        fontSize: 18,
        color: "#000"
    },
    carphoto: {
        height: '18px',
        marginRight: '10px'
    },
}));

export default function CancelCallCenter(props) {
    const styles = useStyles();
    const {vipReportIdRef, vipReportRef, singleUserRef, singleBookingRef} = useContext(FirebaseContext);
    const {
        updateCallCancelById,
        cancelBooking
    } = api;
    const dispatch = useDispatch();
    const [listReason, setListReason] = useState([]);
    const [reason, setReason] = useState(null);
    const cancelreasondata = useSelector(state => state.cancelreasondata);


    const handleChangeReason = (event, value) => {
        setReason(value);
    };

    useEffect(() => {
        if (props.fromTT) {
            let reasons = [];
            if (cancelreasondata && cancelreasondata.complex) {
                cancelreasondata.complex.filter((r) => r.type == 'system').map((c) => {
                    reasons.push(c.label);
                })
            }
            setListReason(reasons);
        } else {
            if (listReason) {
                setListReason([]);
            }
        }
    }, [props.fromTT]);

    return (
        <FormControl>
            <Grid container spacing={1} style={{minHeight: 63}}>
                <Grid item sm={12} md={12} lg={12} style={{paddingTop: 28}}>
                    <Text>{"Hủy cuộc gọi"}</Text>
                    <Text>{" - " + props.time + " ||| SĐT: " + HiddenPhone(props.phone)}</Text>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                    {props.listLyDo || listReason ?
                        <Autocomplete
                            value={reason}
                            freeSolo
                            autoSelect
                            disablePortal
                            defaultValue={props.listLyDo[0]}
                            id={"reason_" + props.uniqueid}
                            options={listReason && listReason.length > 0 ? listReason.map((option) => option) : props.listLyDo.map((option) => option)}
                            onChange={handleChangeReason}
                            renderInput={(params) => <TextField {...params} label="Lý do khách hủy 2"/>}
                        /> : null}
                </Grid>
                <Grid item xs={5} sm={5} md={5} lg={5}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        id={"buttonreason_" + props.uniqueid}
                        size="small"
                        className={styles.submit}
                        onClick={() => {
                            var auth = store.getState().auth ? store.getState().auth.user : null;
                            if (props.fromTT && props.booking_id ) {
                                onValue(singleBookingRef(props.booking_id), (snapshot) => {
                                    let data = snapshot.val();
                                    data.id = props.booking_id;
                                    /*if (data.customer_wish) {
                                        alert("Khách đã lên xe, bạn không thể hủy chuyến đi");
                                    } else {

                                    }*/

                                    setTimeout(async () => {
                                        let dataR = {
                                            status: "cancel",
                                            reason: reason ? reason : "Không xác định",
                                            cancel_by: 'system'
                                        }
                                        dispatch(updateCallCancelById(props.firebase_id, dataR));

                                        // Lý do khách huỷ khôi phục điểm VIP
                                        /*if (data && data.trip_code) {
                                            let snapshotB = await get(query(vipReportRef(), orderByChild("codeId"), equalTo(props.booking_id)));
                                            let etoByTripcode = snapshotB && snapshotB.val() ? snapshotB.val() : null;
                                            var hasRemoveEto = false;
                                            var hasRecoverLimit = false;
                                            var hasRecoverETO = false;
                                            if (etoByTripcode) {
                                                const arrB = Object.keys(etoByTripcode)
                                                    .filter(i => etoByTripcode[i] && etoByTripcode[i].usertype && etoByTripcode[i].usertype != 'admin')
                                                    .map(i => {
                                                        etoByTripcode[i].id = i;
                                                        return etoByTripcode[i];
                                                    });
                                                if (arrB) {
                                                    await remove(vipReportIdRef(arrB[0].id));
                                                    hasRemoveEto = true;
                                                }
                                            }

                                            let snapshotBW = await get(query(vipReportRef(), orderByChild("newCode"), equalTo(props.booking_id)));
                                            let etoByTripcodeW = snapshotBW && snapshotBW.val() ? snapshotBW.val() : null;


                                            if (data.hasVipLimitStatus) {
                                                onValue(singleUserRef(data.driver), (snapshotUser) => {
                                                    if (snapshotUser.val()) {
                                                        let oldVipLimit = snapshotUser.val().vip_limit ? snapshotUser.val().vip_limit : 0;
                                                        set(singleUserRef(data.driver + '/vip_limit'), oldVipLimit + 1);
                                                        hasRecoverLimit = true;
                                                    }
                                                }, {onlyOnce: true});
                                            }

                                            if (etoByTripcodeW) {
                                                if (data.hasVipStatus) {
                                                    const arrC = Object.keys(etoByTripcodeW)
                                                        .filter(i => etoByTripcodeW[i] && etoByTripcodeW[i].valueStatus == 'giam')
                                                        .map(i => {
                                                            etoByTripcodeW[i].id = i;
                                                            return etoByTripcodeW[i];
                                                        });
                                                    if (arrC) {
                                                        await update(vipReportIdRef(arrC[0].id), {
                                                            end: '',
                                                            valueStatus: 'tang',
                                                            newWithCodeDriver: '',
                                                            withId: '',
                                                            newCode: '',
                                                            reason: reason ? reason : "Không xác định"
                                                        });
                                                        hasRecoverETO = true;
                                                    }

                                                    onValue(singleUserRef(data.driver), (snapshotUser) => {
                                                        if (snapshotUser.val()) {
                                                            let oldVipLimit = snapshotUser.val().perUse ? snapshotUser.val().perUse : 0;
                                                            set(singleUserRef(data.driver + '/perUse'), oldVipLimit + 1);
                                                        }
                                                    }, {onlyOnce: true});
                                                }
                                            }
                                        }*/

                                        dispatch(cancelBooking({
                                            booking: data,
                                            reason: reason ? reason : "Không xác định",
                                            reason_type: "system",
                                            cancel_by: 'system'
                                            //has_remove_eto: hasRemoveEto,
                                            //has_recover_limit: hasRecoverLimit,
                                            //has_recover_eto: hasRecoverETO,
                                            //content_recover: hasRemoveEto || hasRecoverLimit || hasRecoverETO ? "Khách hàng đã huỷ chuyến đi trạng thái ETO đã được khôi phục" : null
                                        }));

                                    }, 100);

                                }, {onlyOnce: true});
                            } else {
                                setTimeout(() => {
                                    let dataR = {
                                        status: "cancel",
                                        reason: reason ? reason : "Không xác định",
                                        cancel_by: 'system',
                                        msnv: auth.uid
                                    }
                                    dispatch(updateCallCancelById(props.firebase_id, dataR));
                                }, 100);
                            }

                            dispatch({
                                type: CALL_DETAIL_DATA, payload: null,
                            });
                            dispatch(api.stopFetchCallActive(props.firebase_id));
                            setTimeout(props.closeCallback, 200);
                        }
                        }
                    >
                        {props.booking_id ? "Xác nhận" : "Không đặt"}
                    </Button>
                </Grid>

                <Grid item xs={3} sm={3} md={3} lg={3}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="red"
                        size="small"
                        id={"cancelbutton_" + props.uniqueid}
                        className={styles.submit}
                        onClick={props.closeCallback}
                    >
                        {"Bỏ qua"}
                    </Button>
                </Grid>
            </Grid>
        </FormControl>
    )
        ;
}
