import store from './store/store';
import {FirebaseContext, FirebaseProvider} from './config/configureFirebase';
import * as authactions from './actions/authactions';
import * as bookingactions from './actions/bookingactions';
import * as bookinglistactions from './actions/bookinglistactions';
import * as cancelreasonactions from './actions/cancelreasonactions';
import * as cartypeactions from './actions/cartypeactions';
import * as areasactions from './actions/areasactions';
import * as notifyimportanceaction from './actions/notifyimportanceaction';
import * as votesaction from './actions/votesaction';
import * as tasksrunneraction from './actions/tasksrunneraction';
import * as catrucdemactions from './actions/catrucdemactions';
import * as catrucdemconfigactions from './actions/catrucdemconfigactions';
import * as reportsactions from './actions/reportsactions';
import * as groupdriveractions from './actions/groupdriveractions';
import * as groupcoordactions from './actions/groupcoordactions';
import * as reportdriveractions from './actions/reportdriveractions';
import * as giaoxeactions from './actions/giaoxeactions';
import * as estimateactions from './actions/estimateactions';
import * as driverearningaction from './actions/driverearningaction';
import * as earningreportsaction from './actions/earningreportsaction';
import * as walletmanageaction from './actions/walletmanageaction';
import * as notificationactions from './actions/notificationactions';
import * as mistakeaction from './actions/mistakeaction';
import * as mistakeconfigaction from './actions/mistakeconfigaction';
import * as promoactions from './actions/promoactions';
import * as promolocationactions from './actions/promolocationactions';
import * as forcenotifyaction from './actions/forcenotifyaction';
import * as newsaction from './actions/newsaction';
import * as usersactions from './actions/usersactions';
import * as settingsactions from './actions/settingsactions';
import * as paymentactions from './actions/paymentactions';
import * as tripactions from './actions/tripactions';
import * as taskactions from './actions/taskactions';
import * as pacallcenteractions from './actions/pacallcenteractions';
import * as locationactions from './actions/locationactions';
import * as chatactions from './actions/chatactions';
import * as withdrawactions from './actions/withdrawactions';
import * as transactionreportsaction from "./actions/transactionreportsaction";
import * as taxiteamsaction from "./actions/taxiteamsaction";
import * as pricesaction from "./actions/pricesaction";
import * as driverfaviouriteactions from "./actions/driverfaviouriteactions";
import * as pointsactions from "./actions/pointsactions";
import * as tradesactions from "./actions/tradesactions";
import * as marketsactions from "./actions/marketsactions";
import * as walletsactions from "./actions/walletsactions";
import * as friendsactions from './actions/friendsactions';
import * as notelistaction from './actions/notelistaction';
import * as rolepermissionaction from "./actions/rolepermissionaction";
import * as bookingtrackingaction from "./actions/bookingtrackingaction";
import * as drivergroupaction from "./actions/drivergroupaction";
import * as triggeraction from "./actions/triggeraction";
import * as notemapaddressaction from "./actions/notemapaddressaction";
import * as bookingnotiactions from "./actions/bookingnotiactions";

const api = {
    ...authactions,
    ...bookingactions,
    ...bookinglistactions,
    ...cancelreasonactions,
    ...cartypeactions,
    ...areasactions,
    ...notifyimportanceaction,
    ...votesaction,
    ...tasksrunneraction,
    ...catrucdemactions,
    ...catrucdemconfigactions,
    ...reportsactions,
    ...groupdriveractions,
    ...groupcoordactions,
    ...reportdriveractions,
    ...giaoxeactions,
    ...estimateactions,
    ...driverearningaction,
    ...earningreportsaction,
    ...walletmanageaction,
    ...notificationactions,
    ...mistakeaction,
    ...mistakeconfigaction,
    ...promoactions,
    ...promolocationactions,
    ...forcenotifyaction,
    ...newsaction,
    ...usersactions,
    ...settingsactions,
    ...paymentactions,
    ...tripactions,
    ...taskactions,
    ...pacallcenteractions,
    ...locationactions,
    ...chatactions,
    ...withdrawactions,
    ...transactionreportsaction,
    ...taxiteamsaction,
    ...pricesaction,
    ...driverfaviouriteactions,
    ...pointsactions,
    ...tradesactions,
    ...marketsactions,
    ...walletsactions,
    ...friendsactions,
    ...notelistaction,
    ...rolepermissionaction,
    ...bookingtrackingaction,
    ...drivergroupaction,
    ...triggeraction,
    ...notemapaddressaction,
    ...bookingnotiactions
};
export {
    FirebaseContext,
    FirebaseProvider,
    store,
    api
}