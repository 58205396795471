import {FETCH_DRIVER_GROUP_ONCE, FETCH_DRIVER_GROUP_ONCE_FAILED, FETCH_DRIVER_GROUP_ONCE_SUCCESS} from "../store/types";
import {firebase} from '../config/configureFirebase';
import {onValue} from "firebase/database";

export const fetchDriverGroupOnce = () => (dispatch) => {
    const {
        listAreaRef,
    } = firebase;

    dispatch({
        type: FETCH_DRIVER_GROUP_ONCE,
        payload: null,
    });

    onValue(listAreaRef(), (snapshot) => {
        if (snapshot.val()) {
            let data = snapshot.val();
            const arr = Object.keys(data)
                .map(i => {
                    data[i].id = i;
                    return data[i];
                });
            dispatch({
                type: FETCH_DRIVER_GROUP_ONCE_SUCCESS,
                payload: {
                    driver_groups: arr
                }
            });
        } else {
            dispatch({
                type: FETCH_DRIVER_GROUP_ONCE_FAILED,
                payload: "Chưa có vùng kinh doanh",
            });
        }
    }, {onlyOnce: true});
};

export const fetchDriverGroupJsonOnce = () => (dispatch) => {

    dispatch({
        type: FETCH_DRIVER_GROUP_ONCE,
        payload: null,
    });

};

