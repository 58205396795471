export const MainConfig = {
    AppDetails: {
        app_name: "ETO",
        app_description: "ETO - Ứng dụng gọi xe chuyên nghiệp",
        app_identifier: "com.etoviet.taxi",
        ios_app_version: "4.0.5",
        android_app_version: 405
    },
    chatgpt: {
        api_key: "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",
        base_url: "https://api.openai.com/v1",
        org_key: "org-L1iUmM7J0YQbsadMX4jupoo7",
        post_method: {
            chat_completions: "https://api.openai.com/v1/chat/completions"
        }

    },
    domain: "https://eto.net.vn",
    PA_API_CALLNOW: {
        api_key: "4c45e7655ec7fcb3389ec2a9cb9fbe92",
        url: "https://crm.pavietnam.vn/api/callNow.php"
    },
    VIETQR: {
        clientID: "05b64521-a8aa-4f7c-83fc-869e7dad2230",
        apiKey: "bfebe43d-0562-4a05-adc1-f316fd31f88a"
    },
    zaloConfig: {
        login_callback: "login/zalo",
        app_id: "281230242200426903",
        oa_id: "3668069540900918550",
        app_secret: "9kB55DBmGUBv4BQ48TDo",
        sdk_version: "v4",
        sdk_url: "https://oauth.zaloapp.com",
        icon: "https://page.widget.zalo.me/static/images/2.0/Logo.svg"
    },
    FirebaseConfig: {
        apiKey: "AIzaSyA_0wK28YfcjFCOL46tzLKYBV7tu8mSOMU",
        authDomain: "eto-viet.firebaseapp.com",
        databaseURL: "https://eto-viet-default-rtdb.firebaseio.com",
        projectId: "eto-viet",
        storageBucket: "eto-viet.appspot.com",
        messagingSenderId: "807990849271",
        appId: "1:807990849271:web:ec9cb54126406bd17eadf2",
        measurementId: "G-MJTP1HBQNT",
        WebPushCertificate: "BFoVUZ2klp0gfGEyk1UXKmaAfBSos7xi8u2az_a0u_PIvSVHSBcv2x6Vy9XONjgZv3dsn4uETDburKBpMXVcZFw"
    },
    Google_Map_Key: "AIzaSyA5_hh_WTj2tBst1nml6CVs-8FcM_b_KG0",
    Google_Map_Key_Web: "AIzaSyA_0wK28YfcjFCOL46tzLKYBV7tu8mSOMU",
    iosClientId: "314152728806-kt5410q75ue7d3fnhnefrk1hqc9inko5.apps.googleusercontent.com",
    androidClientId: "314152728806-cmh728bv04jdad8dag7n1mko4g0oc4gg.apps.googleusercontent.com",
    iosStandaloneAppClientId: "1:314152728806:ios:efffc664413951b0e16327",
    androidStandaloneAppClientId: "1:656348424055:android:097ef1b4f77a33e7582372",
    PurchaseDetails: {
        CodeCanyon_Purchase_Code: "71940451-60a5-4743-ab02-52c118f28b70",
        Buyer_Email_Address: "tkvclub01@hotmail.com"
    },
    CATCHA_SITE_KEY: "6Le3O0QbAAAAAK2a2lCRcfvsZdN-ZBE19NTJLeV8",
    CATCHA_SECRET_KEY: "6Le3O0QbAAAAAGJsnVJ3wt3iky6v-e7iJa1NeBbG",
    FIREBASE_APPCHECK_DEBUG_TOKEN: "E393C352-9C08-495D-9BBA-B2C31CC8C892",
    ANDROID_DEBUG_TOKEN: "5F155D37-5988-450D-87EA-B987728BF3F0",
    Twilio: {
        TWILIO_AUTH_TOKEN: "0fea99c047ce8d90d2113830b2d26357",
        TWILIO_AUTH_SID: "AC3da16921dae0384caf45e7c13e10606a"
    },
    expo_owner: "thanhtanico",
    expo_slug: "eto",
    expo_project_id: "23207ae1-5d73-45f1-b2c8-d7e2503e1b92"
}