import {FETCH_BOOKING__DISCOUNT_FAILED, FETCH_BOOKING__DISCOUNT_SUCCESS, FETCH_BOOKING_DISCOUNT,} from "../store/types";
import {firebase} from '../config/configureFirebase';
import {off, onValue} from "firebase/database";

export const fetchEarningsReport = () => (dispatch) => {

    const {
        bookingRef
    } = firebase;

    dispatch({
        type: FETCH_BOOKING_DISCOUNT,
        payload: null
    });
    off(bookingRef);
    onValue(bookingRef, snapshot => {
        if (snapshot.val()) {
            const mainArr = snapshot.val();

            var monthsName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            var renderobj = {};
            Object.keys(mainArr).map(j => {
                if (mainArr[j].usertype != 'admin') {

                }
                return null;
            });
            if (renderobj) {
                const arr = Object.keys(renderobj).map(i => {
                    renderobj[i].myEarning = parseFloat(renderobj[i].customerPaid - renderobj[i].driverShare).toFixed(0);
                    renderobj[i].customerPaid = parseFloat(renderobj[i].customerPaid).toFixed(0);
                    renderobj[i].rideCost = parseFloat(renderobj[i].tripCost - renderobj[i].convenienceFee).toFixed(0);
                    renderobj[i].driverShare = parseFloat(renderobj[i].driverShare).toFixed(0);
                    return renderobj[i];
                })
                dispatch({
                    type: FETCH_BOOKING__DISCOUNT_SUCCESS,
                    payload: arr
                });
            }

        } else {
            dispatch({
                type: FETCH_BOOKING__DISCOUNT_FAILED,
                payload: "No data available."
            });
        }
    });
};
