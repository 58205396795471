import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {AppBar, CssBaseline, Drawer, Hidden, IconButton, Toolbar} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AppMenu from "../views/AppMenu";
import NearMeIcon from '@mui/icons-material/NearMe';
import DirectionsIcon from '@mui/icons-material/Directions';
import EditNoteIcon from '@mui/icons-material/EditNote';
import HistoryIcon from '@mui/icons-material/History';
import CallIcon from '@mui/icons-material/Call';
import {colors} from "../config/theme";
import {View} from "react-native-web";
import LocalTaxiOutlinedIcon from "@mui/icons-material/LocalTaxiOutlined";
import {useDispatch, useSelector} from "react-redux";
import {CALL_DETAIL_DATA, CHANGE_MAP_TYPE, CHANGE_TAB_INDEX, CHANGE_TAB_INDEX_RIGHT, FETCH_BOOKING_BY_ID_SUCCESS} from "common/src/store/types";
import {ListItemIcon, ListItemText, MenuItem, Select} from "@mui/material";
import {useNavigate} from 'react-router-dom';
import {api, store} from "common";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {},
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        /*marginLeft: drawerWidth,
        [theme.breakpoints.up('sm')]: {
          width: `calc(100% - ${drawerWidth}px)`,
        },*/
        left: 0
    },
    menuButton: {
        marginRight: theme.spacing(1),
        /*[theme.breakpoints.up('sm')]: {
          display: 'none',
        },*/
        display: 'block'
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        marginTop: 63,
    },
    content: {
        /*flexGrow: 1,
        padding: theme.spacing(1),*/
    },
}));

function ResponsiveDrawer(props) {
    const {container} = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);
    const menuIndexLeft = useSelector(state => state.sessiondata.tabIndex);
    const menuIndexRight = useSelector(state => state.sessiondata.tabIndexRight);
    const navigate = useNavigate();

    function handleDrawerToggle() {
        setMobileOpen(!mobileOpen);
    }

    function handleChangeMenuTopLeft(value) {
        dispatch({
            type: CHANGE_TAB_INDEX, payload: value,
        });
    }

    function handleChangeMenuTopRight(value) {
        dispatch({
            type: CHANGE_TAB_INDEX_RIGHT, payload: value,
        });
    }

    return (
        <div className={classes.root}>
            <CssBaseline/>
            {<AppBar position="fixed" className={classes.appBar}>
                <Toolbar style={{
                    backgroundColor: colors.WHITE,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignCenter: 'center',
                    width: '100%'
                }}>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        alignCenter: 'center'
                    }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon color={"action"}/>
                        </IconButton>

                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={() => {
                                handleChangeMenuTopLeft(0);
                                navigate('/dashboard')
                            }
                            }
                            className={classes.menuButton}
                        >
                            <AccountCircleIcon color={"action"} fontSize={menuIndexLeft === 0 ? "large" : "medium"}/>
                        </IconButton>


                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={() => {
                                handleChangeMenuTopLeft(1)
                            }}
                            className={classes.menuButton}
                        >
                            <NearMeIcon color={"action"} fontSize={menuIndexLeft === 1 ? "large" : "medium"}/>
                        </IconButton>

                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={() => {
                                if (menuIndexLeft == 4) {
                                    handleChangeMenuTopLeft(0);
                                } else {
                                    handleChangeMenuTopLeft(2);
                                }

                            }}
                            className={classes.menuButton}
                        >
                            <Select
                                defaultValue={'roadmap'}
                                onChange={(event) => {
                                    dispatch({
                                        type: CHANGE_MAP_TYPE, payload: event.target.value,
                                    });
                                }
                                }
                                displayEmpty
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',  // Remove the border
                                        padding: 0
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',  // Remove border on hover as well
                                        padding: 0
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',  // Remove border when focused
                                        padding: 0
                                    },
                                }}
                            >
                                <MenuItem value="hybrid" sx={{
                                    "& fieldset": {
                                        border: "none",
                                        padding: 0
                                    },
                                }}
                                >
                                    {/*<ListItemIcon>
                                        <MapIcon color={"action"}/>
                                    </ListItemIcon>
                                    <ListItemText primary="Vệ tinh"/>*/}
                                    <img src={require("../assets/img/hybrid.png")} alt="Vệ tinh" style={{width: 30}}/>
                                </MenuItem>
                                <MenuItem value="roadmap" selected={true} sx={{
                                    "& fieldset": {
                                        border: "none",
                                        padding: 0
                                    },
                                }}>
                                    <img src={require("../assets/img/roadmap.png")} alt="Tiêu chuẩn" style={{width: 30}}/>
                                </MenuItem>
                            </Select>
                        </IconButton>

                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={() => {
                                handleChangeMenuTopLeft(3);
                                navigate('/note_map_address');
                            }}
                            className={classes.menuButton}
                        >
                            <EditNoteIcon color={"action"} fontSize={menuIndexLeft === 3 ? "large" : "medium"}/>
                        </IconButton>


                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={() => {
                                const callCenter = store.getState().pacallcenterdata.callCenter;
                                const bookingActive = store.getState().bookinglistdata.booking_active;
                                dispatch(api.resetTripData(callCenter && callCenter.id ? callCenter.id : null, bookingActive && bookingActive.id ? bookingActive.id : null));
                                navigate('/dashboard');
                                if (menuIndexLeft == 4) {
                                    handleChangeMenuTopLeft(0);
                                } else {
                                    handleChangeMenuTopLeft(4);
                                }

                            }}
                            className={classes.menuButton}
                        >
                            <DirectionsIcon color={"action"} fontSize={menuIndexLeft === 4 ? "large" : "medium"}/>
                        </IconButton>

                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={() => {
                                handleChangeMenuTopLeft(5)
                            }}
                            className={classes.menuButton}
                        >
                            <HistoryIcon color={"action"} fontSize={menuIndexLeft === 5 ? "large" : "medium"}/>
                        </IconButton>
                    </View>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        alignCenter: 'center'
                    }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={() => {
                                handleChangeMenuTopRight(0)
                            }
                            }
                            className={classes.menuButton}
                        >
                            <LocalTaxiOutlinedIcon color="action" fontSize={menuIndexRight === 0 ? "large" : "medium"}/>
                        </IconButton>

                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={() => {
                                handleChangeMenuTopRight(1)
                            }
                            }
                            className={classes.menuButton}
                        >
                            <CallIcon color={"action"} fontSize={menuIndexRight === 1 ? "large" : "medium"}/>
                        </IconButton>
                    </View>

                </Toolbar>
            </AppBar>}
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <AppMenu/>
                    </Drawer>
                </Hidden>
            </nav>
            <main>
                <div/>
                {props.children}
            </main>
        </div>
    );
}

ResponsiveDrawer.propTypes = {
    container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};

export default ResponsiveDrawer;
