import {
    CALL_DETAIL_DATA,
    CHANGE_MAP_TYPE,
    CHANGE_TAB_INDEX,
    CHANGE_TAB_INDEX_RIGHT,
    HAS_BOOKING_ACCEPTED,
    LIST_USER_LOST_CONNECT,
    UPDATE_CONNECT_INFO,
    UPDATE_INACTIVE_STATE,
    UPDATE_SESSION_BOOKING_ID,
    UPDATE_SESSION_DATA_METTER,
    UPDATE_SESSION_FIRST_VIEW,
    UPDATE_SESSION_HAS_BOOKING,
    UPDATE_SESSION_HAS_BOOKING_ACTIVE,
    UPDATE_SESSION_HAS_FIX_BG,
    UPDATE_SESSION_HAS_LOGOUT,
    UPDATE_SESSION_HAS_NOTIFY, UPDATE_SESSION_HAS_NOTIFY_LOCATION,
    UPDATE_SESSION_HAS_UPDATE,
    UPDATE_SESSION_HAS_UPDATE_CURRENT_AREA,
    UPDATE_SESSION_HAS_UPDATE_GROUP,
    UPDATE_SESSION_HAS_UPDATE_TIME_ACTIVE,
    UPDATE_SESSION_HAS_UPDATE_TIME_OUT,
    UPDATE_SESSION_IS_FIRST_LOAD,
    UPDATE_SESSION_NOTIFY,
    UPDATE_SESSION_PLAY_BOOKING,
    UPDATE_SESSION_SOUND_BATDONGHO,
    UPDATE_SESSION_SOUND_BOOKING,
    UPDATE_SESSION_SOUND_BOOKING_FIRST,
    UPDATE_SESSION_SOUND_BUTTON_CLICK,
    UPDATE_SESSION_SOUND_CLOSE,
    UPDATE_SESSION_SOUND_LOICHUC,
    UPDATE_SESSION_SOUND_OPEN,
    UPDATE_SESSION_SOUND_WARNING_CUSTOMER,
    UPDATE_SESSION_START_BOOKING_OFF,
    UPDATE_SESSION_START_NATVIGATION,
    UPDATE_SESSION_TEAM_SELECTED,
    UPDATE_SESSION_TOTAL_LOAD,
    UPDATE_ZOOM_MAP,
} from "../store/types";
import {getRandomInt} from "../other/CommonFunctions";

const INITIAL_STATE = {
    session: null,
    notify_group_empty: null,
    hasPlaySoundBooking: false,
    teamSelected: null,
    soudButtonClick: null,
    soundOpen: null,
    soundClose: null,
    soundBatDongHo: null,
    soundBooking: null,
    soundBookingFirst: null,
    soundLoiChuc: null,
    soundWarningCustomer: null,
    hasUpdate: false,
    hasNotify: false,
    hasBooking: false,
    hasFixBg: false,
    hasUpdateGroup: null,
    hasUpdateTimeOut: null,
    hasUpdateTimeActive: null,
    hasBookingActive: null,
    hasUpdateCurrenArea: null,
    bookingNewId: null,
    isFistView: true,
    dataMetter: null,
    isFirstLoad: false,
    hasBookingAccepted: false,
    hasLogout: false,
    totalLoadData: 0,
    networkInfo: null,
    tabIndex: 0,
    inActiveState: null,
    tabIndexRight: 0,
    callDetail: null,
    mapType: 'roadmap',
    webMapZoom: 13,
    listConnect: [],
    hasNotifyLocation: false
}
export const sessionreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LIST_USER_LOST_CONNECT:
            return {
                ...state,
                listConnect: action.payload
            };
        case UPDATE_ZOOM_MAP:
            let zoomRd = action.payload.toString() + "." + getRandomInt(2, 9);
            return {
                ...state,
                webMapZoom: parseFloat(zoomRd)
            };
        case CALL_DETAIL_DATA:
            return {
                ...state,
                callDetail: action.payload
            };
        case CHANGE_MAP_TYPE:
            return {
                ...state,
                mapType: action.payload
            };
        case UPDATE_INACTIVE_STATE:
            return {
                ...state,
                inActiveState: action.payload
            };
        case CHANGE_TAB_INDEX:
            return {
                ...state,
                tabIndex: action.payload
            };
        case CHANGE_TAB_INDEX_RIGHT:
            return {
                ...state,
                tabIndexRight: action.payload
            };
        case UPDATE_CONNECT_INFO:
            return {
                ...state,
                networkInfo: action.payload
            };
        case UPDATE_SESSION_TOTAL_LOAD:
            return {
                ...state,
                totalLoadData: action.payload
            };
        case UPDATE_SESSION_BOOKING_ID:
            return {
                ...state,
                bookingNewId: action.payload
            };
        case UPDATE_SESSION_TEAM_SELECTED:
            return {
                ...state,
                teamSelected: action.payload.teamSelected
            };
        case UPDATE_SESSION_SOUND_BUTTON_CLICK:
            return {
                ...state,
                soudButtonClick: action.payload.soudButtonClick
            };
        case UPDATE_SESSION_SOUND_OPEN:
            return {
                ...state,
                soundOpen: action.payload.soundOpen
            };
        case UPDATE_SESSION_SOUND_CLOSE:
            return {
                ...state,
                soundClose: action.payload.soundClose
            };
        case UPDATE_SESSION_SOUND_BATDONGHO:
            return {
                ...state,
                soundBatDongHo: action.payload.soundBatDongHo
            };
        case UPDATE_SESSION_SOUND_BOOKING:
            return {
                ...state,
                soundBooking: action.payload.soundBooking
            };
        case UPDATE_SESSION_SOUND_BOOKING_FIRST:
            return {
                ...state,
                soundBookingFirst: action.payload.soundBookingFirst
            };
        case UPDATE_SESSION_SOUND_LOICHUC:
            return {
                ...state,
                soundLoiChuc: action.payload.soundLoiChuc
            };
        case UPDATE_SESSION_SOUND_WARNING_CUSTOMER:
            return {
                ...state,
                soundWarningCustomer: action.payload.soundWarningCustomer
            };
        case UPDATE_SESSION_NOTIFY:
            return {
                ...state,
                notify_group_empty: action.payload
            };
        case UPDATE_SESSION_PLAY_BOOKING:
            return {
                ...state,
                hasPlaySoundBooking: action.payload
            };
        case UPDATE_SESSION_HAS_UPDATE:
            return {
                ...state,
                hasUpdate: action.payload
            };
        case UPDATE_SESSION_HAS_BOOKING:
            return {
                ...state,
                hasBooking: action.payload
            };

        case UPDATE_SESSION_HAS_NOTIFY:
            return {
                ...state,
                hasNotify: action.payload
            };
        case UPDATE_SESSION_HAS_NOTIFY_LOCATION:
            return {
                ...state,
                hasNotifyLocation: action.payload
            };
        case UPDATE_SESSION_HAS_LOGOUT:
            return {
                ...state,
                hasLogout: action.payload
            };
        case UPDATE_SESSION_HAS_FIX_BG:
            return {
                ...state,
                hasFixBg: action.payload
            };

        case UPDATE_SESSION_HAS_UPDATE_GROUP:
            return {
                ...state,
                hasUpdateGroup: action.payload
            };
        case UPDATE_SESSION_HAS_UPDATE_TIME_OUT:
            return {
                ...state,
                hasUpdateTimeOut: action.payload
            };
        case UPDATE_SESSION_HAS_UPDATE_TIME_ACTIVE:
            return {
                ...state,
                hasUpdateTimeActive: action.payload
            };
        case UPDATE_SESSION_HAS_UPDATE_CURRENT_AREA:
            return {
                ...state,
                hasUpdateCurrenArea: action.payload
            };
        case UPDATE_SESSION_HAS_BOOKING_ACTIVE:
            return {
                ...state,
                hasBookingActive: action.payload
            };
        case UPDATE_SESSION_FIRST_VIEW:
            return {
                ...state,
                isFistView: action.payload
            };
        case UPDATE_SESSION_DATA_METTER:
            return {
                ...state,
                dataMetter: action.payload
            };

        case UPDATE_SESSION_START_NATVIGATION:
            return {
                ...state,
                isStart: action.payload
            };

        case UPDATE_SESSION_IS_FIRST_LOAD:
            return {
                ...state,
                isFirstLoad: action.payload
            };

        case UPDATE_SESSION_START_BOOKING_OFF:
            return {
                ...state,
                isStartBookingOff: action.payload
            };
        case HAS_BOOKING_ACCEPTED:
            return {
                ...state,
                hasBookingAccepted: action.payload
            };
        default:
            return state;
    }
};