import {EDIT_NOTIFICATIONS, FETCH_NOTIFICATIONS, FETCH_NOTIFICATIONS_FAILED, FETCH_NOTIFICATIONS_SUCCESS, SEND_NOTIFICATION, SEND_NOTIFICATION_FAILED, SEND_NOTIFICATION_SUCCESS, STOP_FETCH_NOTIFICATIONS, UPDATE_SESSION_TOTAL_LOAD,} from "../store/types";

import {language} from 'config';
import store from "../store/store";
import {RequestPushMsg} from '../other/NotificationFunctions';
import {firebase} from '../config/configureFirebase';
import {off, onValue, push, remove, set} from "firebase/database";

export const fetchNotifications = () => (dispatch) => {

    const {
        notifyRef
    } = firebase;

    dispatch({
        type: FETCH_NOTIFICATIONS,
        payload: null
    });
    off(notifyRef);
    onValue(notifyRef, snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();

            const arr = Object.keys(data).map(i => {
                data[i].id = i
                return data[i]
            });

            dispatch({
                type: FETCH_NOTIFICATIONS_SUCCESS,
                payload: arr
            });

            const totalLoad = store.getState().sessiondata.totalLoadData ? parseInt(store.getState().sessiondata.totalLoadData) : 0;
            dispatch({
                type: UPDATE_SESSION_TOTAL_LOAD, payload: totalLoad + 1
            });
        } else {
            dispatch({
                type: FETCH_NOTIFICATIONS_FAILED,
                payload: "No data available."
            });
        }
    });
};

export const stopFetchNotifications = () => (dispatch) => {

    const {
        notifyRef
    } = firebase;

    dispatch({
        type: STOP_FETCH_NOTIFICATIONS,
        payload: null
    });
    off(notifyRef);
};

export const editNotifications = (notifications, method) => (dispatch) => {

    const {
        notifyRef,
        notifyEditRef
    } = firebase;

    dispatch({
        type: EDIT_NOTIFICATIONS,
        payload: {method, notifications}
    });
    if (method === 'Add') {
        push(notifyRef, notifications);
    } else if (method === 'Delete') {
        remove(notifyEditRef(notifications.id));
    } else {
        set(notifyEditRef(notifications.id), notifications);
    }
}

export const sendNotification = (notification) => (dispatch) => {

    dispatch({
        type: SEND_NOTIFICATION,
        payload: notification
    });

    let users = store.getState().usersdata.users;
    let arr = [];
    for (let i = 0; i < users.length; i++) {
        let usr = users[i];
        let obj = {
            "to": null,
            "title": notification.title,
            "msg": notification.body,
        };
        if (notification.usertype === 'All' && notification.devicetype === 'All') {
            if (usr.pushToken) {
                obj.to = usr.pushToken;
                arr.push(obj);
            }
        } else if (notification.usertype === 'All' && notification.devicetype !== 'All') {
            if (usr.pushToken && usr.userPlatform === notification.devicetype) {
                obj.to = usr.pushToken;
                arr.push(obj);
            }
        } else if (notification.usertype !== 'All' && notification.devicetype === 'All') {
            if (usr.pushToken && usr.usertype === notification.usertype) {
                obj.to = usr.pushToken;
                arr.push(obj);
            }
        } else {
            if (usr.pushToken && usr.usertype === notification.usertype && usr.userPlatform === notification.devicetype) {
                obj.to = usr.pushToken;
                arr.push(obj);
            }
        }
    }

    if (arr.length > 0) {
        for (let x = 0; x < arr.length; x++) {
            RequestPushMsg(arr[x].to, arr[x].title, arr[x].msg)
        }
        dispatch({
            type: SEND_NOTIFICATION_SUCCESS,
            payload: arr
        });
    } else {
        dispatch({
            type: SEND_NOTIFICATION_FAILED,
            payload: language.no_user_match,
        });
    }
}

